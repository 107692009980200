import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./UiSlice";
import authSlice from "./AuthSlice";
import { AuthState, FlowboosterState, UiState } from "../models/State/States";
import flowboosterSlice from "./FlowboosterSlice";
import roleSlice from "./RoleSlice";
import accountsSlice from "./AccountSlice";
import usersSlice from "./UsersSlice";
import salarySlice from "./SalarySlice";
import tarificationSlice from "./TarificationSlice";
import extraCostSlice from "./ExtraCostSlice";
import clientsSlice from "./ClientsSlice";
import serverSlice from "./ServersSlice";

const store = configureStore({
	reducer: {
		ui: uiSlice.reducer,
		auth: authSlice.reducer,
		flowbooster: flowboosterSlice.reducer,
		roles: roleSlice.reducer,
		accounts: accountsSlice.reducer,
		users: usersSlice.reducer,
		salary: salarySlice.reducer,
		tarification: tarificationSlice.reducer,
		extraCosts: extraCostSlice.reducer,
		clients: clientsSlice.reducer,
		servers: serverSlice.reducer,
	},
});

export const authState: AuthState = store.getState().auth;
export const uiState: UiState = store.getState().ui;
export const flowboosterState: FlowboosterState = store.getState().flowbooster;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

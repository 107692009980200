import AccountApiModel from "../../models/Api/Accounts/AccountsApiModel";
import UserAccountApiModel from "../../models/Api/Accounts/UserAccountApiModel";
import CustomersApiModel from "../../models/Api/Customers/Models";
import UserApiModel from "../../models/Api/Users/UserApiModel";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { clientsSliceActions } from "../ClientsSlice";
import uiSlice, { uiSliceActions } from "../UiSlice";
import { executeGet } from "./AsyncActions";

export const loadClientList = () => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));

		await dispatch(
			executeGet(EndpointUrls.Clients, (apiData) => {
				dispatch(clientsSliceActions.setClients(apiData));
			})
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

export const loadClient = (clientId: string) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));

		await dispatch(
			executeGet(
				EndpointUrls.Clients,
				(apiData) => {
					console.log(apiData, "customer");
					dispatch(clientsSliceActions.setApiDataToActiveClient(apiData));
				},
				[{ key: "id", value: clientId }]
			)
		);
	};
};

export const loadClientProfile = (clientId: string) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));
		await dispatch(
			executeGet(
				EndpointUrls.Clients,
				(apiData) => {
					dispatch(clientsSliceActions.setCurrentClientProfile(apiData));
				},
				[{ key: "userId", value: clientId }]
			)
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

export const loadAssociatedUsers = (clientId: string) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));
		await dispatch(
			executeGet(
				EndpointUrls.UserClientRelation,
				(apiData: UserApiModel[]) => {
					dispatch(clientsSliceActions.setAssociatedUsersList(apiData));
				},
				[{ key: "clientId", value: clientId }]
			)
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

export const loadAssociatedAccounts = (clientId: string) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));
		await dispatch(
			executeGet(
				EndpointUrls.ClientAccountRelation,
				(apiData: UserAccountApiModel[]) => {
					dispatch(clientsSliceActions.setAssociatedAccountList(apiData));
				},
				[{ key: "clientId", value: clientId }]
			)
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

export enum UserProfileDispatchAction {
  Load = "LOAD_DATA",
  Username = "USERNAME",
  FirstName = "FIRST_NAME",
  LastName = "LAST_NAME",
  DateOfBirth = "DOB",
  Address = "ADDRESS",
  PhoneNumber = "PHONE_NUMBER",
  Email = "EMAIL",
  BankAccountNumber = "BANKACCOUNT_NUMBER",
  BankAccountHolder = "BANKACCOUNT_HOLDER",
}

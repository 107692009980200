import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mapApiResponseToMessageModel } from "../../../Helpers/MessageHelpers";
import { useAppSelector } from "../../../hooks/StateHooks";
import useMessageApi from "../../../hooks/UseMessageApi";
import { Message } from "../../../models/Api/Messages/Models";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { FlowboosterSendMessageRequest } from "../../../models/Flowbooster/Models";
import {
  checkMessagesLimit,
  startFlowboosterSession,
} from "../../../store/Actions/FlowBoosterActions";
import { BasicTextArea } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import ErrorNotification from "../../ui/ErrorNotification/ErrorNotification";
import DuplicateMessageListBox from "../DuplicateMessageListBox/DuplicateMessageListBox";
import EmoticonBar from "./EmoticonBar";

import "./_sendMessageForm.scss";

const SendMessageForm: React.FC<{
  platform: string;
  userId: string;
  sendMessageRequest: FlowboosterSendMessageRequest;
  ipAddress: string;
  imageId?: string;
  conversationStartDate?: string;
  onMessageSent: () => void;
  onRemoveMediaButtonClicked: () => void;
  onAddMediaButtonClicked: () => void;
  isMediaButtonAvailable?: boolean;
  isMediaListExhausted?: boolean;
}> = (props) => {
  const [messageContent, setMessageContent] = useState<string>("");
  const { isLoading, sendMessage, saveMessage } = useMessageApi();
  const currentUserInformation = useAppSelector(
    (state) => state.users.currentUser?.profile
  );
  const currentAccountInformation = useAppSelector(
    (state) => state.users.currentUser?.accounts
  );
  const errorState = useAppSelector((state) => state.ui.error);
  const user = useAppSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [isMessageBeingVerified, setIsMessageBeingVerified] =
    useState<boolean>(false);
  const [isMessageVerified, setIsMessageVerified] = useState<boolean>(false);
  const isMessageLimitReached = useAppSelector(
    (state) => state.flowbooster.isLimitReached
  );

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(EndpointUrls.SignalRApi)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);

    return () => {
      if (connection !== null) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    const doConnect = async () => {
      if (connection !== null) {
        if (connection.state !== HubConnectionState.Connected) {
          await connection.start();
        }

        if (connection.state === HubConnectionState.Connected) {
          connection.on("ProcessReviewedMessage", (message) => {
            if (message !== undefined && message !== null) {
              var mappedMessage = mapApiResponseToMessageModel(
                message,
                message.id
              );
              if (
                mappedMessage !== undefined &&
                mappedMessage.authorId === props.userId
              ) {
                setIsMessageBeingVerified(false);
                setIsMessageVerified(true);
                if (
                  mappedMessage.feedbackType !== undefined &&
                  mappedMessage.feedbackBody !== undefined &&
                  mappedMessage.feedbackType !== null &&
                  mappedMessage.feedbackBody !== null &&
                  mappedMessage.feedbackType.length !== 0 &&
                  mappedMessage.feedbackBody.length !== 0
                ) {
                  handleMessage(mappedMessage, mappedMessage.feedbackBody);
                } else {
                  handleMessage(mappedMessage, mappedMessage.messageBody);
                }
              }
            }
          });
        }
      }
    };
    doConnect();
  }, [connection]);

  useEffect(() => {
    if (isMessageLimitReached) {
      dispatch(
        startFlowboosterSession({
          ipAddress: props.ipAddress,
          isLogin: false,
          platform: props.platform,
          userId: props.userId,
        })
      );
    }
  }, [isMessageLimitReached]);
  const handleMessage = async (message: Message, messageBody?: string) => {
    if (
      message === undefined ||
      message === null ||
      messageBody!.trim().length === 0
    ) {
      return;
    }
    let sendMessageRequest = { ...props.sendMessageRequest };
    sendMessageRequest.message = messageBody!.trim();
    sendMessageRequest.subject = "";
    console.log(props.imageId, "image id");
    if (props.imageId !== undefined) {
      sendMessageRequest.mediaId = props.imageId;
    }

    await sendMessage(
      props.ipAddress,
      props.userId,
      props.platform,
      sendMessageRequest
    );

    await saveMessage(props.userId, message).then(() => {
      props.onMessageSent();
    });

    dispatch(checkMessagesLimit(props.userId));
  };

  const submitMessageFormHandler: (formEvent: FormEvent) => void = async (
    formEvent
  ) => {
    formEvent.preventDefault();

    let message: Message = {
      authorId: props.userId,
      messageBody: messageContent.trim(),
      sendDate: new Date(),
      authorName: `${currentUserInformation?.firstName}`,
      accountName: `${currentAccountInformation?.accountNumber}`,
      isFlagged: "false",
      accountId: currentAccountInformation?.id,
      weekNumber: 0,
      year: new Date().getFullYear(),
      isHandled: false,
    };

    if (errorState.isError) {
      props.onMessageSent();
    } else {
      if (
        user?.roles.name.toLowerCase() === "pro_operator" ||
        user?.roles.name.toLowerCase() === "admin"
      ) {
        handleMessage(message, messageContent.trim());
      } else {
        connection?.invoke("ReviewMessage", message);
        setIsMessageBeingVerified(true);
      }
    }
  };

  return (
    <div>
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <React.Fragment>
          {isMessageBeingVerified && (
            <Card theme={"orange"} isDashboardElement={false}>
              Your message is currently being reviewed.
            </Card>
          )}

          {isMessageVerified && (
            <Card theme={"orange"} isDashboardElement={false}>
              Your message is currently being reviewed.
            </Card>
          )}
          <span className="conversation-start-date">
            This conversation has been started on:{" "}
            <strong>{props.conversationStartDate}</strong>
          </span>
          <form
            onSubmit={submitMessageFormHandler}
            className="send-message-form"
          >
            <BasicTextArea
              onChange={(event) => {
                setMessageContent(event.currentTarget.value);
              }}
              value={messageContent}
              className="send-message-form__textinput"
            />
            <div>
              {(!props.isMediaButtonAvailable ||
                props.isMediaListExhausted) && (
                <ErrorNotification description="There are no pictures available." />
              )}
              {!props.isMediaListExhausted && props.isMediaButtonAvailable && (
                <div className="send-message-form__button-container">
                  <Button
                    theme={"info"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onAddMediaButtonClicked();
                    }}
                    tabIndex={1}
                  >
                    <span className="icon--filled">add_photo_alternate</span>
                    {/* <span>Add Media</span> */}
                  </Button>{" "}
                  <Button
                    theme={"info"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onRemoveMediaButtonClicked();
                    }}
                    tabIndex={2}
                  >
                    <span className="icon--filled">delete</span>
                    {/* <span>Remove Media</span> */}
                  </Button>
                </div>
              )}
            </div>
            {/* <EmoticonBar
							className="emoticon-container"
							onEmoticonChanged={onEmoticonChangedHandler}
						/> */}
            <div className="send-message-form__call-to-action">
              <div className="send-message-form__message-information">
                <span className="send-message-form__message-information__title">
                  Character Number:
                </span>
                <span className="message-information__charcount">
                  {messageContent.length}
                </span>
              </div>
              <Button
                tabIndex={0}
                theme={"warning"}
                className={`send-message-form__submit ${
                  messageContent.length >= 70 &&
                  messageContent.length <= 120 &&
                  !isMessageBeingVerified
                    ? ""
                    : "button--disabled"
                }`}
                disabled={
                  messageContent.length >= 70 &&
                  messageContent.length <= 120 &&
                  !isMessageBeingVerified
                    ? false
                    : true
                }
                type="submit"
              >
                <span className="icon--filled">send</span>
                <span>Send</span>
              </Button>
            </div>
          </form>
          <DuplicateMessageListBox
            userId={props.userId}
            message={messageContent}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default SendMessageForm;

import React, { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/StateHooks";
import { useParams, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { PlatformType } from "../models/Enums/Platform/PlaformEnum";
import MessagingInterface from "../components/Messages/MessagingInterface/MessagingInterface";
import ErrorNotification from "../components/ui/ErrorNotification/ErrorNotification";
import { loadUser } from "../store/Actions/UsersActions";
import { flowboosterSliceActions } from "../store/FlowboosterSlice";
import StatsEntryForm from "../components/Statistics/StatsEntryForm/StatsEntryForm";
import { checkMessagesLimit } from "../store/Actions/FlowBoosterActions";

const MessagesPage: React.FC<{}> = (props) => {
	const refreshKey = useAppSelector((s) => s.flowbooster.refreshKey);
	const { userId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useAppSelector((state) => state.users.currentUser);
	const isMessageLimitReached = useAppSelector(
		(state) => state.flowbooster.isLimitReached
	);

	if (userId == undefined) {
		navigate("/login");
	}
	useEffect(() => {
		if (userId !== undefined) {
			dispatch(loadUser(userId));
		}
	}, []);

	if (user?.accounts.accountType != undefined) {
		dispatch(flowboosterSliceActions.setPlatform(user.accounts.accountType));
		dispatch(checkMessagesLimit(user.userId));
	}

	if (isMessageLimitReached) {
		return (
			<ErrorNotification description="You've reach your daily limit of 200 messages. Come back again tomorrow." />
		);
	}

	return (
		<React.Fragment>
			{userId !== undefined &&
				userId !== null &&
				user !== undefined &&
				user.accounts !== undefined &&
				user.accounts.accountType !== undefined &&
				(user.accounts.accountType === PlatformType.cdc ||
					user.accounts.accountType === PlatformType.cdm) && (
					<MessagingInterface
						UserId={userId}
						Platform={user.accounts.accountType}
					/>
				)}
			{userId !== undefined &&
				userId !== null &&
				user !== undefined &&
				user.accounts !== undefined &&
				user.accounts.accountType !== undefined &&
				user.accounts.accountType === PlatformType.panel && (
					<ErrorNotification description="This feature is not available for your account." />
				)}
		</React.Fragment>
	);
};

export default MessagesPage;

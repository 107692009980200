import React, { FormEvent, useEffect, useReducer, useState } from "react";
import FormContainer from "../Forms/FormContainer";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { useDispatch } from "react-redux";
import { roleSliceActions } from "../../store/RoleSlice";
import { accountsSliceActions } from "../../store/AccountSlice";
import Button from "../ui/Button/Button";
import { uiSliceActions } from "../../store/UiSlice";
import {
  EditOperatorFormState,
  PayRangeInfo,
} from "../../models/Api/PayRange/PayRange";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";
import AccountApiModel from "../../models/Api/Accounts/AccountsApiModel";
import {
  FormElementGroup,
  FormHeader,
  FormInputContainer,
  FormRow,
} from "../Forms/FormElements/FormStructure";
import { BasicSelectInput } from "../Forms/FormInputs/BasicInputs";
import AccountTypeSelect from "../Accounts/AccountTypeSelect";
import MultipleTariffRangeInput from "../Tarification/MultipleTariffRangeInput";
import { mapApiResponseToPayRange } from "../../Helpers/PayRangeHelpers";
import { PayloadAction } from "@reduxjs/toolkit";
import { loadAccounts } from "../../store/Actions/AccountActions";
import LoadingAnimation from "../Loading/Loading";
import { loadUserList } from "../../store/Actions/UsersActions";
import "./_editOperatorForm.scss";

const EditOperatorFormActions: React.FC<{}> = (props) => {
  let dispatch = useDispatch();
  return (
    <React.Fragment>
      <Button
        type="reset"
        theme="info"
        fullWidth={false}
        onClick={(event) => {
          dispatch(uiSliceActions.showModal(false));
        }}
      >
        <span>Cancel</span>
      </Button>
      <Button type="submit" theme="primary" fullWidth={false}>
        <span>Save</span>
      </Button>
    </React.Fragment>
  );
};
const initialValue: EditOperatorFormState = {
  cdcTariffRange: [],
  panelDayTariffRange: [],
  panelNightTariffRange: [],
  correspondingAccountList: [],
  userRoleId: "",
  userAccountId: "",
};

const editOperatorReducer = (
  state: EditOperatorFormState,
  action: PayloadAction<any>
) => {
  switch (action.type) {
    case "CDC": {
      let newState = { ...state };
      newState.cdcTariffRange = [...action.payload];
      return newState;
    }

    case "PANEL_DAY": {
      let newState = { ...state };
      newState.panelDayTariffRange = [...action.payload];
      return newState;
    }
    case "PANEL_NIGHT": {
      let newState = { ...state };
      newState.panelNightTariffRange = [...action.payload];
      return newState;
    }
    case "ROLE": {
      let newState = { ...state };
      newState.userRoleId = action.payload;
      return newState;
    }
    case "ACCOUNT": {
      let newState = { ...state };
      newState.userAccountId = action.payload;
      return newState;
    }
    case "CORRESPONDING_ACCOUNTS": {
      let newState = { ...state };
      newState.correspondingAccountList = [...action.payload];
      return newState;
    }
    case "PLATFORM": {
      let newState = { ...state };
      newState.platformType = action.payload;

      return newState;
    }
    default:
      return state;
  }
};

const EditOperatorForm: React.FC<{ userId?: string }> = (props) => {
  let roleList = useAppSelector((state) => state.roles.roles);
  let accountList = useAppSelector((state) => state.accounts.accounts);
  let uiIsLoading = useAppSelector((state) => state.ui.isScreenLoading);
  const [isVisible, setIsvisible] = useState<boolean>(false);
  const [isVisible1, setIsvisible1] = useState<boolean>(false);
  const [isVisible2, setIsvisible2] = useState<boolean>(false);
  let { isLoading, executeGet: fetchData, executeAdd: saveData } = useHttp();
  let dispatch = useDispatch();
  let [editOperatorFormState, editOperatorFormDispatcher] = useReducer(
    editOperatorReducer,
    initialValue
  );

  const handlePayRangeApiData = (apiData: any) => {
    let mappedPayRangeInfo = mapApiResponseToPayRange(apiData);
    if (mappedPayRangeInfo !== undefined) {
      if (mappedPayRangeInfo.platform === PlatformType.cdc) {
        editOperatorFormDispatcher({
          type: "CDC",
          payload: mappedPayRangeInfo!.dayTariff,
        });
      } else if (mappedPayRangeInfo.platform === PlatformType.panel) {
        editOperatorFormDispatcher({
          type: "PANEL_DAY",
          payload: mappedPayRangeInfo!.dayTariff,
        });

        editOperatorFormDispatcher({
          type: "PANEL_NIGHT",
          payload: mappedPayRangeInfo!.nightTariff,
        });
      }

      onPlatformeSelectValueChange(mappedPayRangeInfo.platform);
    }
  };

  const handleUserInformationApiData = (apiData: any) => {
    if (apiData === undefined || apiData === null) {
      return;
    }

    if (apiData.accounts !== undefined && apiData.accounts !== null) {
      editOperatorFormDispatcher({
        type: "ACCOUNT",
        payload: apiData.accounts.id,
      });
      let platformType: PlatformType;
      platformType = Number.parseInt(apiData.accounts.accountType);
      editOperatorFormDispatcher({
        type: "PLATFORM",
        payload: platformType,
      });
    }
    if (apiData.roles !== undefined && apiData.roles !== null) {
      editOperatorFormDispatcher({
        type: "ROLE",
        payload: apiData.roles.id,
      });
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchData(EndpointUrls.Role, (apiData) => {
        dispatch(roleSliceActions.setRoles(apiData));
      });

      dispatch(loadAccounts());

      if (props.userId !== undefined) {
        await fetchData(EndpointUrls.Users, handleUserInformationApiData, [
          { key: "userId", value: props.userId },
        ]);

        await fetchData(EndpointUrls.PayRange, handlePayRangeApiData, [
          { key: "userId", value: props.userId },
        ]);
      }
    };

    loadData();
  }, [roleList.length, accountList.length]);

  const submitEventHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.userId !== undefined) {
      await saveData(
        EndpointUrls.UserRoleManagement,
        editOperatorFormState.userRoleId,
        [{ key: "userId", value: props.userId }]
      );

      if (props.userId !== undefined) {
        await saveData(
          EndpointUrls.UserAccountManagement,
          editOperatorFormState.userAccountId,
          [{ key: "userId", value: props.userId }]
        );

        if (editOperatorFormState.platformType !== undefined) {
          let payRangeModel: PayRangeInfo = {
            platform: editOperatorFormState.platformType,
            dayTariff: [],
            nightTariff: [],
            userId: props.userId,
          };

          if (editOperatorFormState.platformType === PlatformType.cdc) {
            payRangeModel.dayTariff = payRangeModel.dayTariff.concat(
              editOperatorFormState.cdcTariffRange
            );
          } else {
            payRangeModel.dayTariff = payRangeModel.dayTariff.concat(
              editOperatorFormState.panelDayTariffRange
            );
            payRangeModel.nightTariff = payRangeModel.nightTariff.concat(
              editOperatorFormState.panelNightTariffRange
            );
          }

          await saveData(EndpointUrls.PayRange, payRangeModel, [
            { key: "userId", value: props.userId },
          ]);

          dispatch(uiSliceActions.showModal(false));
        }
      }
      dispatch(loadUserList());
    }
  };

  const onPlatformeSelectValueChange = (platformType?: PlatformType) => {
    if (platformType === undefined) {
      return;
    }

    let resultList: AccountApiModel[] = [];
    accountList.forEach((account) => {
      if (account.accountType === platformType) {
        resultList = resultList.concat(account);
      }
    });

    editOperatorFormDispatcher({
      type: "PLATFORM",
      payload: platformType,
    });

    editOperatorFormDispatcher({
      type: "CORRESPONDING_ACCOUNTS",
      payload: resultList,
    });
  };

  const onVisibleHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (isVisible1 === true) {
      setIsvisible1(false);
    }
    if (isVisible2 === true) {
      setIsvisible2(false);
    }
    setIsvisible((previousState) => !previousState);
  };
  const onVisibleHandler1 = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (isVisible === true) {
      setIsvisible(false);
    }
    if (isVisible2 === true) {
      setIsvisible2(false);
    }
    setIsvisible1((previousState) => !previousState);
  };
  const onVisibleHandler2 = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (isVisible === true) {
      setIsvisible(false);
    }
    if (isVisible1 === true) {
      setIsvisible1(false);
    }
    setIsvisible2((previousState) => !previousState);
  };
  return (
    <div className="edit-operator-form">
      {(isLoading || uiIsLoading) && <LoadingAnimation />}
      {!isLoading && !uiIsLoading && (
        <React.Fragment>
          <div className="edit-operator-form__icon-container">
            <div className="__tooltip">
              <span
                className="material-icons-outlined icon"
                onClick={onVisibleHandler}
              >
                assignment_ind
              </span>
              <span className="__tooltip-text">Assign role</span>
            </div>
            <div className="__tooltip">
              <span
                className="material-icons-outlined icon"
                onClick={onVisibleHandler1}
              >
                assignment
              </span>
              <span className="__tooltip-text">Assign account</span>
            </div>
            <div className="__tooltip">
              <span
                className="material-icons-outlined icon"
                onClick={onVisibleHandler2}
              >
                monetization_on
              </span>
              <span className="__tooltip-text">Pay range</span>
            </div>
          </div>
          <div className="edit-operator-form__container">
            <FormContainer
              onSubmit={submitEventHandler}
              formActions={<EditOperatorFormActions />}
              className={`set-role-form ${isVisible ? "active" : ""}`}
            >
              <FormHeader title={"Assign Role"} description={""} />
              {roleList !== undefined && roleList.length > 0 && (
                <FormRow type="row">
                  <FormInputContainer>
                    <BasicSelectInput
                      label="Role:"
                      value={editOperatorFormState.userRoleId}
                      onChange={(selection) => {
                        if (selection.currentTarget.value !== "default") {
                          editOperatorFormDispatcher({
                            type: "ROLE",
                            payload: selection.currentTarget.value,
                          });
                        }
                      }}
                    >
                      <option value="default">Select a role</option>
                      {roleList.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </BasicSelectInput>
                  </FormInputContainer>
                </FormRow>
              )}
            </FormContainer>
            {/* Account type + account List */}
            <FormContainer
              onSubmit={submitEventHandler}
              formActions={<EditOperatorFormActions />}
              className={`set-account-form ${isVisible1 ? "active" : ""}`}
            >
              <FormHeader title={"Assign Account"} description={""} />
              <FormRow type="row">
                <FormInputContainer>
                  <AccountTypeSelect
                    platformType={editOperatorFormState.platformType}
                    onPlateformTypeChanged={onPlatformeSelectValueChange}
                  />
                </FormInputContainer>
              </FormRow>
              {editOperatorFormState.correspondingAccountList !== undefined && (
                <FormRow type="row">
                  <FormInputContainer>
                    <BasicSelectInput
                      label="Account:"
                      value={editOperatorFormState.userAccountId}
                      disabled={
                        editOperatorFormState.correspondingAccountList.length ==
                        0
                          ? true
                          : false
                      }
                      onChange={(selection) => {
                        if (selection.currentTarget.value != "default") {
                          editOperatorFormDispatcher({
                            type: "ACCOUNT",
                            payload: selection.currentTarget.value,
                          });
                        }
                      }}
                    >
                      <option value="default">Select an account</option>
                      {editOperatorFormState.correspondingAccountList.map(
                        (account) => (
                          <option key={account.id} value={account.id}>
                            {account.accountNumber}
                          </option>
                        )
                      )}
                    </BasicSelectInput>
                  </FormInputContainer>
                </FormRow>
              )}
            </FormContainer>
            {/* Account type + pay range  */}
            <FormContainer
              onSubmit={submitEventHandler}
              formActions={<EditOperatorFormActions />}
              className={`set-pay-range-form ${isVisible2 ? "active" : ""}`}
            >
              <FormHeader title={"Pay Range"} description={""} />
              <FormRow type="row">
                <FormInputContainer>
                  <AccountTypeSelect
                    platformType={editOperatorFormState.platformType}
                    onPlateformTypeChanged={onPlatformeSelectValueChange}
                  />
                </FormInputContainer>
              </FormRow>
              {editOperatorFormState.platformType !== undefined &&
                (editOperatorFormState.platformType === PlatformType.cdc ||
                  editOperatorFormState.platformType === PlatformType.cdm) && (
                  <MultipleTariffRangeInput
                    tariffRangeList={editOperatorFormState.cdcTariffRange}
                    onUpdateTariffs={(data) => {
                      editOperatorFormDispatcher({
                        type: "CDC",
                        payload: data,
                      });
                    }}
                  />
                )}

              {editOperatorFormState.platformType !== undefined &&
                editOperatorFormState.platformType === PlatformType.panel && (
                  <React.Fragment>
                    <FormElementGroup sectionTitle="Day Tariff">
                      <MultipleTariffRangeInput
                        tariffRangeList={
                          editOperatorFormState.panelDayTariffRange
                        }
                        onUpdateTariffs={(data) => {
                          editOperatorFormDispatcher({
                            type: "PANEL_DAY",
                            payload: data,
                          });
                        }}
                      />
                    </FormElementGroup>
                    <FormElementGroup sectionTitle="Night Tariff">
                      <MultipleTariffRangeInput
                        tariffRangeList={
                          editOperatorFormState.panelNightTariffRange
                        }
                        onUpdateTariffs={(data) => {
                          editOperatorFormDispatcher({
                            type: "PANEL_NIGHT",
                            payload: data,
                          });
                        }}
                      />
                    </FormElementGroup>
                  </React.Fragment>
                )}
            </FormContainer>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default EditOperatorForm;

import React, { useEffect, useState } from "react";
import { TwoColumnsFocusLeft } from "../../Layouts/PageStructure/GridColumns";
import TarificationDetails from "../TarificationDetails/TarificationDetails";
import TarificationList from "../TarificationList/TarificationList";

import "./_tarification-view.scss";

const TarificationView: React.FC<{}> = (props) => {
  let [tarificationId, setTarificationId] = useState<string>();

  const itemClickedHandler = (data?: string) => {
    setTarificationId(data);
  };

  return (
    <TwoColumnsFocusLeft className="tarification-grid">
      <TarificationDetails tarificationItemId={tarificationId} />
      <TarificationList onItemClicked={itemClickedHandler} />
    </TwoColumnsFocusLeft>
  );
};

export default TarificationView;

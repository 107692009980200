import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router";
import CustomerLayout from "../../components/Layouts/CustomerLayout/CustomerLayout";
import { loadClient } from "../../store/Actions/ClientsActions";
import { loadUser } from "../../store/Actions/UsersActions";
import { uiSliceActions } from "../../store/UiSlice";

const CustomerBasePage: React.FC<{}> = (props) => {
	const dispatch = useDispatch();
	const { clientId } = useParams();

	dispatch(uiSliceActions.showModal(false));

	useEffect(() => {
		if (clientId !== undefined) {
			dispatch(loadUser(clientId, true));
			dispatch(loadClient(clientId));
		}
	}, []);

	return (
		<CustomerLayout>
			<Outlet />
		</CustomerLayout>
	);
};
export default CustomerBasePage;

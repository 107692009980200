import React, { FormEvent, useEffect, useState } from "react";
import { mapApiDataToAccountTarificationDetails } from "../../../Helpers/AccountTarificationHelpers";
import useHttp from "../../../hooks/UseHttp";
import { TariffRange } from "../../../models/Api/PayRange/PayRange";
import { TarificationApiModel } from "../../../models/Api/Tarification/Tarification";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { PlatformType } from "../../../models/Enums/Platform/PlaformEnum";
import AccountTypeSelect from "../../Accounts/AccountTypeSelect";
import FormContainer from "../../Forms/FormContainer";
import {
  FormInputContainer,
  FormRow,
} from "../../Forms/FormElements/FormStructure";
import { BasicInput } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";
import MultipleTariffRangeInput from "../MultipleTariffRangeInput";
import { useDispatch } from "react-redux";
import { loadTarificationDetailsList } from "../../../store/Actions/TarificationActions";

import "./_tarification-details-form.scss";

const TarificationDetailsFormActions: React.FC<{ isLoading: boolean }> = (
  props
) => {
  return (
    <React.Fragment>
      <Button theme={"primary"} type="reset">
        Cancel
      </Button>
      <Button
        theme={"secondary"}
        type="submit"
        className={`account-tarification-form__submit-button ${
          props.isLoading ? "button--disabled" : ""
        }`}
        disabled={props.isLoading}
      >
        {props.isLoading && <LoadingAnimation size="medium" />}
        {!props.isLoading && <span>Save</span>}
      </Button>
    </React.Fragment>
  );
};

const TarificationDetailsForm: React.FC<{
  accountTypeId?: string;
}> = (props) => {
  let [tariffList, setTariffList] = useState<TariffRange[]>([]);
  let [platformType, setPlatformType] = useState<PlatformType>();
  let [
    tarificationDetails,
    setTarificationDetails,
  ] = useState<TarificationApiModel>();

  let [name, setName] = useState<string>("");
  let {
    isLoading,
    executeAdd: saveTariffs,
    executeGet: fetchTariffs,
  } = useHttp();
  let dispatch = useDispatch();

  const handleApiData = (apiData: any) => {
    var data:
      | TarificationApiModel
      | undefined = mapApiDataToAccountTarificationDetails(apiData);

    if (data != undefined && data != null) {
      setTarificationDetails((prev) => data);
      setTariffList((prev) => data!.tarificationDetails);
      setName(data.name);
      setPlatformType(data.platform);
    }
  };

  useEffect(() => {
    if (props.accountTypeId != undefined && props.accountTypeId != null) {
      fetchTariffs(EndpointUrls.AccountTarification, handleApiData, [
        { key: "id", value: props.accountTypeId },
      ]);
    }
  }, [props.accountTypeId, name == ""]);

  const submitFormHandler = (event: FormEvent) => {
    event.preventDefault();
    if (platformType == undefined) {
      return;
    }

    let tarificationData: TarificationApiModel = {
      platform: platformType,
      tarificationDetails: tariffList,
      name: name,
    };

    if (
      tarificationDetails != undefined &&
      tarificationDetails != null &&
      tarificationDetails.id != undefined &&
      tarificationDetails.id != null
    ) {
      tarificationData.id = tarificationDetails.id;
    }

    saveTariffs(EndpointUrls.AccountTarification, tarificationData).then(() => {
      dispatch(loadTarificationDetailsList());
    });

    setPlatformType(undefined);
    setName("");
    setTariffList([]);
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingAnimation size="large" />}
      {!isLoading && (
        <FormContainer
          onSubmit={submitFormHandler}
          formActions={<TarificationDetailsFormActions isLoading={isLoading} />}
          className="account-tarification-form"
        >
          <FormRow type={"row"}>
            <FormInputContainer>
              <BasicInput
                placeholder="Name"
                id="account_name"
                value={name}
                onChange={(event) => {
                  if (
                    event.currentTarget != undefined &&
                    event.currentTarget != null
                  ) {
                    setName(event.currentTarget.value);
                  }
                }}
              />
            </FormInputContainer>
          </FormRow>
          <FormRow type={"row"}>
            <FormInputContainer>
              <AccountTypeSelect
                platformType={platformType}
                onPlateformTypeChanged={(data) => {
                  if (data != undefined) {
                    setPlatformType(data);
                  }
                }}
              />
            </FormInputContainer>
          </FormRow>

          <MultipleTariffRangeInput
            tariffRangeList={tariffList}
            onUpdateTariffs={(data) => {
              setTariffList((prevState) => data);
            }}
          />
        </FormContainer>
      )}
    </React.Fragment>
  );
};

export default TarificationDetailsForm;

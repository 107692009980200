import CustomersApiModel from "../models/Api/Customers/Models";

export const mapApiModelToClientProfile = (data: any) => {
  if (data === undefined) {
    return {
      clientId: "",
      id: "",
      firstname: "",
      lastname: "",
      username: "",
      businessEmailAddress: "",
      businessAddress: "",
      businessPhoneNumber: "",
      creationDate: "",
      modificationDate: "",
      identificationNumber: "",
      isConfirmed: "",
    };
  }
  let clientProfile: CustomersApiModel = {
    clientId: data.clientId,
    id: data.id,
    firstname: data.firstName,
    lastname: data.lastName,
    username: data.username,
    businessEmailAddress: data.businessEmailAddress,
    businessAddress: data.businessAddress,
    businessPhoneNumber: data.businessPhoneNumber,
    creationDate: data.creationDate,
    modificationDate: data.modificationDate,
    identificationNumber: data.identificationNumber,
    isConfirmed: data.isConfirmed,
  };
  return clientProfile;
};

export const mapResponseToApiModel: (
  data: any
) => CustomersApiModel | undefined = (data) => {
  if (data == undefined || data == null) {
    return undefined;
  }
  let client: CustomersApiModel = {
    clientId: data.clientId,
    id: data.id,
    firstname: data.firstName,
    lastname: data.lastName,
    username: data.username,
    businessEmailAddress: data.businessEmailAddress,
    businessAddress: data.businessAddress,
    businessPhoneNumber: data.businessPhoneNumber,
    creationDate: data.creationDate,
    modificationDate: data.modificationDate,
    identificationNumber: data.identificationNumber,
    isConfirmed: data.isConfirmed,
  };
  return client;
};
export const mapDataToClientApiModelList: (
  data: any
) => CustomersApiModel[] | undefined = (data) => {
  if (data == undefined || data == null) {
    return undefined;
  }
  let clientApiModelList: CustomersApiModel[] = data.map((client: any) =>
    mapResponseToApiModel(client)
  );

  // for (const key in data) {
  //   let client = mapResponseToApiModel(data[key]);
  //   if (client !== undefined) {
  //     clientApiModelList = clientApiModelList.concat(client);
  //   }
  // }

  if (clientApiModelList.length > 0) {
    return clientApiModelList;
  }
  return undefined;
};

import React from "react";

import "./_flowboosterPresentation.scss";

const FlowboosterPresentationCard: React.FC<{ className?: string }> = (
	props
) => {
	return (
		<div
			className={`flowbooster-presentation-card ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			<div className="flowbooster-presentation-card__inner-layer">
				<div className="flowbooster-presentation-card__intro">
					<span className="icon--filled icon--filled icon--very-large login-panel__flowbooster-intro__left">
						offline_bolt
					</span>
					<span className="flowbooster-presentation-card__intro__brand">
						FlowBooster
					</span>
				</div>
			</div>
		</div>
	);
};

export default FlowboosterPresentationCard;

import { useState, useEffect } from "react";

import "./_value-comparator-display.scss";

const ValueComparatorDisplay: React.FC<{
	previousValue: number;
	currentValue: number;
}> = (props) => {
	let [progress, setProgress] = useState<"neutral" | "positive" | "negative">();
	let difference: number = +props.currentValue - +props.previousValue;

	let differencePercentage: number =
		difference < 0
			? (difference * -1 * 100) / props.previousValue
			: (difference * 100) / props.previousValue;

	differencePercentage = Number.isNaN(differencePercentage)
		? 0
		: differencePercentage;

	useEffect(() => {
		if (props.currentValue == undefined || props.previousValue == undefined) {
			return;
		}
		if (difference < 0) {
			setProgress("negative");
		} else if (difference > 0) {
			setProgress("positive");
		} else {
			setProgress("neutral");
		}
	}, []);

	return (
		<div className={`statistics--${progress}`}>
			{/* {differencePercentage.toFixed(2)} */}
		</div>
	);
};

export default ValueComparatorDisplay;

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
	FlowboosterSendMessageRequest,
	MediaItem,
	Message,
} from "../../../models/Flowbooster/Models";
import { MessagesHistoryItemProps } from "../../../models/Props/Messages/MessagesHistoryProps";
import ImageGallery from "../../ImageGallery/ImageGallery";
import LoadingAnimation from "../../Loading/Loading";
import Modal from "../../Modal/Modal";
import Button from "../../ui/Button/Button";
import MessagesHistoryList from "../MessagesHistory/MessagesHistoryList";
import SendMessageForm from "../SendMessageForm/SendMessageForm";

const MainPanel: React.FC<{
	platform: string;
	className?: string;
	isLoading: boolean;
	messageList?: Message[];
	userId: string;
	sendMessageRequestModel: FlowboosterSendMessageRequest;
	ipAddress: string;
	availableMediaList?: MediaItem[];
	isMediaButtonAvailable?: boolean;
	isMediaListExhausted?: boolean;
	conversationStartDate?: string;

	onMessageSent: () => void;
}> = (props) => {
	const [imageId, setImageId] = useState<string>();
	const [showModal, setShowModal] = useState<boolean>();
	const dispatch = useDispatch();

	const onImageSelectedHandler: (id: string) => void = (id) => {
		setImageId(id);
	};

	const closeModal: (event: React.MouseEvent) => void = (event) => {
		event.preventDefault();
		setShowModal(false);
	};

	const onRemoveMediaButtonClicked: () => void = () => {
		setImageId(undefined);
	};

	const updateCloseModal: () => void = () => {
		setShowModal(false);
	};

	const onAddMediaButtonClicked: () => void = () => {
		setShowModal(true);
	};
	return (
		<div className={`${props.className !== undefined ? props.className : ""}`}>
			{props.availableMediaList !== undefined &&
				props.availableMediaList !== null &&
				props.availableMediaList.length > 0 &&
				showModal && (
					<Modal onClose={updateCloseModal}>
						<ImageGallery
							imageList={props.availableMediaList}
							onImageSelected={onImageSelectedHandler}
						/>
						<div
							style={{
								display: "flex",
								marginTop: "20px",
								gap: "7px",
								justifyContent: "flex-end",
							}}
						>
							<Button theme={"info"} onClick={closeModal}>
								Ok
							</Button>
							<Button theme={"info"} onClick={closeModal}>
								Cancel
							</Button>
						</div>
					</Modal>
				)}

			{props.isLoading && <LoadingAnimation />}
			{!props.isLoading && (
				<React.Fragment>
					<SendMessageForm
						platform={props.platform}
						onMessageSent={props.onMessageSent}
						userId={props.userId}
						sendMessageRequest={props.sendMessageRequestModel}
						ipAddress={props.ipAddress}
						conversationStartDate={props.conversationStartDate}
						imageId={imageId}
						onRemoveMediaButtonClicked={onRemoveMediaButtonClicked}
						isMediaButtonAvailable={props.isMediaButtonAvailable}
						isMediaListExhausted={props.isMediaListExhausted}
						onAddMediaButtonClicked={onAddMediaButtonClicked}
					></SendMessageForm>
					{props.messageList !== undefined && props.messageList.length > 0 && (
						<MessagesHistoryList
							messageList={props.messageList.map((message) => {
								let mappedMessage: MessagesHistoryItemProps = {
									content: message.messageBody,
									authorName: message.authorId,
									date: new Date(),
									isInbound: message.isInbound,
									attachments: message.attachment,
								};

								if (message.received !== undefined) {
									mappedMessage.date = message.received;
								}

								return mappedMessage;
							})}
						/>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default MainPanel;

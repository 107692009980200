import React from "react";
import { ClickableListItemProps } from "../../models/Props/List/ListProps";

const ClickableListItem: React.FC<ClickableListItemProps> = (props) => {
  return (
    <li
      className={`${props.className != undefined ? props.className : ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </li>
  );
};

export default ClickableListItem;

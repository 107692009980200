import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { UnassignedAccountFormProps } from "../../../models/Props/Account/AssignUnassignedAccountProps";
import { loadUserList } from "../../../store/Actions/UsersActions";
import { uiSliceActions } from "../../../store/UiSlice";
import FormContainer from "../../Forms/FormContainer";
import { FormHeader } from "../../Forms/FormElements/FormStructure";
import {
  BasicInput,
  BasicSelectInput,
} from "../../Forms/FormInputs/BasicInputs";
import Button from "../../ui/Button/Button";
import './UnassignedAccountList.scss'; 

const AddUnassignedAccountForm: React.FC<UnassignedAccountFormProps> = (
  props
) => {
  let usersState = useAppSelector((state) => state.users.users);
  let { isLoading, executeAdd: assignAccount } = useHttp();
  let dispatch = useDispatch();
  const [userId, setUserId] = useState<string>("");
  
  

  useEffect(() => {
    dispatch(loadUserList());
  }, []);

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      event.currentTarget !== undefined &&
      event.currentTarget.value !== undefined &&
      event.currentTarget.value !== ""
    ) {
      console.log(event.currentTarget.value);
      setUserId(event.currentTarget.value);
    }
  };

  const submitFormHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      userId !== undefined &&
      userId !== null &&
      userId !== "default" &&
      props.accountId !== undefined
    ) {
      // register the account to the user
      // parameters are accountId and userId
      await assignAccount(EndpointUrls.UserAccountManagement, props.accountId, [
        { key: "userId", value: userId },
      ]);
      if (props.fetchData !== undefined) {
        props.fetchData();
      }
      if(props.isAssigned !== undefined) {
        props.isAssigned(false);
      }
      
      dispatch(uiSliceActions.showModal(false));
    }
  };

  return (
    <FormContainer
      onSubmit={submitFormHandler}
      className={`assign-account-form ${
        props.className !== undefined ? props.className : ""
      }`}
    >
      <FormHeader
        title={"Assignment Form"}
        description={""}
      />
      <BasicInput disabled value={props.account} />
      {usersState != null && usersState != undefined && usersState.length > 0 && (
        <BasicSelectInput onChange={onChangeHandler} value={userId}>
          <option value="default">Please Select</option>
          {usersState.map((user) => (
            <option
              key={Math.random()}
              value={user.userId}
            >{`${user.profile.lastName} ${user.profile.firstName}`}</option>
          ))}
        </BasicSelectInput>
      )}
      <Button theme={"primary"} type="submit">
        Assign
      </Button>
    </FormContainer>
  );
};
export default AddUnassignedAccountForm;

import React from "react";
import HeaderProps from "../../../models/Props/Layout/HeaderProps";
import TopNavigation from "../../Navigation/TopNavigation/TopNavigation";

import "./_header.scss";

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <header
      className={`container-grid--header header ${
        props.className != undefined ? props.className : ""
      }`}
    >
      <TopNavigation />
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import Card from "../../components/ui/Card/Card";
import { SingleFullWidthColumnContentCentered } from "../../components/Layouts/PageStructure/GridColumns";
import CardInformation from "../../components/CardInformation/CardInformation";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../../store/UiSlice";
import Modal from "../../components/Modal/Modal";
import { useAppSelector } from "../../hooks/StateHooks";

import useHttp from "../../hooks/UseHttp";
import LoadingAnimation from "../../components/Loading/Loading";
import { roleSliceActions } from "../../store/RoleSlice";
import RoleApiModel from "../../models/Api/Roles/RoleApiModel";
import { OverviewTableDataProps } from "../../models/Props/OverviewTable/OverviewTableProps";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import AddRoleForm from "../../components/Roles/AddRoleForm";

const ManageUserRolesDashboard: React.FC<{}> = (props) => {
  let showModal = useAppSelector((state) => state.ui.showModal);
  let rolesState = useAppSelector((state) => state.roles.roles);
  let [roleId, setRoleId] = useState<string>("");

  let {
    isLoading,
    executeGet: fetchRoles,
    executeDelete: removeRole,
  } = useHttp();
  let dispatch = useDispatch();

  const handleApiResponse: (data: any) => void = (data) => {
    dispatch(roleSliceActions.setRoles(data));
  };

  useEffect(() => {
    fetchRoles(EndpointUrls.Role, handleApiResponse);
  }, [rolesState.length]);

  const addRoleEventHandler = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(uiSliceActions.showModal(true));
  };

  const editRole: (data: string) => void = (data: string) => {
    setRoleId(data);
    dispatch(uiSliceActions.showModal(true));
  };

  const deleteRole: (data: string) => void = (data: string) => {
    let roleToBeRemoved: RoleApiModel = {
      id: data,
      description: "",
      name: "",
    };
    removeRole(EndpointUrls.Role, roleToBeRemoved);
    dispatch(roleSliceActions.deleteRole(data));
  };

  return (
    <React.Fragment>
      {showModal && (
        <Modal title="Manage Roles">
          <AddRoleForm roleId={roleId} />
        </Modal>
      )}

      <SingleFullWidthColumnContentCentered>
        <Card
          isDashboardElement={true}
          theme="white"
          className="overview-item__column"
        >
          <CardInformation
            variant="fullGrid"
            title="title"
            header="header"
            description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi sint tempora, molestiae dolor laboriosam officia facilis corporis ab iusto, ea suscipit delectus asperiores quos omnis atque aperiam architecto blanditiis unde."
            headerCallToActionElement={
              <a
                href="#"
                className="button button-ghost__alternate"
                onClick={addRoleEventHandler}
              >
                <span className="material-icons-outlined">
                  add_circle_outline
                </span>
                <span> Add Role</span>
              </a>
            }
          ></CardInformation>
          {isLoading && <LoadingAnimation />}
          {!isLoading && (
            <OverviewTable
              size={4}
              header={[{element: "Role ID"}, {element: "Role Name"}, {element: "Description"}, {element: "Actions"}]}
              tableData={rolesState.map((role) => {
                let overviewTable: OverviewTableDataProps = {
                  data: [
                    {element: role.id},
                    {element: role.name},
                    {element: role.description},
                    {element: <React.Fragment>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          editRole(role.id);
                        }}
                        className="button-icon"
                      >
                        <span className="material-icons-outlined">edit</span>
                      </a>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          deleteRole(role.id);
                        }}
                        className="button-icon"
                      >
                        <span className="material-icons-outlined">delete</span>
                      </a>
                    </React.Fragment>},
                  ],
                };
                return overviewTable;
              })}
              display="lastColumnSmall"
            ></OverviewTable>
          )}
        </Card>
      </SingleFullWidthColumnContentCentered>
    </React.Fragment>
  );
};

export default ManageUserRolesDashboard;

import React from "react";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import useMessageApi from "../../../hooks/UseMessageApi";
import { NoteHistoryItemProps } from "../../../models/Props/Messages/MessageNotesProps";
import LoadingAnimation from "../../Loading/Loading";
import "./_profileNotes.scss";

const ProfileNotesHistoryItem: React.FC<NoteHistoryItemProps> = (props) => {
	const { isLoading, deleteNote } = useMessageApi();
	const ipAddress = useAppSelector((state) => state.flowbooster.ipAddress);
	const userId = useAppSelector((state) => state.users.currentUser?.id);
	const deleteNoteHandler: (event: React.MouseEvent) => void = async (
		event
	) => {
		if (ipAddress !== undefined && userId !== undefined) {
			await deleteNote(ipAddress, userId, props.platform, {
				isPlayerNote: props.isPlayerNote,
				noteId: props.id,
				userId: userId,
			});
			if (props.onNoteDelete !== undefined) {
				props.onNoteDelete();
			}
		}
	};
	return (
		<div className="note-history-item">
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<React.Fragment>
					<div className="note-history-item__note-information">
						<div className="note-history-item__note-information__author-information">
							{props.postedDate !== undefined && (
								<span className="author-information__id">
									{new Date(props.postedDate.toString()).toLocaleDateString()}
								</span>
							)}

							{/* <span className="author-information__username">
						({props.authorName})
					</span> */}
						</div>
						{/* <div className="posted-date">({props.postedDate})</div>*/}
					</div>
					<div className="content">{props.content}</div>
					<span
						className="note-history-item__delete icon--outlined"
						onClick={deleteNoteHandler}
					>
						delete
					</span>
				</React.Fragment>
			)}
		</div>
	);
};

export default ProfileNotesHistoryItem;

import React, { useState } from "react";
import Card from "../../components/ui/Card/Card";
import {
	EvenlySizedColumnGrid,
	TwoColumnsFocusLeft,
} from "../../components/Layouts/PageStructure/GridColumns";
import CardInformation from "../../components/CardInformation/CardInformation";
import Calendar from "react-calendar";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { useParams } from "react-router-dom";
import DailyMessagesSentGraph from "../../components/Graphs/DailyMessagesSentGraph";
import StatsEntryForm from "../../components/Statistics/StatsEntryForm/StatsEntryForm";

import "./_messages-stats.scss";
import DailyStatsView from "../../components/Statistics/DailyStatsView/DailyStatsView";
import { useAppSelector } from "../../hooks/StateHooks";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";
import ErrorNotification from "../../components/ui/ErrorNotification/ErrorNotification";

const MessageStatsPage: React.FC<{}> = () => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [weekNumber, setWeekNumber] = useState<number>();
	const [year, setYear] = useState<string>(new Date().getFullYear().toString());
	const userProfile = useAppSelector((state) => state.users.currentUser);

	let platformeType: PlatformType | undefined = undefined;
	if (userProfile !== undefined) {
		const userAccount = userProfile.accounts;
		if (userAccount !== undefined && userAccount.accountType !== undefined) {
			platformeType = userAccount.accountType;
		}
	}

	let { userId } = useParams();

	const handleEntryFormResponse = (weekNumber: number, year: string) => {
		setWeekNumber(weekNumber);
		setYear(year);
	};

	// if (platformeType === PlatformType.cdc) {
	// 	return (
	// 		<div>
	// 			<ErrorNotification description="This functionality has been disabled."></ErrorNotification>
	// 		</div>
	// 	);
	// }

	return (
		<React.Fragment>
			<TwoColumnsFocusLeft className="messages-stats-grid">
				<Card
					theme={"white"}
					isDashboardElement={true}
					className="overview-item__column messages-stats__stats-card"
				>
					<CardInformation
						variant={"fullGrid"}
						title={`Enter your stats for week ${weekNumber}`}
						description="Here you can enter your stats for the selected week. In order to view or add your stats for a selected week, start by picking a day from the calender on the right hand side."
						header="Statistics"
						headerCallToActionElement={
							<React.Fragment>
								<input
									className="stats-datepicker--small"
									type="date"
									onChange={(event) => {
										if (
											event.currentTarget !== undefined &&
											event.currentTarget.value.trim().length !== 0
										) {
											let date = new Date(event.currentTarget.value);
											setSelectedDate(date);
										}
									}}
								/>
							</React.Fragment>
						}
					></CardInformation>

					<div className="stats">
						<div className="add-stats">
							{userId !== undefined &&
								userId !== null &&
								(platformeType === PlatformType.cdc ||
									platformeType === PlatformType.cdm) && (
									<DailyStatsView
										userId={userId}
										selectedDate={selectedDate}
										exposeWeekData={handleEntryFormResponse}
									/>
								)}
							{userId !== undefined &&
								userId !== null &&
								platformeType === PlatformType.panel && (
									<StatsEntryForm
										userId={userId}
										selectedDate={selectedDate}
										exposeWeekData={handleEntryFormResponse}
									/>
								)}

							{userId !== undefined &&
								selectedDate !== undefined &&
								weekNumber !== undefined && (
									<div className="stats__graph stats__graph--inner">
										<DailyMessagesSentGraph
											graphType="bar"
											userId={userId}
											weekNumber={weekNumber}
											endpoint={EndpointUrls.CurrentWeekStats}
											shift={"both"}
											year={year}
										/>
									</div>
								)}
						</div>
					</div>
				</Card>

				<Card
					theme={"white"}
					isDashboardElement={true}
					className="stats__graph stats__graph--outter"
				>
					{userId !== undefined &&
						selectedDate !== undefined &&
						weekNumber !== undefined && (
							<DailyMessagesSentGraph
								graphType="bar"
								userId={userId}
								weekNumber={weekNumber}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"both"}
								year={year}
							/>
						)}
				</Card>
				<Card
					theme={"white"}
					isDashboardElement={true}
					className="stats-datepicker--large"
				>
					<CardInformation variant={"fullGrid"}></CardInformation>
					<Calendar
						onChange={setSelectedDate}
						value={selectedDate}
						maxDate={new Date()}
					></Calendar>
				</Card>
			</TwoColumnsFocusLeft>
			<EvenlySizedColumnGrid className="messages-stats-grid--col-4">
				{userId !== undefined &&
					selectedDate !== undefined &&
					weekNumber !== undefined && (
						<Card
							theme={"white"}
							isDashboardElement={true}
							className="overview-item__column"
						>
							<CardInformation
								variant={"full"}
								title={`Messages sent for week ${weekNumber - 1}`}
								description=""
								header="Statistics"
							></CardInformation>
							<DailyMessagesSentGraph
								graphType="line"
								userId={userId}
								weekNumber={weekNumber - 1}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"both"}
								year={year}
							/>
						</Card>
					)}
				{userId !== undefined &&
					selectedDate !== undefined &&
					weekNumber !== undefined && (
						<Card
							theme={"white"}
							isDashboardElement={true}
							className="overview-item__column"
						>
							<CardInformation
								variant={"full"}
								title={`Messages sent for week ${weekNumber - 2}`}
								description=""
								header="Statistics"
							></CardInformation>
							<DailyMessagesSentGraph
								graphType="line"
								userId={userId}
								weekNumber={weekNumber - 2}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"both"}
								year={year}
							/>
						</Card>
					)}
				{userId !== undefined &&
					selectedDate !== undefined &&
					weekNumber !== undefined && (
						<Card
							theme={"white"}
							isDashboardElement={true}
							className="overview-item__column"
						>
							<CardInformation
								variant={"full"}
								title={`Messages sent for week ${weekNumber - 3}`}
								description=""
								header="Statistics"
							></CardInformation>
							<DailyMessagesSentGraph
								graphType="line"
								userId={userId}
								weekNumber={weekNumber - 3}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"both"}
								year={year}
							/>
						</Card>
					)}
				{userId !== undefined &&
					selectedDate !== undefined &&
					weekNumber !== undefined && (
						<Card
							theme={"white"}
							isDashboardElement={true}
							className="overview-item__column"
						>
							<CardInformation
								variant={"full"}
								title={`Messages sent for week ${weekNumber - 4}`}
								description=""
								header="Statistics"
							></CardInformation>
							<DailyMessagesSentGraph
								graphType="line"
								userId={userId}
								weekNumber={weekNumber - 4}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"both"}
								year={year}
							/>
						</Card>
					)}
			</EvenlySizedColumnGrid>
		</React.Fragment>
	);
};

export default MessageStatsPage;

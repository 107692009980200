import React, { useState } from "react";
import { UsersPerformanceCardProps } from "../../../models/Api/Users/DailyPerformanceCard";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import AssignOperatorToForm from "../AssignOperatorTo";
import "./_userDailyPerformanceTable.scss";

const UsersDailyPerformanceCard: React.FC<UsersPerformanceCardProps> = (
  props
) => {
  const [isDropped, setIsDropped] = useState<boolean>(false);

  const onDropHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsDropped((previousState) => !previousState);
  };
  return (
    <Card
      className="daily-overview-container__inner-container relative-dropdown"
      key={Math.random()}
      theme={"white"}
      isDashboardElement={false}
    >
      <div className="daily-overview-container__inner-container__card-title">
        <span>{props.infoKey}</span>
        <div className="btn-group-container">
          <Button theme={"secondary"} onClick={props.navigate}>
            <span className="icon--filled">visibility</span>
            <span>View Profile</span>
          </Button>
          <Button
            theme={"secondary"}
            onClick={onDropHandler}
            key={Math.random()}
          >
            <span className="icon--filled">assignment</span>
          </Button>
          <div
            className={`assign-operator-dropdown-container ${
              isDropped ? "__dropped" : ""
            }`}
          >
            <AssignOperatorToForm
              isAssigned={setIsDropped}
              fetchData={props.fetchData}
              operator={props.infoKey}
              operatorId={props.operatorId}
            />
          </div>
        </div>
      </div>
      {props.children}
    </Card>
  );
};
export default UsersDailyPerformanceCard;

import React, { HtmlHTMLAttributes } from "react";
import OverviewTableProps from "../../models/Props/OverviewTable/OverviewTableProps";
import "./_overview-table.scss";

const OverviewTable: React.FC<
  HtmlHTMLAttributes<HTMLDivElement> & OverviewTableProps
> = (props) => {
  let size = props.size;

  if (props.size > 12) {
    size = 12;
  }

  let type: string = "";
  switch (props.display) {
    case "spaceEvenly":
      break;
    case "lastColumnLarge":
      type = "__last-column__large";
      break;
    case "lastColumnSmall":
      type = "__before-last-two-column__large--last__small";
      break;
    case "lastTwoColumnsLarge":
      type = "__last-two-column__large";
      break;
    default:
      break;
  }

  let counter: number = 0;
  let innerCounter: number = 0;
  let headerCounter: number = 0;
  return (
    <div className="overview-table">
      <div
        className={`overview-table__header overview-table__header--col-${size}${type}`}
      >
        {props.header.map((header) => {
          headerCounter = headerCounter + 1;
          return <span key={headerCounter} className={header.className}>{header.element}</span>;
        })}
      </div>

      {props.tableData.map((tableData) => {
        counter = counter + 1;
        return (
          <div 
            key={counter}
            className={`overview-table__row overview-table__row--col-${size}${type}`}
          >
        {tableData.actions}
            {tableData.data.map((data) => {
              innerCounter = innerCounter + 1;
              return <div key={innerCounter} className={data.className}>{data.element}</div>;
            })}

          </div>
        );
      })}
    </div>
  );
};

export default OverviewTable;

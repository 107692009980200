import React from "react";
import ForexDetailsView from "../../components/Forex/ForexDetailsView";
import UpdateForexButton from "../../components/Forex/UpdateForexButton";
import { TwoColumnsFocusLeft } from "../../components/Layouts/PageStructure/GridColumns";
import Card from "../../components/ui/Card/Card";

const ForexSettingsPage: React.FC<{}> = (props) => {
  return (
    <React.Fragment>
      <ForexDetailsView />
    </React.Fragment>
  );
};

export default ForexSettingsPage;

import React, { HtmlHTMLAttributes } from "react";
import { BurgetNavigationProps } from "../../../models/Props/Navigation/NavigationProps";
import "./_burger-navigation.scss";

const BurgerNavigation: React.FC<BurgetNavigationProps> = (props) => {
	return (
		<div>
			<button
				className={`burger-navigation-button ${
					props.className !== undefined ? props.className : ""
				} ${props.isDropped ? "burger-navigation-button--alternate" : ""}`}
			>
				<span className="icon--filled">menu</span>
			</button>
		</div>
	);
};

export default BurgerNavigation;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardInformation from "../CardInformation/CardInformation";
import Card from "../ui/Card/Card";
import { useAppSelector } from "../../hooks/StateHooks";
import { useDispatch } from "react-redux";
import { loadUserProfile } from "../../store/Actions/UsersActions";

const ProfileDisplayCard: React.FC<{ userId: string }> = (props) => {
  let userData = useAppSelector((state) => state.users.currentProfile);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile(props.userId));
  }, []);

  return (
    <Card
      theme={"white"}
      isDashboardElement={true}
      className="overview-item__column"
    >
      <CardInformation
        variant="fullGrid"
        title="Personal Information Summary"
        header="Information"
        description=""
        headerCallToActionElement={
          <Link to={`/profile/${props.userId}/edit`}>Edit profile</Link>
        }
      ></CardInformation>

      <div>
        <div className="display-group">
          <div className="grid-2-col">
            <h6>Personal information</h6>
          </div>
          <div className="grid-2-col">
            <span>Username:</span>
            <span>{userData?.profile.username}</span>
          </div>
          <div className="grid-2-col">
            <span>First name:</span>
            <span>{userData?.profile.firstName}</span>
          </div>
          <div className="grid-2-col">
            <span>Last name:</span>
            <span>{userData?.profile.lastName}</span>
          </div>
          <div className="grid-2-col">
            <span>Date of birth:</span>
            <span>{userData?.profile.dateOfBirth}</span>
          </div>
        </div>
        <div className="display-group">
          <div className="grid-2-col">
            <h6>Contact Information</h6>
          </div>

          <div className="grid-2-col">
            <span>Address:</span>
            <span>{userData?.profile.address}</span>
          </div>
          <div className="grid-2-col">
            <span>Phone number:</span>
            <span>{userData?.profile.phoneNumber}</span>
          </div>
          <div className="grid-2-col">
            <span>Email:</span>
            <span>{userData?.profile.emailAddress}</span>
          </div>
        </div>

        <div className="display-group">
          <div className="grid-2-col">
            <h6>Bank information</h6>
          </div>
          <div className="grid-2-col">
            <span>Phone number:</span>
            <span>{userData?.profile.bankAccountNumber}</span>
          </div>
          <div className="grid-2-col">
            <span>Account holder:</span>
            <span>{userData?.profile.bankAccountHolderName}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProfileDisplayCard;

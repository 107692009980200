import React from "react";
import "./_error-notification.scss";

const ErrorNotification: React.FC<{ title?: string; description?: string }> = (
	props
) => {
	return (
		<div className="error-notification">
			<div className="error-notification__error-block">
				{props.title !== undefined && (
					<span className="error-block__title">{props.title}</span>
				)}
				{props.description !== undefined && (
					<span className="error-notification__error-block__details">
						{props.description}
					</span>
				)}
			</div>
		</div>
	);
};
export default ErrorNotification;

import React, { useEffect } from "react";
import CardInformation from "../../components/CardInformation/CardInformation";
import { TwoColumnsFocusLeft } from "../../components/Layouts/PageStructure/GridColumns";
import Card from "../../components/ui/Card/Card";
import { useParams } from "react-router-dom";
import UserSalaryHistoryBlock from "../../components/Salary/UserSalaryHistoryBlock";
import UserSalaryGraphCard from "../../components/Salary/UserSalaryGraphCard";
import { useDispatch } from "react-redux";
import { loadUserProfile } from "../../store/Actions/UsersActions";
import ProfileNavigation from "../../components/Profile/ProfileNavigation/ProfileNavigation";

const ProfileSalaryPage: React.FC<{}> = (props) => {
	let dispatch = useDispatch();
	let { userId } = useParams();

	useEffect(() => {
		if (userId != null) {
			dispatch(loadUserProfile(userId));
		}
	}, [userId]);
	return (
		<React.Fragment>
			{userId !== undefined &&
				userId !== null &&
				userId.trim().length !== 0 && <ProfileNavigation userId={userId} />}
			<TwoColumnsFocusLeft>
				{userId != undefined && <UserSalaryHistoryBlock userId={userId} />}
				<Card
					theme={"cream"}
					isDashboardElement={true}
					className="overview-item__column"
				>
					<CardInformation
						variant={"full"}
						description={`Show your salary progression for ${new Date().toLocaleString(
							undefined,
							{ month: "long", year: "numeric" }
						)}`}
						title="Salary History"
						header="Salary"
					></CardInformation>
					{userId !== undefined && (
						<div className="current-week--graph">
							<UserSalaryGraphCard userId={userId} />
						</div>
					)}
				</Card>
			</TwoColumnsFocusLeft>
		</React.Fragment>
	);
};

export default ProfileSalaryPage;

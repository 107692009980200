import { useState } from "react";
import { HttpError } from "../models/Errors/HttpError";
import { queryStringBuilder } from "../Helpers/HttpHelpers";
import { KeyValuePair } from "../models/General/GeneralModel";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../store/UiSlice";

const useHttp = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [data, setData] = useState<any>();
	const dispatch = useDispatch();
	const baseUrl = process.env.REACT_APP_API_SERVER ?? "https://localhost:7056";

	const executeGet = async (
		url: string,
		callBack: (data: any) => any,
		urlParameters?: KeyValuePair[],
		shouldIncludeBase: boolean = true
	) => {
		try {
			setIsLoading(true);

			let fetchUrl: string = shouldIncludeBase ? baseUrl.concat(url) : url;

			if (urlParameters != undefined) {
				let queryString = queryStringBuilder(urlParameters);

				fetchUrl = shouldIncludeBase
					? baseUrl.concat(url, "?", queryString)
					: url.concat("?", queryString);
			}

			let response = await fetch(fetchUrl);

			if (!response.ok) {
				throw new HttpError(
					"Something went wrong while communicating with the server.  Please contact the system administrator."
				);
			}

			let jsonResponse = await response.json();

			callBack(jsonResponse);

			setData(jsonResponse);
			setIsLoading(false);
		} catch (error: any) {
			setError(error.message);
			setIsLoading(false);
			dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
		}
	};

	const executeAdd = async <T>(
		url: string,
		body: T,
		urlParameters?: KeyValuePair[],
		callBack?: (data: any) => void,
		shouldIncludeBase: boolean = true
	) => {
		try {
			setIsLoading(true);

			let postUrl = shouldIncludeBase ? baseUrl.concat(url) : url;
			if (urlParameters != undefined) {
				let queryString = queryStringBuilder(urlParameters);

				postUrl = shouldIncludeBase
					? baseUrl.concat(url, "?", queryString)
					: url.concat("?", queryString);
			}

			let response = await fetch(postUrl, {
				body: JSON.stringify(body),
				method: "PUT",
				headers: {
					"Content-Type": "Application/json",
				},
			});

			if (!response.ok) {
				throw new HttpError(
					"Something went wrong while communicating with the server.  Please contact the system administrator."
				);
			}
			const jsonResponse = await response.json();
			setData(jsonResponse);
			if (callBack !== undefined) {
				callBack(jsonResponse);
			}
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			setError(error.message);
			dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
		}
	};

	const executePost = async <T>(
		url: string,
		body: T,
		callBack?: (data: any) => void,
		urlParameters?: KeyValuePair[],
		shouldIncludeBase: boolean = true
	) => {
		try {
			setIsLoading(true);
			let postUrl = shouldIncludeBase ? baseUrl.concat(url) : url;
			if (urlParameters != undefined) {
				let queryString = queryStringBuilder(urlParameters);

				postUrl = shouldIncludeBase
					? baseUrl.concat(url, "?", queryString)
					: url.concat("?", queryString);
			}

			let response = await fetch(postUrl, {
				body: JSON.stringify(body),
				method: "POST",
				headers: {
					"Content-Type": "Application/json",
				},
			});

			if (!response.ok) {
				throw new HttpError(
					"Something went wrong while communicating with the server.  Please contact the system administrator."
				);
			}

			var apiData = await response.json();
			if (callBack != undefined) {
				callBack(apiData);
			}

			setData(apiData);
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			setError(error.message);
			dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
		}
	};

	const executeDelete = async <T>(
		url: string,
		body: T,

		urlParameters?: KeyValuePair[],
		callBack?: (data: any) => void,
		shouldIncludeBase: boolean = true
	) => {
		try {
			setIsLoading(true);
			let postUrl = shouldIncludeBase ? baseUrl.concat(url) : url;
			if (urlParameters != undefined) {
				let queryString = queryStringBuilder(urlParameters);

				postUrl = shouldIncludeBase
					? baseUrl.concat(url, "?", queryString)
					: url.concat("?", queryString);
			}

			let response = await fetch(postUrl, {
				body: JSON.stringify(body),
				method: "DELETE",
				headers: {
					"Content-Type": "Application/json",
				},
			});

			if (!response.ok) {
				throw new HttpError(
					"Something went wrong while communicating with the server.  Please contact the system administrator."
				);
			}

			let data = await response.json();
			setData(data);
			if (callBack != undefined) {
				callBack(data);
			}

			setIsLoading(false);
			return data;
		} catch (error: any) {
			setIsLoading(false);
			setError(error.message);
			dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
		}
	};

	return {
		isLoading,
		error,
		data,
		executeGet,
		executeAdd,
		executePost,
		executeDelete,
	};
};

export default useHttp;

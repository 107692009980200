import React from "react";
import { OverviewNotificationProps } from "../../../models/Props/OverviewNotification/OverviewNotificationProps";

import "./_overview-notification.scss";

const OverviewNotification: React.FC<OverviewNotificationProps> = (props) => {
  return (
    <div className="overview-notification">
      <div className="overview-notification__icon">
        <span className="icon--filled"> {props.icon} </span>
        <div className="overview-notification__vertical-line"></div>
      </div>
      <div className="overview-notification__description">
        <span className="overview-notification__description--title">
          {props.title}
        </span>
        <span className="overview-notification__description--date">
          {props.date}
        </span>
      </div>
    </div>
  );
};

export default OverviewNotification;

import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "../../components/Authentication/Login/LoginForm";
import FlowboosterPresentationCard from "../../components/Flowbooster/FlowboosterPresentationCard";
import FlexContainer from "../../components/Layouts/PageStructure/FlexContainer";
import Card from "../../components/ui/Card/Card";

import "./_login-page.scss";

const LoginPage: React.FC<{}> = (props) => {
	return (
		<React.Fragment>
			<FlexContainer className="desktop">
				<div className="login-container">
					<div className="login-panel login-panel--grid">
						<div className="login-panel--grid__left">
							<Card theme={"white"} isDashboardElement={false}>
								<div className="login-panel__top-content">
									<div className="login-panel__form-title">
										<h1>Account Login</h1>
									</div>
									<div className="login-panel__form-intro">
										<p>
											If you are already a member you can log in with your email
											address and password.
										</p>
									</div>
								</div>

								<LoginForm />
								<div className="login-panel__addendum">
									<span>
										Don't have an account yet?{" "}
										{/* <Link to="/create-account" className="login-panel__link">
											Join the FlowBooster team!
										</Link> */}
										<a href="skype:live:.cid.64edb2ae32d9a0c9?chat">
											Contact us now!
										</a>
									</span>
								</div>
							</Card>
						</div>
						<FlowboosterPresentationCard className="login-panel--grid__right" />
					</div>
				</div>
			</FlexContainer>
			<FlexContainer className="mobile">
				<div className="login-panel">
					<div className="login-panel__white-overlay">
						<div className="login-panel__logo">
							<span className="icon--filled login-panel__logo--text">
								offline_bolt
							</span>
						</div>
						<div className="login-panel__intro-text">
							<span>FlowBooster</span>
						</div>
						<LoginForm />
						<div className="login-panel__addendum">
							<span>
								Don't have an account yet?{" "}
								<Link to="/create-account" className="login-panel__link">
									Sign up.
								</Link>
							</span>
						</div>
					</div>
				</div>
			</FlexContainer>
		</React.Fragment>
	);
};

export default LoginPage;

export enum CustomerAction {
    Load = "LOAD_DATA", 
    Firstname = "CUSTOMER_FIRSTNAME", 
    Lastname = "CUSTOMER_LASTNAME", 
    Username = "CUSTOMER_USERNAME", 
    BusinessEmailAddress = "CUSTOMER_EMAIL", 
    BusinessAddress = "CUSTOMER_ADDRESS", 
    BusinessPhoneNumber = "CUSTOMER_PHONE_NUMBER",   
    IdentificationNumber = "CUSTOMER_IDENTIFICATION_NUMBER" , 
    IsConfirmed = "CUSTOMER_IS_CONFIRMED" 
}
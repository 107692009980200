import React, { FormEvent, useState, useEffect } from "react";
import useHttp from "../../../hooks/UseHttp";
import FormContainer from "../../Forms/FormContainer";
import { FormHeader, FormRow } from "../../Forms/FormElements/FormStructure";
import {
	BasicInput,
	BasicSelectInput,
} from "../../Forms/FormInputs/BasicInputs";
import Button from "../../ui/Button/Button";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../../../store/UiSlice";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { KeyValuePair } from "../../../models/General/GeneralModel";
import { mapResponseToApiModelList } from "../../../Helpers/AccountHelpers";

import { mapDataToUserApiModelList } from "../../../Helpers/UserHelpers";
import { ExtraCostRequestApiModel } from "../../../models/Api/ExtraCosts/ExtraCostsApiModel";
import { ExtraCostType } from "../../../models/Enums/ExtraCosts/ExtraCostsEnum";
import { loadExtraCostsList } from "../../../store/Actions/ExtraCostsActions";

import "./_extra-cost-entry-form.scss";

const ExtraCostEntryFormActions: React.FC<{ isSaveDisabled: boolean }> = (
	props
) => {
	let dispatch = useDispatch();
	return (
		<React.Fragment>
			<Button
				theme={"info"}
				onClick={(event) => {
					event.preventDefault();
					dispatch(uiSliceActions.showModal(false));
				}}
			>
				Cancel
			</Button>
			<Button
				className={`${props.isSaveDisabled ? "button--disabled" : ""}`}
				theme={"primary"}
				disabled={props.isSaveDisabled}
			>
				Save
			</Button>
		</React.Fragment>
	);
};
const ExtraCostsEntryForm: React.FC<{}> = (props) => {
	let [entryType, setEntryType] = useState<ExtraCostType>();
	let [title, setTitle] = useState<string>();
	let { isLoading, executeGet: fetchData, executeAdd: saveData } = useHttp();
	let [accountList, setAccountList] = useState<KeyValuePair[]>();
	let [userList, setUserList] = useState<KeyValuePair[]>();
	let [accountId, setAccountId] = useState<string>();
	let [userId, setUserId] = useState<string>();
	let [currency, setCurrency] = useState<string>();
	let [amount, setAmount] = useState<number>();
	let [date, setDate] = useState<Date>();

	let dispatch = useDispatch();

	useEffect(() => {
		fetchData(EndpointUrls.Account, (apiData) => {
			let mappedAccountListData = mapResponseToApiModelList(apiData);
			if (mappedAccountListData !== undefined) {
				setAccountList((prev) => {
					let accountList: KeyValuePair[] = mappedAccountListData!.map((t) => {
						let keyValue: KeyValuePair = {
							key: t.accountNumber,
							value: t.id,
						};
						return keyValue;
					});

					return accountList;
				});
			}
		});

		fetchData(EndpointUrls.Users, (apiData) => {
			let mappedUserListData = mapDataToUserApiModelList(apiData);
			if (mappedUserListData !== undefined) {
				setUserList((prev) => {
					let userList: KeyValuePair[] = mappedUserListData!.map((t) => {
						let keyValue: KeyValuePair = {
							key: `${t.profile.lastName} ${t.profile.firstName}`,
							value: t.userId,
						};
						return keyValue;
					});

					return userList;
				});
			}
		});
	}, []);

	let isFormInvalid =
		currency === undefined ||
		entryType === undefined ||
		title === undefined ||
		amount === undefined ||
		date === undefined;
	const submitFormHandler = (event: FormEvent) => {
		event.preventDefault();

		if (isFormInvalid) {
			return;
		}

		let request: ExtraCostRequestApiModel = {
			amount: amount,
			costType: entryType,
			title: title,
			currency: currency,
			date: date,
			relationId: entryType === ExtraCostType.ACCOUNT ? accountId : userId,
		};

		saveData(EndpointUrls.ExtraCosts, request).then(() => {
			dispatch(loadExtraCostsList());
			dispatch(uiSliceActions.showModal(false));
		});
	};

	return (
		<FormContainer
			onSubmit={submitFormHandler}
			formActions={<ExtraCostEntryFormActions isSaveDisabled={isFormInvalid} />}
			className="extra-costs-entry-form"
		>
			<FormHeader
				title={"Extra costs entry form"}
				description={"Lorem"}
				className="extra-costs-entry-form__header"
			></FormHeader>
			<FormRow type={"row"}>
				<BasicInput
					placeholder="Date"
					type="date"
					required
					onChange={(event) => {
						if (event.currentTarget.value.length > 0) {
							setDate(new Date(event.currentTarget.value));
						}
					}}
				/>
			</FormRow>
			<FormRow type={"row"}>
				<BasicInput
					placeholder="Title"
					required
					value={title}
					onChange={(event) => {
						setTitle(event.currentTarget.value);
					}}
				/>
			</FormRow>
			<FormRow type={"row"}>
				<BasicSelectInput
					placeholder="Title"
					label="What is this cost for?"
					onChange={(event) => {
						if (event.currentTarget.value !== "default") {
							setEntryType(Number.parseInt(event.currentTarget.value));
						} else {
							setEntryType(undefined);
						}
					}}
				>
					<option value="default"> Please select</option>
					<option value={ExtraCostType.ACCOUNT}>Account</option>
					<option value={ExtraCostType.OPERATOR}>Operator</option>
				</BasicSelectInput>
			</FormRow>
			{entryType === ExtraCostType.ACCOUNT && (
				<FormRow type={"row"}>
					<BasicSelectInput
						placeholder="Title"
						label="Account"
						value={accountId}
						onChange={(event) => {
							if (event.currentTarget.value !== "default") {
								setAccountId(event.currentTarget.value);
							} else {
								setAccountId(undefined);
							}
						}}
					>
						<option value="default">Select the corresponding account</option>
						{accountList !== undefined &&
							accountList.map((accountInfo) => (
								<option key={accountInfo.value} value={accountInfo.value}>
									{accountInfo.key}
								</option>
							))}
					</BasicSelectInput>
				</FormRow>
			)}

			{entryType === ExtraCostType.OPERATOR && (
				<FormRow type={"row"}>
					<BasicSelectInput
						placeholder="Title"
						label="Operator"
						value={userId}
						onChange={(event) => {
							if (event.currentTarget.value !== "default") {
								setUserId(event.currentTarget.value);
							} else {
								setUserId(undefined);
							}
						}}
					>
						<option value="default">Select the corresponding user</option>
						{userList !== undefined &&
							userList.map((userInfo) => (
								<option key={userInfo.value} value={userInfo.value}>
									{userInfo.key}
								</option>
							))}
					</BasicSelectInput>
				</FormRow>
			)}
			<FormRow type={"row"}>
				<BasicInput
					placeholder="Amount"
					type="number"
					step="0.01"
					disabled={entryType === undefined}
					required
					value={amount}
					onChange={(event) => {
						if (event.currentTarget.value.length > 0) {
							let value: number = Number.parseFloat(event.currentTarget.value);
							setAmount(value);
						}
					}}
				/>
			</FormRow>
			<FormRow type={"row"}>
				<span>In which currency is it?</span>
			</FormRow>
			<FormRow type={"grid"}>
				<BasicInput
					type="radio"
					placeholder="Ariary"
					id="currency_mga"
					name="currency"
					value="MGA"
					disabled={entryType === undefined}
					required
					onChange={(event) => {
						setCurrency(event.currentTarget.value);
					}}
				></BasicInput>
				<BasicInput
					disabled={entryType === undefined}
					type="radio"
					placeholder="Euro"
					id="currency_eur"
					name="currency"
					value="EUR"
					required
					onChange={(event) => {
						setCurrency(event.currentTarget.value);
					}}
				></BasicInput>
			</FormRow>
		</FormContainer>
	);
};

export default ExtraCostsEntryForm;

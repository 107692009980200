import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import { ServerInformation, ServerState } from "../models/Api/Servers/Model";
import tarificationSlice from "./TarificationSlice";

const initialState: ServerState = {
	serverList: [],
	isLoading: false,
};

const setServerList = (
	state: ServerState,
	action: PayloadAction<ServerInformation[]>
) => {
	let newState = { ...state };

	newState.serverList = action.payload;
	return newState;
};

const setLoading = (state: ServerState, action: PayloadAction<boolean>) => {
	let newState = { ...state };
	newState.isLoading = action.payload;
	return newState;
};

const serverSlice = createSlice({
	initialState: initialState,
	name: "servers",
	reducers: { setServerList, setLoading },
});

export const serversSliceAction = serverSlice.actions;
export default serverSlice;

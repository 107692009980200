import React, { useRef, useState } from "react";
import {
	TabsButtonContainerProps,
	TabsContentProps,
	TabsProps,
} from "../../models/Props/Tabs/Model";

import "./_tabs.scss";

export const TabsButtonContainer: React.FC<TabsButtonContainerProps> = (
	props
) => {
	return (
		<div className="tabs__button-container">
			{props.buttons.map((button) => (
				<div
					className={`tabs__button-container__button ${
						button.isActive ? `tabs__button-container__button--active ` : ``
					}`}
					id={button.id}
					onClick={button.onClick}
				>
					<span>{button.title}</span>
				</div>
			))}
		</div>
	);
};
export const TabsContentContainer: React.FC<{}> = (props) => {
	return <div className="tabs__content-container">{props.children}</div>;
};

export const TabsContent: React.FC<TabsContentProps> = (props) => {
	return (
		<div
			className={`tabs__content-container__tab-content ${
				props.isActive ? `tabs__content-container__tab-content--active-tab` : ""
			}`}
		>
			{props.children}
		</div>
	);
};

const Tabs: React.FC<TabsProps> = (props) => {
	return (
		<div
			className={`tabs ${props.className !== undefined ? props.className : ""}`}
		>
			{props.children}
		</div>
	);
};

export default Tabs;

import { OperatorPerformanceModel } from "../models/Api/OperatorsPerformance/OperatorPerformanceModel";

export const mapApiDataToOperatorPerformanceModel = (apiData: any) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	let result: OperatorPerformanceModel = {
		accountName: apiData.accountName,
		fullName: apiData.fullName,
		id: apiData.id,
		performanceScore: apiData.performanceScore,
		totalMessagesSent: apiData.totalMessagesSent,
		totalSalary: apiData.totalSalary,
		totalWorkedHours: apiData.totalWorkedHours,
		userId: apiData.userId,
	};

	return result;
};

export const mapApiDataToOperatorPerformanceModelList = (apiData: any) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	let resultList: OperatorPerformanceModel[] = [];

	apiData.forEach((item: any) => {
		let mappedResult = mapApiDataToOperatorPerformanceModel(item);

		if (mappedResult !== undefined) {
			resultList = resultList.concat(mappedResult);
		}
	});

	return resultList;
};

import React, { ChangeEvent, FormEvent, useState } from "react";
import useMessageApi from "../../../hooks/UseMessageApi";
import {
	NoteCategory,
	SendNoteRequest,
} from "../../../models/Flowbooster/Models";
import {
	BasicSelectInput,
	BasicTextArea,
} from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";
import "./_profileNoteForm.scss";

const ProfileNoteForm: React.FC<{
	platform: string;
	playerProfileId?: string;
	targetProfileId?: string;
	noteCategoryList: NoteCategory[];
	userId: string;
	isPlayerNote?: boolean;
	ipAddress: string;
	onNoteSent: () => void;
}> = (props) => {
	const [noteDetail, setNoteDetail] = useState<string>();
	const [selectedCategory, setSelectedCategory] = useState<number>();
	let isNoteEmpty: boolean = true;

	const { isLoading, sendNote } = useMessageApi();

	const onNoteContentFieldChangeHandler = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		if (event.currentTarget !== undefined) {
			setNoteDetail(event.currentTarget.value);
		}
	};

	const onCategorySelectorChanged: (
		event: ChangeEvent<HTMLSelectElement>
	) => void = (event) => {
		event.preventDefault();
		if (event.currentTarget.value !== "0") {
			let category: number = +event.currentTarget.value;
			setSelectedCategory(category);
		}
	};

	isNoteEmpty = noteDetail === undefined || noteDetail?.trim().length === 0;

	const submitNoteHandler = async (event: FormEvent) => {
		event.preventDefault();

		if (
			selectedCategory === 0 ||
			isNoteEmpty ||
			selectedCategory === undefined
		) {
			return;
		}

		let noteRequest: SendNoteRequest = {
			categoryId: selectedCategory.toString(),
			content: noteDetail,
			isPlayerNote: props.isPlayerNote,
			playerId: props.playerProfileId,
			targetId: props.targetProfileId,
		};

		await sendNote(props.ipAddress, props.userId, props.platform, noteRequest);
		props.onNoteSent();
	};

	let count = 0;

	return (
		<div className="add-profile-note-container">
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<form onSubmit={submitNoteHandler} className="add-profile-note-form">
					<h3 className="add-profile-note-form__title">Add note:</h3>
					<BasicSelectInput
						className="add-profile-note-form__note-category"
						onChange={onCategorySelectorChanged}
					>
						<option value={0}> Select your category</option>
						{props.noteCategoryList.map((noteCategory) => (
							<option key={count++} value={noteCategory.id}>
								{noteCategory.name}
							</option>
						))}
					</BasicSelectInput>
					<BasicTextArea
						className="add-profile-note-form__note-content"
						onChange={onNoteContentFieldChangeHandler}
					></BasicTextArea>
					<div>
						<Button
							theme={"warning"}
							className={`add-profile-note-form__submit ${
								isNoteEmpty ? "button--disabled" : ""
							}`}
							type="submit"
							disabled={
								isNoteEmpty && selectedCategory === undefined ? true : false
							}
						>
							<span className="icon--filled">note_add</span>
							<span>Add Note</span>
						</Button>
					</div>
				</form>
			)}
		</div>
	);
};

export default ProfileNoteForm;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/StateHooks";
import { DialogConfirmationProps } from "../../models/Props/Confirmation/DialogConfirmationProps";
import { uiSliceActions } from "../../store/UiSlice";
import FormContainer from "../Forms/FormContainer";
import { FormHeader, FormRow } from "../Forms/FormElements/FormStructure";
import Button from "../ui/Button/Button";
import "./_confirmation_dialog.scss";

const DialogConfirmationForm: React.FC<DialogConfirmationProps> = (props) => {
let dispatch = useDispatch();
 

  const submitHandler= (event: React.FormEvent) => {
    event.preventDefault(); 
    if (props.data !== undefined && props.data !== null) {     
        props.action(props.data);
        dispatch(uiSliceActions.showModal(false));
    }
  };

 
  return (
    <FormContainer
      onSubmit={submitHandler}
      onReset={props.resetHandler}
      className="dialog-confirmation-form"
    >
      <FormHeader title={"Action confirmation"} description={""} />

      <FormRow type={"grid"}>
        <p>This action is irreversible</p>
        <span>Are you sure?</span>
      </FormRow>

      <FormRow type={"grid"}>
        <Button theme={"primary"} type="reset">
          Cancel
        </Button>
        <Button theme={"danger"} type="submit">
          Yes
        </Button>
      </FormRow>
    </FormContainer>
  );
};
export default DialogConfirmationForm;

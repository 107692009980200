import React from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout/DashboardLayout";
import { Outlet } from "react-router";

const DashboardIndexPage: React.FC<{}> = (props) => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default DashboardIndexPage;

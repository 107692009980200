import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import AddAccountForm from "../../components/Accounts/AddAccountForm";
import CreateAccountForm from "../../components/Authentication/Registration/CreateAccountForm";
import CniValidationForm from "../../components/Customers/CNI/CniValidation";
import { CustomerCardViewList } from "../../components/Customers/Dashboard/CustomerCardViewList";
import { DashboardTitle } from "../../components/Layouts/DashboardLayout/DashboardLayout";
import { Breadcrumb } from "../../components/ui/Breadcrumbs/Breadcrumbs";
import Card from "../../components/ui/Card/Card";
import {
	NotificationAlert,
	NotificationCardList,
} from "../../components/ui/Notification/NotificationCard";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import {
	CustomerDashboardListProps,
	CustomerDashboardProps,
} from "../../models/Props/Customers/CustomerDashboardProps";
import { NotificationCardProps } from "../../models/Props/Notification/NotificationCard";
import {
	loadAssociatedAccounts,
	loadAssociatedUsers,
	loadClient,
} from "../../store/Actions/ClientsActions";
import { loadUser } from "../../store/Actions/UsersActions";
import "./_customer-dashboard.scss";

const CustomerDashboard: React.FC<{}> = (props) => {
	const currentUser = useAppSelector((state) => state.users.currentUser);
	const currentClient = useAppSelector((state) => state.clients.currentClient);
	const accountList = useAppSelector(
		(state) => state.clients.associatedAccountsList
	);
	const userList = useAppSelector((state) => state.clients.associatedUsersList);
	console.log(currentUser, "current(user)");

	const dispatch = useDispatch();

	const { clientId } = useParams();

	const cardViewList: CustomerDashboardProps[] = [];

	console.log(clientId);

	if (accountList !== undefined && accountList.length > 0) {
		cardViewList.push({
			cardAccount: accountList.length,
			cardTitle: "Accounts",
			cardLink: `/customer/${clientId}/accounts`,
			textColor: "blue",
			description: "Account(s) registered in our system.",
		});
	} else {
		cardViewList.push({
			cardAccount: 0,
			cardTitle: "Accounts",
			cardLink: `/customer/${clientId}/accounts`,
			textColor: "blue",
			description: "You haven't registered any account(s) yet.",
		});
	}

	if (userList !== undefined && userList.length > 0) {
		cardViewList.push({
			cardAccount: userList.length,
			cardTitle: "Operators",
			cardLink: `/customer/${clientId}/operators`,
			textColor: "yellow",
			description: "Operator(s) registered in our system.",
		});
	} else {
		cardViewList.push({
			cardAccount: 0,
			cardTitle: "Operators",
			cardLink: `/customer/${clientId}/operators`,
			textColor: "yellow",
			description: "You haven't registerd any operator(s) yet/",
		});
	}

	const notificationList: NotificationCardProps[] = [
		{
			notificationTitle: "Latest Account Registered",
			notificationIcon: "account_box",
			notificationName: "Account's Name",
		},
		{
			notificationTitle: "Latest Operator Registered",
			notificationIcon: "group",
			notificationName: "Operator's Name",
		},
		{
			notificationTitle: "Latest Invoice",
			notificationIcon: "receipt",
			notificationName: "Invoice's Number",
		},
	];

	useEffect(() => {
		if (clientId !== undefined) {
			dispatch(loadAssociatedAccounts(clientId));
			dispatch(loadAssociatedUsers(clientId));
		}
	}, []);

	return (
		<React.Fragment>
			{currentClient !== undefined &&
				currentClient !== null &&
				!currentClient.isConfirmed && (
					<CniValidationForm userId={currentClient.id} />
				)}

			{currentClient !== undefined &&
				currentClient !== null &&
				currentClient.isConfirmed && (
					<React.Fragment>
						<DashboardTitle dashboardTitle={"Dashboard"} />

						<Breadcrumb
							crumbLink={`/customer/${clientId}/dashboard`}
							pageTitle={"Home"}
						/>
						<NotificationAlert
							className="mobile"
							notificationLink={`/customer/${clientId}/invoice`}
							notification={"1 Open Invoice(s)"}
						/>
						<CustomerCardViewList cardList={cardViewList} />
						<NotificationCardList
							className="desktop"
							notificationCardList={notificationList}
						/>
					</React.Fragment>
				)}
		</React.Fragment>
	);
};

export default CustomerDashboard;

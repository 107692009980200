import React, { useEffect, useState } from "react";
import Card from "../../components/ui/Card/Card";
import { SingleFullWidthColumnContentCentered } from "../../components/Layouts/PageStructure/GridColumns";
import CardInformation from "../../components/CardInformation/CardInformation";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal/Modal";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import LoadingAnimation from "../../components/Loading/Loading";
import { OverviewTableDataProps } from "../../models/Props/OverviewTable/OverviewTableProps";
import { usersSliceActions } from "../../store/UsersSlice";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import UserApiModel from "../../models/Api/Users/UserApiModel";
import { Link } from "react-router-dom";
import EditOperatorForm from "../../components/Users/EditOperatorForm";
import { uiSliceActions } from "../../store/UiSlice";
import { loadUserList } from "../../store/Actions/UsersActions";
import DialogConfirmationForm from "../../components/Confirmation/DialogConfirmation";
import { sassTrue } from "sass";
import Table, {
	TableHeader,
	TableItem,
	TableRow,
	TableRowWithSlider,
} from "../../components/Tables/Table";
import "./_manage-tables.scss";
import SelectCustomerForm from "../../components/Customers/SelectCustomerForm/SelectCustomerForm";

const ManageOperatorsDashboard: React.FC<{}> = (props) => {
	const showModal = useAppSelector((state) => state.ui.showModal);
	const usersState = useAppSelector((state) => state.users.users);
	const [selectedUserId, setSelectedUserId] = useState<string>("");
	const [isClicked, setIsClicked] = useState<boolean>(false);
	const [showEditUserForm, setShowEditUserForm] = useState<boolean>(false);
	const [isRemoved, setIsRemoved] = useState<boolean>(false);
	const [isDeleted, setIsDeleted] = useState<boolean>(false);
	const [showActions, setShowActions] = useState<boolean>(false);
	const [test, setTest] = useState<boolean>(false);
	const [showClientSelectionForm, setShowClientSelectionForm] =
		useState<boolean>(false);

	const elementClass = `element-actions ${showActions ? "not-show" : ""}`;
	const { isLoading, executeDelete: deleteAction } = useHttp();
	const isScreenLoading = useAppSelector((state) => state.ui.isScreenLoading);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadUserList());
	}, []);
	useEffect(() => {}, [showClientSelectionForm]);

	const editUser: (data: string) => void = (data: string) => {
		if (data === undefined || data.trim().length === 0) {
			return;
		}
		setSelectedUserId(data);
		setShowEditUserForm(true);
		dispatch(uiSliceActions.showModal(true));
	};

	const removeAccountFromUser = async (data: string) => {
		if (data === undefined || data.trim().length === 0) {
			console.log("null data");
			return;
		}

		let selectedUser = usersState.find((t) => t.userId === data);

		if (selectedUser != undefined && selectedUser !== null) {
			await deleteAction(EndpointUrls.UserAccountManagement, undefined, [
				{ key: "userId", value: data },
				{ key: "accountId", value: selectedUser.accounts.id },
			]);
			dispatch(loadUserList());
			setIsRemoved(false);
		}
	};

	const deleteUser: (data: string) => void = (data: string) => {
		if (data === undefined || data.trim().length === 0) {
			return;
		}
		deleteAction<string>(EndpointUrls.Users, data).then(() => {
			dispatch(usersSliceActions.removeUser(data));
			setIsDeleted(false);
		});
	};

	const showForm = (data: string) => {
		setSelectedUserId(data);
		dispatch(uiSliceActions.showModal(true));
	};

	const resetActionHandler = (event: React.FormEvent) => {
		event.preventDefault();
		if (isRemoved) {
			setIsRemoved(false);
			dispatch(uiSliceActions.showModal(false));
		}
		if (isDeleted) {
			setIsDeleted(false);
			dispatch(uiSliceActions.showModal(false));
		}
	};

	return (
		<React.Fragment>
			{showModal && selectedUserId.trim().length > 0 && showEditUserForm && (
				<Modal
					title="Manage Operators"
					onClose={() => {
						setIsClicked(false);
						setShowEditUserForm(false);
					}}
				>
					<EditOperatorForm userId={selectedUserId} />
				</Modal>
			)}

			{isRemoved && showModal && selectedUserId.trim().length > 0 && (
				<Modal
					title="Remove Account"
					onClose={() => {
						setIsRemoved(false);
					}}
				>
					<DialogConfirmationForm
						data={selectedUserId}
						action={removeAccountFromUser}
						resetHandler={resetActionHandler}
					/>
				</Modal>
			)}
			{isDeleted && showModal && selectedUserId.trim().length > 0 && (
				<Modal
					title="Delete User"
					onClose={() => {
						setIsDeleted(false);
					}}
				>
					<DialogConfirmationForm
						data={selectedUserId}
						action={deleteUser}
						resetHandler={resetActionHandler}
					/>
				</Modal>
			)}

			{showClientSelectionForm &&
				showModal &&
				selectedUserId.trim().length > 0 && (
					<Modal
						onClose={() => {
							setShowClientSelectionForm(false);
						}}
					>
						<SelectCustomerForm
							relationId={selectedUserId}
							saveEndpoint={EndpointUrls.UserClientRelation}
						/>
					</Modal>
				)}

			<SingleFullWidthColumnContentCentered>
				<Card
					isDashboardElement={true}
					theme="grey"
					className="overview-item__column"
				>
					<CardInformation
						variant="fullGrid"
						header="Manage Operators"
					></CardInformation>
					{isLoading || (isScreenLoading && <LoadingAnimation />)}
					{!isLoading &&
						!isScreenLoading &&
						usersState != null &&
						usersState != undefined &&
						usersState.length > 0 && (
							<Table className="manage-table">
								<TableHeader className="manage-table__header">
									<TableItem>User ID</TableItem>
									<TableItem>Name</TableItem>
									<TableItem className="desktop">Contact</TableItem>
									<TableItem className="desktop">Role</TableItem>
									<TableItem>Account</TableItem>
									<TableItem>Actions</TableItem>
								</TableHeader>
								{usersState.map((user) => {
									let tset: boolean = false;
									return (
										<TableRowWithSlider
											key={Math.random()}
											className="manage-table__data-row"
											showSliderOn="mobile"
											sliderContent={
												<React.Fragment>
													<Link
														to={`/profile/${user.userId}/overview`}
														className="button-icon"
													>
														<span className="icon--outlined">visibility</span>
													</Link>
													<a
														href="#"
														onClick={(event) => {
															event.preventDefault();
															editUser(user.userId);
														}}
														className="button-icon"
													>
														<span className="icon--outlined ">
															manage_accounts
														</span>
													</a>
													<a
														href="#"
														onClick={(event) => {
															event.preventDefault();
															console.log("is this triggered");
															setIsRemoved(true);
															showForm(user.userId);
														}}
														className="button-icon"
													>
														<span className="icon--outlined ">no_accounts</span>
													</a>
													<a
														href="#"
														onClick={(event) => {
															event.preventDefault();
															setSelectedUserId(user.userId);
															setShowClientSelectionForm(true);
															dispatch(uiSliceActions.showModal(true));
														}}
														className="button-icon"
													>
														<span className="material-icons-outlined">
															supervised_user_circle
														</span>
													</a>
													<a
														href="#"
														onClick={(event) => {
															event.preventDefault();
															setIsDeleted(true);
															showForm(user.userId);
														}}
														className="button-icon"
													>
														<span className="icon--outlined ">delete</span>
													</a>
												</React.Fragment>
											}
										>
											<TableItem className="desktop">{user.userId}</TableItem>
											<TableItem className={elementClass}>
												{user.firstName} {user.lastName}
											</TableItem>
											<TableItem className="desktop">{user.email}</TableItem>
											<TableItem className="desktop">
												{user.roles.name}
											</TableItem>
											<TableItem className={elementClass}>
												{user.accounts.accountNumber}
											</TableItem>
											<TableItem className="desktop">
												<Link
													to={`/profile/${user.userId}/overview`}
													className="button-icon"
												>
													<span className="icon--outlined desktop">
														visibility
													</span>
												</Link>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														editUser(user.userId);
													}}
													className="button-icon"
												>
													<span className="icon--outlined desktop">
														manage_accounts
													</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														console.log("is this triggered");
														setIsRemoved(true);
														showForm(user.userId);
													}}
													className="button-icon"
												>
													<span className="icon--outlined desktop">
														no_accounts
													</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														setSelectedUserId(user.userId);
														setShowClientSelectionForm(true);
														dispatch(uiSliceActions.showModal(true));
													}}
													className="button-icon"
												>
													<span className="material-icons-outlined">
														supervised_user_circle
													</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														setIsDeleted(true);
														showForm(user.userId);
													}}
													className="button-icon"
												>
													<span className="icon--outlined desktop">delete</span>
												</a>
											</TableItem>
										</TableRowWithSlider>
									);
								})}
							</Table>
						)}
				</Card>
			</SingleFullWidthColumnContentCentered>
		</React.Fragment>
	);
};

export default ManageOperatorsDashboard;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useHttp from "../../../hooks/UseHttp";
import AccountApiModel from "../../../models/Api/Accounts/AccountsApiModel";
import { AddAccountApiResponse } from "../../../models/Api/Accounts/Model";
import UserAccountApiModel from "../../../models/Api/Accounts/UserAccountApiModel";

import { BaseApiResponse } from "../../../models/Api/Base/Model";
import { ClientRelationRequest } from "../../../models/Api/Customers/Models";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { PlatformType } from "../../../models/Enums/Platform/PlaformEnum";
import { loadAssociatedAccounts } from "../../../store/Actions/ClientsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import AccountTypeSelect from "../../Accounts/AccountTypeSelect";
import FormContainer from "../../Forms/FormContainer";
import { FormRow } from "../../Forms/FormElements/FormStructure";
import { BasicInput } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";

const CreateAccountFormAction: React.FC<{
	isFormValid: boolean;
	isLoading: boolean;
}> = (props) => {
	return (
		<React.Fragment>
			<Button type="reset" theme="info" fullWidth={true}>
				<span>Cancel</span>
			</Button>
			<Button
				type="submit"
				theme="warning"
				fullWidth={true}
				className={`registration-form__submit-button ${
					props.isFormValid ? "" : "button--disabled"
				}`}
				disabled={!props.isFormValid}
			>
				{props.isLoading && <LoadingAnimation size="small" />}
				{!props.isLoading && <span>Save</span>}
			</Button>
		</React.Fragment>
	);
};

const AddCustomerAccount: React.FC<{ clientId: string; accountId?: string }> = (
	props
) => {
	const dispatch = useDispatch();
	const [password, setPassword] = useState<string>("");
	const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
	const [accountName, setAccountName] = useState<string>("");
	const [isAccountNameValid, setIsAccountNameValid] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [platform, setPlatform] = useState<PlatformType | undefined>();
	const [ipAddress, setIpAddress] = useState<string>("");
	const [dateAdded, setDateAdded] = useState<string>("");
	const {
		isLoading,
		executePost: saveData,
		executeGet: getInformation,
	} = useHttp();

	const saveCustomerAccountEventHandler = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		let accountInformation: AccountApiModel = {
			id: "",
			accountNumber: accountName,
			accountPassword: password,
			emailAccount: email,
			emailPassword: email,
			skypeAccount: email,
			skypePassword: email,
			dateAdded: dateAdded,
			dateModified: "",
			ipAddress: ipAddress,
			clientId: "",
			serverId: "",
		};

		if (props.accountId !== undefined) {
			accountInformation.id = props.accountId;
		}

		await saveData(
			EndpointUrls.Account,
			accountInformation,
			async (response: AddAccountApiResponse) => {
				if (response.isSuccessful) {
					let clientAccountRelationRequest: ClientRelationRequest = {
						relationId: response.addedAccountId,
						clientId: props.clientId,
					};
					await saveData(
						EndpointUrls.ClientAccountRelation,
						clientAccountRelationRequest,
						(response: BaseApiResponse) => {
							if (response.isSuccessful) {
								dispatch(loadAssociatedAccounts(props.clientId));
								dispatch(uiSliceActions.showModal(false));
							}
						}
					);
				}
			}
		);
	};
	const platformTypeChangeHandler = (type?: PlatformType) => {
		if (type !== undefined) {
			setPlatform(type);
		}
	};

	useEffect(() => {
		const loadInformation = async () => {
			if (props.accountId !== undefined) {
				await getInformation(
					EndpointUrls.Account,
					(accountInformation: UserAccountApiModel) => {
						setAccountName(accountInformation.accountNumber);
						setEmail(accountInformation.emailAccount);
						setPassword(accountInformation.accountPassword);
						setPlatform(accountInformation.accountType);
						setIpAddress(accountInformation.ipAddress);
						setIsAccountNameValid(true);
						setIsEmailValid(true);
						setIsPasswordValid(true);
						setDateAdded(accountInformation.dateAdded);
					},
					[{ key: "id", value: props.accountId }]
				);
			}
		};

		loadInformation();
	}, [props.accountId]);
	const isPlatformTypeValid = platform !== undefined;
	const isFormValid =
		isAccountNameValid &&
		isPasswordValid &&
		isEmailValid &&
		isPlatformTypeValid;
	return (
		<React.Fragment>
			<FormContainer
				onSubmit={saveCustomerAccountEventHandler}
				onReset={(e) => {
					e.preventDefault();
					dispatch(uiSliceActions.showModal(false));
				}}
				formActions={
					<CreateAccountFormAction
						isFormValid={isFormValid}
						isLoading={false}
					/>
				}
			>
				<FormRow type={"row"}>
					<AccountTypeSelect
						platformType={platform}
						onPlateformTypeChanged={platformTypeChangeHandler}
					/>
				</FormRow>
				<FormRow type={"row"}>
					<BasicInput
						label="Account Name"
						value={accountName}
						onChange={(e) => {
							e.preventDefault();
							setAccountName(e.currentTarget.value);
						}}
						placeholder="Your account number"
						validationRegex="^[\w]{1,}$"
						errorMessage="This field is mandatory and should not contain illegal characters."
						onValidateInput={(e) => {
							setIsAccountNameValid(e);
						}}
					></BasicInput>
				</FormRow>
				<FormRow type={"row"}>
					<BasicInput
						label="Account Password"
						value={password}
						onChange={(e) => {
							e.preventDefault();
							setPassword(e.currentTarget.value);
						}}
						placeholder="The password of your account"
						validationRegex="^[\w]{1,}$"
						errorMessage="This field is mandatory and should not contain illegal characters."
						onValidateInput={(e) => {
							setIsPasswordValid(e);
						}}
					></BasicInput>
				</FormRow>
				<FormRow type={"row"}>
					<BasicInput
						label="Account Email"
						value={email}
						onChange={(e) => {
							e.preventDefault();
							setEmail(e.currentTarget.value);
						}}
						placeholder="The email associated with this account"
						validationRegex="^([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{2,})@([a-zA-Z0-9_\\-\\!@#$%^&*()+=]{2,}).([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{1,})$"
						errorMessage="E-mail address is invalid."
						onValidateInput={(e) => {
							setIsEmailValid(e);
						}}
					></BasicInput>
				</FormRow>
			</FormContainer>
		</React.Fragment>
	);
};

export default AddCustomerAccount;

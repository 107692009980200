import React, { useEffect, useState } from "react";
import OverviewItem from "../OverviewItem/OverviewItem";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { mapApiDataToUserSalaryModel } from "../../Helpers/SalaryHelper";
import LoadingAnimation from "../Loading/Loading";
import ValueComparatorDisplay from "../Statistics/ValueComparatorDisplay/ValueComparatorDisplay";
import { formatDate } from "../../Helpers/DateHelpers";

const CurrentWeekSalaryForecastCard: React.FC<{ userId: string }> = (props) => {
	let { isLoading, executeGet: fetchSalaryInfo } = useHttp();
	let [currentWeekNightShiftSalary, setCurrentWeekNightShiftSalary] =
		useState<number>(0);
	let [currentWeekDayShiftSalary, setCurrentWeekDayShiftSalary] =
		useState<number>(0);

	let [previousWeekNightShiftSalary, setPreviousWeekNightShiftSalary] =
		useState<number>(0);
	let [previousWeekDayShiftSalary, setPreviousWeekDayShiftSalary] =
		useState<number>(0);

	useEffect(() => {
		fetchSalaryInfo(
			EndpointUrls.CalculateSalary,
			(apiData) => {
				let salary = mapApiDataToUserSalaryModel(apiData);
				if (salary == undefined) {
					return;
				}
				let totalDayShiftSalary: number = 0;
				let totalNightShiftSalary: number = 0;

				salary.salary.forEach((s) => {
					if (
						s.daySalary != null &&
						s.daySalary != undefined &&
						!Number.isNaN(s.daySalary) &&
						s.daySalary.toString() != "NaN"
					) {
						totalDayShiftSalary += +s.daySalary;
					} else {
						totalDayShiftSalary += +0;
					}

					if (
						s.nightSalary != null &&
						s.nightSalary != undefined &&
						!Number.isNaN(s.nightSalary) &&
						s.nightSalary.toString() != "NaN"
					) {
						totalNightShiftSalary += +s.nightSalary;
					} else {
						totalNightShiftSalary += +0;
					}
				});

				setCurrentWeekDayShiftSalary((previousState) => totalDayShiftSalary);
				setCurrentWeekNightShiftSalary(
					(previousState) => totalNightShiftSalary
				);
			},
			[
				{ key: "userId", value: props.userId },
				{
					key: "date",
					value: formatDate(new Date()),
				},
			]
		).then(() => {
			var today = new Date();
			var previousWeek = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDay() - 8
			);
			fetchSalaryInfo(
				EndpointUrls.CalculateSalary,
				(apiData) => {
					let salary = mapApiDataToUserSalaryModel(apiData);
					if (salary == undefined) {
						return;
					}
					let totalDayShiftSalary: number = 0;
					let totalNightShiftSalary: number = 0;

					salary.salary.forEach((s) => {
						if (
							s.daySalary != null &&
							s.daySalary != undefined &&
							!Number.isNaN(s.daySalary) &&
							s.daySalary.toString() != "NaN"
						) {
							totalDayShiftSalary += +s.daySalary;
						} else {
							totalDayShiftSalary += +0;
						}

						if (
							s.nightSalary != null &&
							s.nightSalary != undefined &&
							!Number.isNaN(s.nightSalary) &&
							s.nightSalary.toString() != "NaN"
						) {
							totalNightShiftSalary += +s.nightSalary;
						} else {
							totalNightShiftSalary += +0;
						}
					});

					setPreviousWeekDayShiftSalary((previousState) => totalDayShiftSalary);
					setPreviousWeekNightShiftSalary(
						(previousState) => totalNightShiftSalary
					);
				},
				[
					{ key: "userId", value: props.userId },
					{
						key: "date",
						value: formatDate(previousWeek),
					},
				]
			);
		});
	}, [props.userId]);

	return (
		<OverviewItem
			title={"Current Week Salary Forecast"}
			icon={"insights"}
			iconColor="blue"
		>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<React.Fragment>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Day Shift Salary
						</span>
						<span className="overview-item__details__description text-type--price--ar">
							{currentWeekDayShiftSalary}
						</span>
						<ValueComparatorDisplay
							previousValue={previousWeekDayShiftSalary}
							currentValue={currentWeekDayShiftSalary}
						/>
					</div>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Night Shift Salary
						</span>
						<span className="overview-item__details__description text-type--price--ar">
							{currentWeekNightShiftSalary}{" "}
						</span>
						<ValueComparatorDisplay
							previousValue={previousWeekNightShiftSalary}
							currentValue={currentWeekNightShiftSalary}
						/>
					</div>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">Total Salary</span>
						<span className="overview-item__details__description text-type--price--ar">
							{+currentWeekNightShiftSalary + +currentWeekDayShiftSalary}
						</span>
						<ValueComparatorDisplay
							previousValue={
								+previousWeekNightShiftSalary + +previousWeekDayShiftSalary
							}
							currentValue={
								+currentWeekNightShiftSalary + +currentWeekDayShiftSalary
							}
						/>
					</div>
				</React.Fragment>
			)}
		</OverviewItem>
	);
};
export default CurrentWeekSalaryForecastCard;

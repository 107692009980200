import { TarificationApiModel } from "../models/Api/Tarification/Tarification";

export const mapApiDataToAccountTarificationDetails = (apiData: any) => {
  if (apiData == undefined || apiData == null) {
    return undefined;
  }

  let result: TarificationApiModel = {
    platform: Number.parseInt(apiData.platform),
    tarificationDetails: [...apiData.tarificationDetails],
    id: apiData.id,
    name: apiData.name,
  };

  return result;
};

export const mapApiDataToAccountTarificationDetailsList = (apiData: any) => {
  if (apiData == undefined || apiData == null) {
    return undefined;
  }

  let resultList: TarificationApiModel[] = [];

  for (let tarificationDetailsKey in apiData) {
    let result = mapApiDataToAccountTarificationDetails(
      apiData[tarificationDetailsKey]
    );
    if (result != undefined) {
      resultList = resultList.concat(result);
    }
  }

  return resultList;
};

import React from "react";
import { FormHeaderProps } from "../../../models/Props/Forms/FormElementProps";

export const FormInputContainer: React.FC<{}> = (props) => {
  return <div className="form__input-container">{props.children}</div>;
};

export const FormRow: React.FC<{ type: "grid" | "row"; className?: string }> = (
  props
) => {
  let type = "";

  switch (props.type) {
    case "grid":
      type = "row--grid";
      break;
    case "row":
      type = "row--full";
      break;
  }
  return (
    <div
      className={`form__${type} ${
        props.className != undefined ? props.className : ""
      } `}
    >
      {props.children}
    </div>
  );
};

export const FormElementGroup: React.FC<{
  sectionTitle?: string;
  className?: string;
}> = (props) => {
  return (
    <div
      className={`form-group ${
        props.className != undefined ? props.className : ""
      }`}
    >
      <div>
        <h6>{props.sectionTitle}</h6>
      </div>
      {props.children}
    </div>
  );
};

export const FormHeader: React.FC<FormHeaderProps> = (props) => {
  return (
    <div
      className={`form__header ${
        props.className != undefined ? props.className : ""
      }`}
    >
      <span className="form__header__title">{props.title}</span>
      <p className="form__header__description">{props.description}</p>
    </div>
  );
};

import CardInformationProps, {
  CardInformationHeaderProps,
  CardInformationHeaderSectionProps,
} from "../../models/Props/CardInformation/CardInformationProps";
import React from "react";
import { Link } from "react-router-dom";
import './_cardInformation.scss'; 

const CardInformationHeaderSection: React.FC<CardInformationHeaderSectionProps> = (
  props
) => {
  return (
    <div className={`card-header--${props.position}`}>{props.children}</div>
  );
};

const CardInformationHeader: React.FC<CardInformationHeaderProps> = (props) => {
  return (
    <React.Fragment>
      <CardInformationHeaderSection position="left">
        <h6>{props.header}</h6>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </CardInformationHeaderSection>
      <CardInformationHeaderSection position="right">
        {props.element}
      </CardInformationHeaderSection>
    </React.Fragment>
  );
};
const CardInformation: React.FC<CardInformationProps> = (props) => {
  let variantType: string = "";

  switch (props.variant) {
    case "full":
      variantType = "card--information__full";
      break;
    case "fullGrid":
      variantType = "card--information__full-grid";
      break;
    default:
      variantType = "card--information";
      break;
  }
  return (
    <div className={`${variantType} ${props.className ? props.className : ""}`}>
      {props.variant === "fullGrid" && (
        <CardInformationHeader
          header={props.header}
          title={props.title}
          description={props.description}
          element={props.headerCallToActionElement}
        />
      )}
      {props.variant !== "fullGrid" && (
        <React.Fragment>
          <h6>{props.header}</h6>
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </React.Fragment>
      )}

      {props.children}
    </div>
  );
};

export default CardInformation;

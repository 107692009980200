import React, { useEffect, useCallback, useState } from "react";
import Card from "../../components/ui/Card/Card";
import { SingleFullWidthColumnContentCentered } from "../../components/Layouts/PageStructure/GridColumns";
import CardInformation from "../../components/CardInformation/CardInformation";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../../store/UiSlice";
import Modal from "../../components/Modal/Modal";
import { useAppSelector } from "../../hooks/StateHooks";

import useHttp from "../../hooks/UseHttp";
import LoadingAnimation from "../../components/Loading/Loading";
import { OverviewTableDataProps } from "../../models/Props/OverviewTable/OverviewTableProps";
import AccountApiModel from "../../models/Api/Accounts/AccountsApiModel";
import { accountsSliceActions } from "../../store/AccountSlice";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import AddAccountForm from "../../components/Accounts/AddAccountForm";
import AccountNotAssignedList from "../../components/Accounts/UnassignedAccountOverview/UnassignedAccountList";
import AddUnassignedAccountForm from "../../components/Accounts/UnassignedAccountOverview/UnassignedAccountForm";
import Table, {
	TableHeader,
	TableItem,
	TableRow,
	TableRowWithSlider,
} from "../../components/Tables/Table";
import "./_manage-tables.scss";
import UnassignedAccountList from "../../components/Accounts/UnassignedAccountOverview/UnassignedAccountList";
import SelectCustomerForm from "../../components/Customers/SelectCustomerForm/SelectCustomerForm";
import { BaseApiResponse } from "../../models/Api/Base/Model";
import { loadAccounts } from "../../store/Actions/AccountActions";

const ManageAccountsDashboard: React.FC<{}> = (props) => {
	const showModal = useAppSelector((state) => state.ui.showModal);
	const accountState = useAppSelector((state) => state.accounts.accounts);
	const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
	const [showClientSelectionForm, setShowClientSelectionForm] =
		useState<boolean>(false);
	const [selectedAccountId, setSelectedAccountId] = useState<string>();

	const [formPrefillModelId, setFormPrefillModelId] = useState<string>();
	const [isClicked, setIsClicked] = useState<boolean>(false);

	const {
		isLoading,
		executeGet: fetchAccounts,
		executeDelete: removeAccount,
	} = useHttp();
	const dispatch = useDispatch();

	const handleApiResponse: (data: any) => void = (data) => {
		dispatch(accountsSliceActions.setAccounts(data));
	};

	const mapResponseToApiModel: (data: any) => void = (data) => {
		let model: AccountApiModel = {
			accountNumber: data.accountNumber,
			accountPassword: data.accountPassword,
			emailAccount: data.emailAccount,
			emailPassword: data.emailPassword,
			skypeAccount: data.skypeAccount,
			skypePassword: data.skypePassword,
			dateAdded: data.dateAdded,
			dateModified: data.dateModified,
			id: data.id,
			ipAddress: data.ipAddress,
			clientId: data.clientId,
			serverId: data.serverId,
		};
	};

	useEffect(() => {
		fetchAccounts(EndpointUrls.Account, handleApiResponse);
	}, [accountState.length]);

	const addAccountEventHandler = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		event.preventDefault();
		setIsClicked(true);
		setIsFormEditMode(true);
		setFormPrefillModelId((prevState) => undefined);
		dispatch(uiSliceActions.showModal(true));
	};

	const editAccount: (data: string) => void = (data: string) => {
		if (data == undefined || data.trim().length == 0) {
			return;
		}
		fetchAccounts(EndpointUrls.Account, mapResponseToApiModel, [
			{ key: "id", value: data },
		]);
		setIsFormEditMode(true);
		setFormPrefillModelId(data);
		dispatch(uiSliceActions.showModal(true));
	};

	const deleteAccount: (data: string) => void = (data: string) => {
		if (data == undefined || data.trim().length == 0) {
			return;
		}

		removeAccount<string>(EndpointUrls.Account, data);
		dispatch(accountsSliceActions.deleteAccount(data));
	};

	const viewAccountDetails: (data: string) => void = (data: string) => {
		if (data == undefined || data.trim().length == 0) {
			return;
		}

		setIsFormEditMode(false);
		setFormPrefillModelId(data);
		dispatch(uiSliceActions.showModal(true));
	};

	const removeServerFromAccount = async (accountId: string) => {
		await removeAccount(
			EndpointUrls.DeleteServerFromAccount,
			accountId,
			undefined,
			async (response: BaseApiResponse) => {
				if (response.isSuccessful) {
					await removeAccount(
						EndpointUrls.UnassignServer,
						accountId,
						undefined,
						(response: BaseApiResponse) => {
							if (response.isSuccessful) {
								dispatch(loadAccounts());
							}
						}
					);
				}
			}
		);
	};

	return (
		<React.Fragment>
			{isClicked && showModal && (
				<Modal
					title="Manage Accounts"
					onClose={() => {
						setIsClicked(false);
					}}
				>
					<AddAccountForm
						isEditMode={isFormEditMode}
						prefillModel={formPrefillModelId}
					/>
				</Modal>
			)}

			{showClientSelectionForm && showModal && selectedAccountId !== undefined && (
				<Modal
					onClose={() => {
						setShowClientSelectionForm(false);
					}}
				>
					<SelectCustomerForm
						relationId={selectedAccountId}
						saveEndpoint={EndpointUrls.ClientAccountRelation}
					/>
				</Modal>
			)}

			<SingleFullWidthColumnContentCentered>
				<Card
					isDashboardElement={false}
					theme="grey"
					className="overview-item__column"
				>
					<CardInformation
						variant="fullGrid"
						header="Manage Accounts"
						className="manage-table__card-header"
						headerCallToActionElement={
							<React.Fragment>
								<a
									href="#"
									className="button button-ghost__alternate desktop"
									onClick={addAccountEventHandler}
								>
									<span className="material-icons-outlined ">
										add_circle_outline
									</span>
									<span> Add Account</span>
								</a>
								<a
									href="#"
									className="button button-ghost__alternate mobile add-icon"
									onClick={addAccountEventHandler}
								>
									<span className="material-icons-outlined ">
										add_circle_outline
									</span>
								</a>
							</React.Fragment>
						}
					></CardInformation>

					{isLoading && <LoadingAnimation />}
					{!isLoading && (
						<Table className="manage-table">
							{!isLoading && <UnassignedAccountList></UnassignedAccountList>}
							<TableHeader className="manage-table__header">
								<TableItem>Account ID</TableItem>
								<TableItem className="desktop">Account Email</TableItem>
								<TableItem className="desktop">Server</TableItem>
								<TableItem>Actions</TableItem>
							</TableHeader>

							{accountState.map((account) => {
								return (
									<TableRowWithSlider
										key={Math.random()}
										className="manage-table__data-row"
										showSliderOn={"mobile"}
										sliderContent={
											<React.Fragment>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														setIsClicked(true);
														viewAccountDetails(account.id);
													}}
													className="button-icon"
												>
													<span className="icon--outlined">visibility</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														setIsClicked(true);
														editAccount(account.id);
													}}
													className="button-icon"
												>
													<span className="icon--outlined">edit</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();

														deleteAccount(account.id);
													}}
													className="button-icon"
												>
													<span className="icon--outlined">delete</span>
												</a>
											</React.Fragment>
										}
									>
										<TableItem>{account.accountNumber}</TableItem>
										<TableItem className="desktop">
											{account.emailAccount}
										</TableItem>
										<TableItem className="desktop">
											{account.ipAddress}
										</TableItem>
										<TableItem className="desktop">
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													setIsClicked(true);
													viewAccountDetails(account.id);
												}}
												className="button-icon"
											>
												<span className="material-icons-outlined">
													visibility
												</span>
											</a>
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													setIsClicked(true);
													editAccount(account.id);
												}}
												className="button-icon"
											>
												<span className="material-icons-outlined">edit</span>
											</a>
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													setSelectedAccountId(account.id);
													setShowClientSelectionForm(true);
													dispatch(uiSliceActions.showModal(true));
												}}
												className="button-icon"
											>
												<span className="material-icons-outlined">
													supervised_user_circle
												</span>
											</a>
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													removeServerFromAccount(account.id);
												}}
												className="button-icon"
											>
												<span className="material-icons-outlined">dns</span>
											</a>
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();

													deleteAccount(account.id);
												}}
												className="button-icon"
											>
												<span className="material-icons-outlined">delete</span>
											</a>
										</TableItem>
									</TableRowWithSlider>
								);
							})}
						</Table>
					)}
				</Card>
			</SingleFullWidthColumnContentCentered>
		</React.Fragment>
	);
};

export default ManageAccountsDashboard;

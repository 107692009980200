import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import CardInformation from "../../../components/CardInformation/CardInformation";
import AddCustomerAccount from "../../../components/Customers/AddAccounts/AddCustomerAccount";
import { DashboardTitle } from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import Modal from "../../../components/Modal/Modal";
import Table, {
	TableHeader,
	TableItem,
	TableRow,
} from "../../../components/Tables/Table";
import BreadcrumbsList, {
	Breadcrumb,
} from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Button from "../../../components/ui/Button/Button";
import Card from "../../../components/ui/Card/Card";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { loadAssociatedAccounts } from "../../../store/Actions/ClientsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import { DashboardCard } from "../../../stories/Ui/Card.stories";

const CustomerAccountsTable: React.FC<{}> = (props) => {
	const userAccountList = useAppSelector(
		(state) => state.clients.associatedAccountsList
	);
	const { clientId } = useParams();
	const showModal = useAppSelector((state) => state.ui.showModal);
	const dispatch = useDispatch();
	const [selectedAccountId, setSelectedAccountId] = useState<string>();
	const { isLoading, executeDelete: removeAccount } = useHttp();

	useEffect(() => {
		if (clientId !== undefined) {
			dispatch(loadAssociatedAccounts(clientId));
		}
	}, []);

	const addNewAccount = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		dispatch(uiSliceActions.showModal(true));
	};
	const deleteAccount = async (accountId: string) => {
		await removeAccount(
			EndpointUrls.DeleteServerFromAccount,
			accountId,
			undefined,
			async (response: BaseApiResponse) => {
				if (response.isSuccessful) {
					await removeAccount(
						EndpointUrls.ClientAccountRelation,
						accountId,
						undefined,
						async (response: BaseApiResponse) => {
							if (response.isSuccessful) {
								await removeAccount(
									EndpointUrls.Account,
									accountId,
									undefined,
									async (response: BaseApiResponse) => {}
								);
								if (clientId !== undefined) {
									dispatch(loadAssociatedAccounts(clientId));
								}
							}
						}
					);
				}
			}
		);
	};

	if (
		userAccountList === undefined ||
		userAccountList === null ||
		userAccountList.length === 0
	) {
		return (
			<React.Fragment>
				{showModal && (
					<Modal>
						{clientId !== undefined && (
							<AddCustomerAccount clientId={clientId} />
						)}
					</Modal>
				)}
				<DashboardTitle dashboardTitle={"Account Overview"} />

				<BreadcrumbsList
					crumbsList={[
						{ crumbLink: `/customer/${clientId}/dashboard`, pageTitle: "Home" },
						{ crumbLink: "", pageTitle: "Account Overview" },
					]}
				/>
				<Card
					theme={"white"}
					isDashboardElement={false}
					className="customer-operators"
				>
					<div className="customer-operators__information-message">
						<p>
							We could not find any accounts related to you. Start by adding a
							new account by clicking on the button below
						</p>
						<Button theme={"warning"} onClick={addNewAccount}>
							Add a new account
						</Button>
					</div>
				</Card>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			{showModal && (
				<Modal>
					{clientId !== undefined && (
						<AddCustomerAccount
							clientId={clientId}
							accountId={selectedAccountId}
						/>
					)}
				</Modal>
			)}
			<DashboardTitle dashboardTitle={"Account Overview"} />

			<BreadcrumbsList
				crumbsList={[
					{ crumbLink: `/customer/${clientId}/dashboard`, pageTitle: "Home" },
					{ crumbLink: "", pageTitle: "Account Overview" },
				]}
			/>
			<Card theme={"white"} isDashboardElement={false}>
				<CardInformation
					headerCallToActionElement={
						<Button theme={"primary"} onClick={addNewAccount}>
							Add
						</Button>
					}
					variant={"fullGrid"}
				></CardInformation>

				<Table>
					<TableHeader>
						<TableItem>Name</TableItem>
						<TableItem>Email</TableItem>
						<TableItem>Password</TableItem>
						<TableItem>Status</TableItem>
						<TableItem>Actions</TableItem>
					</TableHeader>
					{userAccountList.map((account) => {
						return (
							<TableRow>
								<TableItem>{account.accountNumber}</TableItem>
								<TableItem>{account.emailAccount}</TableItem>
								<TableItem>{account.accountPassword}</TableItem>
								<TableItem>
									{account.ipAddress === undefined ||
									account.ipAddress === null ||
									account.ipAddress.length === 0 ? (
										<span className="status-icon status-icon--pending"></span>
									) : (
										<span className="status-icon status-icon--active"></span>
									)}
								</TableItem>
								<TableItem>
									<a
										href="#"
										onClick={(event) => {
											event.preventDefault();

											setSelectedAccountId(account.id);

											dispatch(uiSliceActions.showModal(true));
										}}
										className="button-icon"
									>
										<span className="material-icons-outlined">edit</span>
									</a>
									<a
										href="#"
										onClick={(event) => {
											event.preventDefault();

											deleteAccount(account.id);
										}}
										className="button-icon"
									>
										<span className="icon--outlined">delete</span>
									</a>
								</TableItem>
							</TableRow>
						);
					})}
				</Table>
			</Card>
		</React.Fragment>
	);
};

export default CustomerAccountsTable;

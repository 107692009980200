import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import useMessageApi from "../../../hooks/UseMessageApi";
import { startFlowboosterSession } from "../../../store/Actions/FlowBoosterActions";
import { flowboosterSliceActions } from "../../../store/FlowboosterSlice";
import { GridContainer } from "../../Layouts/PageStructure/GridColumns";

import Tabs, {
	TabsButtonContainer,
	TabsContentContainer,
	TabsContent,
} from "../../Tabs/Tabs";
import MainPanel from "./MainPanel";
import SidePanel from "./SidePanel";

const MessagingContainer: React.FC<{
	userId: string;
	ipAddress: string;
	platform: string;

	onMessageIsLoading: (isLoading: boolean) => void;
}> = (props) => {
	const { isLoading, interfaceInformation, loadMessage } = useMessageApi();
	const [isPlayerContentDisplayed, setIsPlayerContentDisplayed] =
		useState<boolean>(false);
	const [isTargetContentDisplayed, setIsTargetContentDisplayed] =
		useState<boolean>(false);
	const error = useAppSelector((state) => state.ui.error);
	let dispatch = useDispatch();
	let isMounted = false;
	useEffect(() => {
		const loadInformation = async () => {
			await loadMessage({
				platform: props.platform,
				ipAddress: props.ipAddress,
				userId: props.userId,
				isLogin: false,
			});
		};

		isMounted = true;

		if (isMounted) {
			dispatch(
				flowboosterSliceActions.setErrorState({
					errorMessage: "",
					isError: false,
				})
			);

			loadInformation();
		}

		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (!isMounted) {
			return;
		}

		if (
			interfaceInformation !== undefined &&
			!interfaceInformation.isSuccessful
		) {
			dispatch(
				flowboosterSliceActions.setErrorState({
					errorMessage:
						"The request took quite some time. Please close your session and start a new one to proceed.",
					isError: true,
				})
			);
		}
	}, [interfaceInformation?.isSuccessful]);

	const onMessageSentHandler = async () => {
		dispatch(flowboosterSliceActions.setLoadingState(true));
		props.onMessageIsLoading(true);
		await loadMessage({
			platform: props.platform,
			ipAddress: props.ipAddress,
			userId: props.userId,
			isLogin: false,
		});
		props.onMessageIsLoading(false);
		dispatch(flowboosterSliceActions.setLoadingState(false));
	};

	useEffect(() => {
		if (!isMounted) {
			return;
		}
		if (error.isError) {
			dispatch(
				startFlowboosterSession({
					platform: props.platform,
					ipAddress: props.ipAddress,
					isLogin: false,
					userId: props.userId,
				})
			);
		}
	}, [error.isError]);

	return (
		<div className="messaging-container">
			<Tabs className="mobile">
				<TabsButtonContainer
					buttons={[
						{
							id: "player-profile",
							title: "Player Profile",
							onClick: (event: React.MouseEvent) => {
								event.preventDefault();
								setIsPlayerContentDisplayed((prevState) => !prevState);
								setIsTargetContentDisplayed(false);
							},
							isActive: isPlayerContentDisplayed,
						},
						{
							id: "target-profile",
							title: "Target Profile",
							onClick: (event: React.MouseEvent) => {
								event.preventDefault();
								setIsTargetContentDisplayed((prevState) => !prevState);
								setIsPlayerContentDisplayed(false);
							},
							isActive: isTargetContentDisplayed,
						},
					]}
				></TabsButtonContainer>
				<TabsContentContainer>
					<TabsContent isActive={isPlayerContentDisplayed}>
						<SidePanel
							platform={props.platform}
							userId={props.userId}
							ipAddress={props.ipAddress}
							isLoading={isLoading}
							className="messaging-container__left"
							primaryInformation={{
								isPlayerNote: true,
								noteHistoryList: interfaceInformation?.playerNotes,
								profileDetails: interfaceInformation?.playerDetails,
								profileInformation: interfaceInformation?.playerProfile,
							}}
							noteCategoryList={interfaceInformation?.noteCategories}
							playerProfileId={interfaceInformation?.playerProfile?.id}
							targetProfileId={interfaceInformation?.targetProfile?.id}
						></SidePanel>
					</TabsContent>
					<TabsContent isActive={isTargetContentDisplayed}>
						<SidePanel
							platform={props.platform}
							ipAddress={props.ipAddress}
							userId={props.userId}
							isLoading={isLoading}
							className="messaging-container__right"
							primaryInformation={{
								isPlayerNote: false,
								noteHistoryList: interfaceInformation?.targetNotes,
								profileDetails: interfaceInformation?.targetDetails,
								profileInformation: interfaceInformation?.targetProfile,
							}}
							noteCategoryList={interfaceInformation?.noteCategories}
							playerProfileId={interfaceInformation?.playerProfile?.id}
							targetProfileId={interfaceInformation?.targetProfile?.id}
						></SidePanel>
					</TabsContent>
				</TabsContentContainer>
			</Tabs>
			<SidePanel
				platform={props.platform}
				userId={props.userId}
				ipAddress={props.ipAddress}
				isLoading={isLoading}
				className="messaging-container__left desktop"
				primaryInformation={{
					isPlayerNote: true,
					noteHistoryList: interfaceInformation?.playerNotes,
					profileDetails: interfaceInformation?.playerDetails,
					profileInformation: interfaceInformation?.playerProfile,
				}}
				noteCategoryList={interfaceInformation?.noteCategories}
				playerProfileId={interfaceInformation?.playerProfile?.id}
				targetProfileId={interfaceInformation?.targetProfile?.id}
			></SidePanel>
			<MainPanel
				platform={props.platform}
				onMessageSent={onMessageSentHandler}
				userId={props.userId}
				className="messaging-container__main"
				isLoading={isLoading}
				messageList={interfaceInformation?.messages}
				ipAddress={props.ipAddress}
				conversationStartDate={
					interfaceInformation?.conversationInformation?.startDate
				}
				isMediaButtonAvailable={
					interfaceInformation?.conversationInformation?.hasMediaButton
				}
				isMediaListExhausted={
					interfaceInformation?.conversationInformation?.isMediaListExhausted
				}
				availableMediaList={
					interfaceInformation?.conversationInformation?.availableMediaList
				}
				sendMessageRequestModel={{
					conversationId: interfaceInformation?.conversationInformation?.id,
					lastInId:
						interfaceInformation?.conversationInformation
							?.lastMessageReceivedId,
					lastOutId:
						interfaceInformation?.conversationInformation?.lastMessageSentId,
					parentMessageId: interfaceInformation?.parentMessage?.id,
					site: interfaceInformation?.parentMessage?.site,
				}}
			></MainPanel>
			<SidePanel
				platform={props.platform}
				ipAddress={props.ipAddress}
				userId={props.userId}
				isLoading={isLoading}
				className="messaging-container__right desktop"
				primaryInformation={{
					isPlayerNote: false,
					noteHistoryList: interfaceInformation?.targetNotes,
					profileDetails: interfaceInformation?.targetDetails,
					profileInformation: interfaceInformation?.targetProfile,
				}}
				noteCategoryList={interfaceInformation?.noteCategories}
				playerProfileId={interfaceInformation?.playerProfile?.id}
				targetProfileId={interfaceInformation?.targetProfile?.id}
			></SidePanel>
		</div>
	);
};

export default MessagingContainer;

import React, { useState } from "react";
import { AccordionProps } from "../../models/Props/List/AccordionProps";
import "./_accordion.scss";

const Accordion: React.FC<AccordionProps> = (props) => {
	let [isVisible, setIsVisible] = useState<boolean>(false);
	const visibilityHandler = (event: React.MouseEvent) => {
		event.preventDefault();
		setIsVisible((previousStateValue) => !previousStateValue);
	};
	return (
		<div
			className={`accordion ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			<div
				className={`accordion__header ${
					isVisible
						? "accordion__header--inactive"
						: "accordion__header--active"
				}`}
				onClick={visibilityHandler}
			>
				<div className="accordion__header__title">{props.header}</div>
			</div>

			<div
				className={`accordion__elements ${
					isVisible ? "accordion--active" : "accordion--inactive"
				}`}
			>
				{props.children}
			</div>
		</div>
	);
};

export default Accordion;

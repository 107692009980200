import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { accountsSliceActions } from "../AccountSlice";
import { uiSliceActions } from "../UiSlice";
import { executeGet } from "./AsyncActions";

export const loadAccounts = (
	accountId?: string,
	callback?: (data: any) => void
) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));

		if (accountId === undefined && callback === undefined) {
			await dispatch(
				executeGet(EndpointUrls.Account, (apiData) => {
					dispatch(accountsSliceActions.setAccounts(apiData));
				})
			);
		} else if (accountId !== undefined && callback !== undefined) {
			await dispatch(
				executeGet(
					EndpointUrls.Account,
					(apiData) => {
						callback(apiData);
					},
					[{ key: "id", value: accountId }]
				)
			);
		}

		dispatch(uiSliceActions.setLoadingState(false));
	};
};

import React from "react";
import { ProfileInformationProps } from "../../../models/Props/Messages/ProfileInformationProps";
import ImageSlider from "../../ImageSlider/ImageSlider";
import { GridContainer } from "../../Layouts/PageStructure/GridColumns";
import Accordion from "../../List/Accordion";
import "./_profileInformation.scss";

const ProfileInformationBlock: React.FC<ProfileInformationProps> = (props) => {
	const dob: string | undefined =
		props.profileInformation?.dob !== undefined
			? new Date(props.profileInformation.dob.toString()).toLocaleDateString()
			: undefined;
	return (
		<div
			className={`profile-information-container ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			{props.profileInformation !== undefined && (
				<React.Fragment>
					<div className="profile-summary">
						<div className="align-center">
							<img
								className="profile-summary__picture"
								src={props.profileInformation.photo}
							/>
						</div>
						<div className="left">
							<div className="profile-information__information-block">
								<span className="profile-information__label">Username:</span>
								<span>{props.profileInformation?.name}</span>
							</div>
							{/* <div className="profile-information__information-block">
									<span className="profile-information__label">Gender:</span>
									<span>{props.profileInformation?.gender}</span>
								</div> */}
							{/* {dob !== undefined && (
									<div className="profile-information__information-block">
										<span className="profile-information__label">
											Date of Birth:
										</span>
										<span>{dob}</span>
									</div>
								)} */}
							<div className="profile-information__information-block">
								<span className="profile-information__label">Real name:</span>
								<span>
									<span>{props.profileDetails?.realName ?? "N/A"}</span>
								</span>
							</div>
							<div className="profile-information__information-block">
								<span className="profile-information__label">
									Civil Status:
								</span>
								<span>{props.profileDetails?.civilStatus ?? "N/A"}</span>
							</div>
							<div className="profile-information__information-block">
								<span className="profile-information__label">Age:</span>
								<span>{props.profileInformation?.age}</span>
							</div>
							<div className="profile-information__information-block">
								<span className="profile-information__label">Profession:</span>
								<span>{props.profileDetails?.profession ?? "N/A"}</span>
							</div>
							<div className="profile-information__information-block address-information__region-information">
								<span className="profile-information__label">City:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoRegion4 ?? "N/A"}
								</span>
							</div>
						</div>
						{/* <div className="align-right mobile">
							<img
								className="profile-summary__picture"
								src={props.profileInformation.photo}
							/>
						</div> */}
						{/* <GridContainer className="row--grid row--grid-2-col"></GridContainer> */}
					</div>
					{props.profileDetails !== undefined && (
						<div className="profile-information">
							<Accordion header={"Personal information"}>
								{/* <div className="profile-information__information-block">
									{props.profileInformation.images !== undefined &&
										props.profileInformation.images.length > 0 && (
											<ImageSlider
												imageList={props.profileInformation.images.map(
													(image) => image.largeImage
												)}
											/>
										)}
								</div> */}
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Introduction:
									</span>
									<span>{props.profileDetails.title ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Real name:</span>
									<span>{props.profileDetails.realName ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Constellation:
									</span>
									<span>{props.profileInformation?.constellation}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">About me:</span>
									<span>{props.profileDetails.aboutMe ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										What am I looking for?
									</span>
									<span>{props.profileDetails.aboutYou ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Build:</span>
									<span>{props.profileDetails.build ?? "N/A"}</span>
								</div>

								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Civil Status:
									</span>
									<span>{props.profileDetails.civilStatus ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Eyes color:
									</span>
									<span>{props.profileDetails.eyeColor ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Hair color:
									</span>
									<span>{props.profileDetails.hairColor ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Hobbies:</span>
									<span>{props.profileDetails.hobbies ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Piercing:</span>
									<span>{props.profileDetails.piercing ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Smoking?</span>
									<span>{props.profileDetails.smoke ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">Tattoos?</span>
									<span>{props.profileDetails.tattoo ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Profession:
									</span>
									<span>{props.profileDetails.profession ?? "N/A"}</span>
								</div>
								<div className="profile-information__information-block">
									<span className="profile-information__label">
										Type of habitation
									</span>
									<span>{props.profileDetails.habitat ?? "N/A"}</span>
								</div>
							</Accordion>
						</div>
					)}

					<div className="address-information">
						<Accordion header={"Address Information"}>
							<div className="profile-information__information-block address-information__region-information">
								<span className="profile-information__label">Region:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoRegion1 ?? "N/A"}
								</span>
							</div>
							<div className="profile-information__information-block address-information__region-information">
								<span className="profile-information__label">Department:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoRegion2 ?? "N/A"}
								</span>
							</div>
							<div className="profile-information__information-block address-information__region-information">
								<span className="profile-information__label">Commune:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoRegion3 ?? "N/A"}
								</span>
							</div>
							<div className="profile-information__information-block address-information__region-information">
								<span className="profile-information__label">City:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoRegion4 ?? "N/A"}
								</span>
							</div>
							<div className=" profile-information__information-block address-information__region-information">
								<span className="profile-information__label">Postcode:</span>
								<span className="region-information__details">
									{props.profileInformation?.geoPostcode ?? "N/A"}
								</span>
							</div>
						</Accordion>
					</div>
					{props.profileInformation?.lastNotes !== undefined &&
						props.profileInformation.lastNotes !== null &&
						props.profileInformation.lastNotes.length > 0 && (
							<div className="latest-notes">
								<Accordion header={"Latest notes"}>
									{props.profileInformation.lastNotes.map((note) => (
										<div
											key={note.id}
											className="profile-information__information-block latest-notes__information"
										>
											{note.modified !== undefined && (
												<span className="profile-information__label latest-notes__information__date">
													{new Date(
														note.modified?.toString()
													).toLocaleDateString()}
													:
												</span>
											)}

											<span className="latest-notes__information__content">
												{note.content}
											</span>
										</div>
									))}
								</Accordion>
							</div>
						)}
				</React.Fragment>
			)}
			{props.profileInformation === undefined && (
				<div>No information available for this profile.</div>
			)}
		</div>
	);
};

export default ProfileInformationBlock;

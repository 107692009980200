import React from "react";
import { SingleFullWidthColumnContentCentered } from "../Layouts/PageStructure/GridColumns";
import SalaryTable from "./SalaryTable/SalaryTable";

const SalaryViewBlock: React.FC<{}> = (props) => {
  return (
    <React.Fragment>
      <SingleFullWidthColumnContentCentered>
        <SalaryTable />
      </SingleFullWidthColumnContentCentered>
    </React.Fragment>
  );
};

export default SalaryViewBlock;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CreateAccountForm from "../../../components/Authentication/Registration/CreateAccountForm";
import CardInformation from "../../../components/CardInformation/CardInformation";
import CustomerAccountAssignmentForm from "../../../components/Customers/AssignAccounts/CustomerAccountAssignmentForm";
import { DashboardTitle } from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import Modal from "../../../components/Modal/Modal";
import Table, {
	TableHeader,
	TableItem,
	TableRow,
} from "../../../components/Tables/Table";
import BreadcrumbsList, {
	Breadcrumb,
} from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Button from "../../../components/ui/Button/Button";
import Card from "../../../components/ui/Card/Card";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { loadAssociatedUsers } from "../../../store/Actions/ClientsActions";
import uiSlice, { uiSliceActions } from "../../../store/UiSlice";

const CustomerOperatorsTable: React.FC<{}> = (props) => {
	const userList = useAppSelector((state) => state.clients.associatedUsersList);
	const showModal = useAppSelector((state) => state.ui.showModal);
	const [showAssignAccountForm, setShowAssignAccountForm] =
		useState<boolean>(false);
	const [selectedUserId, setSelectedUserId] = useState<string>("");

	const { clientId } = useParams();
	const { isLoading, executeDelete: removeInformation } = useHttp();
	const dispatch = useDispatch();

	useEffect(() => {
		if (clientId !== undefined) {
			dispatch(loadAssociatedUsers(clientId));
		}
	}, []);

	const addUser = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		dispatch(uiSliceActions.showModal(true));
	};

	const unassignAccount = async (userId: string, accountId: string) => {
		await removeInformation(
			EndpointUrls.UserAccountAssignment,
			accountId,
			[{ key: "userId", value: userId }],
			(response: BaseApiResponse) => {
				if (response.isSuccessful) {
					if (clientId !== undefined) {
						dispatch(loadAssociatedUsers(clientId));
					}
				}
			}
		);
	};

	const removeUser = async (userId: string, clientId: string) => {
		await removeInformation(
			EndpointUrls.Users,
			userId,
			undefined,
			async (response: BaseApiResponse) => {
				if (response.isSuccessful) {
					dispatch(loadAssociatedUsers(clientId));
				}
			}
		);
	};

	if (userList === undefined || userList.length === 0) {
		return (
			<React.Fragment>
				{showModal && (
					<Modal>
						<CreateAccountForm />
					</Modal>
				)}
				<DashboardTitle dashboardTitle={"Operators Overview"} />

				<BreadcrumbsList
					crumbsList={[
						{ crumbLink: `/customer/${clientId}/dashboard`, pageTitle: "Home" },
						{ crumbLink: "", pageTitle: "Operators Overview" },
					]}
				/>
				<Card
					theme={"white"}
					isDashboardElement={false}
					className="customer-operators"
				>
					<div className="customer-operators__information-message">
						<p>
							We could not find any users related to you. Start by adding a user
							by clicking on the button below
						</p>
						<Button theme={"warning"} onClick={addUser}>
							Add a new user
						</Button>
					</div>
				</Card>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			{showModal && !showAssignAccountForm && (
				<Modal>
					<CreateAccountForm />
				</Modal>
			)}

			{showModal && clientId !== undefined && showAssignAccountForm && (
				<Modal>
					<CustomerAccountAssignmentForm
						clientId={clientId}
						selectedUserId={selectedUserId}
					/>
				</Modal>
			)}
			<DashboardTitle dashboardTitle={"Operators Overview"} />

			<BreadcrumbsList
				crumbsList={[
					{ crumbLink: `/customer/${clientId}/dashboard`, pageTitle: "Home" },
					{ crumbLink: "", pageTitle: "Operators Overview" },
				]}
			/>
			<Card theme={"white"} isDashboardElement={false}>
				<CardInformation
					headerCallToActionElement={
						<Button theme={"primary"} onClick={addUser}>
							Add
						</Button>
					}
					variant={"fullGrid"}
				></CardInformation>

				<Table>
					<TableHeader>
						<TableItem>Name</TableItem>
						<TableItem>Contacts</TableItem>
						<TableItem>Account</TableItem>
						<TableItem>Activated</TableItem>
						<TableItem>Actions</TableItem>
					</TableHeader>
					{userList !== undefined &&
						userList.length > 0 &&
						userList.map((user) => {
							let accountNumber =
								user.accounts !== undefined && user.accounts !== null
									? user.accounts.accountNumber
									: "No account";
							return (
								<TableRow>
									<TableItem>
										{user.firstName} {user.lastName}
									</TableItem>
									<TableItem>{user.email}</TableItem>
									<TableItem>{accountNumber}</TableItem>
									<TableItem>
										{accountNumber === "No account" ? (
											<span className="status-icon status-icon--pending"></span>
										) : (
											<span className="status-icon status-icon--active"></span>
										)}
									</TableItem>
									<TableItem>
										{/* <Link
											to={`/profile/${user.userId}/overview`}
											className="button-icon"
										>
											<span className="icon--outlined">visibility</span>
										</Link> */}
										<a
											href="#"
											onClick={(event) => {
												event.preventDefault();
												dispatch(uiSliceActions.showModal(true));
												setShowAssignAccountForm(true);
												setSelectedUserId(user.userId);
											}}
											className="button-icon"
										>
											<span className="icon--outlined ">manage_accounts</span>
										</a>
										{user.accounts !== undefined && user.accounts !== null && (
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													console.log("is this triggered");
													unassignAccount(user.id, user.accounts.id);
												}}
												className="button-icon"
											>
												<span className="icon--outlined ">no_accounts</span>
											</a>
										)}

										<a
											href="#"
											onClick={(event) => {
												event.preventDefault();
												if (clientId !== undefined) {
													removeUser(user.userId, clientId);
												}
											}}
											className="button-icon"
										>
											<span className="icon--outlined ">delete</span>
										</a>
									</TableItem>
								</TableRow>
							);
						})}
				</Table>
			</Card>
		</React.Fragment>
	);
};
export default CustomerOperatorsTable;

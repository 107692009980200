import { ExtraCostResponseModel } from "../models/Api/ExtraCosts/ExtraCostsApiModel";

export const mapApiDataToExtraCostsResponseModel = (data: any) => {
  if (data === undefined) {
    return undefined;
  }

  let response: ExtraCostResponseModel = {
    Id: data.id,
    amount: data.amount,
    date: data.date,
    designation: data.designation,
    title: data.title,
  };

  return response;
};

export const mapApiDataToExtraCostsResponseModelList = (data: any) => {
  if (data === undefined) {
    return undefined;
  }

  let resultList: ExtraCostResponseModel[] = [];

  data.forEach((result: any) => {
    let mappedResult = mapApiDataToExtraCostsResponseModel(result);

    if (mappedResult !== undefined) {
      resultList = resultList.concat(mappedResult);
    }
  });

  return resultList;
};

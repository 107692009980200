import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { KeyValuePair } from "../../models/General/GeneralModel";
import LoadingAnimation from "../Loading/Loading";

const UserSalaryGraphCard: React.FC<{
  userId: string;
  showYearData?: boolean;
}> = (props) => {
  let [graphData, setGraphData] = useState<any>();
  let [chartOption, setChartOption] = useState<ApexOptions>();
  let { isLoading, executeGet: fetchMonthSalaryStats } = useHttp();

  useEffect(() => {
    let parameters: KeyValuePair[] = [
      { key: "userId", value: props.userId },
      { key: "date", value: new Date().toDateString() },
    ];
    if (props.showYearData != undefined && props.showYearData) {
      parameters.push({ key: "entireYear", value: "true" });
    }
    fetchMonthSalaryStats(
      EndpointUrls.MonthlySalaryStats,
      (apiData) => {
        setGraphData((previousStats: any) => apiData);
      },
      parameters
    );
  }, [props.userId, props.showYearData]);

  useEffect(() => {
    if (graphData != undefined) {
      let chartId: string = `${graphData.name || `current`}_month_data `;

      let chartOptions: ApexOptions = {
        chart: {
          type: "bar",
          id: chartId,
          toolbar: { show: false },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
          height: 450,
          zoom: { enabled: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },

        xaxis: {
          labels: { show: true },
          categories: graphData.label,
        },
        theme: {
          mode: "light",

          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#ffb347",
            shadeTo: "dark",
            shadeIntensity: 0.65,
          },
        },
        grid: { show: false },
        series: [
          {
            name: graphData.name,
            data: graphData.data,
          },
        ],
      };
      setChartOption((previousState) => chartOptions);
    }
  }, [graphData]);

  return (
    <React.Fragment>
      {isLoading && <LoadingAnimation />}
      {!isLoading && graphData != undefined && chartOption != undefined && (
        <ReactApexChart
          type="bar"
          options={chartOption}
          series={[{ name: graphData.name, data: graphData.data }]}
        />
      )}
    </React.Fragment>
  );
};

export default UserSalaryGraphCard;

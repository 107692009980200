import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CardInformation from "../../components/CardInformation/CardInformation";
import AddCustomerForm from "../../components/Customers/AddCustomer/AddCustomerForm";
import { SingleFullWidthColumnContentCentered } from "../../components/Layouts/PageStructure/GridColumns";
import LoadingAnimation from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import Table, {
	TableHeader,
	TableItem,
	TableItemActionDropDown,
	TableRowWithSlider,
} from "../../components/Tables/Table";
import Card from "../../components/ui/Card/Card";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { uiSliceActions } from "../../store/UiSlice";
import "./_manage-tables.scss";
import { loadClientList } from "../../store/Actions/ClientsActions";
import { clientsSliceActions } from "../../store/ClientsSlice";

const ManageCustomersDashboard: React.FC<{}> = (props) => {
	const showModal = useAppSelector((state) => state.ui.showModal);
	const clientsList = useAppSelector((state) => state.clients.clients);
	const [isClicked, setIsClicked] = useState<boolean>(false);
	const [isDropped, setIsDropped] = useState<boolean>(false);
	// Fetch all the clients from database;
	let {
		isLoading,
		executeGet: fetchClientsList,
		executeDelete: deleteClient,
	} = useHttp();
	let dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadClientList());
	}, []);

	const addCustomerHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		setIsClicked(true);

		dispatch(uiSliceActions.showModal(true));
	};

	const deleteClientHandler: (data: string) => void = (data: string) => {
		if (data === undefined || data.trim().length === 0) {
			return;
		}

		deleteClient<string>(EndpointUrls.Clients, data).then(() => {
			dispatch(clientsSliceActions.removeClient(data));
		});
	};

	let count = 0;
	return (
		<React.Fragment>
			{isClicked && showModal && (
				<Modal
					title="Manage Customer"
					onClose={() => {
						setIsClicked(false);
					}}
				>
					<AddCustomerForm />
				</Modal>
			)}
			<SingleFullWidthColumnContentCentered>
				<Card
					isDashboardElement={true}
					theme="grey"
					className="overview-item__column"
				>
					<CardInformation
						variant="fullGrid"
						header="Customer Overview"
						className="manage-table__card-header"
						headerCallToActionElement={
							<React.Fragment>
								<a
									href="#"
									className="button button-ghost__alternate desktop"
									onClick={addCustomerHandler}
								>
									<span className="material-icons-outlined ">
										add_circle_outline
									</span>
									<span> Add Customer</span>
								</a>
								<a
									href="#"
									className="button button-ghost__alternate mobile add-icon"
									onClick={addCustomerHandler}
								>
									<span className="material-icons-outlined ">person_add</span>
								</a>
							</React.Fragment>
						}
					></CardInformation>
					{isLoading && <LoadingAnimation />}
					{!isLoading && (
						<Table className="manage-table">
							<TableHeader className="manage-table__header">
								<TableItem>Client ID</TableItem>
								<TableItem>Client Name</TableItem>
								<TableItem className="desktop">Client Email</TableItem>
								<TableItem className="desktop">Status</TableItem>
								<TableItem>Actions</TableItem>
							</TableHeader>
							{clientsList.map((client) => {
								return (
									<TableRowWithSlider
										key={Math.random()}
										className="manage-table__data-row"
										showSliderOn={"mobile"}
										sliderContent={
											<React.Fragment>
												<Link
													to={`/customer/${client.id}/dashboard`}
													className="button-icon"
													target={"_blank"}
												>
													<span className="icon--outlined">visibility</span>
												</Link>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														deleteClientHandler(client.id);
													}}
													className="button-icon"
												>
													<span className="icon--outlined">delete</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														console.log("deleted is clicked");
													}}
													className="button-icon"
												>
													<span className="icon--outlined">receipt</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														console.log("deleted is clicked");
													}}
													className="button-icon"
												>
													<span className="icon--outlined">group</span>
												</a>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														console.log("deleted is clicked");
													}}
													className="button-icon"
												>
													<span className="icon--outlined">switch_account</span>
												</a>
											</React.Fragment>
										}
									>
										<TableItem>{client.clientId}</TableItem>
										<TableItem>{client.username}</TableItem>
										<TableItem className="desktop">
											{client.businessEmailAddress}
										</TableItem>
										<TableItem className="desktop">
											<div
												className={`is-active ${
													client.isConfirmed ? "ok" : ""
												}`}
											></div>
										</TableItem>
										<TableItem className="desktop">
											<React.Fragment>
												<Link
													to={`/customer/${client.id}/dashboard`}
													className="button-icon"
													target={"_blank"}
												>
													<span className="icon--outlined">visibility</span>
												</Link>
												<a
													href="#"
													onClick={(event) => {
														event.preventDefault();
														deleteClientHandler(client.id);
													}}
													className="button-icon"
												>
													<span className="icon--outlined ">delete</span>
												</a>

												<TableItemActionDropDown
													actionContent={
														<React.Fragment>
															<a
																href="#"
																onClick={(event) => {
																	event.preventDefault();
																	console.log("deleted is clicked");
																}}
																className="button-icon"
															>
																<span className="icon--outlined">receipt</span>
															</a>
															<a
																href="#"
																onClick={(event) => {
																	event.preventDefault();
																	console.log("deleted is clicked");
																}}
																className="button-icon"
															>
																<span className="icon--outlined">group</span>
															</a>
															<a
																href="#"
																onClick={(event) => {
																	event.preventDefault();
																	console.log("deleted is clicked");
																}}
																className="button-icon"
															>
																<span className="icon--outlined">
																	switch_account
																</span>
															</a>
														</React.Fragment>
													}
													actionDroppedOn={"desktop"}
												/>
											</React.Fragment>
										</TableItem>
									</TableRowWithSlider>
								);
							})}
						</Table>
					)}
				</Card>
			</SingleFullWidthColumnContentCentered>
		</React.Fragment>
	);
};

export default ManageCustomersDashboard;

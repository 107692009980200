import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import ModalProps from "../../models/Props/Modal/ModalProps";
import { uiSliceActions } from "../../store/UiSlice";

import "./_modal.scss";

export const Backdrop: React.FC<{
	className?: string;
	onClose?: () => void;
}> = (props) => {
	
	return (
		<div
			className={`backdrop__black ${props.className}`}
			onClick={props.onClose}
		>
			{props.children}
		</div>
	);
};

export const ModalContent: React.FC<ModalProps> = (props) => {
	return (
		<div className={`modal  ${props.className} `}>
			<div className="modal__header">
				<div className="modal__header__title">{props.title}</div>
				<div className="modal__header__close" onClick={props.onClose}>
					close
				</div>
			</div>
			<div className="card card--white modal__card">
				<div className="modal__content">{props.children}</div>
				<div className="modal__action">
					{props.actions != null && props.actions.map((action) => action)}
				</div>
			</div>
		</div>
	);
};

const Modal: React.FC<ModalProps> = (props) => {
	let dispatch = useDispatch();
	
	const onCloseHandler = () => {
		if (props.onClose !== undefined) {
			props.onClose();
		}
		setClassName((prevState) => "modal--hidden");
	
		dispatch(uiSliceActions.showModal(false));
	};
	const [className, setClassName] = useState<string>("modal--visible");

	return ReactDOM.createPortal(
		<div className="modal-container">
			<Backdrop className={className} onClose={onCloseHandler}></Backdrop>
			<ModalContent
				actions={props.actions}
				title={props.title}
				className={className}
				// isVisible={props.isVisible}
				onClose={onCloseHandler}
			>
				{props.children}
			</ModalContent>
		</div>,
		document.getElementById("modal-container") as HTMLElement
	);
};

export default Modal;

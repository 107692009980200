import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { AddCustomerRequest } from "../../../models/Api/Customers/Models";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import {
  loadClient,
  loadClientList,
} from "../../../store/Actions/ClientsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import FormContainer from "../../Forms/FormContainer";
import { FormRow } from "../../Forms/FormElements/FormStructure";
import { BasicInput } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";

const AddCustomerFormAction: React.FC<{
  isFormValid: boolean;
  isLoading: boolean;
}> = (props) => {
  return (
    <Fragment>
      <Button type="reset" theme="info">
        <span>Cancel</span>
      </Button>
      <Button
        type="submit"
        theme="warning"
        className={`add-customer-form__submit-button ${
          props.isFormValid ? "" : "button--disabled"
        }`}
        disabled={!props.isFormValid}
      >
        {props.isLoading && <LoadingAnimation size="small" />}
        {!props.isLoading && <span>Add new customer</span>}
      </Button>
    </Fragment>
  );
};

const AddCustomerForm: React.FC<{}> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [cni, setCni] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  let { isLoading, executePost: addCustomer } = useHttp();
  let dispatch = useDispatch();

  let emailRegex = new RegExp(
    "^([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{2,})@([a-zA-Z0-9_\\-\\!@#$%^&*()+=]{2,}).([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{1,})$"
  );
  const emailIsValid = emailRegex.test(email);
  const cniIsValid = cni.length > 0 && cni !== "";
  // const cniIsValid = cniRegex.test(cni);
  console.log(emailIsValid, "emailisvalid");
  console.log(cniIsValid, "cniIsValid");

  const changeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    // if (
    //   event.currentTarget !== undefined &&
    //   event.currentTarget.value !== undefined &&
    //   event.currentTarget.value !== ""
    //   ) {
    //     console.log(event.currentTarget.value);
    //     console.log(setEmail(event.currentTarget.value));

    // setEmail(event.currentTarget.value);

    //   }
  };

  const changeCniHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCni(event.currentTarget.value);
    //  if(event.currentTarget !== undefined && event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
    // setCni(event.currentTarget.value);

    //  }
  };
  // if(emailIsValid && cniIsValid) {
  //   setIsValid(true)
  // };
  const formIsValid = emailIsValid && cniIsValid;
  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    //here the function to send the data to the back end
    if (formIsValid) {
      const addClientRequestModel: AddCustomerRequest = {
        email: email,
        identificationNumber: cni,
      };
      await addCustomer(EndpointUrls.ClientRegister, addClientRequestModel);
      setEmail("");
      setCni("");
      dispatch(uiSliceActions.showModal(false));
      dispatch(loadClientList());
    }
  };

  const resetHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmail("");
    setCni("");
    dispatch(uiSliceActions.showModal(false));
  };
  console.log(email, "email");

  return (
    <FormContainer
      onSubmit={submitHandler}
      onReset={resetHandler}
      formActions={
        <AddCustomerFormAction
          isFormValid={formIsValid}
          isLoading={isLoading}
        />
      }
    >
      <FormRow type={"grid"}>
        <BasicInput
          label="Email"
          type="email"
          id="customer-email"
          name="customer-email"
          value={email}
          placeholder="Email"
          required
          onChange={changeEmailHandler}
        />
      </FormRow>
      <FormRow type={"grid"}>
        <BasicInput
          label="Identification Number"
          type="text"
          id="customer-identification-number"
          name="customer-identification-number"
          value={cni}
          placeholder="Identification Number"
          required
          onChange={changeCniHandler}
        />
      </FormRow>
    </FormContainer>
  );
};

export default AddCustomerForm;

import { Message } from "../models/Api/Messages/Models";
import UserMessageApiModel from "../models/Api/Users/UserMessageApiModel";

export const mapApiResponseToMessageModel: (
	apiData: any,
	id: any
) => Message | undefined = (apiData, id) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	return {
		accountName: apiData.accountName,
		authorId: apiData.authorId,
		authorName: apiData.authorName,
		feedbackAuthorId: apiData.feedbackAuthorId,
		feedbackAuthorName: apiData.feedbackAuthorName,
		feedbackBody: apiData.feedbackBody,
		feedbackType: apiData.feedbackType,
		isFlagged: apiData.isFlagged,
		messageBody: apiData.messageBody,
		id: id,
		weekNumber: apiData.weekNumber,
		year: apiData.year,
		isHandled: apiData.isHandled,
		dateCreated: apiData.dateCreated,
		sendDate: apiData.sendDate,
		accountId: apiData.accountId,
	};
};

export const mapApiResponseToMessageModelList: (
	apiData: any
) => Message[] | undefined = (apiData) => {
	if (apiData === undefined || apiData === null || apiData.length === 0) {
		return undefined;
	}

	let result: Message[] = [];

	for (const key in apiData) {
		if (Object.prototype.hasOwnProperty.call(apiData, key)) {
			const element = apiData[key];
			let message = mapApiResponseToMessageModel(apiData[key], key);
			if (message !== undefined) {
				result = result.concat(message);
			}
		}
	}

	return result;
};

export const mapApiResponseToUserMessageModel = (apiData: any) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	let userMessage: UserMessageApiModel = {
		id: apiData.id,
		authorId: apiData.authorId,
		authorName: apiData.authorName,
		feedbackAuthorId: apiData.feedbackAuthorId,
		feedbackAuthorName: apiData.feedbackAuthorName,
		feedbackType: apiData.feedbackType,
		isFlagged: apiData.isFlagged,
		sendDate: apiData.sendDate,
		messageBody: apiData.messageBody,
		feedbackBody: apiData.feedbackBody,
		accountName: apiData.accountName,
	};

	return userMessage;
};

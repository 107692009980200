export enum AccountAction {
	Number = "ACCOUNT_NUMBER",
	Password = "ACCOUNT_PASSWORD",
	Email = "ACCOUNT_EMAIL",
	EmailPassword = "EMAIL_PASSWORD",
	Skype = "ACCOUNT_SKYPE",
	SkypePassword = "SKYPE_PASSWORD",
	AccountType = "ACCOUNT_TYPE",
	SetInitialState = "SET_INITIAL_STATE",
	IpAddress = "ID_ADDRESS",
	ClientId = "CLIENT_ID",
	ServerId = "SERVER_ID",
}

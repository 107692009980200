import React from "react";
import {
	SingleFullWidthColumn,
	TwoColumnsFocusLeft,
	TwoColumnsFocusRight,
} from "../../components/Layouts/PageStructure/GridColumns";
import Card from "../../components/ui/Card/Card";
import { useParams } from "react-router-dom";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import DailyMessagesSentGraph from "../../components/Graphs/DailyMessagesSentGraph";
import { useAppSelector } from "../../hooks/StateHooks";
import AccountAssignmentHistoryCard from "../../components/Accounts/AccountAssignementHistoryCard";
import ProfileNavigation from "../../components/Profile/ProfileNavigation/ProfileNavigation";
import EditProfileCard from "../../components/Profile/EditProfileCard";
import MessageListCard from "../../components/Messages/MessageListCard/MessageListCard";

const ProfileEditInfoPage: React.FC<{}> = (props) => {
	let currentUserProfile = useAppSelector(
		(state) => state.users.currentProfile?.profile
	);
	let { userId } = useParams();

	return (
		<React.Fragment>
			{userId !== undefined &&
				userId !== null &&
				userId.trim().length !== 0 && <ProfileNavigation userId={userId} />}

			<TwoColumnsFocusLeft>
				{userId !== undefined &&
					userId !== null &&
					userId.trim().length !== 0 && <EditProfileCard userId={userId} />}

				{currentUserProfile?.userId !== undefined && (
					<AccountAssignmentHistoryCard userId={currentUserProfile?.userId} />
				)}
			</TwoColumnsFocusLeft>
			<SingleFullWidthColumn>
				{/* <Card
					theme={"white"}
					isDashboardElement={true}
					className="overview-item__column"
				>
					{userId !== undefined && (
						<div className="current-week--graph">
							<DailyMessagesSentGraph
								userId={userId}
								date={new Date().toDateString()}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"day"}
								year={new Date().getFullYear().toString()}
							/>
						</div>
					)}
				</Card> */}
				{userId !== undefined && userId !== null && (
					<MessageListCard userId={userId} />
				)}
			</SingleFullWidthColumn>
		</React.Fragment>
	);
};

export default ProfileEditInfoPage;

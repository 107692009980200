import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExtraCostResponseModel } from "../models/Api/ExtraCosts/ExtraCostsApiModel";
import { ExtraCostsState } from "../models/State/States";

let initalData: ExtraCostsState = {
  extraCostsList: [],
  isLoading: false,
};

const setIsLoading = (
  state: ExtraCostsState,
  action: PayloadAction<boolean>
) => {
  let newState = { ...state };
  newState.isLoading = action.payload;
  return newState;
};

const setExtraCostsList = (
  state: ExtraCostsState,
  action: PayloadAction<ExtraCostResponseModel[]>
) => {
  let newState = { ...state };
  newState.extraCostsList = action.payload;
  return newState;
};

const extraCostSlice = createSlice({
  name: "extraCosts",
  initialState: initalData,
  reducers: { setIsLoading, setExtraCostsList },
});

export const extraCostSliceAction = extraCostSlice.actions;
export default extraCostSlice;

import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/UseHttp";
import OverviewItem from "../OverviewItem/OverviewItem";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { PayRangeInfo } from "../../models/Api/PayRange/PayRange";
import { mapApiResponseToPayRange } from "../../Helpers/PayRangeHelpers";
import LoadingAnimation from "../Loading/Loading";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";

const AccountTarificationOverviewCard: React.FC<{ userId: string }> = (
	props
) => {
	let { isLoading, executeGet: fetchPayRangeInfo } = useHttp();
	let [payRange, setPayRange] = useState<PayRangeInfo>();

	const handlePayRangeApiResponse = (data: any) => {
		console.log("data" + data);
		let payRangeInfo = mapApiResponseToPayRange(data);

		if (payRangeInfo !== undefined) {
			setPayRange((previousState) => payRangeInfo);
		}
	};

	useEffect(() => {
		fetchPayRangeInfo(EndpointUrls.PayRange, handlePayRangeApiResponse, [
			{ key: "userId", value: props.userId },
		]);
	}, [props.userId]);
	let count = 0;

	return (
		<OverviewItem title={"Tariffs"} icon={"monetization_on"} iconColor="gold">
			{isLoading && <LoadingAnimation />}
			{!isLoading && payRange !== undefined && (
				<React.Fragment>
					{(payRange.platform === PlatformType.cdc ||
						payRange.platform === PlatformType.cdm) && (
						<React.Fragment>
							<div className="overview-item__details--grid">
								<span className="overview-item__details__title">From</span>
								<span className="overview-item__details__title">To</span>
								<span className="overview-item__details__title">Tariff</span>
							</div>
							{payRange.dayTariff.map((dayTariff) => (
								<div key={count++} className="overview-item__details--grid">
									<span className="overview-item__details__description">
										{dayTariff.startRange}
									</span>
									<span className="overview-item__details__description">
										{dayTariff.endRange}
									</span>
									<span className="overview-item__details__description text-type--price--ar">
										{dayTariff.tariff}
									</span>
								</div>
							))}
						</React.Fragment>
					)}
					{payRange.platform === PlatformType.panel && (
						<React.Fragment>
							<div className="overview-item__details--grid">
								<span className="overview-item__details__title">Day</span>
							</div>
							<div className="overview-item__details--grid">
								<span className="overview-item__details__title">From</span>
								<span className="overview-item__details__title">To</span>
								<span className="overview-item__details__title">Tariff</span>
							</div>
							{payRange.dayTariff.map((dayTariff) => (
								<div key={count++} className="overview-item__details--grid">
									<span className="overview-item__details__description">
										{dayTariff.startRange}
									</span>
									<span className="overview-item__details__description">
										{dayTariff.endRange}
									</span>
									<span className="overview-item__details__description text-type--price--ar">
										{dayTariff.tariff}
									</span>
								</div>
							))}
							<div className="overview-item__details--grid">
								<span className="overview-item__details__title">Night</span>
							</div>
							<div className="overview-item__details--grid">
								<span className="overview-item__details__title">From</span>
								<span className="overview-item__details__title">To</span>
								<span className="overview-item__details__title">Tariff</span>
							</div>
							{payRange.nightTariff.map((nightTariff) => (
								<div key={count++} className="overview-item__details--grid">
									<span className="overview-item__details__description">
										{nightTariff.startRange}
									</span>
									<span className="overview-item__details__description">
										{nightTariff.endRange}
									</span>
									<span className="overview-item__details__description text-type--price--ar">
										{nightTariff.tariff}
									</span>
								</div>
							))}
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</OverviewItem>
	);
};

export default AccountTarificationOverviewCard;

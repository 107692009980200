import React, { useEffect, useState } from "react";
import OverviewItem from "../OverviewItem/OverviewItem";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import AccountApiModel from "../../models/Api/Accounts/AccountsApiModel";
import { mapApiResponseToAccountModel } from "../../Helpers/AccountHelpers";
import { mapResponseToApiModel } from "../../Helpers/UserHelpers";

import LoadingAnimation from "../Loading/Loading";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";
import ErrorNotification from "../ui/ErrorNotification/ErrorNotification";

const CurrentAccountInformationCard: React.FC<{ userId: string }> = (props) => {
	let { isLoading, executeGet: fetchData } = useHttp();
	let [currentAccount, setCurrentAccount] = useState<AccountApiModel>();
	let [accountId, setAccountId] = useState<string>();

	const handleAccountApiResponse: (data: any) => void = (data) => {
		var currentAccount = mapApiResponseToAccountModel(data);
		if (currentAccount != undefined) {
			setCurrentAccount((previousState) => currentAccount);
		}
	};

	const handleUserApiResponse = (data: any) => {
		var currentUser = mapResponseToApiModel(data);
		if (currentUser != undefined) {
			if (currentUser.accounts != undefined && currentUser.accounts != null) {
				setAccountId(currentUser.accounts.id);
			}
		}
	};

	useEffect(() => {
		fetchData(EndpointUrls.Users, handleUserApiResponse, [
			{ key: "userId", value: props.userId },
		]).then(() => {
			if (accountId != undefined) {
				fetchData(EndpointUrls.Account, handleAccountApiResponse, [
					{ key: "id", value: accountId },
				]);
			}
		});
	}, [props.userId, accountId]);

	return (
		<OverviewItem
			title={"Account Information"}
			icon={"3p"}
			iconColor="dark-green"
		>
			{isLoading && <LoadingAnimation />}
			{!isLoading && currentAccount != undefined && (
				<React.Fragment>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Current Account Type:
						</span>
						<span className="overview-item__details__description">
							{currentAccount.accountType == PlatformType.cdc ? `CDC` : `Panel`}
						</span>
					</div>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Current Account Name:
						</span>
						<span className="overview-item__details__description">
							{currentAccount.accountNumber}
						</span>
					</div>
				</React.Fragment>
			)}
			{!isLoading && currentAccount == undefined && (
				<ErrorNotification description="There is no data available for the selected period" />
			)}
		</OverviewItem>
	);
};

export default CurrentAccountInformationCard;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mapApiResponseToFlowboosterInterface } from "../Helpers/FlowboosterHelper";
import { PlatformType } from "../models/Enums/Platform/PlaformEnum";
import { Error } from "../models/Errors/Error";
import { FlowboosterState } from "../models/State/States";

let initialValues: FlowboosterState = {
	platform: PlatformType.cdc,
	isLoading: false,
	isSessionStarted: false,
	refreshKey: 0,
	ipAddress: "",
	error: {
		isError: false,
		errorMessage: "",
	},
	interfaceInformation: {
		conversationInformation: {
			id: "",
			userId: "",
			playerProfileId: "",
			targetProfileId: "",
			numberOfMessagesSent: 0,
			numberOfMessagesReceived: 0,
			lastMessageReceivedId: "",
			lastMessageSentId: "",
			startDate: "",
			isMediaListExhausted: false,
			hasMediaButton: false,
		},
		playerProfile: {
			id: "",
			name: "",
			photo: "",
			gender: "",
			country: "",
			city: "",
			myLength: 0,
			yourLength: 0,
			age: 0,
			dob: undefined,
			constellation: "",
			geoRegion1: "",
			geoRegion2: "",
			geoRegion3: "",
			geoRegion4: "",
			geoLocality: "",
			geoPostcode: "",
			geoTimezone: "",
			hasLastNotes: false,
			lastNotes: [],
		},
		targetProfile: {
			id: "",
			name: "",
			photo: "",
			gender: "",
			country: "",
			city: "",
			myLength: 0,
			yourLength: 0,
			age: 0,
			dob: undefined,
			constellation: "",
			geoRegion1: "",
			geoRegion2: "",
			geoRegion3: "",
			geoRegion4: "",
			geoLocality: "",
			geoPostcode: "",
			geoTimezone: "",
			hasLastNotes: false,
			lastNotes: [],
		},
		messages: [],
		lastMessages: {
			sendDate: undefined,
			attachment: "",
			messageBody: "",
			feedbackBody: "",
			isFlagged: false,
			authorId: "",
			authorName: "",
			feedbackAuthorId: "",
			feedbackAuthorName: "",
			feedbackType: "",
			accountName: "",
			isInbound: false,
			received: undefined,
		},
		noteCategories: [],
	},
	isLimitReached: false,
};

const refreshWindow = (state: FlowboosterState, action: PayloadAction) => {
	state.refreshKey = state.refreshKey + 1;
};

const updateSession = (
	state: FlowboosterState,
	action: PayloadAction<boolean>
) => {
	state.isSessionStarted = action.payload;
};
const setLoadingState = (
	state: FlowboosterState,
	action: PayloadAction<boolean>
) => {
	state.isLoading = action.payload;
};
const setIpAddress = (
	state: FlowboosterState,
	action: PayloadAction<string>
) => {
	state.ipAddress = action.payload;
};

const setErrorState = (
	state: FlowboosterState,
	action: PayloadAction<Error>
) => {
	state.error = action.payload;
};

const setInterfaceInformation = (
	state: FlowboosterState,
	action: PayloadAction<any>
) => {
	var mappedInterfaceInformation = mapApiResponseToFlowboosterInterface(
		action.payload
	);
	if (mappedInterfaceInformation !== undefined) {
		state.interfaceInformation = action.payload;
	}
};

const updateMessageLimit = (
	state: FlowboosterState,
	action: PayloadAction<boolean>
) => {
	state.isLimitReached = action.payload;
};

const setPlatform: (
	state: FlowboosterState,
	action: PayloadAction<PlatformType>
) => void = (state, action) => {
	state.platform = action.payload;
};

const flowboosterSlice = createSlice({
	name: "flowbooster",
	initialState: initialValues,
	reducers: {
		refreshWindow,
		updateSession,
		setLoadingState,
		setIpAddress,
		setErrorState,
		setInterfaceInformation,
		setPlatform,
		updateMessageLimit,
	},
	// extraReducers: (builder) => {
	// 	builder.addCase(startFlowboosterSession.fulfilled, (state, action) => {
	// 		console.log(action);
	// 		console.log(state);
	// 	});
	// },
});

export const flowboosterSliceActions = flowboosterSlice.actions;

export default flowboosterSlice;

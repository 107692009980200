import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
	mapApiResponseToFlowboosterInterface,
	mapApiResponseToFlowboosterNoteHistory,
	mapApiResponseToFlowboosterNoteHistoryList,
} from "../Helpers/FlowboosterHelper";
import { mapApiResponseToMessageModelList } from "../Helpers/MessageHelpers";
import { Message } from "../models/Api/Messages/Models";
import EndpointUrls from "../models/Enums/Api/Endpoints";
import {
	DeleteNoteRequest,
	FlowboosterInterface,
	FlowboosterNoteHistory,
	FlowboosterRequest,
	FlowboosterSendMessageRequest,
	SendNoteRequest,
} from "../models/Flowbooster/Models";
import { DynamicKeyValuePair } from "../models/General/GeneralModel";
import { uiSliceActions } from "../store/UiSlice";
import useHttp from "./UseHttp";

const useMessageApi = () => {
	const {
		isLoading,
		error,
		executeGet: fetchMessage,
		executePost: sendData,
		executeDelete: deleteData,
	} = useHttp();

	const [interfaceInformation, setInterfaceInformation] =
		useState<FlowboosterInterface>();

	const [noteHistory, setNoteHistory] = useState<FlowboosterNoteHistory>();
	const [messageList, setMessageList] = useState<Message[]>();

	const dispatch = useDispatch();

	const handleApiResult: (isSuccessful: boolean, message: string) => void = (
		isSuccessful,
		message
	) => {
		if (!isSuccessful) {
			dispatch(
				uiSliceActions.setErrorState({ errorMessage: message, isError: true })
			);
		}
	};

	const loadMessage = async (request: FlowboosterRequest) => {
		try {
			let url = `${request.ipAddress}${EndpointUrls.MessageInterface}`;
			await fetchMessage(
				url,
				(apiData) => {
					let result = mapApiResponseToFlowboosterInterface(apiData);
					setInterfaceInformation(result);
				},
				[
					{ key: "userId", value: request.userId },
					{ key: "platform", value: request.platform },
				],
				false
			);
		} catch (error: any) {}

		return interfaceInformation;
	};

	const sendMessage = async (
		ipAddress: string,
		userId: string,
		platform: string,
		sendMessageRequest: FlowboosterSendMessageRequest
	) => {
		try {
			let url = `${ipAddress}${EndpointUrls.MessageInterface}`;
			await sendData(
				url,
				sendMessageRequest,
				(apiData: any) => {
					handleApiResult(
						apiData.isSuccessful,
						"We could not send this message. It has already been handled by another operator."
					);
				},
				[
					{ key: "userId", value: userId },
					{ key: "platform", value: platform },
				],
				false
			);
		} catch (error: any) {}
	};

	const loadNotes = async (
		ipAddress: string,
		userId: string,
		profileId: string,
		platform: string,
		callback: (
			data: DynamicKeyValuePair<string, FlowboosterNoteHistory[]>[]
		) => void,
		targetId?: string
	) => {
		try {
			let url = `${ipAddress}${EndpointUrls.NoteService}`;

			let parameters = [
				{ key: "userId", value: userId },
				{ key: "profileId", value: profileId },
				{ key: "platform", value: platform },
			];

			if (targetId !== undefined) {
				parameters = parameters.concat({ key: "targetId", value: targetId });
			}
			await fetchMessage(
				url,
				(apiData: any) => {
					let mappedNoteHistory =
						mapApiResponseToFlowboosterNoteHistoryList(apiData);

					if (mappedNoteHistory !== undefined) {
						callback(mappedNoteHistory);
					}
				},
				parameters,
				false
			);
		} catch (error: any) {}
	};

	const sendNote = async (
		ipAddress: string,
		userId: string,
		platform: string,
		sendNoteRequest: SendNoteRequest
	) => {
		try {
			let url = `${ipAddress}${EndpointUrls.NoteService}`;
			await sendData(
				url,
				sendNoteRequest,
				(apiData: any) => {
					setNoteHistory(apiData);
				},
				[
					{ key: "userId", value: userId },
					{ key: "platform", value: platform },
				],
				false
			);
		} catch (error: any) {}
	};

	const deleteNote = async (
		ipAddress: string,
		userId: string,
		platform: string,
		deleteNoteRequest: DeleteNoteRequest
	) => {
		try {
			let url = `${ipAddress}${EndpointUrls.NoteService}`;
			await deleteData(
				url,
				{
					isPlayer: deleteNoteRequest.isPlayerNote,
					noteId: deleteNoteRequest.noteId,
				},
				[
					{ key: "userId", value: userId },
					{ key: "platform", value: platform },
				],
				undefined,
				false
			);
		} catch (error: any) {}
	};

	const saveMessage = async (userId: string, message: Message) => {
		let isSuccessful = false;

		await sendData(
			EndpointUrls.Message,
			message,
			(apiData) => {
				handleApiResult(
					apiData.isSuccessful,
					"We could not send this message. It has already been handled by another operator."
				);
			},
			[{ key: "userId", value: userId }]
		);
	};

	const loadMessageList = async (userId: string) => {
		await fetchMessage(
			EndpointUrls.Message,
			(apiData) => {
				let mappedResult = mapApiResponseToMessageModelList(apiData);

				if (mappedResult !== undefined) {
					setMessageList(mappedResult);
				}
			},
			[{ key: "userId", value: userId }]
		);
	};

	return {
		error,
		isLoading,
		interfaceInformation,
		noteHistory,
		messageList,
		loadMessage,
		sendMessage,
		sendNote,
		loadNotes,
		deleteNote,
		saveMessage,
		loadMessageList,
	};
};

export default useMessageApi;

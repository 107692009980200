import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/UseHttp";
import { DuplicateMessageRequest } from "../../../models/Api/Messages/Models";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import LoadingAnimation from "../../Loading/Loading";
import "./_duplicateMessageListBox.scss";

const DuplicateMessageListBox: React.FC<{ userId: string; message: string }> = (
	props
) => {
	const { isLoading, executePost: checkForDuplicate } = useHttp();
	const [duplicateMessageList, setDuplicateMessageList] = useState<string[]>();
	const delay = 1;
	let timer: ReturnType<typeof setTimeout>;
	useEffect(() => {
		const verifyMessage = async () => {
			const duplicateMessageRequest: DuplicateMessageRequest = {
				messageBody: props.message,
				userId: props.userId,
			};
			await checkForDuplicate(
				EndpointUrls.DuplicateMessage,
				duplicateMessageRequest,
				(apiData) => {
					if (apiData !== undefined && apiData !== null) {
						setDuplicateMessageList(apiData);
					}
				}
			);
		};
		if (props.message.trim().length > 0) {
			timer = setTimeout(() => {
				verifyMessage();
			}, delay * 1000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [props.message.trim().length]);
	return (
		<div className="duplicate-message-box">
			{!isLoading &&
				duplicateMessageList !== undefined &&
				duplicateMessageList.length > 0 && (
					<span className="duplicate-message-box__title">
						You already sent similar messages! Please correct. See information
						below:
					</span>
				)}

			{!isLoading &&
				(duplicateMessageList === undefined ||
					duplicateMessageList.length === 0) && (
					<span className="duplicate-message-box__title">
						No similar message have been found.
					</span>
				)}
			<ul>
				{isLoading && <LoadingAnimation />}
				{!isLoading &&
					duplicateMessageList !== undefined &&
					duplicateMessageList.map((message) => (
						<li key={Math.random()} className="duplicate-message-box__item">
							<span dangerouslySetInnerHTML={{ __html: message }}></span>
						</li>
					))}
			</ul>
		</div>
	);
};

export default DuplicateMessageListBox;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import RoleApiModel from "../models/Api/Roles/RoleApiModel";

import { RolesState } from "../models/State/States";

let initialState: RolesState = {
  roles: [],
};
const getRoleIndex: (state: RolesState, roleId: string) => number = (
  state,
  roleId
) => {
  return state.roles.findIndex((role) => role.id == roleId);
};

const setRoles = (state: RolesState, action: PayloadAction<any>) => {
  if (action.payload == undefined || action.payload == null) {
    return state;
  }

  let roleApiModelList: RoleApiModel[] = [];
  let data = { ...action.payload };
  for (const key in data) {
    let role: RoleApiModel = {
      description: data[key].description,
      name: data[key].name,
      id: data[key].id,
    };

    roleApiModelList = roleApiModelList.concat(role);
  }

  state.roles = roleApiModelList;
  return state;
};

const addRoles = (state: RolesState, action: PayloadAction<RoleApiModel>) => {
  state.roles = state.roles.concat(action.payload);
};

const deleteRole = (state: RolesState, action: PayloadAction<string>) => {
  let roleIndex = getRoleIndex(state, action.payload);
  state.roles = state.roles.splice(roleIndex, 1);
};

const updateRole = (state: RolesState, action: PayloadAction<RoleApiModel>) => {
  state.roles = state.roles.concat(action.payload);
};

const roleSlice = createSlice({
  name: "roles",
  initialState: initialState,
  reducers: {
    setRoles,
    addRoles,
    deleteRole,
    updateRole,
  },
});

export const roleSliceActions = roleSlice.actions;

export default roleSlice;

import { tarificationSliceAction } from "../TarificationSlice";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { mapApiDataToAccountTarificationDetailsList } from "../../Helpers/AccountTarificationHelpers";
import { executeGet } from "./AsyncActions";

export const loadTarificationDetailsList = () => {
  return (dispatch: any) => {
    dispatch(tarificationSliceAction.setIsLoading(true));

    dispatch(
      executeGet(EndpointUrls.AccountTarification, (apiData) => {
        let tarificationDetailsList = mapApiDataToAccountTarificationDetailsList(
          apiData
        );
        if (tarificationDetailsList != undefined) {
          dispatch(
            tarificationSliceAction.setTarificationDetailsList(
              tarificationDetailsList
            )
          );
        }
        dispatch(tarificationSliceAction.setIsLoading(false));
      })
    );
  };
};

import React from "react";
import CardProps, {
	CardThemeProps,
} from "../../../models/Props/Card/CardProps";

import "./_card.scss";

const Card: React.FC<CardProps> = (props) => {
	const getCardColor = (): string => {
		switch (props.theme) {
			case "blue":
				return "card--blue";
			case "cream":
				return "card--cream";
			case "lightBlue":
				return "card--light-blue";
			case "orange":
				return "card--orange";
			case "white":
				return "card--white";
			case "grey":
				return "card--grey";
			case "image":
				return "card--image";
			default:
				return "card--white";
		}
	};

	const getCardClasses = (): string[] => {
		let result: string[] = [];

		result.push(getCardColor());

		if (props.isDashboardElement) {
			result.push("card-item");
		}

		return result;
	};

	let classes = getCardClasses();
	let classNames = `card ${
		props.className !== undefined ? props.className : ""
	}`;
	classes.forEach((theme) => {
		classNames = classNames + " " + theme;
	});
	return <div className={classNames}>{props.children}</div>;
};

export const FocusCard: React.FC<CardThemeProps> = (props) => {
	return (
		<Card
			theme={props.theme}
			isDashboardElement={false}
			className={`card--flex card--flex__focus ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			{props.children}
		</Card>
	);
};

export default Card;

import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useHttp from "../../hooks/UseHttp";
import { KeyValuePair } from "../../models/General/GeneralModel";
import LoadingAnimation from "../Loading/Loading";

const DailyMessagesSentGraph: React.FC<{
	userId: string;
	date?: string;
	endpoint: string;
	weekNumber?: number;
	year: string;
	graphType?: "bar" | "line";
	shift: "day" | "night" | "both";
}> = (props) => {
	let [stats, setStats] = useState<{ name: string; data: number[] }[]>();
	let { isLoading, executeGet: fetchData } = useHttp();

	let [endpoint, setEndpoint] = useState<string>(props.endpoint);

	useEffect(() => {
		let params: KeyValuePair[] = [
			{ key: "userId", value: props.userId },
			{ key: "shift", value: props.shift },
			{ key: "year", value: props.year },
		];

		if (props.weekNumber != undefined) {
			params.push({ key: "week", value: props.weekNumber.toString() });
		} else {
			if (props.date != undefined) {
				params.push({ key: "date", value: props.date });
			}
		}

		fetchData(
			endpoint,
			(data) => {
				if (data != undefined) {
					setStats(data);
				}
			},
			params
		);
	}, [props.userId, props.date, props.weekNumber]);

	let chartOptions: ApexOptions = {
		chart: {
			id: "giveitsomeId",
			toolbar: { show: false },
			animations: {
				enabled: true,
				easing: "easeinout",
				speed: 800,
			},
			height: 450,
			zoom: { enabled: false },
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: false,
			},
		},

		xaxis: {
			labels: { show: true },
			categories: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
				"Sunday",
			],
		},
		theme: {
			mode: "light",

			palette: "palette1",
			monochrome: {
				enabled: false,
				color: "#ffb347",
				shadeTo: "dark",
				shadeIntensity: 0.65,
			},
		},
		grid: { show: false },
		series: [
			{
				name: "daily",
				data: [200, 650, 333, 520, 100, 322, 52],
			},
		],
	};

	return (
		<React.Fragment>
			{isLoading && <LoadingAnimation />}
			{!isLoading &&
				stats != undefined &&
				stats.length > 0 &&
				stats.length <= 1 &&
				stats.map((stat) => {
					if (stat == undefined) {
						return <p>stat is undefined</p>;
					}
					return (
						<Chart
							key={stat.name}
							type="bar"
							options={chartOptions}
							series={[
								{
									name: stat.name,
									data: stat.data,
								},
							]}
						></Chart>
					);
				})}

			{!isLoading && stats != undefined && stats.length >= 2 && (
				<Chart
					type={props.graphType || "bar"}
					options={chartOptions}
					series={stats.map((stat) => {
						return {
							name: stat.name,
							data: stat.data,
						};
					})}
				></Chart>
			)}
		</React.Fragment>
	);
};

export default DailyMessagesSentGraph;

import React from "react";
import { NavigationInformation } from "../../../models/Props/Navigation/NavigationProps";

import DashboardContainer, {
	DashboardMainContent,
} from "../DashboardContainer/DashboardContainer";
import SideNavigation from "../../Navigation/SideNavigation/SideNavigation";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useAppSelector } from "../../../hooks/StateHooks";

import "./_dashboard-layout.scss";
import Modal from "../../Modal/Modal";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../../../store/UiSlice";

export const DashboardTitle: React.FC<{ dashboardTitle: string }> = (props) => {
	return <h1 className="dashboard-title">{props.dashboardTitle}</h1>;
};
const DashboardLayout: React.FC<{}> = (props) => {
	const userProfile = useAppSelector((state) => state.users.currentUser);
	const error = useAppSelector((state) => state.ui.error);
	const dispatch = useDispatch();

	let isFlowboosterSessionStarted = useAppSelector(
		(state) => state.flowbooster.isSessionStarted
	);
	const showDasbhoardMenu = useAppSelector(
		(state) => state.ui.showDashboardMenu
	);

	let sideNavigations: NavigationInformation[] = [];

	let operatorHomeSection: NavigationInformation = {
		category: "Home",
		navigations: [
			{ text: "Dashboard", to: "/dashboard", icon: "home" },
			{
				text: "Performance",
				to: "/dashboard/performance",
				icon: "trending_up",
			},
		],
	};

	let adminHomeSection: NavigationInformation = {
		category: "Home",
		navigations: [
			{ text: "Dashboard", to: "/dashboard", icon: "home" },
			{ text: "Accounts", to: "/dashboard/accounts", icon: "trending_up" },
			{
				text: "Operators",
				to: "/dashboard/operators",
				icon: "settings_accessibility",
			},
		],
	};
	
	let financeSection: NavigationInformation = {
		category: "Finances",
		navigations: [
			{
				text: "Accounts Overview",
				to: "/finances/accounts-overview",
				icon: "rocket",
			},
			{
				text: "Operators Overview",
				to: "/finances/operators-overview",
				icon: "people_outline",
			},
			{
				text: "Salaries",
				to: "/finances/salary-overview",
				icon: "price_check",
			},
			{
				text: "Extra Costs",
				to: "/finances/extra-costs",
				icon: "grading",
			},
		],
	};

	let settingsSection: NavigationInformation = {
		category: "Settings",
		navigations: [
			{
				text: "Tarification",
				to: "/settings/tarification",
				icon: "monetization_on",
			},
			{
				text: "Currency Settings",
				to: "/settings/forex",
				icon: "euro_symbol",
			},
		],
	};

	let administrationSection: NavigationInformation = {
		category: "Admin",
		navigations: [
			{
				text: "Manage Operators",
				to: "/administration/manage-operators",
				icon: "group_add",
			},
			{
				text: "Manage Accounts",
				to: "/administration/manage-accounts",
				icon: "manage_accounts",
			},
			{
				text: "Manage User Roles",
				to: "/administration/manage-roles",
				icon: "key",
			},
			{
				text: "Manage Customers",
				to: "/administration/manage-customers",
				icon: "support_agent",
			},
			{
				text: "Manage Servers",
				to: "/administration/manage-servers",
				icon: "support_agent",
			},
		],
	};

	const qualitySection: NavigationInformation = {
		category: "Quality",
		navigations: [
			{
				text: "Accounts Overview List",
				to: "/quality/accounts-overview",
				icon: "group_add",
			},
			{
				text: "Operators Overview List",
				to: "/quality/operators-overview",
				icon: "manage_accounts",
			},
		],
	};

	let currentRole = userProfile?.roles.name.toLowerCase();
	let currentRoleId = userProfile?.roles.id;

	if (currentRole === "operator" || currentRole === "pro_operator") {
		sideNavigations.push(operatorHomeSection);
	}

	if (currentRole === "operation") {
		sideNavigations.push(adminHomeSection);
		sideNavigations.push(qualitySection);
	}

	if (currentRole === "quality") {
		sideNavigations.push(adminHomeSection);
		sideNavigations.push(qualitySection);
	}

	if (currentRole === "admin") {
		sideNavigations.push(adminHomeSection);
		sideNavigations.push(qualitySection);
		sideNavigations.push(financeSection);
		sideNavigations.push(administrationSection);
		sideNavigations.push(settingsSection);
	}
	if (currentRole === "basic") {
		sideNavigations.push({
			category: "Home",
			navigations: [
				{
					text: "Profile",
					to: `/profile/${userProfile?.userId}/overview`,
				},
			],
		});
	};

	return (
		<div className="wrapper">
			{error.isError && (
				<Modal
					title="Something went wrong"
					onClose={() => {
						dispatch(uiSliceActions.resetErrorState());
					}}
				>
					<span>{error.errorMessage}</span>
				</Modal>
			)}

			{/* {!isFlowboosterSessionStarted && (
				<div className="wrapper__side">
					<SideNavigation navigationInformation={sideNavigations} />
				</div>
			)} */}
			<div className="wrapper__main-container">
				<Header
					logo="../../Assets/logo/lsc_logo.png"
					className="wrapper__main-container__header"
				/>
				<SideNavigation
					navigationInformation={sideNavigations}
					isShown={showDasbhoardMenu}
				/>
				<DashboardContainer className="wrapper__main-container__main">
					<DashboardMainContent>{props.children}</DashboardMainContent>
				</DashboardContainer>
				<Footer className="wrapper__main-container__footer" />
			</div>
		</div>
	);
};

export default DashboardLayout;

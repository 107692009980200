import React from "react";
import OverviewNotificationListProps from "../../../models/Props/OverviewNotification/OverviewNotificationProps";
import OverviewNotification from "../OverviewNotification/OverviewNotification";

import "./_overview-notification-list.scss";

const OverviewNotificationList: React.FC<OverviewNotificationListProps> = (
  props
) => {
  let count: number = 0;
  return (
    <div className="overview-notification-list">
      {props.notifications.map((notification) => {
        count = count + 1;
        return (
          <OverviewNotification
            key={count}
            icon={notification.icon}
            date={notification.date}
            title={notification.title}
          />
        );
      })}
    </div>
  );
};

export default OverviewNotificationList;

import { userInfo } from "os";
import UserRoleApiModel from "../models/Api/Roles/UserRoleApiModel";
import UserApiModel from "../models/Api/Users/UserApiModel";
import UserMessageApiModel from "../models/Api/Users/UserMessageApiModel";
import UserProfileApiModel from "../models/Api/Users/UserProfileApiModel";
import { mapApiResponseToAccountModel } from "./AccountHelpers";
import { mapApiResponseToUserMessageModel } from "./MessageHelpers";

export const mapApiModelToUserProfile = (data: any) => {
	if (data === undefined) {
		return {
			id: "",
			firstName: "",
			lastName: "",
			username: "",
			dateOfBirth: "",
			emailAddress: "",
			phoneNumber: "",
			address: "",
			bankAccountNumber: "",
			bankAccountHolderName: "",
			userId: "",
		};
	}

	let userProfile: UserProfileApiModel = {
		id: data.id,
		firstName: data.firstName,
		userId: data.userId,
		lastName: data.lastName,
		username: data.username,
		dateOfBirth: data.dateOfBirth,
		emailAddress: data.emailAddress,
		phoneNumber: data.phoneNumber,
		address: data.address,
		bankAccountNumber: data.bankAccountNumber,
		bankAccountHolderName: data.bankAccountHolderName,
	};

	return userProfile;
};

export const mapResponseToApiModel: (data: any) => UserApiModel | undefined = (
	data
) => {
	if (data == undefined || data == null) {
		return undefined;
	}

	let user: UserApiModel = {
		messages: [],
		flowboosterSession: {
			id: "",
			loginDate: "",
			sessionInformation: [],
			userId: "",
		},
		profile: {
			id: "",
			firstName: "",
			lastName: "",
			username: "",
			dateOfBirth: "",
			emailAddress: "",
			phoneNumber: "",
			address: "",
			bankAccountHolderName: "",
			bankAccountNumber: "",
			userId: "",
		},
		roles: {
			id: "",
			description: "",
			name: "",
			dateAssigned: "",
			userId: "",
		},
		id: data.id,
		accounts: {
			id: "",
			accountNumber: "",
			accountPassword: "",
			emailAccount: "",
			emailPassword: "",
			skypeAccount: "",
			skypePassword: "",
			dateAdded: "",
			dateModified: "",
			dateAssigned: "",
			ipAddress: "",
			clientId: "",
			serverId: "",
		},
		userId: data.userId,
		firstName: data.firstName,
		email: data.email,
		lastName: data.lastName,
	};

	if (data.accounts !== undefined && data.accounts !== null) {
		let userAccount = mapApiResponseToAccountModel(data.accounts);

		if (userAccount !== undefined) {
			user.accounts = {
				...userAccount,
				dateAssigned: "",
			};
		}
	}

	if (data.messages !== undefined && data.messages !== null) {
		let userMessagesList: UserMessageApiModel[] = [];

		for (const userMessageKey in data.messages) {
			let userMessage = mapApiResponseToUserMessageModel(
				data.message[userMessageKey]
			);

			if (userMessage !== undefined) {
				userMessagesList = userMessagesList.concat(userMessage);
			}
		}
		user.messages = userMessagesList;
	}

	if (data.flowboosterSession !== undefined) {
		user.flowboosterSession.id = data.session.id;
		user.flowboosterSession.loginDate = data.session.loginDate;
		user.flowboosterSession.sessionInformation =
			data.session.sessionInformation;
		user.flowboosterSession.userId = data.session.userId;
	}

	if (data.profile !== undefined && data.profile !== null) {
		user.profile = mapApiModelToUserProfile(data.profile);
	}

	if (data.roles !== undefined && data.roles !== null) {
		let userRole: UserRoleApiModel = {
			dateAssigned: data.roles.dateAssigned,
			userId: data.roles.userId,
			name: data.roles.name,
			id: data.roles.id,
			description: data.roles.description,
		};
		user.roles = userRole;
	}

	return user;
};

export const mapDataToUserApiModelList: (
	data: any
) => UserApiModel[] | undefined = (data) => {
	if (data == undefined || data == null) {
		return undefined;
	}

	let userApiModelList: UserApiModel[] = [];
	// for (const key in data) {
	// 	let user = mapResponseToApiModel(data[key]);
	// 	if (user != undefined) {
	// 		userApiModelList = userApiModelList.concat(user);
	// 	}
	// }

	userApiModelList = data.map((user: any) => mapResponseToApiModel(user));

	if (userApiModelList.length > 0) {
		return userApiModelList;
	}
	return undefined;
};

import { current, PayloadAction } from "@reduxjs/toolkit";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router";
import CreateAccountForm from "../../../components/Authentication/Registration/CreateAccountForm";
import FormContainer from "../../../components/Forms/FormContainer";
import {
	FormElementGroup,
	FormHeader,
	FormRow,
} from "../../../components/Forms/FormElements/FormStructure";
import { BasicInput } from "../../../components/Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../../components/Loading/Loading";
import Button from "../../../components/ui/Button/Button";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import CustomersApiModel from "../../../models/Api/Customers/Models";
import UserProfileApiModel from "../../../models/Api/Users/UserProfileApiModel";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { CustomerAction } from "../../../models/Enums/Customers/CustomersEnum";
import { UserProfileDispatchAction } from "../../../models/Enums/Profile/UserProfileEnum";
import { loadClient } from "../../../store/Actions/ClientsActions";
import { loadUserProfile } from "../../../store/Actions/UsersActions";

let initialClientData: CustomersApiModel = {
	id: "",
	clientId: "",
	firstname: "",
	lastname: "",
	username: "",
	businessEmailAddress: "",
	businessPhoneNumber: "",
	businessAddress: "",
	creationDate: "",
	modificationDate: "",
	identificationNumber: "",
	isConfirmed: false,
};

const clientDataReducer = (
	state: CustomersApiModel,
	action: PayloadAction<CustomersApiModel | string | boolean>
) => {
	switch (action.type) {
		case CustomerAction.Load: {
			// console.log(action.payload, "mappedCustomer");
			let newState = { ...(action.payload as CustomersApiModel) };
			newState.modificationDate = newState.creationDate;
			return newState;
		}
		case CustomerAction.Firstname: {
			let newState = { ...state };
			newState.firstname = action.payload as string;
			return newState;
		}
		case CustomerAction.Lastname: {
			let newState = { ...state };
			newState.lastname = action.payload as string;
			return newState;
		}
		case CustomerAction.BusinessAddress: {
			let newState = { ...state };

			newState.businessAddress = action.payload as string;
			return newState;
		}
		case CustomerAction.BusinessPhoneNumber: {
			let newState = { ...state };
			newState.businessPhoneNumber = action.payload as string;
			return newState;
		}
		default:
			return initialClientData;
	}
};

const CustomerRegistrationPage: React.FC<{}> = (props) => {
	const [isBusinessAddressValid, setIsBusinessAddressValid] =
		useState<boolean>(false);
	const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(false);
	const [isLastNameValid, setIsLastNameValid] = useState<boolean>(false);
	const [isBusinessPhoneNumberValid, setIsBusinessPhoneNumberValid] =
		useState<boolean>(false);
	const { isLoading, executeAdd: saveData } = useHttp();
	const [clientData, clientDataDispatch] = useReducer(
		clientDataReducer,
		initialClientData
	);
	const currentClient = useAppSelector((state) => state.clients.currentClient);
	const { clientId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (clientId !== undefined && clientId !== null) {
			dispatch(loadClient(clientId));
		}
	}, []);

	useEffect(() => {
		if (currentClient !== undefined && currentClient !== null) {
			clientDataDispatch({ type: CustomerAction.Load, payload: currentClient });
		}
	}, [currentClient]);

	const isFormValid =
		isBusinessPhoneNumberValid &&
		isBusinessPhoneNumberValid &&
		isFirstNameValid &&
		isLastNameValid;
	console.log(isFormValid);

	const submitHandler = async (event: React.FormEvent) => {
		event.preventDefault();
		if (currentClient !== undefined && currentClient !== null) {
			if (isFormValid) {
				await saveData(
					EndpointUrls.Clients,
					clientData,
					undefined,
					async (data: BaseApiResponse) => {
						if (data.isSuccessful) {
							const profileModel: UserProfileApiModel = {
								id: "",
								firstName: clientData.firstname,
								lastName: clientData.lastname,
								username: clientData.businessEmailAddress,
								dateOfBirth: "",
								emailAddress: clientData.businessEmailAddress,
								phoneNumber: clientData.businessPhoneNumber,
								address: clientData.businessAddress,
								bankAccountNumber: "",
								bankAccountHolderName: "",
								userId: clientData.id,
							};
							await saveData(
								EndpointUrls.Profile,
								profileModel,
								undefined,
								(data: BaseApiResponse) => {
									if (data.isSuccessful) {
										dispatch(loadClient(currentClient.id));
										navigate(`/customer/${currentClient.id}/dashboard`, {
											replace: true,
										});
									}
								}
							);
						}
					}
				);
			}
		}
	};
	return (
		<FormContainer onSubmit={submitHandler}>
			<FormHeader
				title={"Registration"}
				description={
					"Please complete your informations to access to your dashboard."
				}
			/>
			<FormRow type={"grid"}>
				<BasicInput
					label="Firstname"
					type="text"
					name="customer-firstname"
					id="customer-firstname"
					placeholder="Please enter your firstname"
					validationRegex="^[a-zA-Z-]{3,}$"
					errorMessage="Your first name must be at least 3 characters long and must not contains numbers or special characters except '-'."
					onValidateInput={(result: boolean) => {
						setIsFirstNameValid(result);
					}}
					value={clientData.firstname}
					required
					onChange={(event) => {
						let newData = { ...clientData };
						newData.firstname = event.currentTarget.value;
						clientDataDispatch({
							type: CustomerAction.Firstname,
							payload: event.currentTarget.value,
						});
					}}
				/>
				<BasicInput
					label="Lastname"
					type="text"
					name="customer-laststname"
					id="customer-lastname"
					placeholder="Please enter your lastname"
					validationRegex="^[a-zA-Z-]{3,}$"
					errorMessage="Your last name must be at least 3 characters long and must not contains numbers or special characters except '-'."
					onValidateInput={(result: boolean) => {
						setIsLastNameValid(result);
					}}
					value={clientData.lastname}
					required
					onChange={(event) => {
						let newData = { ...clientData };
						newData.lastname = event.currentTarget.value;
						clientDataDispatch({
							type: CustomerAction.Lastname,
							payload: event.currentTarget.value,
						});
					}}
				/>
			</FormRow>
			<FormRow type={"grid"}>
				<BasicInput
					label="Address"
					type="text"
					name="customer-address"
					id="customer-address"
					placeholder="Please enter your address"
					value={clientData.businessAddress}
					required
					onChange={(event) => {
						let newData = { ...clientData };
						newData.businessAddress = event.currentTarget.value;
						clientDataDispatch({
							type: CustomerAction.BusinessAddress,
							payload: event.currentTarget.value,
						});
						setIsBusinessAddressValid(event.currentTarget.value.length > 1);
					}}
				/>
				<BasicInput
					label="Phone Number"
					type="text"
					name="customer-phone-number"
					id="customer-phonr-number"
					placeholder="Please enter your phone number"
					validationRegex="^([+])([(])([0-9]{1,3})([)])([0-9]{3,})$"
					errorMessage="The phone number must be in format +(123)123456789."
					value={clientData.businessPhoneNumber}
					required
					onValidateInput={(result: boolean) => {
						setIsBusinessPhoneNumberValid(result);
					}}
					onChange={(event) => {
						let newData = { ...clientData };
						newData.businessPhoneNumber = event.currentTarget.value;
						clientDataDispatch({
							type: CustomerAction.BusinessPhoneNumber,
							payload: event.currentTarget.value,
						});
					}}
				/>
			</FormRow>
			<FormRow type={"grid"}>
				<Button
					type="submit"
					onClick={submitHandler}
					theme={"primary"}
					disabled={!isFormValid}
					className={!isFormValid ? `button--disabled` : ""}
				>
					<span className="material-icons-outlined">done</span>
					<span>Complete</span>
				</Button>
			</FormRow>
		</FormContainer>
	);
};
export default CustomerRegistrationPage;

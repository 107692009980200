import { CustomerDashboardListProps } from "../../../models/Props/Customers/CustomerDashboardProps";
import { CustomerCardView } from "./CustomerCardView";

export const CustomerCardViewList: React.FC<CustomerDashboardListProps> = (
    props
  ) => {
    return (
      <ul className="customer-card-view-list ">
        {props.cardList !== undefined &&
          props.cardList.length > 0 &&
          props.cardList.map((item) => (
            <CustomerCardView
              cardAccount={item.cardAccount}
              cardTitle={item.cardTitle}
              cardLink={item.cardLink}
              textColor={item.textColor}
              description={item.description}
            />
          ))}
      </ul>
    );
  };
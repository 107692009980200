import React, { HtmlHTMLAttributes, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../Helpers/DateHelpers";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import UserSalary from "../../../models/Api/Salary/UserSalary";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { PlatformType } from "../../../models/Enums/Platform/PlaformEnum";
import { usersSliceActions } from "../../../store/UsersSlice";
import CardInformation from "../../CardInformation/CardInformation";
import LoadingAnimation from "../../Loading/Loading";
import Table, { TableHeader, TableItem, TableRow } from "../../Tables/Table";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import "./_salary-table.scss";

const SalaryTable: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
	const userIdList = useAppSelector((state) => state.users.userIdList);

	const [currentWeekSalaryList, setCurrentWeekSalaryList] = useState<
		UserSalary[]
	>([]);
	const [currentDate, setCurrentDate] = useState<string>();
	const {
		isLoading,
		executeGet: fetchData,
		executeAdd: saveSalary,
	} = useHttp();
	const dispatch = useDispatch();
	const handleApiDataList = (data: any) => {
		dispatch(usersSliceActions.setUsers(data));
	};
	const platform = useAppSelector((state) => state.flowbooster.platform);

	useEffect(() => {
		if (userIdList == undefined || userIdList.length == 0) {
			fetchData(EndpointUrls.Users, handleApiDataList);
		}
		if (userIdList != undefined && userIdList.length > 0) {
			let userSalaryList: UserSalary[] = [];
			userIdList.forEach((userId) => {
				if (userId == "") {
					return;
				}

				if (currentDate != undefined) {
					let parsedDate = new Date(currentDate);
					fetchData(
						EndpointUrls.CalculateSalary,
						(apiData) => {
							userSalaryList = userSalaryList.concat(apiData);
						},
						[
							{ key: "userId", value: userId },
							{
								key: "date",
								value: formatDate(parsedDate),
							},
							{ key: "isPanel", value: `${platform === PlatformType.panel}` },
						]
					).then(() => {
						setCurrentWeekSalaryList((previousState) => {
							let newState = [...previousState];
							newState = userSalaryList;
							return newState;
						});
					});
				}
			});
		}
	}, [currentDate]);

	let headerElements: string[] = [
		"Week number",
		"Operator Name",
		"Bank Info",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
		"Total",
		"Payout",
		"Actions",
	];

	const payEventHandler = (salaryInfo: UserSalary) => {
		if (currentDate != undefined) {
			saveSalary(EndpointUrls.WeeklySalary, salaryInfo, [
				{ key: "userId", value: salaryInfo.userId },
				{ key: "date", value: currentDate },
			]);
		}
	};
	return (
		<Card
			isDashboardElement={true}
			theme="white"
			className="overview-item__column"
		>
			<CardInformation
				variant="fullGrid"
				title="Current week salary"
				header="Salary"
				headerCallToActionElement={
					<div>
						<input
							type="date"
							id="date"
							value={currentDate}
							onChange={(event) => {
								if (event.currentTarget.value != null) {
									setCurrentDate(event.currentTarget.value);
								}
							}}
						></input>
					</div>
				}
				description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi sint tempora, molestiae dolor laboriosam officia facilis corporis ab iusto, ea suscipit delectus asperiores quos omnis atque aperiam architecto blanditiis unde."
			></CardInformation>
			{isLoading && <LoadingAnimation />}
			{!isLoading && currentWeekSalaryList.length > 0 && (
				<Table className="salary-table">
					<TableHeader className="salary-table__header">
						{headerElements.map((header) => (
							<TableItem key={header}>
								<span>{header}</span>
							</TableItem>
						))}
					</TableHeader>
					{currentWeekSalaryList.map((operatorInfo) => {
						let totalNumberDayShiftMessages: number = 0;
						let totalNumberNightShiftMessages: number = 0;
						let totalNumberCombinedShiftMessages: number = 0;
						let totalAmount: number = 0;

						return (
							<React.Fragment>
								{operatorInfo.salary !== null &&
									operatorInfo.salary.length > 0 && (
										<TableRow className="salary-table__row">
											<TableItem className="salary-table__item ">
												<span>{operatorInfo.weekNumber}</span>
											</TableItem>

											<TableItem className="salary-table__item ">
												<span>{operatorInfo.fullName}</span>
											</TableItem>
											<TableItem className="salary-table__item ">
												<span>{operatorInfo.bankDetails}</span>
											</TableItem>
											{operatorInfo.salary.map((dailySalaryInfo) => {
												totalNumberDayShiftMessages +=
													+dailySalaryInfo.dayStats;
												totalNumberNightShiftMessages +=
													+dailySalaryInfo.nightStats;
												totalNumberCombinedShiftMessages +=
													+dailySalaryInfo.totalStats;
												totalAmount += +dailySalaryInfo.totalSalary;
												return (
													<TableItem className="salary-table__item ">
														<div className="salary-table__inner-column">
															<div className="salary-table__inner-column--grid-3">
																<span className="salary-table__inner-column__title">
																	Day
																</span>
																<span className="salary-table__inner-column__title">
																	Night
																</span>
																<span className="salary-table__inner-column__title">
																	Total
																</span>
															</div>

															<div className="salary-table__inner-column--grid-3">
																<span>{dailySalaryInfo.dayStats}</span>
																<span>{dailySalaryInfo.nightStats}</span>
																<span>{dailySalaryInfo.totalStats}</span>
															</div>

															<div className="salary-table__inner-column--grid-3">
																<span className="text-type--price--ar">
																	{dailySalaryInfo.daySalary}
																</span>
																<span className="text-type--price--ar">
																	{dailySalaryInfo.nightSalary}
																</span>
																<span className="text-type--price--ar">
																	{dailySalaryInfo.totalSalary}
																</span>
															</div>

															{/* smaller screen version */}
															<div className="salary-table__inner-column--row-1">
																<span className="salary-table__inner-column__title">
																	Total
																</span>
															</div>

															<div className="salary-table__inner-column--row-1">
																<span>{dailySalaryInfo.totalStats}</span>
															</div>

															<div className="salary-table__inner-column--row-1">
																<span className="text-type--price--ar">
																	{dailySalaryInfo.totalSalary}
																</span>
															</div>
														</div>
													</TableItem>
												);
											})}

											<TableItem className="salary-table__item ">
												<div className="salary-table__inner-column">
													<div className="salary-table__inner-column--grid-3">
														<span className="salary-table__inner-column__title">
															Day
														</span>
														<span className="salary-table__inner-column__title">
															Night
														</span>
														<span className="salary-table__inner-column__title">
															Total
														</span>
													</div>

													<div className="salary-table__inner-column--grid-3">
														<span>{totalNumberDayShiftMessages}</span>
														<span>{totalNumberNightShiftMessages}</span>
														<span>{totalNumberCombinedShiftMessages}</span>
													</div>

													<div className="salary-table__inner-column--grid-3">
														<span>-</span>
														<span>-</span>
														<span className="text-type--price--ar">
															{totalAmount}
														</span>
													</div>

													{/* small screen display */}

													<div className="salary-table__inner-column--row-1">
														<span className="salary-table__inner-column__title">
															Total
														</span>
													</div>

													<div className="salary-table__inner-column--row-1">
														<span>{totalNumberCombinedShiftMessages}</span>
													</div>

													<div className="salary-table__inner-column--row-1">
														<span className="text-type--price--ar">
															{totalAmount}
														</span>
													</div>
												</div>
											</TableItem>
											<TableItem className="salary-table__item ">
												<span className="text-type--price--ar">
													{totalAmount}
												</span>
											</TableItem>
											<TableItem className="salary-table__item ">
												<Button
													theme={"warning"}
													onClick={(event) => {
														event.preventDefault();
														payEventHandler(operatorInfo);
													}}
												>
													Pay
												</Button>
											</TableItem>
										</TableRow>
									)}
							</React.Fragment>
						);
					})}
				</Table>
			)}
		</Card>
	);
};

export default SalaryTable;

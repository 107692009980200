import React, { useEffect, useMemo, useState } from "react";
import {
  MultipleTariffRange,
  TariffRange,
} from "../../models/Api/PayRange/PayRange";
import {
  FormRow,
  FormInputContainer,
} from "../Forms/FormElements/FormStructure";
import Button from "../ui/Button/Button";
import TariffInput from "./TariffInput";

const MultipleTariffRangeInput: React.FC<{
  tariffRangeList: TariffRange[];
  onUpdateTariffs: (data: TariffRange[]) => void;
}> = (props) => {
  let [tariffRangeList, setTariffRangeList] = useState<MultipleTariffRange[]>([
    { index: 1, startRange: 0, endRange: 0, tariff: 0 },
  ]);
  let [index, setIndex] = useState<number>(2);

  useEffect(() => {
    if (props.tariffRangeList.length > 0) {
      setTariffRangeList((prev) =>
        props.tariffRangeList.map((tariff) => {
          let multipleTariffRange: MultipleTariffRange = {
            index: index++,
            startRange: tariff.startRange,
            endRange: tariff.endRange,
            tariff: tariff.tariff,
          };
          setIndex((prev) => prev + 1);

          return multipleTariffRange;
        })
      );
    }
  }, []);

  useEffect(() => {
    props.onUpdateTariffs(
      tariffRangeList.map((tariff) => {
        let tariffRange: TariffRange = {
          startRange: tariff.startRange,
          endRange: tariff.endRange,
          tariff: tariff.tariff,
        };

        return tariffRange;
      })
    );
  }, [useMemo(() => tariffRangeList, [tariffRangeList])]);

  return (
    <React.Fragment>
      {tariffRangeList.map((tariffRange) => {
        return (
          <FormRow key={tariffRange.index} type={"row"}>
            <FormInputContainer>
              <TariffInput
                startRange={tariffRange.startRange}
                endRange={tariffRange.endRange}
                tariff={tariffRange.tariff}
                isDisabled={false}
                title={"Payment Range"}
                counter={tariffRange.index}
                onTariffValueChange={(data) => {
                  setTariffRangeList((prevState) => {
                    let newState = [...prevState];
                    let tariffRangeIndex = newState.findIndex(
                      (t) => t.index === tariffRange.index
                    );

                    if (tariffRangeIndex > -1) {
                      newState[tariffRangeIndex] = {
                        ...data,
                        index: tariffRange.index,
                      };
                    }
                    return newState;
                  });
                }}
              />
            </FormInputContainer>
          </FormRow>
        );
      })}

      <FormRow
        type={"row"}
        className="account-tarification-form__actions-container"
      >
        <Button
          theme={"info"}
          onClick={(event) => {
            event.preventDefault();
            if (index > 1) {
              setIndex((prev) => prev - 1);
              setTariffRangeList((prevState) => {
                let state = [...prevState];
                if (state.length >= 2) {
                  state.pop();
                }

                return state;
              });
            }
          }}
        >
          Remove
        </Button>
        <Button
          theme={"primary"}
          onClick={(event) => {
            event.preventDefault();
            setIndex((prev) => prev + 1);

            setTariffRangeList((prevState) => {
              let state = [...prevState];
              state.push({
                index: index,
                startRange: 0,
                endRange: 0,
                tariff: 0,
              });

              return state;
            });
          }}
        >
          Add another tariff
        </Button>
      </FormRow>
    </React.Fragment>
  );
};

export default MultipleTariffRangeInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../models/State/States";

let initialValues: AuthState = {
  isLoggedIn: false,
  role: undefined,
  token: undefined,
  userId: undefined,
};

const setLoginState: (
  state: AuthState,
  action: PayloadAction<AuthState>
) => void = (state: AuthState, action: PayloadAction<AuthState>) => {
  let newState = { ...state };
  newState = { ...action.payload };
  return newState;
};

const setLogoutState: (
  state: AuthState,
  action: PayloadAction<AuthState>
) => void = (state: AuthState, action: PayloadAction<AuthState>) => {
  let newState = { ...initialValues };
  return newState;
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialValues,
  reducers: {
    setLoginState,
    setLogoutState,
  },
});

export const authSliceAction = authSlice.actions;
export default authSlice;

import React, { useEffect, useState } from "react";
import OverviewItem from "../OverviewItem/OverviewItem";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { mapApiDataToUserSalaryModel } from "../../Helpers/SalaryHelper";
import LoadingAnimation from "../Loading/Loading";
import ValueComparatorDisplay from "../Statistics/ValueComparatorDisplay/ValueComparatorDisplay";
import { formatDate } from "../../Helpers/DateHelpers";

const CurrentWeekMessagesForecastCard: React.FC<{ userId: string }> = (
	props
) => {
	let { isLoading, executeGet: fetchSalaryInfo } = useHttp();
	let [
		currentWeekNightShiftMessageAmount,
		setcurrentWeekNightShiftMessageAmount,
	] = useState<number>(0);
	let [currentWeekDayShiftMessageAmount, setcurrentWeekDayShiftMessageAmount] =
		useState<number>(0);

	let [
		previousWeekNightShiftMessageAmount,
		setpreviousWeekNightShiftMessageAmount,
	] = useState<number>(0);
	let [
		previousWeekDayShiftMessageAmount,
		setpreviousWeekDayShiftMessageAmount,
	] = useState<number>(0);

	useEffect(() => {
		fetchSalaryInfo(
			EndpointUrls.CalculateSalary,
			(apiData) => {
				let salary = mapApiDataToUserSalaryModel(apiData);
				if (salary == undefined) {
					return;
				}
				let totalDayShiftAmountMessages: number = 0;
				let totalNightShiftAmountMessages: number = 0;

				salary.salary.forEach((s) => {
					totalDayShiftAmountMessages += +s.dayStats;
					totalNightShiftAmountMessages += +s.nightStats;
				});

				setcurrentWeekDayShiftMessageAmount(
					(previousState) => totalDayShiftAmountMessages
				);
				setcurrentWeekNightShiftMessageAmount(
					(previousState) => totalNightShiftAmountMessages
				);
			},
			[
				{ key: "userId", value: props.userId },
				{
					key: "date",
					value: formatDate(new Date()),
				},
			]
		).then(() => {
			var today = new Date();
			var previousWeek = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDay() - 8
			);
			fetchSalaryInfo(
				EndpointUrls.CalculateSalary,
				(apiData) => {
					let salary = mapApiDataToUserSalaryModel(apiData);
					if (salary == undefined) {
						return;
					}
					let totalDayShiftAmountMessages: number = 0;
					let totalNightShiftAmountMessages: number = 0;

					salary.salary.forEach((s) => {
						totalDayShiftAmountMessages += +s.dayStats;
						totalNightShiftAmountMessages += +s.nightStats;
					});

					setpreviousWeekDayShiftMessageAmount(
						(previousState) => totalDayShiftAmountMessages
					);
					setpreviousWeekNightShiftMessageAmount(
						(previousState) => totalNightShiftAmountMessages
					);
				},
				[
					{ key: "userId", value: props.userId },
					{
						key: "date",
						value: formatDate(previousWeek),
					},
				]
			);
		});
	}, [props.userId]);

	return (
		<OverviewItem
			title={"Current Week Messages Forecast"}
			icon={"insights"}
			iconColor="blue"
		>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<React.Fragment>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Day Shift Messages
						</span>
						<span className="overview-item__details__description">
							{currentWeekDayShiftMessageAmount}
						</span>
						<ValueComparatorDisplay
							previousValue={previousWeekDayShiftMessageAmount}
							currentValue={currentWeekDayShiftMessageAmount}
						/>
					</div>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Night Shift Messages
						</span>
						<span className="overview-item__details__description">
							{currentWeekNightShiftMessageAmount}{" "}
						</span>
						<ValueComparatorDisplay
							previousValue={previousWeekNightShiftMessageAmount}
							currentValue={currentWeekNightShiftMessageAmount}
						/>
					</div>
					<div className="overview-item__details--grid">
						<span className="overview-item__details__title">
							Total Messages
						</span>
						<span className="overview-item__details__description">
							{+currentWeekNightShiftMessageAmount +
								+currentWeekDayShiftMessageAmount}
						</span>
						<ValueComparatorDisplay
							previousValue={
								+previousWeekNightShiftMessageAmount +
								+previousWeekDayShiftMessageAmount
							}
							currentValue={
								+currentWeekNightShiftMessageAmount +
								+currentWeekDayShiftMessageAmount
							}
						/>
					</div>
				</React.Fragment>
			)}
		</OverviewItem>
	);
};
export default CurrentWeekMessagesForecastCard;

import React from "react";

const useCookies = (domain: string) => {
  const getCookie = (cookieName: string) => {
    let name = cookieName + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const createCookie = <T>(name: string, value: T, expirationTime: number) => {
    let jsonValue = JSON.stringify(value);
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + expirationTime);
    let expires = "expires=" + currentDate.toString();
    document.cookie = `${name}=${encodeURI(
      jsonValue
    )};${expires};path=/${domain}`;
  };

  const readCookie = <T>(name: string) => {
    let cookieData = getCookie(name);

    let result: T;
    if (cookieData != "") {
      result = JSON.parse(decodeURI(cookieData));
      return result;
    }
  };
  const deleteCookie = <T>(name: string) => {
    let existingCookie = readCookie<T>(name);

    var test = new Date().getTime() - 10 * 24 * 60 * 60 * 1000;

    if (existingCookie != undefined) {
      updateCookie(name, "", -new Date().getTime() - 10 * 24 * 60 * 60 * 1000);
    }
  };
  const updateCookie = <T>(
    name: string,
    value: T,
    expirationTime: number = 3600000
  ) => {
    var cookieData = readCookie<T>(name);

    if (cookieData != undefined) {
      cookieData = { ...value };
      createCookie(name, value, expirationTime);
    }
  };

  return {
    createCookie,
    readCookie,
    deleteCookie,
    updateCookie,
  };
};

export default useCookies;

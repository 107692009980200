import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountState } from "../models/State/States";
import AccountApiModel from "../models/Api/Accounts/AccountsApiModel";
import { mapResponseToApiModelList } from "../Helpers/AccountHelpers";

let initialValue: AccountState = {
  accounts: [],
};

const getAccountIndex: (state: AccountState, accountId: string) => number = (
  state,
  accountId
) => {
  return state.accounts.findIndex((role) => role.id == accountId);
};

const setAccounts = (state: AccountState, action: PayloadAction<any>) => {
  let newState = { ...state };
  let apiData = action.payload;
  let accounts = mapResponseToApiModelList(apiData);

  if (accounts != undefined) {
    newState.accounts = [...accounts];
  }
  return newState;
};

const addAccount = (
  state: AccountState,
  action: PayloadAction<AccountApiModel>
) => {
  state.accounts = state.accounts.concat(action.payload);
};

const deleteAccount = (state: AccountState, action: PayloadAction<string>) => {
  let accountIndex = getAccountIndex(state, action.payload);
  state.accounts.splice(accountIndex, 1);
};

const updateAccount = (
  state: AccountState,
  action: PayloadAction<AccountApiModel>
) => {
  state.accounts = state.accounts.concat(action.payload);
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialValue,
  reducers: {
    addAccount,
    deleteAccount,
    updateAccount,
    setAccounts,
  },
});

export const accountsSliceActions = accountsSlice.actions;
export default accountsSlice;

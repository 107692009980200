import React, { MouseEventHandler } from "react";
import { ClickableListProps } from "../../models/Props/List/ListProps";
import ClickableListItem from "./ClickableListItem";

const ClickableList: React.FC<ClickableListProps> = (props) => {
  let count: number = 0;
  return (
    <ul className={`${props.className !== undefined ? props.className : ""}`}>
      {props.listElements != undefined &&
        props.listElements.length > 0 &&
        props.listElements.map((element) => (
          <ClickableListItem key={count++} className={props.listItemClassName}>
            {element.children}
          </ClickableListItem>
        ))}
    </ul>
  );
};

export default ClickableList;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TarificationApiModel } from "../models/Api/Tarification/Tarification";
import { TarificationState } from "../models/State/States";

const initialValue: TarificationState = {
  tarificationDetailsList: [],
  currentTarificationDetail: undefined,
  isLoading: false,
};

const setTarificationDetailsList = (
  state: TarificationState,
  action: PayloadAction<TarificationApiModel[]>
) => {
  let newState = { ...state };
  newState.tarificationDetailsList = [...action.payload];
  return newState;
};

const setIsLoading = (
  state: TarificationState,
  action?: PayloadAction<boolean>
) => {
  let newState = { ...state };

  if (action !== undefined && action.payload !== undefined) {
    newState.isLoading = action.payload;
  } else {
    newState.isLoading = !newState.isLoading;
  }

  return newState;
};

const tarificationSlice = createSlice({
  initialState: initialValue,
  name: "tarification",
  reducers: { setIsLoading, setTarificationDetailsList },
});

export const tarificationSliceAction = tarificationSlice.actions;

export default tarificationSlice;

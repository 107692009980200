import React from "react";
import AvatarProps from "../../models/Props/Avatar/AvatarProps";
import "./_avatar.scss";

const Avatar: React.FC<AvatarProps> = (props) => {
  return (
    <div className="avatar-info">
      <img
        className="avatar--img"
        src={props.imagePath}
        alt="profile-picture"
      />
      <div className="information">
        <span className="title">{`${props.lastName} ${props.firstName}`}</span>
        <span className="description">{props.email}</span>
      </div>
    </div>
  );
};

export default Avatar;

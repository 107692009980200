import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CardInformation from "../../components/CardInformation/CardInformation";
import LoadingAnimation from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import AddServerForm from "../../components/Servers/AddServerForm";
import Table, {
	TableHeader,
	TableItem,
	TableRow,
} from "../../components/Tables/Table";
import Card from "../../components/ui/Card/Card";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { loadServerList } from "../../store/Actions/SliceActions";
import { uiSliceActions } from "../../store/UiSlice";

const ManageServersDashboard: React.FC<{}> = (props) => {
	const showModal = useAppSelector((state) => state.ui.showModal);
	const isLoading = useAppSelector((state) => state.servers.isLoading);
	const [selectedServerId, setSelectedServerId] =
		useState<string | undefined>(undefined);

	const dispatch = useDispatch();

	const serverList = useAppSelector((state) => state.servers.serverList);
	const { executeDelete: deleteServerInformation } = useHttp();

	const addServerHandler = (event: React.MouseEvent) => {
		event.preventDefault();

		dispatch(uiSliceActions.showModal(true));
	};

	const deleteServerInformationHandler = async (serverId: string) => {
		await deleteServerInformation(EndpointUrls.DeleteServer, serverId);
		dispatch(loadServerList());
	};

	const editServerInformationDetails = (serverId: string) => {
		setSelectedServerId(serverId);
		dispatch(uiSliceActions.showModal(true));
	};

	useEffect(() => {
		dispatch(loadServerList());
	}, []);

	return (
		<React.Fragment>
			{showModal && (
				<Modal
					onClose={() => {
						setSelectedServerId(undefined);
					}}
				>
					<AddServerForm serverId={selectedServerId} />
				</Modal>
			)}
			<Card theme={"white"} isDashboardElement={false}>
				<CardInformation
					variant="fullGrid"
					header="Manage Servers"
					className="manage-table__card-header"
					headerCallToActionElement={
						<React.Fragment>
							<a
								href="#"
								className="button button-ghost__alternate desktop"
								onClick={addServerHandler}
							>
								<span className="material-icons-outlined ">
									add_circle_outline
								</span>
								<span> Add Server</span>
							</a>
							<a
								href="#"
								className="button button-ghost__alternate mobile add-icon"
								onClick={addServerHandler}
							>
								<span className="material-icons-outlined ">
									add_circle_outline
								</span>
							</a>
						</React.Fragment>
					}
				></CardInformation>
				<Table>
					<TableHeader>
						<TableItem>Host name</TableItem>
						<TableItem>Ip</TableItem>
						<TableItem>Machine Name</TableItem>
						<TableItem>Status</TableItem>
						<TableItem>Actions</TableItem>
					</TableHeader>
					{isLoading && <LoadingAnimation />}
					{!isLoading && (
						<React.Fragment>
							{serverList.map((serverInformation) => (
								<TableRow key={Math.random()}>
									<TableItem>{serverInformation.hostName}</TableItem>
									<TableItem>{serverInformation.ipAddress}</TableItem>
									<TableItem>{serverInformation.machineName}</TableItem>
									<TableItem>
										{serverInformation.isAssigned ? (
											<span className="status-icon status-icon--active"></span>
										) : (
											<span className="status-icon status-icon--inactive"></span>
										)}
									</TableItem>

									<TableItem>
										<React.Fragment>
											{/* <a
												href="#"
												onClick={(event) => {
													event.preventDefault();
													setIsClicked(true);
													viewAccountDetails(account.id);
												}}
												className="button-icon"
											>
												<span className="icon--outlined">visibility</span>
											</a>*/}
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();

													editServerInformationDetails(serverInformation.id);
												}}
												className="button-icon"
											>
												<span className="icon--outlined">edit</span>
											</a>
											<a
												href="#"
												onClick={(event) => {
													event.preventDefault();

													deleteServerInformationHandler(serverInformation.id);
												}}
												className="button-icon"
											>
												<span className="icon--outlined">delete</span>
											</a>
										</React.Fragment>
									</TableItem>
								</TableRow>
							))}
						</React.Fragment>
					)}
				</Table>
			</Card>
		</React.Fragment>
	);
};

export default ManageServersDashboard;

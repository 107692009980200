import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SalaryState } from "../models/State/States";
import Salary from "../models/Api/Salary/Salary";
import UserSalary from "../models/Api/Salary/UserSalary";

let initialState: SalaryState = {
  currentUserSalary: [],
  allUsersSalary: [],
};
const setCurrentUserSalary = (
  state: SalaryState,
  action: PayloadAction<Salary[]>
) => {
  let newState = { ...state };
  newState.currentUserSalary = action.payload;
  return newState;
};

const addSalaryToCurrentUser = (
  state: SalaryState,
  action: PayloadAction<Salary>
) => {
  let newState = { ...state };
  newState.currentUserSalary.concat(action.payload);
  return newState;
};

const setAllUsersSalary = (
  state: SalaryState,
  action: PayloadAction<UserSalary[]>
) => {
  let newState = { ...state };
  newState.allUsersSalary = action.payload;
  return newState;
};

const addUserSalaryInformationToSalaryList = (
  state: SalaryState,
  action: PayloadAction<UserSalary>
) => {
  let newState = { ...state };
  newState.allUsersSalary.concat(action.payload);

  state = newState;
};

const salarySlice = createSlice({
  initialState: initialState,
  name: "salary",
  reducers: {
    setCurrentUserSalary,
    setAllUsersSalary,
    addSalaryToCurrentUser,
    addUserSalaryInformationToSalaryList,
  },
});

export const salarySliceActions = salarySlice.actions;

export default salarySlice;

import React from "react";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import LoadingAnimation from "../Loading/Loading";
import Button from "../ui/Button/Button";

const UpdateForexButton: React.FC<{}> = (props) => {
  let { isLoading, executeAdd: updateCurrencyRates } = useHttp();

  const updateExchangeRates = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    updateCurrencyRates(EndpointUrls.ExchangeRates, undefined, [
      { key: "shouldUpdate", value: "true" },
    ]);
  };
  return (
    <Button theme={"info"} onClick={updateExchangeRates}>
      {isLoading && <LoadingAnimation />}
      {!isLoading && <span>Update Currency Rates</span>}
    </Button>
  );
};

export default UpdateForexButton;

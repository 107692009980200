import React from "react";
import CreateAccountForm from "../../components/Authentication/Registration/CreateAccountForm";
import FlowboosterPresentationCard from "../../components/Flowbooster/FlowboosterPresentationCard";
import FlexContainer from "../../components/Layouts/PageStructure/FlexContainer";

import Card from "../../components/ui/Card/Card";
import "./_createAccountPage.scss";

const CreateAccountPage: React.FC<{}> = (props) => {
	return (
		<FlexContainer>
			<div className="registration-panel">
				<div className="registration-panel--left">
					<Card theme={"white"} isDashboardElement={false}>
						<CreateAccountForm />
					</Card>
				</div>
				<FlowboosterPresentationCard className="registration-panel--right" />
			</div>
		</FlexContainer>
	);
};

export default CreateAccountPage;

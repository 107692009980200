import { PayloadAction } from "@reduxjs/toolkit";
import React, { useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import useHttp from "../../hooks/UseHttp";
import UserProfileApiModel from "../../models/Api/Users/UserProfileApiModel";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { UserProfileDispatchAction } from "../../models/Enums/Profile/UserProfileEnum";
import { loadUserProfile } from "../../store/Actions/UsersActions";
import { usersSliceActions } from "../../store/UsersSlice";
import CardInformation from "../CardInformation/CardInformation";
import Card from "../ui/Card/Card";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/StateHooks";
let initialData: UserProfileApiModel = {
  id: "",
  firstName: "",
  lastName: "",
  username: "",
  dateOfBirth: "",
  emailAddress: "",
  phoneNumber: "",
  address: "",
  bankAccountNumber: "",
  bankAccountHolderName: "",
  userId: "",
};

const userDataReducer = (
  state: UserProfileApiModel,
  action: PayloadAction<UserProfileApiModel | string>
) => {
  switch (action.type) {
    case UserProfileDispatchAction.Load: {
      let newState = { ...(action.payload as UserProfileApiModel) };
      return newState;
    }

    case UserProfileDispatchAction.Username: {
      let newState = { ...state };

      newState.username = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.FirstName: {
      let newState = { ...state };
      newState.firstName = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.LastName: {
      let newState = { ...state };

      newState.lastName = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.Email: {
      let newState = { ...state };

      newState.emailAddress = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.DateOfBirth: {
      let newState = { ...state };

      newState.dateOfBirth = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.PhoneNumber: {
      let newState = { ...state };

      newState.phoneNumber = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.Address: {
      let newState = { ...state };

      newState.address = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.BankAccountNumber: {
      let newState = { ...state };

      newState.bankAccountNumber = action.payload as string;
      return newState;
    }

    case UserProfileDispatchAction.BankAccountHolder: {
      let newState = { ...state };

      newState.bankAccountHolderName = action.payload as string;
      return newState;
    }

    default:
      return initialData;
  }
};

const EditProfileCard: React.FC<{ userId: string }> = (props) => {
  let { isLoading, executeAdd: saveData } = useHttp();
  let [userData, userDataDispatch] = useReducer(userDataReducer, initialData);
  let userProfileData = useAppSelector((state) => state.users.currentProfile);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(loadUserProfile(props.userId));
    if (
      userProfileData !== undefined &&
      userProfileData !== null &&
      userProfileData.profile !== undefined &&
      userProfileData.profile !== null
    ) {
      userDataDispatch({
        type: UserProfileDispatchAction.Load,
        payload: userProfileData.profile,
      });
    }
  }, []);

  const saveEventHandler: React.MouseEventHandler<HTMLAnchorElement> = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (props.userId !== undefined) {
      userData.userId = props.userId;
      saveData(EndpointUrls.Profile, userData).then(() => {
        if (!isLoading) {
          dispatch(loadUserProfile(props.userId));
          navigate(`/profile/${props.userId}/info`, { replace: true });
        }
      });
    }
  };
  return (
    <Card
      theme={"white"}
      isDashboardElement={true}
      className="overview-item__column"
    >
      <CardInformation
        variant="fullGrid"
        title="Personal Information Summary"
        header="Information"
        description=""
        headerCallToActionElement={
          <React.Fragment>
            <div className="grid-2-col">
              <Link to={`/profile/${props.userId}/info`}>Cancel</Link>
              <a href="#" onClick={saveEventHandler}>
                Save changes
              </a>
            </div>
          </React.Fragment>
        }
      ></CardInformation>

      <div>
        <div className="display-group">
          <div className="grid-2-col">
            <h6>Personal information</h6>
          </div>

          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="username" className="alternate-label">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="alternate-input"
              value={userData.username}
              onChange={(event) => {
                var newData = { ...userData };
                newData.username = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.Username,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="firstname" className="alternate-label">
              First name:
            </label>
            <input
              type="text"
              id="firstName"
              className="alternate-input"
              value={userData.firstName}
              onChange={(event) => {
                userDataDispatch({
                  type: UserProfileDispatchAction.FirstName,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="grid-2-col">
            <label htmlFor="lastname" className="alternate-label">
              Last name:
            </label>
            <input
              type="text"
              id="lastname"
              className="alternate-input"
              value={userData.lastName}
              onChange={(event) => {
                var newData = { ...userData };
                newData.lastName = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.LastName,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="grid-2-col">
            <label htmlFor="dob" className="alternate-label">
              Date of birth:
            </label>
            <input
              type="date"
              id="dob"
              className="alternate-input"
              value={userData.dateOfBirth}
              onChange={(event) => {
                var newData = { ...userData };
                newData.dateOfBirth = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.DateOfBirth,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
        </div>
        <div className="display-group">
          <div className="grid-2-col">
            <h6>Contact Information</h6>
          </div>

          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="address" className="alternate-label">
              Address:
            </label>
            <input
              type="text"
              id="address"
              className="alternate-input"
              value={userData.address}
              onChange={(event) => {
                var newData = { ...userData };
                newData.address = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.Address,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>

          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="phoneNumber" className="alternate-label">
              Phone number:
            </label>
            <input
              type="text"
              id="phoneNumber"
              className="alternate-input"
              value={userData.phoneNumber}
              onChange={(event) => {
                var newData = { ...userData };
                newData.phoneNumber = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.PhoneNumber,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="email" className="alternate-label">
              Email:
            </label>
            <input
              type="text"
              id="email"
              className="alternate-input"
              value={userData.emailAddress}
              onChange={(event) => {
                var newData = { ...userData };
                newData.emailAddress = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.Email,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
        </div>

        <div className="display-group">
          <div className="grid-2-col">
            <h6>Bank information</h6>
          </div>

          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="bankAccountNumber" className="alternate-label">
              Phone Number:
            </label>
            <input
              type="text"
              id="bankAccountNumber"
              className="alternate-input"
              value={userData.bankAccountNumber}
              onChange={(event) => {
                var newData = { ...userData };
                newData.bankAccountNumber = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.BankAccountNumber,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>

          <div className="grid-2-col grid-2-col--items-centered">
            <label htmlFor="bankAccountHolderName" className="alternate-label">
              Account holder:
            </label>
            <input
              type="text"
              id="bankAccountHolderName"
              className="alternate-input"
              value={userData.bankAccountHolderName}
              onChange={(event) => {
                var newData = { ...userData };
                newData.bankAccountHolderName = event.currentTarget.value;
                userDataDispatch({
                  type: UserProfileDispatchAction.BankAccountHolder,
                  payload: event.currentTarget.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EditProfileCard;

import React from "react";
import { useAppSelector } from "../../hooks/StateHooks";
import Modal from "../Modal/Modal";
import ExtraCostOverviewTable from "./ExtraCostOverviewTable/ExtraCostOverviewTable";
import ExtraCostsEntryForm from "./ExtraCostEntryForm/ExtraCostsEntryForm";

const ExtraCostView: React.FC<{}> = (props) => {
  let showModal = useAppSelector((state) => state.ui.showModal);
  return (
    <React.Fragment>
      {showModal && (
        <Modal title="Extra Costs">
          <ExtraCostsEntryForm />
        </Modal>
      )}

      <ExtraCostOverviewTable />
    </React.Fragment>
  );
};

export default ExtraCostView;

import { useState } from "react";
import { queryStringBuilder } from "../../Helpers/HttpHelpers";
import { HttpError } from "../../models/Errors/HttpError";
import { KeyValuePair } from "../../models/General/GeneralModel";
import { uiSliceActions } from "../UiSlice";

export const executeGet = (
	url: string,
	apiResponseCallBack: (data: any) => any,
	urlParameters?: KeyValuePair[],
	shouldIncludeBase: boolean = true
) => {
	return async (dispatch: any) => {
		try {
			let baseUrl = "";
			if (shouldIncludeBase) {
				baseUrl = process.env.REACT_APP_API_SERVER ?? "https://localhost:7056";
			}
			let fetchUrl: string = baseUrl.concat(url);

			if (urlParameters != undefined) {
				let queryString = queryStringBuilder(urlParameters);

				fetchUrl = baseUrl.concat(url, "?", queryString);
			}

			let response = await fetch(fetchUrl);

			if (!response.ok) {
				throw new HttpError(response.statusText);
			}

			let jsonResponse = await response.json();
			console.log(jsonResponse);
			apiResponseCallBack(jsonResponse);
		} catch (error: any) {
			dispatch(
				uiSliceActions.setErrorState({
					errorMessage: error.message,
					isError: true,
				})
			);
		}
	};
};

export const executeAdd = async <T>(
	url: string,
	body: T,
	urlParameters?: KeyValuePair[],
	callBack?: (data: any) => void,
	shouldIncludeBase: boolean = true
) => {
	//return async (dispatch: any) => {
	try {
		let baseUrl = "";
		if (shouldIncludeBase) {
			baseUrl = process.env.REACT_APP_API_SERVER ?? "https://localhost:7056";
		}

		let postUrl = baseUrl.concat(url);
		if (urlParameters != undefined) {
			let queryString = queryStringBuilder(urlParameters);

			postUrl = baseUrl.concat(url, "?", queryString);
		}

		let response = await fetch(postUrl, {
			body: JSON.stringify(body),
			method: "PUT",
			headers: {
				"Content-Type": "Application/json",
			},
		});

		if (!response.ok) {
			throw new HttpError(response.statusText);
		}
		if (callBack !== undefined) {
			let jsonResponse = await response.json();
			callBack(jsonResponse);
		}
	} catch (error: any) {
		throw new HttpError(error.message);
	}
	//	};
};

export const executePost = async <T>(
	url: string,
	body: T,
	callBack?: (data: any) => void,
	urlParameters?: KeyValuePair[],
	shouldIncludeBase: boolean = true
) => {
	// return async (dispatch: any) => {
	try {
		let baseUrl = "";
		if (shouldIncludeBase) {
			baseUrl = process.env.REACT_APP_API_SERVER ?? "https://localhost:7056";
		}
		let postUrl = baseUrl.concat(url);
		if (urlParameters != undefined) {
			let queryString = queryStringBuilder(urlParameters);

			postUrl = baseUrl.concat(url, "?", queryString);
		}

		let response = await fetch(postUrl, {
			body: JSON.stringify(body),
			method: "POST",
			headers: {
				"Content-Type": "Application/json",
			},
		});

		if (!response.ok) {
			throw new HttpError(response.statusText);
		}

		var apiData = await response.json();
		if (callBack != undefined) {
			callBack(apiData);
		}
	} catch (error: any) {
		throw new HttpError(
			"Something went wrong while communicating with the server.  Please contact the system administrator."
		);
	}
	// };
};

// const executeDelete = async <T>(
//   url: string,
//   body: T,

//   urlParameters?: KeyValuePair[]
// ) => {
//   try {
//     setIsLoading(true);
//     let postUrl = url;
//     if (urlParameters != undefined) {
//       let queryString = queryStringBuilder(urlParameters);

//       postUrl = url.concat("?", queryString);
//     }

//     let response = await fetch(postUrl, {
//       body: JSON.stringify(body),
//       method: "DELETE",
//       headers: {
//         "Content-Type": "Application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new HttpError("Something went wrong in the server!");
//     }

//     let data = await response.json();
//     setData(data);

//     setIsLoading(false);
//     return data;
//   } catch (error: any) {
//     setError(error.message);
//   }
// };

import UserSalary from "../models/Api/Salary/UserSalary";

export const mapApiDataToUserSalaryModel: (
  data: any
) => UserSalary | undefined = (data) => {
  if (data == undefined) {
    return undefined;
  }

  let userSalary: UserSalary = {
    userId: data.userId,
    weekNumber: data.weekNumber,
    salary: data.salary,
    isPaid: data.isPaid,
    bankDetails: data.bankDetails,
    fullName: data.fullName,
  };

  return userSalary;
};

export const mapApiDataToUserSalaryModelList: (
  data: any
) => UserSalary[] | undefined = (data) => {
  if (data == undefined) {
    return undefined;
  }

  let resultList: UserSalary[] = [];
  for (let key in data) {
    let userSalary = mapApiDataToUserSalaryModel(data[key]);
    if (userSalary != undefined) {
      resultList = resultList.concat(userSalary);
    }
  }
  return resultList;
};

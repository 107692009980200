import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../Helpers/DateHelpers";
import { mapApiResponseToDailyOverviewStatsList } from "../../../Helpers/StatisticsHelpers";
import useHttp from "../../../hooks/UseHttp";
import DailyOverviewStats from "../../../models/Api/Users/DailyOverviewStats";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { DynamicKeyValuePair } from "../../../models/General/GeneralModel";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import AssignOperatorToForm from "../AssignOperatorTo";
import UsersDailyPerformanceCard from "./UsersDailyPerformanceCard";
import "./_userDailyPerformanceTable.scss";
const UsersDailyPerformanceTable: React.FC<{}> = (props) => {
  const { isLoading, executeGet: fetchStats } = useHttp();

  const [stats, setStats] =
    useState<DynamicKeyValuePair<string, DailyOverviewStats[]>[]>();
  const navigate = useNavigate();

  const handleApiResponse = (apiData: any) => {
    const mappedData = mapApiResponseToDailyOverviewStatsList(apiData);

    if (mappedData !== undefined) {
      setStats(mappedData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchStats(EndpointUrls.WeeklyUserStats, handleApiResponse, [
      { key: "date", value: formatDate(new Date()) },
    ]);
  };

  return (
    <Card
      theme={"cream"}
      isDashboardElement={false}
      className="daily-overview-container not-selectable"
    >
      {isLoading && <LoadingAnimation />}
      {!isLoading && stats !== undefined && (
        <React.Fragment>
          {stats.map((info) => (
            <UsersDailyPerformanceCard
              key={Math.random()}
              infoKey={info.key}
              navigate={(e) => {
                e.preventDefault();
                navigate(`/profile/${info.value[0].UserId}/overview`);
              }}
              operatorId={info.value[0].UserId}
              fetchData={fetchData}
            >
              {info.value.map((val) => (
                <Card
                  key={Math.random()}
                  theme="grey"
                  isDashboardElement={false}
                >
                  <div key={Math.random()}>
                    <div className="daily-overview-container__information">
                      <span className="text--bold">Date:</span>
                      <span>{val.Date}</span>
                    </div>
                    {val.Statistics.map((stat) => (
                      <React.Fragment key={Math.random()}>
                        <div className="daily-overview-container__information">
                          <span className="text--bold">Account:</span>
                          <span> {stat.key}</span>
                        </div>
                        <div className="daily-overview-container__information">
                          <span className="text--bold">Messages sent:</span>
                          <span> {stat.value}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Card>
              ))}
            </UsersDailyPerformanceCard>
          ))}
        </React.Fragment>
      )}
    </Card>
  );
};

export default UsersDailyPerformanceTable;

import React, { useMemo, useReducer, useState } from "react";
import Button from "../../ui/Button/Button";
import { useEffect } from "react";
import { DailyStatsApiModel } from "../../../models/Api/Stats/DailyStatsApiModel";
import { PayloadAction } from "@reduxjs/toolkit";
import { DateListApiModel } from "../../../models/Api/Dates/DateListApiModel";
import StatsEnum from "../../../models/Enums/Stats/StatsEnum";
import LoadingAnimation from "../../Loading/Loading";
import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";

import "./_daily-stats-view.scss";
import { DailyStatsProps } from "../../../models/Props/Statistics/Models";
import { mapApiResponseToDailyStatsApiModel } from "../../../Helpers/StatisticsHelpers";
import { formatDate } from "../../../Helpers/DateHelpers";

let initialState: DailyStatsApiModel = {
	weekNumber: 0,
	stats: [],
	year: new Date().getFullYear().toString(),
};

const statsReducer = (
	state: DailyStatsApiModel,
	action: PayloadAction<
		| DailyStatsApiModel
		| { key: string; value: string }
		| ApexNonAxisChartSeries
		| ApexAxisChartSeries
	>
) => {
	switch (action.type) {
		case StatsEnum.Load: {
			let newState = { ...(action.payload as DailyStatsApiModel) };
			return newState;
		}
		default:
			return initialState;
	}
};

const DailyStatsView: React.FC<DailyStatsProps> = (props) => {
	let { isLoading, executeGet: fetchData, executeAdd: saveStats } = useHttp();
	let [statsState, statsDispatcher] = useReducer(statsReducer, initialState);

	useEffect(() => {
		let selectedDate =
			props.selectedDate === undefined
				? formatDate(new Date())
				: formatDate(props.selectedDate);

		fetchData(
			EndpointUrls.DailyStats,
			(e) => {
				let apiData = mapApiResponseToDailyStatsApiModel(e);
				if (apiData != null) {
					statsDispatcher({ type: StatsEnum.Load, payload: apiData });
					props.exposeWeekData(apiData.weekNumber, apiData.year);
				}
			},
			[
				{ key: "date", value: selectedDate },
				{ key: "userId", value: props.userId },
			]
		);
	}, [
		useMemo(() => props.selectedDate, [props.selectedDate]),
		statsState.weekNumber,
	]);

	return (
		<React.Fragment>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<div className="daily-stats-view">
					<div>
						<span className="daily-stats-view__label">Date</span>
						<div className="daily-stats-view__group">
							<span className="daily-stats-view__label">Day stats</span>
						</div>

						<div className="daily-stats-view__group">
							<span className="daily-stats-view__label">Night stats</span>
						</div>
						<div className="daily-stats-view__group">
							<span className="daily-stats-view__label">Total stats</span>
						</div>
					</div>
					{statsState !== undefined &&
						statsState.stats !== undefined &&
						statsState.stats.length > 0 &&
						statsState.stats.map((date) => {
							return (
								<React.Fragment key={Math.random()}>
									{date.day != "" && (
										<div>
											<span className="daily-stats-view__label">
												{date.date}
											</span>
											<div className="daily-stats-view__group">
												<span className="daily-stats-view__value">
													{date.dayStats}
												</span>
											</div>

											<div className="daily-stats-view__group">
												<span className="daily-stats-view__value">
													{date.nightStats}
												</span>
											</div>
											<div className="daily-stats-view__group">
												<span className="daily-stats-view__value">
													{+date.dayStats + +date.nightStats}
												</span>
											</div>
										</div>
									)}
								</React.Fragment>
							);
						})}
				</div>
			)}
		</React.Fragment>
	);
};

export default DailyStatsView;

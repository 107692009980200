import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import StatsEntryForm from "../components/Statistics/StatsEntryForm/StatsEntryForm";
import { Error } from "../models/Errors/Error";
import { UiState } from "../models/State/States";

let initialState: UiState = {
	isScreenLoading: false,
	showModal: false,
	error: { errorMessage: "", isError: false },
	showDashboardMenu: false,
};

const setLoadingState: (
	state: UiState,
	action: PayloadAction<boolean>
) => void = (state, action) => {
	state.isScreenLoading = action.payload;
	return state;
};

const showModal = (state: UiState, action: PayloadAction<boolean>) => {
	state.showModal = action.payload;
	return state;
};

const setErrorState: (state: UiState, action: PayloadAction<Error>) => void = (
	state,
	action
) => {
	state.error = action.payload;
	return state;
};

const resetErrorState: (state: UiState) => void = (state) => {
	state.error = { errorMessage: "", isError: false };
	return state;
};

const displayDashboardMenu: (
	state: UiState,
	action: PayloadAction<boolean>
) => void = (state, action) => {
	state.showDashboardMenu = action.payload;
	return state;
};

const uiSlice = createSlice({
	initialState: initialState,
	name: "ui",
	reducers: {
		setLoadingState,
		showModal,
		setErrorState,
		resetErrorState,
		displayDashboardMenu,
	},
});

export const uiSliceActions = uiSlice.actions;

export default uiSlice;

import React from "react";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";
import { BasicSelectInput } from "../Forms/FormInputs/BasicInputs";

const AccountTypeSelect: React.FC<{
	platformType?: PlatformType;
	onPlateformTypeChanged: (type?: PlatformType) => void;
}> = (props) => {
	const onPlatformTypeChangeHandler = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		event.preventDefault();
		if (event.currentTarget.value !== "default") {
			let platformType = Number.parseInt(
				event.currentTarget.value
			) as PlatformType;

			props.onPlateformTypeChanged(platformType);
		}
		props.onPlateformTypeChanged(undefined);
	};

	return (
		<BasicSelectInput
			label="Account Type:"
			value={props.platformType}
			onChange={onPlatformTypeChangeHandler}
		>
			<option value="default">Select an account type</option>
			<option value={0}>CDC</option>
			<option value={1}>Panel</option>
			<option value={2}>CDM</option>
		</BasicSelectInput>
	);
};

export default AccountTypeSelect;

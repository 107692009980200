import React from "react";
import "./_footer.scss";

const Footer: React.FC<{ className?: string }> = (props) => {
  return (
    <footer
      className={`footer ${
        props.className != undefined ? props.className : ""
      }`}
    >
      <div className="footer__links">
        <span>Made with ❤️ by LSC Outsourcing.</span>
      </div>
      {/* <div className="footer__icon-container">
        <span className="material-icons-outlined footer__icon green-item">
          info
        </span>
        <span className="material-icons-outlined footer__icon">
          perm_contact_calendar
        </span>
        <span className="material-icons-outlined footer__icon yellow-item">
          offline_bolt
        </span>
      </div> */}
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import { MessagesHistoryItemProps } from "../../../models/Props/Messages/MessagesHistoryProps";
import { uiSliceActions } from "../../../store/UiSlice";
import Modal from "../../Modal/Modal";
import "./_messageHistoryItem.scss";

const MessagesHistoryItem: React.FC<MessagesHistoryItemProps> = (props) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(uiSliceActions.showModal(false));
	}, []);

	const openImageHandler: (event: React.MouseEvent) => void = (event) => {
		event.preventDefault();

		dispatch(uiSliceActions.showModal(true));
		if (props.onDisplayImage && props.attachments !== undefined) {
			props.onDisplayImage(props.attachments);
		}
	};

	return (
		<div>
			<div
				className={`message-container ${
					props.isInbound
						? "message-container--inbound"
						: "message-container--outbound"
				}`}
			>
				<div className="message-information">
					<div className="message-information__author-name">
						{props.isInbound ? "Target" : "You"}
					</div>
					<div className="message-information__received-date">
						{new Date(props.date.toString()).toLocaleDateString()} -{" "}
						{new Date(props.date.toString()).toLocaleTimeString()}
					</div>
					<div className={`message-information__type`}>
						<span
							className={`icon--filled ${
								props.isInbound
									? "message-information__type--inbound"
									: "message-information__type--outbound"
							}`}
						>
							{props.isInbound ? "call_received" : "call_made"}
						</span>
					</div>
					{/* could be outbound too*/}
				</div>
				<div className="message-content">
					<div className="message-content__text">
						<p dangerouslySetInnerHTML={{ __html: props.content }}></p>
					</div>
				</div>
				{props.attachments !== undefined &&
					props.attachments !== null &&
					props.attachments !== "" && (
						<div className="message-attachment" onClick={openImageHandler}>
							<span className="message-attachment__icon icon--filled">
								image
							</span>
						</div>
					)}
			</div>
		</div>
	);
};

export default MessagesHistoryItem;

import React from "react";
import TarificationView from "../../components/Tarification/TarificationView/TarificationView";

const TarificationOverviewPage: React.FC<{}> = (props) => {
  return (
    <React.Fragment>
      <TarificationView />
    </React.Fragment>
  );
};

export default TarificationOverviewPage;

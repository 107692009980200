import React from "react";
import { MediaItem } from "../../models/Flowbooster/Models";
import "./_imageGallery.scss";

const ImageGallery: React.FC<{
	imageList: MediaItem[];
	onImageSelected?: (imageId: string) => void;
}> = (props) => {
	const originalClassName = "image-gallery__selectable-image";
	let count = 0;
	console.log(props.imageList, "image list");
	return (
		<div className="image-gallery">
			{props.imageList.map((image) => (
				<img
					key={count++}
					className={originalClassName}
					src={image.largeImage}
					onClick={(e) => {
						if (props.onImageSelected !== undefined) {
							props.onImageSelected(image.id);
							e.currentTarget.className = `${originalClassName} ${originalClassName}--selected`;
						}
					}}
				/>
			))}
		</div>
	);
};

export default ImageGallery;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useHttp from "../../hooks/UseHttp";
import { ServerInformation } from "../../models/Api/Servers/Model";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { executePost } from "../../store/Actions/AsyncActions";
import { loadServerList } from "../../store/Actions/SliceActions";
import { serversSliceAction } from "../../store/ServersSlice";
import { uiSliceActions } from "../../store/UiSlice";
import FormContainer from "../Forms/FormContainer";
import { FormRow } from "../Forms/FormElements/FormStructure";
import { BasicInput } from "../Forms/FormInputs/BasicInputs";
import Button from "../ui/Button/Button";

const AddServerForm: React.FC<{ serverId?: string }> = (props) => {
	const dispatch = useDispatch();
	const [hostName, setHostName] = useState<string>("");
	const [ipAddress, setIpAddress] = useState<string>("");
	const [machineName, setMachineName] = useState<string>("");
	const [id, setId] = useState<string | undefined>(undefined);
	const [isHostNameValid, setIsHostNameValid] = useState<boolean>(false);
	const [isIpAddressValid, setIsIpAddressValid] = useState<boolean>(false);
	const [isMachineNameValid, setIsMachineNameValid] = useState<boolean>(false);

	const {
		isLoading,
		executePost: addServerInformation,
		executeGet: loadServerInformation,
	} = useHttp();

	const addServerSubmitHandler = (event: React.FormEvent) => {
		event.preventDefault();
		console.log("click");
		if (isHostNameValid && isIpAddressValid && isMachineNameValid) {
			console.log("submit", "AddServerForm");
			const serverInformation: ServerInformation = {
				hostName: hostName,
				ipAddress: ipAddress,
				machineName: machineName,
				id: id === undefined ? "" : id,
				isAssigned: false,
			};
			addServerInformation(
				EndpointUrls.AddServer,
				serverInformation,
				(result: any) => {
					if (result.isSuccessful) {
						dispatch(loadServerList());
						dispatch(uiSliceActions.showModal(false));
					}
				}
			);
		}
	};

	useEffect(() => {
		const loadInformation = async () => {
			if (props.serverId !== undefined && props.serverId.length > 0) {
				await loadServerInformation(
					EndpointUrls.ServerDetails,
					(data: ServerInformation) => {
						setHostName(data.hostName);
						setIpAddress(data.ipAddress);
						setMachineName(data.machineName);
						setId(data.id);
					},
					[{ key: "serverId", value: props.serverId }]
				);
			}
		};

		loadInformation();
	}, []);

	return (
		<React.Fragment>
			<FormContainer
				onSubmit={addServerSubmitHandler}
				formActions={
					<React.Fragment>
						<Button
							theme={"info"}
							onClick={(e) => {
								dispatch(uiSliceActions.showModal(false));
							}}
						>
							Cancel
						</Button>
						<Button theme={"primary"}>Save</Button>
					</React.Fragment>
				}
			>
				<FormRow type={"row"}>
					<BasicInput
						placeholder="Host Name"
						type={"text"}
						value={hostName}
						onChange={(e) => {
							setHostName(e.currentTarget.value);
						}}
						validationRegex="^[a-zA-Z1-9 -_.//]{1,50}$"
						errorMessage="Invalid input"
						onValidateInput={(result: boolean) => {
							setIsHostNameValid(result);
						}}
					></BasicInput>
				</FormRow>
				<FormRow type={"row"}>
					<BasicInput
						placeholder="Ip Address"
						type={"text"}
						value={ipAddress}
						onChange={(e) => {
							setIpAddress(e.currentTarget.value);
						}}
						validationRegex="^[a-zA-Z1-9 -_.]{1,50}$"
						errorMessage="Invalid input"
						onValidateInput={(result: boolean) => {
							setIsIpAddressValid(result);
						}}
					></BasicInput>
				</FormRow>
				<FormRow type={"row"}>
					<BasicInput
						placeholder="Machine Name"
						type={"text"}
						value={machineName}
						onChange={(e) => {
							setMachineName(e.currentTarget.value);
						}}
						validationRegex="^[a-zA-Z1-9 -_.]{1,50}$"
						errorMessage="Invalid input"
						onValidateInput={(result: boolean) => {
							setIsMachineNameValid(result);
						}}
					></BasicInput>
				</FormRow>
			</FormContainer>
		</React.Fragment>
	);
};

export default AddServerForm;

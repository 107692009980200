enum EndpointUrls {
	Role = "/api/roles",
	Account = "/api/accounts",
	Users = "/api/users",
	Profile = "/api/profile",
	DateRange = "/api/daterange",
	DailyStats = "/api/statistics",
	SaveDailyStats = "/api/statistics/savestats",
	CurrentWeekStats = "/api/weeklystats",
	PreviousWeeksStats = "/api/previousweeksstats",
	CalculateSalary = "/api/calculatedailysalary",
	WeeklySalary = "/api/weeklysalary",
	Register = "/api/registration",
	Login = "/api/authentication/login",
	Logout = "/api/authentication/logout",
	UserRoleManagement = "/api/userroles",
	UserRoleAssignment = "/api/userroleassignment",
	UserAccountManagement = "/api/useraccount",
	UserClientRelation = "/api/UserClient",
	PayRange = "/api/payrange",
	UserAccountAssignment = "/api/useraccountassignment",
	MonthlySalaryStats = "/api/monthlysalarystats",
	AccountTarification = "/api/accounttarification",
	AccountStatisticsOverview = "/api/accountstatisticsoverview",
	DailyAccountStatisticsOverview = "/api/accountstatisticsoverview/daily",
	WeeklyAccountStatisticsOverview = "/api/accountstatisticsoverview/weekly",
	ExchangeRates = "/api/forexsettings",
	ExtraCosts = "/api/extracosts",
	OperatorsPerformance = "/api/operatorsperformance",
	Message = "/api/messages",
	LoadMessageForFeedback = "/api/messages/LoadMessageForReview",
	CountMessagesAwaitingFeedback = "/api/messages/GetAmountOfMessageAwaitingFeedback",
	SaveMessageFeedback = "/api/messages/SaveFeedback",
	DuplicateMessage = "/api/duplicatemessage",
	WeeklyUserStats = "/api/OperatorsPerformance/LoadAllWeeklyUserStats",
	UnassignedAccountList = "/api/UserAccount/UnassignedAccounts",
	AssociatedUser = "/api/UserAccount/AssociatedUser",
	SignalRApi = "/hubs/messages",
	CheckReviewerAvailability = "/api/authentication/CheckReviewerAvailability",
	GetAvailableOperatorsList = "/api/authentication/GetAvailableOperators",
	CheckMessageLimit = "/api/Messages/CheckLimit",
	Clients = "/api/Client",
	ClientRegister = "/api/ClientRegister",
	ClientAccountRelation = "/api/ClientAccountAssignment",
	DeleteClientAccount = "/api/ClientRegister/DeleteClientAccount",
	ClientAccountsList = "/api/ClientAccountAssignment",
	AddServer = "/api/Servers/AddServer",
	ServersList = "/api/Servers/GetServerList",
	DeleteServer = "/api/Servers/RemoveServer",
	ServerDetails = "/api/Servers/GetServerDetails",
	AvailableServersList = "/api/Servers/AvailableServersList",
	LoadClientInformation = "/api/Client/LoadClientInformation",
	DeleteServerFromAccount = "/api/servers/removeServerFromAccount",
	UnassignServer = "/api/accounts/UnassignServer",
	AssignServerToAccount = "/api/servers/AssignServerToAccount",
	ApplicationWindow = "/frame/",
	MessageLogin = "/api/login",
	MessageLogout = "/api/logout",
	MessageInterface = "/api/message",
	NoteService = "/api/notehistory",
}

export default EndpointUrls;

import { PayloadAction } from "@reduxjs/toolkit";
import React, { useReducer, useEffect } from "react";
import FormContainer from "../Forms/FormContainer";
import LoadingAnimation from "../Loading/Loading";
import Button from "../ui/Button/Button";

import RoleApiModel from "../../models/Api/Roles/RoleApiModel";
import { roleSliceActions } from "../../store/RoleSlice";
import { RoleDispatchAction } from "../../models/Enums/Roles/RoleEnums";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { uiSliceActions } from "../../store/UiSlice";
import { useDispatch } from "react-redux";
import {
	FloatingLabelInput,
	FloatingLabelTextArea,
} from "../Forms/FormInputs/FloatingInputs";

const AddRoleFormAction: React.FC<{}> = (props) => {
	return (
		<React.Fragment>
			<Button type="reset" theme="info">
				<span className="material-icons-outlined">clear</span>
				<span>Cancel</span>
			</Button>
			<Button type="submit" theme="primary">
				<span className="material-icons-outlined">done</span>
				<span>Add Role</span>
			</Button>
		</React.Fragment>
	);
};

let roleStateInitialValue: RoleApiModel = {
	id: "",
	description: "",
	name: "",
};

const roleFormReducer: (
	state: RoleApiModel,
	action: PayloadAction<RoleApiModel>
) => RoleApiModel = (
	state: RoleApiModel,
	action: PayloadAction<RoleApiModel>
) => {
	switch (action.type) {
		case RoleDispatchAction.Load: {
			let newState = { ...action.payload };
			return newState;
		}

		case RoleDispatchAction.Name: {
			let newState = { ...state };
			newState.name = action.payload.name;
			return newState;
		}

		case RoleDispatchAction.Description: {
			let newState = { ...state };
			newState.description = action.payload.description;
			return newState;
		}

		default: {
			return roleStateInitialValue;
		}
	}
};
const AddRoleForm: React.FC<{ roleId?: string }> = (props) => {
	let [roleState, roleDispatch] = useReducer(
		roleFormReducer,
		roleStateInitialValue
	);
	let { isLoading, executeGet: fetchRole, executeAdd: saveRole } = useHttp();
	let dispatch = useDispatch();

	useEffect(() => {
		const loadData = async () => {
			if (props.roleId != undefined) {
				await fetchRole(
					EndpointUrls.Role,
					(e) => {
						let roleModel: RoleApiModel = {
							id: e.id,
							description: e.description,
							name: e.name,
						};
						roleDispatch({ type: RoleDispatchAction.Load, payload: roleModel });
					},
					[{ key: "id", value: props.roleId }]
				);
			}
		};

		loadData();
	}, []);

	const onSubmitFormHandler = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		// saveRole(EndpointUrls.Role, roleState).then(() => {
		// 	if (!isLoading) {
		// 		dispatch(roleSliceActions.addRoles(roleState));
		// 		dispatch(uiSliceActions.showModal(false));
		// 	}
		// });

		await saveRole(EndpointUrls.Role, roleState);
		if (!isLoading) {
			dispatch(roleSliceActions.addRoles(roleState));
			dispatch(uiSliceActions.showModal(false));
		}
	};

	return (
		<FormContainer
			onSubmit={onSubmitFormHandler}
			formActions={<AddRoleFormAction />}
			formTitle="add new role"
		>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<React.Fragment>
					<FloatingLabelInput
						id={"roleName"}
						type={"text"}
						placeholder={"Role Name"}
						required={true}
						value={roleState.name}
						onChange={(e) => {
							roleDispatch({
								type: RoleDispatchAction.Name,
								payload: {
									id: "",
									description: "",
									name: e.currentTarget.value,
								},
							});
						}}
					>
						Role Name
					</FloatingLabelInput>
					<FloatingLabelTextArea
						required={true}
						id={"roleDescription"}
						type={"textarea"}
						placeholder={"Role Description"}
						rows={100}
						cols={50}
						value={roleState.description}
						onChange={(e) => {
							roleDispatch({
								type: RoleDispatchAction.Description,
								payload: {
									id: "",
									description: e.currentTarget.value,
									name: "",
								},
							});
						}}
					>
						Role Description
					</FloatingLabelTextArea>
				</React.Fragment>
			)}
		</FormContainer>
	);
};

export default AddRoleForm;

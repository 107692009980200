
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/StateHooks";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { loadAccounts } from "../../store/Actions/AccountActions";
import FormContainer from "../Forms/FormContainer";
import { FormHeader } from "../Forms/FormElements/FormStructure";
import { BasicInput, BasicSelectInput } from "../Forms/FormInputs/BasicInputs";
import Button from "../ui/Button/Button";

const AssignOperatorToForm: React.FC<{
  operator: string;
  operatorId: string; 
  isAssigned ?: (value: React.SetStateAction<boolean>) => void;
  fetchData ?: (() => Promise<void>); 
  className?: string; 
}> = (props) => {
  
  
//on récupère tous les comptes qui sont déjà englobés dans un global state
  let accountsState = useAppSelector((state) => state.accounts.accounts);
  const [accountId, setAccountId] = useState<string>("");
  let { isLoading, executeAdd: assignOperator } = useHttp();

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAccounts());
  }, []);

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      event.currentTarget !== undefined &&
      event.currentTarget.value !== null &&
      event.currentTarget.value !== ""
    ) {
      setAccountId(event.currentTarget.value);
    }
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      accountId !== undefined &&
      accountId !== null &&
      accountId !== "default" &&
      props.operatorId !== undefined
    ) {
      // register the operator to the account
      await assignOperator(EndpointUrls.UserAccountManagement, accountId , [
        { key: "userId", value: props.operatorId },
      ]);
      if(props.fetchData !== undefined) {
          props.fetchData();
      }
      if(props.isAssigned !== undefined) {
          props.isAssigned(false)
      }
    }
  };
  return (
    <FormContainer onSubmit={onSubmitHandler} className= {`assign-operator-form ${props.className ? props.className : ""}`}>
      <FormHeader title={"Assign operator to"} description={""} />
      <BasicInput value={props.operator} disabled />
      {accountsState !== null &&
        accountsState !== undefined &&
        accountsState.length > 0 && (
          <BasicSelectInput onChange={onChangeHandler} value={accountId}>
            <option value="default">Please Select</option>
            {accountsState.map((account) => (
              <option
                key={Math.random()}
                value={account.id}
              >{account.accountNumber}</option>
            ))}
          </BasicSelectInput>
        )}
      <Button theme={"primary"} type="submit">
        Assign
      </Button>
    </FormContainer>
  );
};

export default AssignOperatorToForm;

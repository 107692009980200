import { ServerInformation } from "../../models/Api/Servers/Model";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { serversSliceAction } from "../ServersSlice";
import { executeGet } from "./AsyncActions";

// export const loadServerList = createAsyncThunk(
// 	"servers/loadList",
// 	async (request: string, thunkApi) => {
// 		thunkApi.dispatch(serversSliceAction.setLoading(true));
// 		try {
// 			await executeGet(
// 				EndpointUrls.ServersList,
// 				(data: ServerInformation[]) => {
// 					thunkApi.dispatch(serversSliceAction.setServerList(data));
// 				}
// 			);
// 		} catch (error: any) {
// 			console.log(error);
// 		}
// 		thunkApi.dispatch(serversSliceAction.setLoading(false));
// 	}
// );

export const loadServerList = () => {
	return async (dispatch: any) => {
		dispatch(serversSliceAction.setLoading(true));
		try {
			dispatch(
				executeGet(EndpointUrls.ServersList, (data: ServerInformation[]) => {
					dispatch(serversSliceAction.setServerList(data));
				})
			);
		} catch (error: any) {
			console.log(error);
		}
		dispatch(serversSliceAction.setLoading(false));
	};
};

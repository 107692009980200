import { randomBytes } from "crypto";
import { CurrencyExchangeModelApi } from "../models/Api/Forex/CurrencyExchangeModelApi";

export const mapApiToCurrencyExchangeModelApi: (
  data: any
) => CurrencyExchangeModelApi | undefined = (data: any) => {
  if (data == undefined) {
    return undefined;
  }

  let result: CurrencyExchangeModelApi = {
    base: data.base,
    date: new Date(data.date),
    rates: [],
  };

  for (let currencyName in data.rates) {
    result.rates.push({
      key: currencyName.toString(),
      value: Number.parseFloat(data.rates[currencyName]),
    });
  }

  return result;
};

import React, { useEffect, useState } from "react";
import { ClickableListItemProps } from "../../../models/Props/List/ListProps";
import CardInformation from "../../CardInformation/CardInformation";
import ClickableList from "../../List/ClickableList";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import LoadingAnimation from "../../Loading/Loading";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import { loadTarificationDetailsList } from "../../../store/Actions/TarificationActions";

import "./_tarification-list.scss";

const TarificationList: React.FC<{ onItemClicked: (data?: string) => void }> = (
  props
) => {
  let [clickableList, setClickableList] = useState<ClickableListItemProps[]>(
    []
  );
  let isLoading = useAppSelector((state) => state.tarification.isLoading);

  let dispatch = useDispatch();
  let tarificationDetailsList = useAppSelector(
    (state) => state.tarification.tarificationDetailsList
  );

  useEffect(() => {
    if (tarificationDetailsList.length == 0) {
      dispatch(loadTarificationDetailsList());
    } else {
      setClickableList((prevState) => {
        let newState: ClickableListItemProps[] = [];

        newState = tarificationDetailsList!.map((t) => {
          let itemInfo: ClickableListItemProps = {
            children: (
              <a
                className="tarification__list__item__link"
                onClick={(event) => {
                  event.preventDefault();
                  if (t.id != undefined && t.id != null) {
                    props.onItemClicked(t.id);
                  }
                }}
              >
                {t.name}
              </a>
            ),
          };
          return itemInfo;
        });

        return newState;
      });
    }
  }, [tarificationDetailsList.length]);

  const addNewTarificationDetailsHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    window.location.reload();
  };
  return (
    <Card theme={"white"} isDashboardElement className="tarification-list-card">
      <CardInformation variant={"full"} header="Tarification"></CardInformation>
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <div className="tarification">
          <ClickableList
            listElements={clickableList}
            className="tarification__list"
            listItemClassName="tarification__list__item"
          ></ClickableList>
        </div>
      )}

      <Button theme={"primary"} onClick={addNewTarificationDetailsHandler}>
        Add a new tarification
      </Button>
    </Card>
  );
};

export default TarificationList;

import React, { useEffect, useState } from "react";
import {
  InputPropsBase,
  TextAreaPropsBase,
} from "../../../models/Props/Inputs/InputProps";

export const FloatingLabelInput: React.FC<InputPropsBase> = (props) => {
  let [iconVisibleClassName, setIconVisibleClassName] = useState<string>("");
  let [hasError, setHasError] = useState<boolean | undefined>(props.hasError);

  useEffect(() => {
    setHasError(props.hasError);
  }, [props.hasError]);

  const onFocusHandler: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIconVisibleClassName("form__floating-label__input-icon--show");
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIconVisibleClassName("");
    if (props.onBlur !== undefined) {
      props.onBlur(event);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange !== undefined) {
      props.onChange(event);
    }
  };

  return (
    <div className="form__floating-label">
      <input
        className={`form__floating-label__input ${
          hasError !== undefined && hasError
            ? "form__floating-label__input--has-error"
            : ""
        }`}
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        value={props.value}
        required={props.required}
        disabled={props.disabled}
      />
      <label htmlFor={props.id} className="form__floating-label__label">
        {props.children}
      </label>
      <span
        className={`form__floating-label__input-icon icon--outlined ${iconVisibleClassName}`}
      >
        {" "}
        chevron_left{" "}
      </span>
      {props.errorMessage !== undefined && (
        <div
          className={`form__error-message ${
            hasError != undefined && hasError
              ? "form__error-message--has-error"
              : ""
          }`}
        >
          <p>{props.errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export const FloatingLabelTextArea: React.FC<TextAreaPropsBase> = (props) => {
  return (
    <div className="form__floating-label">
      <textarea
        id={props.id}
        required={props.required}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        cols={props.cols}
        rows={props.rows}
        value={props.value}
      ></textarea>
      <label htmlFor={props.id} className="form__floating-label__label">
        {props.children}
      </label>
    </div>
  );
};

import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isFunctionLike } from "typescript";
import { useAppDispatch, useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import {
	loadAssociatedAccounts,
	loadAssociatedUsers,
} from "../../../store/Actions/ClientsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import FormContainer from "../../Forms/FormContainer";
import { FormRow } from "../../Forms/FormElements/FormStructure";
import { BasicSelectInput } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";

const CustomerAccountAssignmentFormActions: React.FC<{
	isFormValid: boolean;
	isLoading: boolean;
}> = (props) => {
	return (
		<React.Fragment>
			<Button type="reset" theme="info" fullWidth={true}>
				<span>Cancel</span>
			</Button>
			<Button
				type="submit"
				theme="warning"
				fullWidth={true}
				className={`registration-form__submit-button ${
					props.isFormValid ? "" : "button--disabled"
				}`}
				disabled={!props.isFormValid}
			>
				{props.isLoading && <LoadingAnimation size="small" />}
				{!props.isLoading && <span>Assign</span>}
			</Button>
		</React.Fragment>
	);
};
const CustomerAccountAssignmentForm: React.FC<{
	clientId: string;
	selectedUserId: string;
}> = (props) => {
	const { isLoading, executeAdd: saveInformation } = useHttp();
	const userAccounts = useAppSelector(
		(state) => state.clients.associatedAccountsList
	);
	const [selectedAccountId, setSelectedAccountId] = useState<string>();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAssociatedAccounts(props.clientId));
	}, []);

	const formIsValid =
		selectedAccountId !== "default" && selectedAccountId !== undefined;

	const onSaveAccountAssignmentHandler = async (e: FormEvent) => {
		e.preventDefault();

		if (formIsValid) {
			await saveInformation(
				EndpointUrls.UserAccountAssignment,
				selectedAccountId,
				[{ key: "userId", value: props.selectedUserId }],
				(response: BaseApiResponse) => {
					if (response.isSuccessful) {
						dispatch(loadAssociatedUsers(props.clientId));
						dispatch(uiSliceActions.showModal(false));
					}
				}
			);
		}
	};

	const onResetHandler = (e: FormEvent) => {
		e.preventDefault();
		dispatch(uiSliceActions.showModal(false));
	};
	return (
		<FormContainer
			onSubmit={onSaveAccountAssignmentHandler}
			onReset={onResetHandler}
			formActions={
				<CustomerAccountAssignmentFormActions
					isFormValid={formIsValid}
					isLoading={isLoading}
				/>
			}
		>
			<FormRow type={"row"}>
				<BasicSelectInput
					label="Account Name"
					placeholder="Select the account you want to assign"
					onChange={(e) => {
						e.preventDefault();
						setSelectedAccountId(e.currentTarget.value);
					}}
				>
					<option value="default">Select the account you want to assign</option>
					{userAccounts !== undefined &&
						userAccounts.length > 0 &&
						userAccounts.map((account) => (
							<option value={account.id}>{account.accountNumber}</option>
						))}
				</BasicSelectInput>
			</FormRow>
		</FormContainer>
	);
};

export default CustomerAccountAssignmentForm;

import { PayloadAction } from "@reduxjs/toolkit";

import React, {
	useMemo,
	useReducer,
	useEffect,
	useState,
	FormEvent,
} from "react";
import FormContainer from "../Forms/FormContainer";
import LoadingAnimation from "../Loading/Loading";
import Button from "../ui/Button/Button";
import useHttp from "../../hooks/UseHttp";
import AccountApiModel from "../../models/Api/Accounts/AccountsApiModel";
import { AccountAction } from "../../models/Enums/Accounts/AccountEnums";
import { uiSliceActions } from "../../store/UiSlice";
import { useDispatch } from "react-redux";
import { accountsSliceActions } from "../../store/AccountSlice";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { PlatformType } from "../../models/Enums/Platform/PlaformEnum";
import {
	FormElementGroup,
	FormHeader,
	FormInputContainer,
	FormRow,
} from "../Forms/FormElements/FormStructure";
import { BasicSelectInput } from "../Forms/FormInputs/BasicInputs";
import { FloatingLabelInput } from "../Forms/FormInputs/FloatingInputs";
import { loadClient } from "../../store/Actions/ClientsActions";
import { useAppSelector } from "../../hooks/StateHooks";
import { KeyValuePair } from "../../models/General/GeneralModel";
import { useParams } from "react-router";
import FormsStories from "../../stories/components/Forms.stories";
import {
	ServerAssignmentRequest,
	ServerInformation,
} from "../../models/Api/Servers/Model";

let initialReducerState: AccountApiModel = {
	id: "",
	accountNumber: "",
	accountPassword: "",
	emailAccount: "",
	emailPassword: "",
	skypeAccount: "",
	skypePassword: "",
	dateAdded: "",
	dateModified: "",
	ipAddress: "",
	clientId: "",
	serverId: "",
};

const AddAccountFormAction: React.FC<{ isFormEditMode: boolean }> = (props) => {
	return (
		<React.Fragment>
			{props.isFormEditMode && (
				<React.Fragment>
					<Button type="reset" theme="info">
						<span className="material-icons-outlined">clear</span>
						<span>Cancel</span>
					</Button>
					<Button type="submit" theme="warning">
						<span className="material-icons-outlined">done</span>
						<span>Add Account</span>
					</Button>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

const mapResponseToApiModel: (data: any) => AccountApiModel = (data) => {
	let model: AccountApiModel = {
		accountNumber: data.accountNumber,
		accountPassword: data.accountPassword,
		emailAccount: data.emailAccount,
		emailPassword: data.emailPassword,
		skypeAccount: data.skypeAccount,
		skypePassword: data.skypePassword,
		dateAdded: data.dateAdded,
		dateModified: data.dateModified,
		accountType: data.accountType,
		id: data.id,
		ipAddress: data.ipAddress ?? "",
		clientId: "",
		serverId: "",
	};

	if (data.clientId !== null && data.clientId === undefined) {
		model.clientId = data.clientId;
	}

	return model;
};

const addAccountFormReducer: (
	state: AccountApiModel,
	action: PayloadAction<AccountApiModel>
) => AccountApiModel = (state, action) => {
	switch (action.type) {
		case AccountAction.Number: {
			let newState = { ...state };
			newState.accountNumber = action.payload.accountNumber;
			return newState;
		}
		case AccountAction.Password: {
			let newState = { ...state };
			newState.accountPassword = action.payload.accountPassword;
			return newState;
		}
		case AccountAction.Email: {
			let newState = { ...state };
			newState.emailAccount = action.payload.emailAccount;
			return newState;
		}
		case AccountAction.EmailPassword: {
			let newState = { ...state };
			newState.emailPassword = action.payload.emailPassword;
			return newState;
		}
		case AccountAction.Skype: {
			let newState = { ...state };
			newState.skypeAccount = action.payload.skypeAccount;
			return newState;
		}
		case AccountAction.SkypePassword: {
			let newState = { ...state };
			newState.skypePassword = action.payload.skypePassword;
			return newState;
		}
		case AccountAction.SetInitialState: {
			let newState = { ...state };
			newState = { ...action.payload };
			return newState;
		}

		case AccountAction.AccountType: {
			let newState = { ...state };
			newState.accountType = action.payload.accountType;
			return newState;
		}
		case AccountAction.IpAddress: {
			let newState = { ...state };
			newState.ipAddress = action.payload.ipAddress;
			return newState;
		}
		case AccountAction.ClientId: {
			let newState = { ...state };
			newState.clientId = action.payload.clientId;
			return newState;
		}
		case AccountAction.ServerId: {
			let newState = { ...state };
			newState.serverId = action.payload.serverId;
			return newState;
		}
		default:
			return state;
	}
};
const AddAccountForm: React.FC<{
	isEditMode: boolean;
	prefillModel?: string;
}> = (props) => {
	const [isEditMode, setIsEditMode] = useState<boolean>(props.isEditMode);
	const [formState, formDispatch] = useReducer(
		addAccountFormReducer,
		initialReducerState
	);
	let { userId } = useParams();

	const {
		isLoading,
		executeAdd: saveAccount,
		executePost: saveAccountRelation,
		executeGet: fetchData,
	} = useHttp();
	const [availableServersList, setAvailableServersList] =
		useState<KeyValuePair[]>();

	const dispatch = useDispatch();

	useEffect(() => {
		const loadInformation = async () => {
			fetchData(
				EndpointUrls.AvailableServersList,
				(apiData: KeyValuePair[]) => {
					setAvailableServersList(apiData);
				}
			);
		};

		if (userId !== undefined && userId !== null) {
			dispatch(loadClient(userId));
		}
		loadInformation();
	}, []);

	useEffect(() => {
		if (props.prefillModel != undefined) {
			fetchData(
				EndpointUrls.Account,
				(e) => {
					var apiModel = mapResponseToApiModel(e);
					formDispatch({
						type: AccountAction.SetInitialState,
						payload: apiModel,
					});
				},
				[{ key: "id", value: props.prefillModel }]
			);
		}
	}, [useMemo(() => props.prefillModel, [])]);

	const onSubmitFormHandler = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		formDispatch({
			type: AccountAction.ClientId,
			payload: {
				...formState,
				clientId: "",
			},
		});
		if (
			formState.serverId !== undefined &&
			formState.serverId !== null &&
			formState.serverId !== ""
		) {
			let serverAssignmentRequest: ServerAssignmentRequest = {
				serverId: formState.serverId,
				accountId: formState.id,
			};
			await saveAccountRelation(
				EndpointUrls.AssignServerToAccount,
				serverAssignmentRequest
			);
		}

		await saveAccount<AccountApiModel>(EndpointUrls.Account, formState);

		if (!isLoading) {
			formDispatch({
				type: AccountAction.SetInitialState,
				payload: initialReducerState,
			});
			dispatch(accountsSliceActions.addAccount(formState));
			dispatch(uiSliceActions.showModal(false));
		}
	};

	const resetFormHandler = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(uiSliceActions.showModal(false));
	};

	return (
		<FormContainer
			onReset={resetFormHandler}
			onSubmit={onSubmitFormHandler}
			formActions={<AddAccountFormAction isFormEditMode={isEditMode} />}
		>
			{isLoading && <LoadingAnimation />}

			<FormHeader title={"Add an account"} description={""} />

			<FormRow type={"row"}>
				<FormInputContainer>
					<BasicSelectInput
						id="accountType"
						label="Account type"
						value={formState.accountType}
						onChange={(event) => {
							let accountType: PlatformType;
							if (event.currentTarget.value !== "default") {
								accountType = Number.parseInt(event.currentTarget.value);

								formDispatch({
									type: AccountAction.AccountType,
									payload: {
										...formState,
										accountType: accountType,
									},
								});
								console.log(formState.accountType, "here account type");
							}
						}}
					>
						<option value="default">Select an account type</option>
						<option value={PlatformType.cdc}>CDC</option>
						<option value={PlatformType.panel}>Panel</option>
						<option value={PlatformType.cdm}>CDM</option>
					</BasicSelectInput>
				</FormInputContainer>
			</FormRow>
			<FormElementGroup sectionTitle="Account information">
				<FormRow type={"grid"}>
					<FloatingLabelInput
						id={"accountNumber"}
						type={"text"}
						placeholder={"Account Number"}
						required={true}
						disabled={!isEditMode}
						value={formState.accountNumber}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.Number,
								payload: {
									...formState,
									accountNumber: event.currentTarget.value,
								},
							});
						}}
					>
						Account Number
					</FloatingLabelInput>

					<FloatingLabelInput
						id={"accountPassword"}
						type={"text"}
						placeholder={"Account Password"}
						required={true}
						disabled={!isEditMode}
						value={formState.accountPassword}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.Password,
								payload: {
									...formState,
									accountPassword: event.currentTarget.value,
								},
							});
						}}
					>
						Account Password
					</FloatingLabelInput>
				</FormRow>
			</FormElementGroup>

			<FormElementGroup sectionTitle="Email information">
				<FormRow type={"grid"}>
					<FloatingLabelInput
						id={"emailAccount"}
						type={"text"}
						placeholder={"Account Email"}
						required={true}
						disabled={!isEditMode}
						value={formState.emailAccount}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.Email,
								payload: {
									...formState,
									emailAccount: event.currentTarget.value,
								},
							});
						}}
					>
						Account Email
					</FloatingLabelInput>
					<FloatingLabelInput
						id={"emailPassword"}
						type={"text"}
						placeholder={"Email Password"}
						required={true}
						disabled={!isEditMode}
						value={formState.emailPassword}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.EmailPassword,
								payload: {
									...formState,
									emailPassword: event.currentTarget.value,
								},
							});
						}}
					>
						Email Password
					</FloatingLabelInput>
				</FormRow>
			</FormElementGroup>
			<FormElementGroup sectionTitle="Skype information">
				<FormRow type={"grid"}>
					<FloatingLabelInput
						id={"skypeAccount"}
						type={"text"}
						placeholder={"Skype Account"}
						required={true}
						disabled={!isEditMode}
						value={formState.skypeAccount}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.Skype,
								payload: {
									...formState,
									skypeAccount: event.currentTarget.value,
								},
							});
						}}
					>
						Skype Account
					</FloatingLabelInput>
					<FloatingLabelInput
						id={"skypeAccount"}
						type={"text"}
						placeholder={"Skype Password"}
						required={true}
						disabled={!isEditMode}
						value={formState.skypePassword}
						onChange={(event) => {
							formDispatch({
								type: AccountAction.SkypePassword,
								payload: {
									...formState,
									skypePassword: event.currentTarget.value,
								},
							});
						}}
					>
						Skype Password
					</FloatingLabelInput>
				</FormRow>
			</FormElementGroup>
			<FormElementGroup sectionTitle="Server Information">
				<FloatingLabelInput
					id="ipAddress"
					type="text"
					placeholder="Account IP address"
					required={false}
					disabled={true}
					value={formState.ipAddress}
					onChange={(event) => {
						formDispatch({
							type: AccountAction.IpAddress,
							payload: {
								...formState,
								ipAddress: event.currentTarget.value,
							},
						});
					}}
				>
					Ip Address
				</FloatingLabelInput>
				{availableServersList !== undefined && (
					<BasicSelectInput
						label="Server"
						id={"server-id"}
						required={true}
						disabled={!isEditMode}
						value={formState.serverId}
						onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
							if (event.currentTarget.value !== "") {
								formDispatch({
									type: AccountAction.ServerId,
									payload: {
										...formState,
										serverId: event.currentTarget.value,
									},
								});

								await fetchData(
									EndpointUrls.ServerDetails,
									(serverDetail: ServerInformation) => {
										formDispatch({
											type: AccountAction.IpAddress,
											payload: {
												...formState,
												ipAddress: serverDetail.hostName,
											},
										});
									},
									[{ key: "serverId", value: event.currentTarget.value }]
								);
							}
						}}
					>
						<option value={""}>Please Select</option>
						{availableServersList.map((serverInfo) => (
							<option key={Math.random()} value={serverInfo.value}>
								{serverInfo.key}
							</option>
						))}
					</BasicSelectInput>
				)}
				{availableServersList === undefined && (
					<BasicSelectInput label="Server" id={"server-id"} disabled={true}>
						<option value={""}>No available servers</option>
					</BasicSelectInput>
				)}
			</FormElementGroup>
		</FormContainer>
	);
};

export default AddAccountForm;

const months = [
	"01",
	"02",
	"03",
	"04",
	"05",
	"06",
	"07",
	"08",
	"09",
	"10",
	"11",
	"12",
];

export const formatDate: (date: Date) => string = (date) => {
	let day: string = "";
	day =
		date.getDate().toString().length === 1
			? `0${date.getDate()}`
			: `${date.getDate()}`;

	return `${day}/${months[date.getMonth()]}/${date.getFullYear()}`;
};

import React from "react";
import LoadingAnimationProps from "../../models/Props/LoadingAnimation/LoadingAnimationProps";

import "./_loading.scss";

const LoadingAnimation: React.FC<LoadingAnimationProps> = (props) => {
  let size: number = 0;

  switch (props.size) {
    case "large":
      size = 65;
      break;
    case "medium":
      size = 30;
      break;
    case "small":
      size = 15;
      break;
    default:
      size = 45;
      break;
  }

  return (
    <div className={`loading-background ${props.className}`}>
      <svg
        className="spinner"
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path-loading"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
};

export default LoadingAnimation;

import { current } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../hooks/StateHooks";
import { loadClient } from "../../../store/Actions/ClientsActions";
import FormContainer from "../../Forms/FormContainer";
import { BasicInput } from "../../Forms/FormInputs/BasicInputs";
import Button from "../../ui/Button/Button";

const CniValidationForm: React.FC<{ userId: string }> = (props) => {
  const [cni, setCni] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>();
  const currentClient = useAppSelector((state) => state.clients.currentClient);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (props.userId !== undefined && props.userId !== null) {
      dispatch(loadClient(props.userId));
    }
  },[]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.currentTarget !== undefined &&
      event.currentTarget.value !== null &&
      event.currentTarget.value !== ""
    ) {
      setCni(event.currentTarget.value);
    }
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(currentClient?.identificationNumber);
    if (cni === currentClient?.identificationNumber) {
      setIsValid(true);
      navigate(`/customer/${props.userId}/registration`);
    } else {
      setIsValid(false);
    }
  };

  return (
    <FormContainer onSubmit={submitHandler}>
      <BasicInput
        label="Please confirm your identification number"
        type="text"
        id="identification-number-validation"
        name="identification-number"
        placeholder="Your identification number"
        value={cni}
        onChange={changeHandler}
        required
        errorMessage="Sorry, the number is not matching, please contact the customer service."
        hasError={isValid}
      />
      <Button theme={"secondary"} type="submit">
        Validate
      </Button>
    </FormContainer>
  );
};
export default CniValidationForm;

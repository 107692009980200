import { KeyValuePair } from "../models/General/GeneralModel";

export const queryStringBuilder: (params: KeyValuePair[]) => string = (
  param
) => {
  let queryString = param
    .map((parameter) => `${parameter.key}=${parameter.value}`)
    .join("&");
  return queryString;
};

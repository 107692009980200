import React, { useEffect } from "react";
import CardInformation from "../../components/CardInformation/CardInformation";
import {
	SingleFullWidthColumn,
	TwoColumnsFocusLeft,
	TwoColumnsFocusRight,
} from "../../components/Layouts/PageStructure/GridColumns";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import Button from "../../components/ui/Button/Button";
import Card from "../../components/ui/Card/Card";
import { useParams } from "react-router-dom";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import DailyMessagesSentGraph from "../../components/Graphs/DailyMessagesSentGraph";
import { useAppSelector } from "../../hooks/StateHooks";
import { useDispatch } from "react-redux";
import AccountAssignmentHistoryCard from "../../components/Accounts/AccountAssignementHistoryCard";
import { loadUserProfile } from "../../store/Actions/UsersActions";
import ProfileNavigation from "../../components/Profile/ProfileNavigation/ProfileNavigation";
import ProfileDisplayCard from "../../components/Profile/ProfileDisplayCard";
import MessageListCard from "../../components/Messages/MessageListCard/MessageListCard";
import "./_profile-responsive.scss";

const ProfileInfoPage: React.FC<{}> = (props) => {
	let userData = useAppSelector((state) => state.users.currentProfile);
	let { userId } = useParams();
	let dispatch = useDispatch();

	useEffect(() => {
		if (userId !== null && userId !== undefined) {
			dispatch(loadUserProfile(userId));
		}
	}, []);
	return (
		<React.Fragment>
			{userId !== undefined &&
				userId !== null &&
				userId.trim().length !== 0 && <ProfileNavigation userId={userId} />}
			<TwoColumnsFocusLeft className="profile-info-item-left">
				{userId !== undefined &&
					userId !== null &&
					userId.trim().length !== 0 && <ProfileDisplayCard userId={userId} />}

				{userData?.userId != undefined && (
					<AccountAssignmentHistoryCard userId={userData?.userId} />
				)}
			</TwoColumnsFocusLeft>
			<SingleFullWidthColumn>
				{/* <Card
					theme={"white"}
					isDashboardElement={true}
					className="overview-item__column"
				>
					{userId !== undefined && (
						<div className="current-week--graph">
							<DailyMessagesSentGraph
								userId={userId}
								date={new Date().toDateString()}
								endpoint={EndpointUrls.CurrentWeekStats}
								shift={"day"}
								year={new Date().getFullYear().toString()}
							/>
						</div>
					)}
				</Card> */}
				{userId !== undefined && userId !== null && (
					<MessageListCard userId={userId} />
				)}
			</SingleFullWidthColumn>
		</React.Fragment>
	);
};

export default ProfileInfoPage;

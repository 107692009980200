import React, { useReducer, useState } from "react";
import FormContainer from "../../Forms/FormContainer";
import Button from "../../ui/Button/Button";
import { InputPropsBase } from "../../../models/Props/Inputs/InputProps";
import { useNavigate, useParams } from "react-router-dom";

import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import RegistrationModel from "../../../models/Authentication/RegistrationModel";
import { PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../../../models/Authentication/LoginModel";
import LoadingAnimation from "../../Loading/Loading";
import { FloatingLabelInput } from "../../Forms/FormInputs/FloatingInputs";

import "./_registration-form.scss";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import { useDispatch } from "react-redux";
import { uiSliceActions } from "../../../store/UiSlice";
import { loadAssociatedUsers } from "../../../store/Actions/ClientsActions";
import { ClientRelationRequest } from "../../../models/Api/Customers/Models";

let initialReducerState: RegistrationModel = {
	username: "",
	email: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	dateOfBirth: "",
	clientId: 0,
	usernameIsValid: false,
	emailIsValid: false,
	passwordIsValid: false,
	confirmPasswordIsValid: false,
	firstNameIsValid: false,
	lastNameIsValid: false,
	dateOfBirthIsValid: false,
	usernameFieldIsTouched: false,
	emailFieldIsTouched: false,
	passwordFieldIsTouched: false,
	confirmPasswordFieldIsTouched: false,
	firstNameFieldIsTouched: false,
	lastNameFieldIsTouched: false,
	dateOfBirthFieldIsTouched: false,
};

enum RegistrationReducerAction {
	FirstName = "FIRST_NAME",
	LastName = "LAST_NAME",
	Email = "EMAIL",
	Username = "USERNAME",
	DateOfBirth = "DOB",
	Password = "PASSWORD",
	ConfirmPassword = "CONFIRM_PASSWORD",
}

const registrationReducerHandler = (
	state: RegistrationModel,
	action: PayloadAction<string>
) => {
	let alphaNumericRegex = new RegExp("^[a-zA-Z0-9 ]{3,}$");
	let usernameRegex = new RegExp("^[a-zA-Z0-9_-]{5,}$");
	let passwordRegex = new RegExp("^[a-zA-Z0-9!@#$%^&*()\\_\\-\\+\\=\\.]{7,}$");
	let emailRegex = new RegExp(
		"^([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{2,})@([a-zA-Z0-9_\\-\\!@#$%^&*()+=]{2,}).([a-zA-Z0-9_\\-\\.!@#$%^&*()+=]{1,})$"
	);
	switch (action.type) {
		case RegistrationReducerAction.FirstName: {
			let newState = { ...state };
			newState.firstName = action.payload;
			newState.firstNameIsValid = alphaNumericRegex.test(action.payload);
			newState.firstNameFieldIsTouched = true;

			return newState;
		}
		case RegistrationReducerAction.LastName: {
			let newState = { ...state };
			newState.lastName = action.payload;
			newState.lastNameIsValid = alphaNumericRegex.test(action.payload);
			newState.lastNameFieldIsTouched = true;
			return newState;
		}
		case RegistrationReducerAction.Username: {
			let newState = { ...state };
			newState.username = action.payload;
			newState.usernameIsValid = usernameRegex.test(action.payload);
			newState.usernameFieldIsTouched = true;
			return newState;
		}
		case RegistrationReducerAction.Email: {
			let newState = { ...state };
			newState.email = action.payload;
			newState.emailIsValid = emailRegex.test(action.payload);
			newState.emailFieldIsTouched = true;
			return newState;
		}
		case RegistrationReducerAction.Password: {
			let newState = { ...state };
			newState.password = action.payload;
			newState.passwordIsValid = passwordRegex.test(action.payload);
			newState.passwordFieldIsTouched = true;
			return newState;
		}
		case RegistrationReducerAction.ConfirmPassword: {
			let newState = { ...state };
			newState.confirmPassword = action.payload;
			newState.confirmPasswordIsValid = newState.password == action.payload;
			newState.confirmPasswordFieldIsTouched = true;

			return newState;
		}
		case RegistrationReducerAction.DateOfBirth: {
			let newState = { ...state };
			newState.dateOfBirth = action.payload;
			let dateOfBirth = new Date(action.payload);
			if (dateOfBirth == null || dateOfBirth == undefined) {
				newState.dateOfBirthIsValid = false;
			} else {
				let age = new Date().getFullYear() - dateOfBirth.getFullYear();
				newState.dateOfBirthIsValid = age >= 18;
			}
			newState.dateOfBirthFieldIsTouched = true;
			return newState;
		}
		default:
			return state;
	}
};

const CreateAccountFormAction: React.FC<{
	isFormValid: boolean;
	isLoading: boolean;
}> = (props) => {
	return (
		<React.Fragment>
			<Button type="reset" theme="info" fullWidth={true}>
				<span>Cancel</span>
			</Button>
			<Button
				type="submit"
				theme="warning"
				fullWidth={true}
				className={`registration-form__submit-button ${
					props.isFormValid ? "" : "button--disabled"
				}`}
				disabled={!props.isFormValid}
			>
				{props.isLoading && <LoadingAnimation size="small" />}
				{!props.isLoading && <span>Register</span>}
			</Button>
		</React.Fragment>
	);
};
const CreateAccountForm: React.FC<{ clientId?: string }> = () => {
	let { isLoading, executePost: saveInformation } = useHttp();
	const [newUserId, setNewUserId] = useState<string>("");

	const [formErrorMessage, setFormErrorMessage] = useState<string>("");

	const [registrationFormState, registrationFormDispatcher] = useReducer(
		registrationReducerHandler,
		initialReducerState
	);
	const { userId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	let formInputElements: InputPropsBase[] = [
		{
			id: "username",
			placeholder: "Username",
			type: "text",
			label: "Username",
			value: registrationFormState.username,
			hasError:
				!registrationFormState.usernameIsValid &&
				registrationFormState.usernameFieldIsTouched,
			errorMessage:
				"The username field can only contain a combination of letters, number and _ - with a minimum of 5 characters",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Username,
						payload: event.currentTarget.value.trim(),
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Username,
						payload: event.currentTarget.value,
					});
				}
			},

			key: "username",
			required: true,
		},
		{
			id: "firstname",
			placeholder: "First Name",
			type: "text",
			label: "First Name",
			value: registrationFormState.firstName,
			hasError:
				!registrationFormState.firstNameIsValid &&
				registrationFormState.firstNameFieldIsTouched,
			errorMessage:
				"The first name field can only contain letters and number - including white spaces with a minimum of 3 characters",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.FirstName,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.FirstName,
						payload: event.currentTarget.value,
					});
				}
			},

			key: "firstname",
			required: true,
		},
		{
			id: "lastname",
			placeholder: "Last Name",
			type: "text",
			label: "Last Name",
			value: registrationFormState.lastName,
			hasError:
				!registrationFormState.lastNameIsValid &&
				registrationFormState.lastNameFieldIsTouched,
			errorMessage:
				"The last name field can only contain letters and number - including white spaces with a minimum of 3 characters",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.LastName,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.LastName,
						payload: event.currentTarget.value,
					});
				}
			},
			key: "lastname",
			required: true,
		},
		{
			id: "dateOfBirth",
			placeholder: "Date Of Birth",
			type: "date",
			label: "Date Of Birth",
			value: registrationFormState.dateOfBirth,
			hasError:
				!registrationFormState.dateOfBirthIsValid &&
				registrationFormState.dateOfBirthFieldIsTouched,
			errorMessage:
				"The date of birth is required and the person must be > 18 years old",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.DateOfBirth,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.DateOfBirth,
						payload: event.currentTarget.value,
					});
				}
			},
			key: "dob",
			required: true,
		},
		{
			id: "email",
			placeholder: "Email",
			type: "email",
			label: "Email",
			value: registrationFormState.email,
			hasError:
				!registrationFormState.emailIsValid &&
				registrationFormState.emailFieldIsTouched,
			errorMessage:
				"Email address must be in the following format: example@test.com",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Email,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Email,
						payload: event.currentTarget.value,
					});
				}
			},
			key: "email",
			required: true,
		},
		{
			id: "password",
			placeholder: "Password",
			type: "password",
			label: "Password",
			value: registrationFormState.password,
			hasError:
				!registrationFormState.passwordIsValid &&
				registrationFormState.passwordFieldIsTouched,
			errorMessage:
				"Only the following set of characters are allowed in the password field: letters, number and !@#$%^&*(()_-+= with a minimum of 7 characters",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Password,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.Password,
						payload: event.currentTarget.value,
					});
				}
			},
			key: "password",
			required: true,
		},
		{
			id: "confirm-password",
			placeholder: "Confirm Password",
			type: "password",
			label: "Confirm Password",
			value: registrationFormState.confirmPassword,
			hasError:
				!registrationFormState.confirmPasswordIsValid &&
				registrationFormState.confirmPasswordFieldIsTouched,
			errorMessage: "The password and the confirm password values do not match",
			onChange: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.ConfirmPassword,
						payload: event.currentTarget.value,
					});
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.target != undefined) {
					registrationFormDispatcher({
						type: RegistrationReducerAction.ConfirmPassword,
						payload: event.currentTarget.value,
					});
				}
			},
			key: "confirmPassword",
			required: true,
		},
	];
	let isFormValid: boolean =
		registrationFormState.confirmPasswordIsValid &&
		registrationFormState.confirmPasswordFieldIsTouched &&
		registrationFormState.passwordIsValid &&
		registrationFormState.confirmPasswordFieldIsTouched &&
		registrationFormState.usernameIsValid &&
		registrationFormState.usernameFieldIsTouched &&
		registrationFormState.firstNameIsValid &&
		registrationFormState.firstNameFieldIsTouched &&
		registrationFormState.lastNameIsValid &&
		registrationFormState.lastNameFieldIsTouched &&
		registrationFormState.emailIsValid &&
		registrationFormState.emailFieldIsTouched &&
		registrationFormState.dateOfBirthIsValid &&
		registrationFormState.dateOfBirthFieldIsTouched;

	const handleApiResponse = async (apiData: any) => {
		let loginResponse: LoginResponse = { ...apiData };

		if (loginResponse.isOk) {
			if (loginResponse.userId !== undefined && loginResponse.userId !== null) {
				if (userId !== undefined) {
					const userClientRelationRequest: ClientRelationRequest = {
						relationId: loginResponse.userId,
						clientId: userId,
					};
					await saveInformation(
						EndpointUrls.UserClientRelation,
						userClientRelationRequest,
						(response: BaseApiResponse) => {
							if (response.isSuccessful) {
								dispatch(loadAssociatedUsers(userId));
								dispatch(uiSliceActions.showModal(false));
							}
						}
					);
				}
			}
		} else {
			if (
				loginResponse.errorMessage != null &&
				loginResponse.errorMessage != undefined
			) {
				let errorMessage = loginResponse!.errorMessage;
				setFormErrorMessage((previousState) => errorMessage);
			}
		}
	};

	const submitFormHandler: (event: React.FormEvent<HTMLFormElement>) => void =
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (isFormValid) {
				await saveInformation<RegistrationModel>(
					EndpointUrls.Register,
					registrationFormState,
					handleApiResponse
				);
			}
		};

	const cancelRegistrationHandler = (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		navigate("/login", { replace: true });
	};

	return (
		<FormContainer
			className="registration-form"
			onSubmit={submitFormHandler}
			onReset={cancelRegistrationHandler}
			formActions={
				<CreateAccountFormAction
					isFormValid={isFormValid}
					isLoading={isLoading}
				/>
			}
		>
			{formInputElements.map((input) => (
				<FloatingLabelInput
					key={input.key}
					id={input.id}
					placeholder={input.placeholder}
					type={input.type}
					onChange={input.onChange}
					onBlur={input.onBlur}
					value={input.value}
					required={input.required}
					errorMessage={input.errorMessage}
					hasError={input.hasError}
				>
					{input.label}
				</FloatingLabelInput>
			))}
			<div
				className={`form__error-message ${
					formErrorMessage != undefined && formErrorMessage != ""
						? "form__error-message--has-error"
						: ""
				}`}
			>
				<p>{formErrorMessage}</p>
			</div>
		</FormContainer>
	);
};

export default CreateAccountForm;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	mapDataToClientApiModelList,
	mapResponseToApiModel,
} from "../Helpers/ClientsHelper";
import UserAccountApiModel from "../models/Api/Accounts/UserAccountApiModel";
import CustomersApiModel from "../models/Api/Customers/Models";
import UserApiModel from "../models/Api/Users/UserApiModel";
import { ClientsState } from "../models/State/States";

let initialState: ClientsState = {
	clients: [],
	clientIdList: [],
	currentClient: undefined,
	associatedAccountsList: undefined,
	associatedUsersList: undefined,
};

const setClients: (state: ClientsState, action: PayloadAction<any>) => void = (
	state,
	action
) => {
	let newState = { ...state };
	if (action.payload === undefined) {
		return newState;
	}
	let mappedData = mapDataToClientApiModelList(action.payload);
	let clientIdList: string[] = [];

	if (mappedData !== undefined) {
		mappedData.forEach((data) => {
			clientIdList = clientIdList.concat(data.id);
		});
		newState.clientIdList = clientIdList;
		newState.clients = mappedData;
	}
	return newState;
};

const addClient: (
	state: ClientsState,
	action: PayloadAction<CustomersApiModel>
) => void = (state, action) => {
	let newState = { ...state };
	newState.clients.concat(action.payload);
	state = newState;
};

const removeClient: (
	state: ClientsState,
	action: PayloadAction<string>
) => void = (state, action) => {
	let newState = { ...state };
	let clientList = { ...newState.clients };

	let clientIndex = newState.clients.findIndex((u) => u.id === action.payload);
	if (clientIndex > -1) {
		clientList = newState.clients.splice(clientIndex, 1);
	}
	newState.clients = [...clientList];
	state = newState;
};

const setClientIdList: (
	state: ClientsState,
	action: PayloadAction<string>
) => void = (state, action) => {
	let newState = { ...state };
	newState.clientIdList?.concat(action.payload);
	state = newState;
};

const setApiDataToActiveClient = (
	state: ClientsState,
	action: PayloadAction<any>
) => {
	let newState = { ...state };

	let mappedData = mapResponseToApiModel(action.payload);
	if (mappedData !== null && mappedData !== undefined) {
		newState.currentClient = { ...mappedData };
	}

	state = newState;
	return state;
};

const updateCurrentActiveClient = (
	state: ClientsState,
	action: PayloadAction<CustomersApiModel>
) => {
	let newState = { ...state };
	newState.currentClient = { ...action.payload };
	state = newState;
	return state;
};
const updateCurrentActiveUserProfile = (
	state: ClientsState,
	action: PayloadAction<CustomersApiModel>
) => {
	let newState = { ...state };
	if (newState.currentClient === undefined) {
		return state;
	}

	newState.currentClient = { ...action.payload };
	state = newState;
};

const setCurrentClientProfile = (
	state: ClientsState,
	action: PayloadAction<any>
) => {
	let newState = { ...state };
	let mappedData = mapResponseToApiModel(action.payload);

	if (mappedData !== null && mappedData !== undefined) {
		newState.currentClientProfile = { ...mappedData };
	}
	state = newState;

	return state;
};

const setAssociatedUsersList = (
	state: ClientsState,
	action: PayloadAction<UserApiModel[]>
) => {
	let newState = { ...state };
	newState.associatedUsersList = action.payload;
	return newState;
};

const setAssociatedAccountList = (
	state: ClientsState,
	action: PayloadAction<UserAccountApiModel[]>
) => {
	let newState = { ...state };
	newState.associatedAccountsList = action.payload;
	return newState;
};

const clientsSlice = createSlice({
	initialState: initialState,
	name: "clients",
	reducers: {
		setClients,
		addClient,
		removeClient,
		setClientIdList,
		setApiDataToActiveClient,
		updateCurrentActiveClient,
		updateCurrentActiveUserProfile,
		setCurrentClientProfile,
		setAssociatedUsersList,
		setAssociatedAccountList,
	},
});

export const clientsSliceActions = clientsSlice.actions;

export default clientsSlice;

import React, { useEffect, useState } from "react";
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
} from "@microsoft/signalr";
import Button from "../../components/ui/Button/Button";
import { Message } from "../../models/Api/Messages/Models";
import {
	BasicSelectInput,
	BasicTextArea,
} from "../../components/Forms/FormInputs/BasicInputs";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import DuplicateMessageListBox from "../../components/Messages/DuplicateMessageListBox/DuplicateMessageListBox";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../components/Loading/Loading";
import { mapApiResponseToMessageModel } from "../../Helpers/MessageHelpers";

import "./_feedbackPage.scss";
import Card from "../../components/ui/Card/Card";

const FeedbackPage: React.FC<{}> = (props) => {
	const [connection, setConnection] = useState<null | HubConnection>(null);
	const [message, setMessage] = useState<Message>();
	const {
		isLoading,
		executeGet: fetchMessage,
		executeAdd: saveFeedback,
	} = useHttp();

	const { userId } = useParams();
	const [correctedMessage, setCorrectedMessage] = useState<string>("");
	const [shouldCorrect, setShouldCorrect] = useState<boolean>(false);
	const [feedbackReason, setFeedbackReason] = useState<string>("Default");
	const [hasLoadedMessage, setHasLoadedMessage] = useState<boolean>(false);
	const [awaitingFeedbackMessagesCount, setAwaitingFeedbackMessagesCount] =
		useState<number>(0);
	const [availableOperatorCount, setAvailableOperatorCount] =
		useState<number>(0);
	const feedbackReasonList: string[] = [
		"Default",
		"Syntax issues",
		"No call to action",
		"Duplicated content",
	];

	const loadMessageForReview = async () => {
		if (userId !== undefined) {
			await fetchMessage(
				EndpointUrls.LoadMessageForFeedback,
				(apiData) => {
					if (
						apiData !== undefined &&
						apiData !== null &&
						apiData.messageBody !== null
					) {
						let mappedData = mapApiResponseToMessageModel(apiData, apiData.id);
						console.log(mappedData);
						if (mappedData !== undefined && mappedData !== null) {
							setMessage(mappedData);
						}
					}
				},
				[{ key: "userId", value: userId }]
			);
		}
	};

	const loadMessagesAwaitingForReviewList = async () => {
		if (userId !== undefined) {
			await fetchMessage(
				EndpointUrls.CountMessagesAwaitingFeedback,
				(apiData) => {
					if (apiData !== undefined && apiData !== null) {
						setAwaitingFeedbackMessagesCount(apiData.amount);
					}
				}
			);
		}
	};

	const loadAvailableOperatorsList = async () => {
		if (userId !== undefined) {
			await fetchMessage(EndpointUrls.GetAvailableOperatorsList, (apiData) => {
				if (apiData !== undefined && apiData !== null) {
					setAvailableOperatorCount(apiData.availableOperators);
				}
			});
		}
	};
	useEffect(() => {
		const loadOnlineOperatorsCount = async () => {
			await loadAvailableOperatorsList();
		};

		const connect = new HubConnectionBuilder()
			.withUrl(EndpointUrls.SignalRApi)
			.withAutomaticReconnect()
			.build();

		loadOnlineOperatorsCount();
		setConnection(connect);

		return () => {
			if (connection !== null) {
				connection.stop();
			}
		};
	}, []);

	useEffect(() => {
		const doConnect = async () => {
			if (connection !== null) {
				if (connection.state !== HubConnectionState.Connected) {
					await connection.start();
				}

				if (connection.state === HubConnectionState.Connected) {
					connection.on("LoadMessageForReview", async () => {
						if (!hasLoadedMessage || !shouldCorrect) {
							await loadMessagesAwaitingForReviewList();
						}
					});
					connection.on("RefreshAvailableOperatorList", async () => {
						if (!hasLoadedMessage || !shouldCorrect) {
							await loadAvailableOperatorsList();
						}
					});
				}
			}
		};
		doConnect();

		return () => {
			if (connection !== null) {
				connection.stop();
			}
		};
	}, [connection]);

	return (
		<div className="messages-feedback-container">
			{isLoading && <LoadingAnimation />}

			{!isLoading &&
				message !== undefined &&
				message.messageBody !== undefined &&
				message.authorId !== undefined && (
					<Card
						theme={"grey"}
						className="original-message-container"
						isDashboardElement={false}
					>
						<div className="original-message-container__original-message">
							<span className="text--bold">Original Message:</span>
							<span>{message.messageBody}</span>
						</div>

						<DuplicateMessageListBox
							userId={message.authorId}
							message={message.messageBody}
						/>
						{!shouldCorrect && (
							<div className="original-message-container__button-box">
								<Button
									theme={"secondary"}
									onClick={(e) => {
										e.preventDefault();
										setShouldCorrect(true);
										if (message.messageBody !== undefined) {
											setCorrectedMessage(message.messageBody);
										}
									}}
								>
									Correct
								</Button>
								<Button
									theme={"primary"}
									onClick={async (e) => {
										e.preventDefault();

										var reviewedMessage = { ...message };
										reviewedMessage.feedbackBody = "";
										reviewedMessage.feedbackType = "Approved";
										await saveFeedback(
											EndpointUrls.SaveMessageFeedback,
											reviewedMessage
										);
										setShouldCorrect(false);
										setHasLoadedMessage(false);
										setMessage(undefined);
										await loadMessagesAwaitingForReviewList();
										await loadMessageForReview();
									}}
								>
									Approve
								</Button>
							</div>
						)}
					</Card>
				)}
			{!isLoading && message === undefined && (
				<Card
					theme={"grey"}
					className="original-message-container"
					isDashboardElement={false}
				>
					<div className="original-message-container__original-message">
						<span className="text--bold">No messages to review:</span>
						<span>
							There are no messages available for review at the moment. The next
							one will be automatically displayed on the page.
						</span>
					</div>
				</Card>
			)}
			<Card theme={"grey"} isDashboardElement={false}>
				{!isLoading && (
					<React.Fragment>
						<div>
							Number of messages in the queue: {awaitingFeedbackMessagesCount}
						</div>
						<div>Number of online operators: {availableOperatorCount}</div>
						<Button
							theme={"secondary"}
							onClick={async (e) => {
								await loadMessageForReview();
							}}
						>
							Load Messages
						</Button>
					</React.Fragment>
				)}
			</Card>
			{!isLoading && shouldCorrect && (
				<Card
					className="message-feedback-box"
					theme={"cream"}
					isDashboardElement={false}
				>
					<div className="message-feedback-box__text-box">
						<span className="text--bold">Corrected Message:</span>
						<BasicTextArea
							placeholder=""
							value={correctedMessage}
							onChange={(e) => {
								e.preventDefault();
								setCorrectedMessage(e.currentTarget.value);
							}}
						></BasicTextArea>
					</div>
					<div className="message-feedback-box__reason-box">
						<span className="text--bold">Feedback Reason:</span>
						<BasicSelectInput
							label=""
							value={feedbackReason}
							onChange={(e) => {
								e.preventDefault();
								setFeedbackReason(e.currentTarget.value);
							}}
						>
							{feedbackReasonList.map((feedback) => (
								<option key={Math.random()} value={feedback}>
									{feedback}
								</option>
							))}
						</BasicSelectInput>
						<Button
							theme={"primary"}
							onClick={async (e) => {
								e.preventDefault();
								if (correctedMessage !== "") {
									var reviewedMessage = { ...message };
									reviewedMessage.feedbackBody = correctedMessage;
									reviewedMessage.feedbackType = feedbackReason;
									await saveFeedback(
										EndpointUrls.SaveMessageFeedback,
										reviewedMessage
									);
									setShouldCorrect(false);
									setHasLoadedMessage(false);
									setMessage(undefined);
									await loadMessagesAwaitingForReviewList();
									await loadMessageForReview();
								}
							}}
						>
							Send
						</Button>
					</div>
				</Card>
			)}
		</div>
	);
};

export default FeedbackPage;

// import "./App.css";
import LoginPage from "./pages/Login/LoginPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import MessagesPage from "./pages/MessagesPage";
import NotFoundPage from "./pages/NotFoundPage";
import DashboardIndexPage from "./pages/Dashboard/DashboardIndexPage";
import GeneralDashboard from "./pages/Dashboard/GeneralDashboard";
import OperatorDashboard from "./pages/Dashboard/OperatorDashboard";
import AccountsDashboard from "./pages/Dashboard/AccountsDashboard";
import { useAppSelector } from "./hooks/StateHooks";
import React, { useEffect } from "react";
import ManageOperatorsDashboard from "./pages/Admin/ManageOperatorsDashboard";
import ManageUserRolesDashboard from "./pages/Admin/ManageUserRolesDashboard";
import ManageAccountsDashboard from "./pages/Admin/ManageAccountsDashboard";
import ProfileOverviewPage from "./pages/Profile/ProfileOverviewPage";

import ProfileInfoPage from "./pages/Profile/ProfileInfoPage";
import ProfileEditInfoPage from "./pages/Profile/ProfileEditInfoPage";
import MessageStatsPage from "./pages/Statistics/MessagesStats";
import ProfileSalaryPage from "./pages/Profile/ProfileSalaryPage";
import SalaryOverviewPage from "./pages/Finances/SalaryOverviewPage";
import CreateAccountPage from "./pages/Registration/CreateAccountPage";
import useCookies from "./hooks/UseCookies";
import { useDispatch } from "react-redux";
import { authSliceAction } from "./store/AuthSlice";
import { usersSliceActions } from "./store/UsersSlice";
import TarificationOverviewPage from "./pages/Settings/TarificationOverviewPage";
import AccountOverviewPage from "./pages/Finances/AccountOverviewPage";
import ForexSettingsPage from "./pages/Settings/ForexSettingsPage";
import ExtraCostPage from "./pages/Finances/ExtraCostPage";
import OperatorsOverviewPage from "./pages/Finances/OperatorsOverviewPage";
import { loadUser } from "./store/Actions/UsersActions";
import { uiSliceActions } from "./store/UiSlice";
import AccountDetailsOverview from "./pages/Quality/AccountDetailsOverview";
import AccountOverviewList from "./pages/Quality/AccountOverviewList";
import OperatorOverviewList from "./pages/Quality/OperatorOverviewList";
import OperatorDetailOverview from "./pages/Quality/OperatorsDetailsOverview";
import FeedbackPage from "./pages/Feedback/FeedbackPage";
import MessageTestPage from "./pages/Feedback/MessageTestPage";
import AddUnassignedAccountForm from "./components/Accounts/UnassignedAccountOverview/UnassignedAccountForm";
import MessageStylingPage from "./pages/MessageStylingPage";
import ManageCustomersDashboard from "./pages/Admin/ManageCustomersDashboard";
import CustomerDashboard from "./pages/Dashboard/CustomerDashboard";
import CustomerRegistrationPage from "./pages/Customer/Registration/CustomerRegistration";

import ManageServersDashboard from "./pages/Admin/ManageServersDashboard";
import CustomerBasePage from "./pages/Customer/CustomerBasePage";
import CustomerOperatorsTable from "./pages/Customer/OperatorsTable/CustomerOperators";
import CustomerAccountsTable from "./pages/Customer/AccountsTable/CustomerAccountsTable";

function App() {
	let authState = useAppSelector((state) => state.auth);
	const currentUserProfile = useAppSelector((state) => state.users.currentUser);

	let navigate = useNavigate();
	let { readCookie, updateCookie } = useCookies("");
	let dispatch = useDispatch();

	var currentUserSession = readCookie<string>("GLOBAL_T");
	if (currentUserSession !== undefined && currentUserSession !== null) {
		updateCookie("GLOBAL_T", currentUserSession);
	}

	useEffect(() => {
		dispatch(uiSliceActions.resetErrorState());
		if (currentUserSession === undefined) {
			navigate("/login", { replace: true });
		} else {
			if (authState !== null && authState.isLoggedIn) {
				return;
			} else {
				dispatch(loadUser(currentUserSession));
				if (currentUserProfile !== undefined)
					dispatch(
						authSliceAction.setLoginState({
							isLoggedIn: true,
							role: currentUserProfile.roles.name,
							token: "",
							userId: currentUserProfile.userId,
						})
					);
				dispatch(usersSliceActions.setApiDataToActiveUser(currentUserSession));

				updateCookie("GLOBAL_T", currentUserSession);
			}

			return () => {};
		}
	}, [authState]);

	console.log(process.env.REACT_APP_API_SERVER);

	return (
		<Routes>
			<Route path="login" element={<LoginPage />}></Route>
			<Route path="create-account" element={<CreateAccountPage />}></Route>
			<Route path="customer" element={<CustomerBasePage />}>
				<Route
					path=":clientId/registration"
					element={<CustomerRegistrationPage />}
				/>
				<Route path=":clientId/dashboard" element={<CustomerDashboard />} />
				<Route
					path=":clientId/operators"
					element={<CustomerOperatorsTable />}
				/>
				<Route path=":clientId/accounts" element={<CustomerAccountsTable />} />
			</Route>
			<Route path="administration" element={<DashboardIndexPage />}>
				<Route path="manage-operators" element={<ManageOperatorsDashboard />} />
				<Route path="manage-roles" element={<ManageUserRolesDashboard />} />
				<Route path="manage-accounts" element={<ManageAccountsDashboard />} />
				<Route path="manage-customers" element={<ManageCustomersDashboard />} />
				<Route path="manage-servers" element={<ManageServersDashboard />} />
			</Route>
			<Route path="profile" element={<DashboardIndexPage />}>
				<Route path=":userId/overview" element={<ProfileOverviewPage />} />
				<Route path=":userId/info" element={<ProfileInfoPage />} />
				<Route path=":userId/edit" element={<ProfileEditInfoPage />} />
				<Route path=":userId/salary" element={<ProfileSalaryPage />} />
			</Route>
			<Route path="messages" element={<DashboardIndexPage />}>
				<Route path=":userId" element={<MessagesPage />} />
				<Route path=":userId/feedback" element={<FeedbackPage />} />
			</Route>
			<Route path="quality" element={<DashboardIndexPage />}>
				<Route path="accounts-overview" element={<AccountOverviewList />}>
					<Route path=":accountId" element={<AccountDetailsOverview />} />
				</Route>
				<Route path="operators-overview" element={<OperatorOverviewList />}>
					<Route path=":userId" element={<OperatorDetailOverview />} />
				</Route>
			</Route>
			<Route path="stats" element={<DashboardIndexPage />}>
				<Route path="messages/:userId" element={<MessageStatsPage />} />
			</Route>

			<Route path="finances" element={<DashboardIndexPage />}>
				<Route path="salary-overview" element={<SalaryOverviewPage />} />
				<Route path="accounts-overview" element={<AccountOverviewPage />} />
				<Route path="operators-overview" element={<OperatorsOverviewPage />} />
				<Route path="extra-costs" element={<ExtraCostPage />} />
			</Route>
			<Route path="settings" element={<DashboardIndexPage />}>
				<Route path="tarification" element={<TarificationOverviewPage />} />
				<Route path="forex" element={<ForexSettingsPage />} />
			</Route>
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default App;

import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { uiSliceActions } from "../UiSlice";
import { executeGet } from "./AsyncActions";
import { usersSliceActions } from "../UsersSlice";

export const loadUserList = () => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));

		await dispatch(
			executeGet(EndpointUrls.Users, (apiData) => {
				dispatch(usersSliceActions.setUsers(apiData));
			})
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

export const loadUser = (userId: string, isClient: boolean = false) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));
		const url = !isClient
			? EndpointUrls.Users
			: EndpointUrls.LoadClientInformation;
		await dispatch(
			executeGet(
				url,
				(apiData) => {
					dispatch(usersSliceActions.setApiDataToActiveUser(apiData));
				},
				[{ key: "userId", value: userId }]
			)
		);
	};
};

export const loadUserProfile = (userId: string) => {
	return async (dispatch: any) => {
		dispatch(uiSliceActions.setLoadingState(true));

		await dispatch(
			executeGet(
				EndpointUrls.Users,
				(apiData) => {
					dispatch(usersSliceActions.setCurrentUserProfile(apiData));
				},
				[{ key: "userId", value: userId }]
			)
		);
		dispatch(uiSliceActions.setLoadingState(false));
	};
};

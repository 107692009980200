import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import { loadUserProfile } from "../../../store/Actions/UsersActions";
import Avatar from "../../Avatar/Avatar";
import { OneColumnGrid } from "../../Layouts/PageStructure/GridColumns";
import CardNavigation from "../../Navigation/CardNavigation/CardNavigation";
import Card from "../../ui/Card/Card";

import "./_profile-navigation.scss";

const ProfileNavigation: React.FC<{ userId: string }> = (props) => {
  let userData = useAppSelector((state) => state.users.currentProfile?.profile);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile(props.userId));
  }, []);
  return (
    <OneColumnGrid>
      <Card
        theme={"white"}
        isDashboardElement={false}
        className="profile-navigation"
      >
        {(userData != undefined || userData != null) && (
          <Avatar
            firstName={userData.firstName}
            lastName={userData.lastName}
            email={userData.phoneNumber}
            imagePath={
              "https://static-cdn.jtvnw.net/user-default-pictures-uv/215b7342-def9-11e9-9a66-784f43822e80-profile_image-300x300.png"
            }
          ></Avatar>
        )}

        <CardNavigation
          navigations={[
            { text: "Overview", to: `/profile/${props.userId}/overview` },
            { text: "Info", to: `/profile/${props.userId}/info` },
            { text: "Salary", to: `/profile/${props.userId}/salary` },
          ]}
        ></CardNavigation>
      </Card>
    </OneColumnGrid>
  );
};

export default ProfileNavigation;

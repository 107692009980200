import React, { HTMLAttributes, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { KeyValuePair } from "../../../models/General/GeneralModel";
import { uiSliceActions } from "../../../store/UiSlice";
import Modal from "../../Modal/Modal";
import CurrentAccountInformationCard from "../CurrentAccountInformationCard";
import AddUnassignedAccountForm from "./UnassignedAccountForm";
import "./UnassignedAccountList.scss";

const UnassignedAccountList: React.FC<HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  let showModal = useAppSelector((state) => state.ui.showModal);
  const [unassignedAccountList, setUnassignedAccountList] = useState<
    KeyValuePair[]
  >([]);
  const [currentAccount, setCurrentAccount] = useState<KeyValuePair>();

  const [isClicked, setIsClicked] = useState<boolean>(false);

  let {
    isLoading,
    executeGet: fetchUnassignedAccounts,
    executeAdd: assignAccount, // naming parameter (fonction : fonction name)
  } = useHttp(); //object deconstruction

  const handleApiResponse: (data: any) => void = (data) => {
    //data : empty, rempli , null, undefined
    /*
    {
  "0425046b-c803-4e9f-89a2-15de75163dc3": "FRMC1041799",
  "2aac2c74-59ef-46fc-a88c-c2229f124881": "Patricia-10412",
  "5dbbfe6a-9772-495c-a53f-bbc58df8ded9": "FRMC1198437"

}*/

    // [{key: "key1", value: "value1"},etc]
    if (data !== null && data !== undefined) {
      let mappedResult: KeyValuePair[] = [];

      for (const accountId in data) {
        if (Object.prototype.hasOwnProperty.call(data, accountId)) {
          const accountName = data[accountId];
          mappedResult = mappedResult.concat({
            key: accountId,
            value: accountName,
          });
        }
      }
      setUnassignedAccountList(mappedResult);
    }
  };
  const fetchData = async () => {
    console.log("calling fetch data");
    await fetchUnassignedAccounts(
      EndpointUrls.UnassignedAccountList,
      handleApiResponse,
    );
    setIsClicked(false);
    console.log(isClicked);
  };

  // useEffect est triggered qd il ya des changements dans un de ces dependances
  useEffect(() => {
    fetchData();
  }, [unassignedAccountList.length]);

  //onclick handler to show the modal with the right accountNumber;
  let dispatch = useDispatch();
  const showForm: (data: KeyValuePair) => void = (data) => {
    if (data == undefined) {
      return;
    }
    setCurrentAccount(data);
    dispatch(uiSliceActions.showModal(true));
  };

  return (
    <React.Fragment>
      {currentAccount !== undefined && isClicked && showModal && (
        <Modal
          title="Assign Account"
          onClose={() => {
            setIsClicked(false);
          }}
        >
          <AddUnassignedAccountForm
            fetchData={fetchData}
            accountId={currentAccount.key}
            account={currentAccount.value}
          />
        </Modal>
      )}
      {unassignedAccountList !== undefined && (
        <div
          className={`unassigned-account-list-container ${
            props.className !== undefined ? props.className : ""
          }`}
        >
          <div className="unassigned-account-title">
            <h6>Warning</h6>
            <p>Theses accounts are not assigned yet</p>
          </div>
          <span className="material-icons-outlined assignment-late">
            assignment_late
          </span>
          <ul className="unassigned-account-list">
            {unassignedAccountList.map((account) => (
              <React.Fragment>
                <li key={Math.random()}>
                  <p className="unassigned-account">
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setIsClicked(true);
                        showForm(account);
                      }}
                    >
                      {account.value}
                    </a>
                  </p>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};
export default UnassignedAccountList;

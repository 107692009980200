import React, { useEffect, useState } from "react";
import CardInformation from "../../CardInformation/CardInformation";
import Table, { TableHeader, TableItem, TableRow } from "../../Tables/Table";
import Card from "../../ui/Card/Card";
import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { OperatorPerformanceModel } from "../../../models/Api/OperatorsPerformance/OperatorPerformanceModel";
import { mapApiDataToOperatorPerformanceModelList } from "../../../Helpers/OperatorsPerformanceHelper";
import LoadingAnimation from "../../Loading/Loading";

import "./_users-performance-table.scss";
import ErrorNotification from "../../ui/ErrorNotification/ErrorNotification";
import Button from "../../ui/Button/Button";
import { Link } from "react-router-dom";

const UsersPerformanceTable: React.FC<{}> = (props) => {
	let [userData, setUserData] = useState<OperatorPerformanceModel[]>();
	let [selectedDate, setSelectedDate] = useState<Date>();
	let { isLoading, executeGet: fetchData } = useHttp();

	useEffect(() => {
		if (selectedDate !== undefined) {
			fetchData(
				EndpointUrls.OperatorsPerformance,
				(apiData) => {
					let mappedResult = mapApiDataToOperatorPerformanceModelList(apiData);
					if (mappedResult !== undefined) {
						setUserData((prev) => mappedResult);
					}
				},
				[{ key: "date", value: selectedDate?.toDateString() }]
			);
		}
	}, [selectedDate]);
	let count = 0;
	return (
		<Card theme={"white"} isDashboardElement={true}>
			<CardInformation
				variant={"fullGrid"}
				header="Finances"
				title="Operators Financial Overview"
				description="To show the relevant data, please select a date from the date selector"
				headerCallToActionElement={
					<input
						type="date"
						id="date-selector"
						onChange={(event) => {
							if (event.currentTarget.value.trim().length !== 0) {
								let selectedDate = new Date(event.currentTarget.value);
								setSelectedDate((prev) => selectedDate);
							}
						}}
					></input>
				}
			></CardInformation>
			{isLoading && <LoadingAnimation />}
			{!isLoading && userData === undefined && (
				<ErrorNotification description="There is no data available for the selected period" />
			)}
			{!isLoading && userData !== undefined && (
				<Table className="users-performance-table">
					<TableHeader className="users-performance-table__header">
						{/* <TableItem>User Id</TableItem> */}
						<TableItem>Full Name</TableItem>
						<TableItem>Assigned Account</TableItem>
						{/* <TableItem>Last Connected</TableItem> */}
						<TableItem>Total Messages Sent</TableItem>
						<TableItem>Total Salary</TableItem>
						<TableItem>Actions</TableItem>
					</TableHeader>
					{userData !== undefined &&
						userData.map((user) => {
							return (
								<TableRow
									key={count++}
									className="users-performance-table__row"
								>
									{/* <TableItem>{user.userId}</TableItem> */}
									<TableItem>{user.fullName}</TableItem>
									<TableItem>{user.accountName}</TableItem>
									{/* <TableItem>{new Date().toLocaleDateString()}</TableItem> */}
									<TableItem>{user.totalMessagesSent}</TableItem>
									{/* <TableItem>{user.totalWorkedHours}</TableItem> */}
									<TableItem>
										<span className="text-type--price--eur">
											{user.totalSalary.toFixed(2)}
										</span>
									</TableItem>
									<TableItem>
										<Button theme={"secondary"}>
											<Link to={`/profile/${user.userId}/overview`}>
												View Details
											</Link>
										</Button>
									</TableItem>
								</TableRow>
							);
						})}
				</Table>
			)}
		</Card>
	);
};

export default UsersPerformanceTable;

import {
	ConversationInformation,
	FlowboosterInterface,
	FlowboosterNoteHistory,
	MediaItem,
	Message,
	NoteCategory,
	NoteDetail,
	ParentMessageInformation,
	ProfileDetails,
	ProfileInformation,
} from "../models/Flowbooster/Models";
import { DynamicKeyValuePair } from "../models/General/GeneralModel";

export const mapApiResponseToFlowboosterMessage: (
	apiData: any
) => Message | undefined = (apiData: any) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		accountName: apiData.accountName,
		attachment: apiData.attachment,
		messageBody: apiData.messageBody,
		sendDate: apiData.sendDate,
		isFlagged: apiData.isFlagged,
		authorId: apiData.authorId,
		authorName: apiData.authorName,
		feedbackAuthorId: apiData.feedbackAuthorId,
		feedbackAuthorName: apiData.feedbackAuthorName,
		feedbackBody: apiData.feedbackBody,
		feedbackType: apiData.feedbackType,
		isInbound: apiData.isInbound,
		received: apiData.received,
	};
};

export const mapApiResposneToFlowboosterConversationInfo: (
	apiData: any
) => ConversationInformation | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	let result: ConversationInformation = {
		id: apiData.id,
		userId: apiData.userId,
		playerProfileId: apiData.playerProfileId,
		targetProfileId: apiData.targetProfileId,
		numberOfMessagesReceived: apiData.numberOfMessagesReceived,
		numberOfMessagesSent: apiData.numberOfMessagesSent,
		lastMessageReceivedId: apiData.lastMessageReceivedId,
		lastMessageSentId: apiData.lastMessageSentId,
		startDate: apiData.startDate,
		usedMedia: apiData.usedMedia,
		isMediaListExhausted: apiData.isMediaListExhausted,
		hasMediaButton: apiData.hasMediaAvailable,
		availableMediaList: apiData.availableMediaList,
	};

	if (
		apiData.availableMediaList !== undefined &&
		apiData.availableMediaList !== null &&
		apiData.availableMediaList.length > 0
	)
		result.availableMediaList = apiData.availableMediaList.map((t: any) =>
			mapiApiResponseToFlowboosterMediaItem(t)
		);

	return result;
};

export const mapApiResponseToFlowboosterNoteDetail: (
	apiData: any
) => NoteDetail | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		modified: apiData.modified,
		created: apiData.created,
		content: apiData.content,
		id: apiData.id,
	};
};

export const mapApiResponseToFlowboosterNoteCategory: (
	apiData: any
) => NoteCategory | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		description: apiData.description,
		id: apiData.id,
		name: apiData.name,
	};
};

export const mapiApiResponseToFlowboosterMediaItem: (
	apiData: any
) => MediaItem | undefined = (apiData) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	return {
		id: apiData.id,
		file: apiData.file,
		largeImage: apiData.largeImage,
		photo: apiData.photo,
		smallImage: apiData.smallImage,
	};
};

export const mapApiResponseToFlowboosterProfileInformation: (
	apiData: any
) => ProfileInformation | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	let result: ProfileInformation = {
		id: apiData.id,
		name: apiData.name,
		photo: apiData.photo,
		gender: apiData.gender,
		country: apiData.country,
		city: apiData.city,
		myLength: apiData.myLength,
		yourLength: apiData.yourLength,
		age: apiData.age,
		dob: apiData.dob,
		constellation: apiData.constellation,
		geoRegion1: apiData.geoRegion1,
		geoRegion2: apiData.geoRegion2,
		geoRegion3: apiData.geoRegion3,
		geoRegion4: apiData.geoRegion4,
		geoLocality: apiData.geoLocality,
		geoPostcode: apiData.geoPostcode,
		geoTimezone: apiData.geoTimezone,
		hasLastNotes: apiData.hasLastNotes,
	};

	if (apiData.images !== undefined && apiData.images !== null) {
		result.images = apiData.images.map((image: any) =>
			mapiApiResponseToFlowboosterMediaItem(image)
		);
	}

	if (apiData.lastNotes !== undefined && apiData.lastNotes !== null) {
		result.lastNotes = apiData.lastNotes.map((note: any) =>
			mapApiResponseToFlowboosterNoteDetail(note)
		);
	}

	return result;
};

export const mapApiResponseToFlowboosterProfileDetail: (
	apiData: any
) => ProfileDetails | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		aboutMe: apiData.aboutMe,
		aboutYou: apiData.aboutYou,
		age: apiData.age,
		civilStatus: apiData.civilStatus,
		hairColor: apiData.hairColor,
		eyeColor: apiData.eyeColor,
		hobbies: apiData.hobbies,
		smoke: apiData.smoke,
		tattoo: apiData.tattoo,
		piercing: apiData.piercing,
		profession: apiData.profession,
		habitat: apiData.habitat,
		realName: apiData.realName,
		build: apiData.build,
		title: apiData.title,
	};
};

export const mapApiResponseToFlowboosterNoteHistory: (
	apiData: any
) => FlowboosterNoteHistory | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		auhorName: apiData.authorName,
		authorId: apiData.authorId,
		category: apiData.category,
		categoryDescription: apiData.categoryDescription,
		categoryId: apiData.categoryId,
		content: apiData.content,
		created: apiData.created,
		id: apiData.id,
		modified: apiData.modified,
	};
};

export const mapApiResponseToFlowboosterParentMessage: (
	apiData: any
) => ParentMessageInformation | undefined = (apiData) => {
	if (apiData === undefined) {
		return undefined;
	}

	return {
		attachment: apiData.attachment,
		content: apiData.content,
		conversationId: apiData.conversationId,
		foreignMessageId: apiData.foreignMessageId,
		freeCredits: apiData.freeCredits,
		id: apiData.id,
		parentId: apiData.id,
		parentType: apiData.parentType,
		published: apiData.published,
		scheduled: apiData.scheduled,
		site: apiData.site,
		subject: apiData.subject,
		type: apiData.type,
		userId: apiData.userId,
	};
};

export const mapApiResponseToFlowboosterNoteHistoryList: (
	apiData: any
) => DynamicKeyValuePair<string, FlowboosterNoteHistory[]>[] | undefined = (
	apiData
) => {
	if (apiData === undefined) {
		return undefined;
	}

	let mappedData: DynamicKeyValuePair<string, FlowboosterNoteHistory[]>[] = [];

	for (const key in apiData) {
		if (Object.prototype.hasOwnProperty.call(apiData, key)) {
			const noteHistoryList = apiData[key].map((noteHistoryItem: any) =>
				mapApiResponseToFlowboosterNoteHistory(noteHistoryItem)
			);
			const categoryInformation: DynamicKeyValuePair<
				string,
				FlowboosterNoteHistory[]
			> = { key: key, value: noteHistoryList };

			mappedData = mappedData.concat(categoryInformation);
		}
	}

	return mappedData;
};

export const mapApiResponseToFlowboosterInterface: (
	apiData: any
) => FlowboosterInterface | undefined = (apiData: any) => {
	if (apiData === undefined) {
		return undefined;
	}

	let result: FlowboosterInterface = {
		conversationInformation: {
			id: "",
			userId: "",
			playerProfileId: "",
			targetProfileId: "",
			numberOfMessagesSent: 0,
			numberOfMessagesReceived: 0,
			lastMessageReceivedId: "",
			lastMessageSentId: "",
			startDate: "",
			isMediaListExhausted: false,
			hasMediaButton: false,
		},
		playerProfile: {
			id: "",
			name: "",
			photo: "",
			gender: "",
			country: "",
			city: "",
			myLength: 0,
			yourLength: 0,
			age: 0,
			dob: undefined,
			constellation: "",
			geoRegion1: "",
			geoRegion2: "",
			geoRegion3: "",
			geoRegion4: "",
			geoLocality: "",
			geoPostcode: "",
			geoTimezone: "",
			hasLastNotes: false,
			lastNotes: [],
		},
		targetProfile: {
			id: "",
			name: "",
			photo: "",
			gender: "",
			country: "",
			city: "",
			myLength: 0,
			yourLength: 0,
			age: 0,
			dob: undefined,
			constellation: "",
			geoRegion1: "",
			geoRegion2: "",
			geoRegion3: "",
			geoRegion4: "",
			geoLocality: "",
			geoPostcode: "",
			geoTimezone: "",
			hasLastNotes: false,
			lastNotes: [],
		},
		messages: [],
		lastMessages: {
			sendDate: undefined,
			attachment: "",
			messageBody: "",
			feedbackBody: "",
			isFlagged: false,
			authorId: "",
			authorName: "",
			feedbackAuthorId: "",
			feedbackAuthorName: "",
			feedbackType: "",
			accountName: "",
			isInbound: false,
			received: undefined,
		},
		noteCategories: [],
	};

	if (apiData.conversationInformation !== undefined) {
		result.conversationInformation =
			mapApiResposneToFlowboosterConversationInfo(
				apiData.conversationInformation
			);
	}

	if (apiData.playerProfile !== undefined) {
		result.playerProfile = mapApiResponseToFlowboosterProfileInformation(
			apiData.playerProfile
		);
	}

	if (apiData.targetProfile !== undefined) {
		result.targetProfile = mapApiResponseToFlowboosterProfileInformation(
			apiData.targetProfile
		);
	}

	if (apiData.playerProfileDetails !== undefined) {
		result.playerDetails = mapApiResponseToFlowboosterProfileDetail(
			apiData.playerProfileDetails
		);
	}

	if (apiData.targetProfileDetails !== undefined) {
		result.targetDetails = mapApiResponseToFlowboosterProfileDetail(
			apiData.targetProfileDetails
		);
	}

	if (apiData.messages !== undefined && apiData.messages.length > 0) {
		result.messages = apiData.messages.map((message: any) =>
			mapApiResponseToFlowboosterMessage(message)
		);
	}

	if (apiData.lastMessages !== undefined) {
		result.lastMessages = mapApiResponseToFlowboosterMessage(
			apiData.lastMessages
		);
	}

	if (
		apiData.noteCategories !== undefined &&
		apiData.noteCategories.length > 0
	) {
		result.noteCategories = apiData.noteCategories.map((note: any) =>
			mapApiResponseToFlowboosterNoteCategory(note)
		);
	}

	if (apiData.playerNotes !== undefined && apiData.playerNotes !== null) {
		let mappedData = mapApiResponseToFlowboosterNoteHistoryList(
			apiData.playerNotes
		);

		result.playerNotes = mappedData;
	}

	if (apiData.targetNotes !== undefined && apiData.targetNotes !== null) {
		let mappedData = mapApiResponseToFlowboosterNoteHistoryList(
			apiData.targetNotes
		);

		result.targetNotes = mappedData;
	}

	if (apiData.parentMessage !== undefined && apiData.parentMessage !== null) {
		result.parentMessage = mapApiResponseToFlowboosterParentMessage(
			apiData.parentMessage
		);
	}

	return result;
};

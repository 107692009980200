import React, { useEffect, useState } from "react";
import useMessageApi from "../../../hooks/UseMessageApi";
import {
	FlowboosterNoteHistory,
	NoteCategory,
} from "../../../models/Flowbooster/Models";
import { DynamicKeyValuePair } from "../../../models/General/GeneralModel";
import { NoteHistoryItemProps } from "../../../models/Props/Messages/MessageNotesProps";
import { SidePanelProps } from "../../../models/Props/Messages/SidePanelProps";
import LoadingAnimation from "../../Loading/Loading";
import ProfileInformationBlock from "../ProfileInformation/ProfileInformation";
import ProfileNoteForm from "../ProfileNotesForm/ProfileNoteForm";
import ProfileNotesHistoryList from "../ProfileNotesHistory/ProfileNotesHistoryList";

import "./_sidePanel.scss";

const SidePanel: React.FC<{
	platform: string;
	className?: string;
	isLoading: boolean;
	primaryInformation?: SidePanelProps;

	userId: string;
	ipAddress: string;
	noteCategoryList?: NoteCategory[];
	playerProfileId?: string;
	targetProfileId?: string;
}> = (props) => {
	const [primaryNoteHistoryList, setPrimaryNoteHistoryList] =
		useState<
			DynamicKeyValuePair<string, FlowboosterNoteHistory[]>[] | undefined
		>();

	const { loadNotes } = useMessageApi();

	useEffect(() => {
		setPrimaryNoteHistoryList(props.primaryInformation?.noteHistoryList);
	}, [props.primaryInformation?.noteHistoryList?.length]);

	const onPrimaryNoteUpdatedHandler: () => void = () => {
		if (props.primaryInformation?.isPlayerNote) {
			if (props.playerProfileId !== undefined)
				loadNotes(
					props.ipAddress,
					props.userId,
					props.playerProfileId,
					props.platform,
					(apiData) => {
						setPrimaryNoteHistoryList(apiData);
					}
				);
		} else {
			if (
				props.playerProfileId !== undefined &&
				props.targetProfileId !== undefined
			)
				loadNotes(
					props.ipAddress,
					props.userId,
					props.playerProfileId,
					props.platform,
					(apiData) => {
						setPrimaryNoteHistoryList(apiData);
					},
					props.targetProfileId
				);
		}
	};

	return (
		<div className={`${props.className !== undefined ? props.className : ""}`}>
			{props.isLoading && <LoadingAnimation />}
			{!props.isLoading && (
				<React.Fragment>
					<div className="messaging-container__information">
						<ProfileInformationBlock
							profileInformation={props.primaryInformation?.profileInformation}
							profileDetails={props.primaryInformation?.profileDetails}
						></ProfileInformationBlock>
						{primaryNoteHistoryList !== undefined &&
							primaryNoteHistoryList.map(
								(
									categoryInfo: DynamicKeyValuePair<
										string,
										FlowboosterNoteHistory[]
									>
								) => (
									<div
										key={Math.random()}
										className="note-history-list-container"
									>
										<ProfileNotesHistoryList
											category={categoryInfo.key}
											notes={categoryInfo.value.map((note) => {
												const element: NoteHistoryItemProps = {
													authorName: note.auhorName,
													category: note.category,
													content: note.content,
													postedDate: note.created,
													authorId: note.authorId,
													id: note.id,
													isPlayerNote: props.primaryInformation?.isPlayerNote,
													platform: props.platform,
													onNoteDelete: onPrimaryNoteUpdatedHandler,
												};
												return element;
											})}
										/>
									</div>
								)
							)}
						{props.noteCategoryList !== undefined &&
							props.noteCategoryList.length > 0 && (
								<ProfileNoteForm
									platform={props.platform}
									onNoteSent={onPrimaryNoteUpdatedHandler}
									userId={props.userId}
									noteCategoryList={props.noteCategoryList}
									isPlayerNote={props.primaryInformation?.isPlayerNote}
									ipAddress={props.ipAddress}
									playerProfileId={props.playerProfileId}
									targetProfileId={props.targetProfileId}
								/>
							)}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default SidePanel;

import React from "react";
import "./_page-structure.scss";

const FlexContainer: React.FC<{ className?: string }> = (props) => {
	return (
		<div className={`container ${props.className ? props.className : ""}`}>
			{props.children}
		</div>
	);
};

export default FlexContainer;

import React, { useEffect } from "react";
import {
	EvenlySizedColumnGrid,
	SingleFullWidthColumn,
	TwoColumnsFocusLeft,
} from "../../components/Layouts/PageStructure/GridColumns";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/StateHooks";
import { useDispatch } from "react-redux";
import AccountAssignmentHistoryCard from "../../components/Accounts/AccountAssignementHistoryCard";
import CurrentWeekSalaryForecastCard from "../../components/Salary/CurrentWeekSalaryForecastCard";
import CurrentWeekMessagesForecastCard from "../../components/Messages/CurrentWeekMessagesForecastCard";
import CurrentAccountInformationCard from "../../components/Accounts/CurrentAccountInformationCard";
import AccountTarificationOverviewCard from "../../components/Accounts/AccountTarificationOverviewCard";
import { loadUserProfile } from "../../store/Actions/UsersActions";
import ProfileNavigation from "../../components/Profile/ProfileNavigation/ProfileNavigation";
import MessageListCard from "../../components/Messages/MessageListCard/MessageListCard";
import "./_profile-responsive.scss";

const ProfileOverviewPage: React.FC<{}> = (props) => {
	let userData = useAppSelector((state) => state.users.currentProfile);
	let dispatch = useDispatch();
	let { userId } = useParams();

	useEffect(() => {
		if (userId != null) {
			dispatch(loadUserProfile(userId));
		}
	}, []);

	return (
		<React.Fragment>
			{userId !== undefined &&
				userId !== null &&
				userId.trim().length !== 0 && <ProfileNavigation userId={userId} />}
			<EvenlySizedColumnGrid>
				{userId !== undefined && (
					<CurrentWeekSalaryForecastCard userId={userId} />
				)}
				{userId !== undefined && (
					<CurrentWeekMessagesForecastCard userId={userId} />
				)}
				{userId !== undefined && (
					<CurrentAccountInformationCard userId={userId} />
				)}
				{userId !== undefined && (
					<AccountTarificationOverviewCard userId={userId} />
				)}
			</EvenlySizedColumnGrid>

			<TwoColumnsFocusLeft className="profile-overview-item-left">
				{userData?.userId !== undefined && (
					<AccountAssignmentHistoryCard userId={userData?.userId} />
				)}
			</TwoColumnsFocusLeft>
			<SingleFullWidthColumn>
				{userId !== undefined && userId !== null && (
					<MessageListCard userId={userId} />
				)}
			</SingleFullWidthColumn>
		</React.Fragment>
	);
};

export default ProfileOverviewPage;

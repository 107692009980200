import React from "react";
import "./_page-structure.scss";

export const EvenlySizedColumnGrid: React.FC<{ className?: string }> = (
	props
) => {
	return (
		<div
			className={`row--grid row--grid-4-col ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			{props.children}
		</div>
	);
};

export const TwoColumnsFocusLeft: React.FC<{ className?: string }> = (
	props
) => {
	return (
		<div
			className={`row--grid row--grid-2-col--left-large ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			{props.children}
		</div>
	);
};

export const TwoColumnsFocusRight: React.FC<{ className?: string }> = (
	props
) => {
	return (
		<div
			className={`row--grid row--grid-2-col--right-large ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			{props.children}
		</div>
	);
};

export const OneColumnGrid: React.FC<{}> = (props) => {
	return <div className="row--grid row--grid-1-col">{props.children}</div>;
};
export const SingleFullWidthColumn: React.FC<{}> = (props) => {
	return (
		<div className="row--flex row--flex-1-col row--items-centered">
			{props.children}
		</div>
	);
};

export const SingleFullWidthColumnContentCentered: React.FC<{}> = (props) => {
	return (
		<div className="row--flex row--flex-1-col row--items-centered">
			{props.children}
		</div>
	);
};

export const GridContainer: React.FC<{ className?: string }> = (props) => {
	return (
		<div
			className={`grid ${props.className !== undefined ? props.className : ""}`}
		>
			{props.children}
		</div>
	);
};

import React, { useEffect, useState } from "react";
import CardInformation from "../CardInformation/CardInformation";
import OverviewNotificationList from "../OverviewNotification/OverviewNotificationList/OverviewNotificationList";
import Card from "../ui/Card/Card";
import useHttp from "../../hooks/UseHttp";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { OverviewNotificationProps } from "../../models/Props/OverviewNotification/OverviewNotificationProps";
import LoadingAnimation from "../Loading/Loading";

const mapApiDataToNotificationList = (data: any) => {
	let resultList: OverviewNotificationProps[] = [];

	if (data === undefined || data === null) {
		return undefined;
	}

	for (const key in data) {
		let accountHistoryItem: OverviewNotificationProps = {
			icon: "recent_actors",
			title: `The account: ${data[key].accountName} has been assigned to you on:`,
			date: new Date(data[key].assignmentDate).toLocaleDateString(),
		};
		resultList = resultList.concat(accountHistoryItem);
	}

	return resultList;
};

const AccountAssignmentHistoryCard: React.FC<{ userId: string }> = (props) => {
	let { isLoading, executeGet: fetchData } = useHttp();
	let [notificationList, setNotificationList] =
		useState<OverviewNotificationProps[]>();

	const handleApiResponse = (data: any) => {
		var result = mapApiDataToNotificationList(data);

		if (result != undefined) {
			setNotificationList((previousState) => result);
		}
	};

	useEffect(() => {
		fetchData(EndpointUrls.UserAccountAssignment, handleApiResponse, [
			{ key: "userId", value: props.userId },
		]);
	}, [props.userId]);
	return (
		<Card
			theme={"cream"}
			isDashboardElement={true}
			className="overview-item__column card__medium"
		>
			<CardInformation
				variant={"full"}
				header="Account"
				title="Account assignment history"
				description=""
			></CardInformation>
			{isLoading && <LoadingAnimation />}
			{!isLoading && notificationList != undefined && (
				<OverviewNotificationList
					notifications={notificationList}
				></OverviewNotificationList>
			)}
		</Card>
	);
};

export default AccountAssignmentHistoryCard;

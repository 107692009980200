import React from "react";
import AccountsOverviewTable from "../../components/Accounts/AccountOverviewTable/AccountsOverviewTable";
import AccountStatisticsOverviewTable from "../../components/Accounts/Statistics/AccountStatisticsOverviewTable";

const AccountOverviewList: React.FC<{}> = (props) => {
	return (
		<div>
			<AccountsOverviewTable />
		</div>
	);
};

export default AccountOverviewList;

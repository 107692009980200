import { PayRangeInfo } from "../models/Api/PayRange/PayRange";
import { PlatformType } from "../models/Enums/Platform/PlaformEnum";

export const mapApiResponseToPayRange: (data: any) => PayRangeInfo | undefined =
	(data) => {
		if (data === undefined || data === null) {
			return undefined;
		}

		let platformType: PlatformType = Number.parseInt(data.platform);

		let payRange: PayRangeInfo = {
			platform: platformType,
			dayTariff: [],
			nightTariff: [],
			userId: data.userId,
		};

		if (data.dayTariff != null && data.dayTariff != undefined) {
			payRange.dayTariff = [...data.dayTariff];
		}
		if (data.nightTariff != null && data.nightTariff != undefined) {
			payRange.nightTariff = [...data.nightTariff];
		}

		return payRange;
	};

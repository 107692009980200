import {
    NotificationAlertProps,
  NotificationCardListProps,
  NotificationCardProps,
} from "../../../models/Props/Notification/NotificationCard";
import Card from "../Card/Card";
import "./_notification-card.scss";

export const NotificationAlert: React.FC<NotificationAlertProps> = (props) => {
  return (
    <div className={`notification-alert ${props.className !== undefined ? props.className : ""}`}>
      <span className="icon--outlined">notifications</span>
      <a className="notification-alert__link" href={props.notificationLink}>{props.notification}</a>
    </div>
  );
};


const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  return (
    <Card
      className="notification-card"
      theme={"white"}
      isDashboardElement={true}
    >
      <div className="notification-card__header">
        <h4 className="notification-card__header__title">
          {props.notificationTitle}
        </h4>
        <span className="icon--outlined">notifications</span>
      </div>
      <div className="notification-card__content">
        <span className="icon--outlined">{props.notificationIcon}</span>
        <p>{props.notificationName}</p>
      </div>
    </Card>
  );
};

export default NotificationCard;
export const NotificationCardList: React.FC<NotificationCardListProps> = (
  props
) => {
  return (
    <ul
      className={`notification-card__list ${
        props.className !== undefined ? props.className : ""
      }`}
    >
      {props.notificationCardList !== undefined &&
        props.notificationCardList.length > 0 &&
        props.notificationCardList.map((notification) => (
          <NotificationCard
            notificationTitle={notification.notificationName}
            notificationIcon={notification.notificationIcon}
            notificationName={notification.notificationName}
          />
        ))}
    </ul>
  );
};

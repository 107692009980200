import React, { useMemo, useReducer, useState } from "react";
import Button from "../../ui/Button/Button";
import { useEffect } from "react";
import { DailyStatsApiModel } from "../../../models/Api/Stats/DailyStatsApiModel";
import { PayloadAction } from "@reduxjs/toolkit";
import StatsEnum from "../../../models/Enums/Stats/StatsEnum";
import LoadingAnimation from "../../Loading/Loading";
import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";

import "./_stats-entry-form.scss";
import { DailyStatsProps } from "../../../models/Props/Statistics/Models";
import { mapApiResponseToDailyStatsApiModel } from "../../../Helpers/StatisticsHelpers";
import { formatDate } from "../../../Helpers/DateHelpers";

let initialState: DailyStatsApiModel = {
	weekNumber: 0,
	stats: [],
	year: new Date().getFullYear().toString(),
};

const statsReducer = (
	state: DailyStatsApiModel,
	action: PayloadAction<
		| DailyStatsApiModel
		| { key: string; value: string }
		| ApexNonAxisChartSeries
		| ApexAxisChartSeries
	>
) => {
	switch (action.type) {
		case StatsEnum.Load: {
			let newState = { ...(action.payload as DailyStatsApiModel) };
			newState.year = `${new Date().getFullYear()}`;
			return newState;
		}

		case StatsEnum.AddDayStats: {
			let newData = action.payload as { key: string; value: string };
			let newState = { ...state };
			newState.stats.find((t) => t.day == newData.key)!.dayStats =
				newData.value;
			return newState;
		}

		case StatsEnum.AddNightStats: {
			let newData = action.payload as { key: string; value: string };
			let newState = { ...state };
			newState.stats.find((t) => t.day == newData.key)!.nightStats =
				newData.value;
			return newState;
		}
		default:
			return initialState;
	}
};

const StatsEntryForm: React.FC<DailyStatsProps> = (props) => {
	let { isLoading, executeGet: fetchData, executeAdd: saveStats } = useHttp();
	let [statsState, statsDispatcher] = useReducer(statsReducer, initialState);
	let [isDataSaved, setIsDataSaved] = useState<boolean>(false);

	useEffect(() => {
		fetchData(
			EndpointUrls.DailyStats,
			(e) => {
				let apiData = mapApiResponseToDailyStatsApiModel(e);
				console.log(e, "e");
				console.log(apiData, "apiData");
				if (apiData != null) {
					statsDispatcher({ type: StatsEnum.Load, payload: apiData });
					props.exposeWeekData(apiData.weekNumber, apiData.year);
				}
			},
			[
				{ key: "date", value: formatDate(props.selectedDate) },
				{ key: "userId", value: props.userId },
			]
		);
	}, [
		useMemo(() => props.selectedDate, [props.selectedDate]),
		statsState.weekNumber,
	]);

	const saveStatsHandler = async () => {
		await saveStats(EndpointUrls.SaveDailyStats, statsState, [
			{ key: "userId", value: props.userId },
		]);

		setIsDataSaved(true);
	};

	return (
		<React.Fragment>
			<div className="stats-entry-form">
				<div className="stats-entry-form__container">
					{statsState?.stats.map((date) => {
						return (
							<React.Fragment>
								{date.day != "" && (
									<React.Fragment>
										<div className="stats-entry-form__element">
											<label
												htmlFor={date.day}
												className="stats-entry-form__label stats-entry-form__date"
											>
												<div className="stats-entry-form__day">
													{date.day}: {date.date}
												</div>
											</label>
											<input
												className="stats-entry-form__input"
												id={`${date.day}_day`}
												type="number"
												placeholder="Day Shift Stats"
												max={200}
												min={0}
												onChange={(event) => {
													setIsDataSaved(false);
													statsDispatcher({
														type: StatsEnum.AddDayStats,
														payload: {
															key: date.day,
															value: event.currentTarget.value,
														},
													});
												}}
												value={date.dayStats}
											/>
											<input
												className="stats-entry-form__input"
												id={`${date.day}_night`}
												placeholder="Night Shift Stats"
												type="number"
												max={200}
												min={0}
												onChange={(event) => {
													setIsDataSaved(false);
													statsDispatcher({
														type: StatsEnum.AddNightStats,
														payload: {
															key: date.day,
															value: event.currentTarget.value,
														},
													});
												}}
												value={date.nightStats}
											/>
										</div>
									</React.Fragment>
								)}
							</React.Fragment>
						);
					})}
				</div>
			</div>

			<Button
				theme={!isLoading && isDataSaved ? "success" : "primary"}
				onClick={saveStatsHandler}
			>
				{!isLoading && isDataSaved && (
					<span className="icon--outlined">done_outline</span>
				)}

				{isLoading && <LoadingAnimation size="large" />}

				{!isLoading && !isDataSaved && <span> Save</span>}
			</Button>
		</React.Fragment>
	);
};

export default StatsEntryForm;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserApiModel from "../models/Api/Users/UserApiModel";
import UserProfileApiModel from "../models/Api/Users/UserProfileApiModel";
import { UsersState } from "../models/State/States";
import {
	mapDataToUserApiModelList,
	mapResponseToApiModel,
} from "../Helpers/UserHelpers";

let initialState: UsersState = {
	users: [],
	userIdList: [],
	currentUser: undefined,
};

const setUsers: (state: UsersState, action: PayloadAction<any>) => void = (
	state,
	action
) => {
	let newState = { ...state };

	if (action.payload === undefined) {
		return newState;
	}

	let mappedData = mapDataToUserApiModelList(action.payload);

	let userIdList: string[] = [];
	if (mappedData !== undefined) {
		mappedData.forEach((data) => {
			userIdList = userIdList.concat(data.userId);
		});
		newState.userIdList = userIdList;
		newState.users = mappedData;
	}

	return newState;
};

const addUser: (
	state: UsersState,
	action: PayloadAction<UserApiModel>
) => void = (state, action) => {
	let newState = { ...state };
	newState.users.concat(action.payload);
	state = newState;
};

const removeUser: (state: UsersState, action: PayloadAction<string>) => void = (
	state,
	action
) => {
	let newState = { ...state };
	let userList = [...newState.users];
	let userIndex = newState.users.findIndex((t) => t.id === action.payload);
	if (userIndex > -1) {
		userList = newState.users.splice(userIndex, 1);
	}

	newState.users = [...userList];
	state = newState;
};

const setUserIdList = (state: UsersState, action: PayloadAction<string[]>) => {
	let newState = { ...state };
	newState.userIdList?.concat(action.payload);
	state = newState;
};

const setApiDataToActiveUser = (
	state: UsersState,
	action: PayloadAction<any>
) => {
	let newState = { ...state };

	let mappedData = mapResponseToApiModel(action.payload);
	if (mappedData !== null && mappedData !== undefined) {
		newState.currentUser = { ...mappedData };
	}

	state = newState;
	return state;
};

const updateCurrentActiveUser = (
	state: UsersState,
	action: PayloadAction<UserApiModel>
) => {
	let newState = { ...state };
	newState.currentUser = { ...action.payload };
	state = newState;
	return state;
};

const updateCurrentActiveUserProfile = (
	state: UsersState,
	action: PayloadAction<UserProfileApiModel>
) => {
	let newState = { ...state };
	if (newState.currentUser === undefined) {
		return state;
	}

	newState.currentUser.profile = { ...action.payload };
	state = newState;
};

const setCurrentUserProfile = (
	state: UsersState,
	action: PayloadAction<any>
) => {
	let newState = { ...state };
	let mappedData = mapResponseToApiModel(action.payload);

	if (mappedData !== null && mappedData !== undefined) {
		newState.currentProfile = { ...mappedData };
	}
	state = newState;

	return state;
};

const usersSlice = createSlice({
	initialState: initialState,
	name: "users",
	reducers: {
		setUsers,
		addUser,
		removeUser,
		setUserIdList,
		setApiDataToActiveUser,
		updateCurrentActiveUser,
		updateCurrentActiveUserProfile,
		setCurrentUserProfile,
	},
});

export const usersSliceActions = usersSlice.actions;

export default usersSlice;

import React, { HtmlHTMLAttributes, useState } from "react";
import {
  TableItemActionDropDownProps,
  TableRowWithSliderProps,
} from "../../models/Props/Table/Model";
import "./_table.scss";

export const TableHeader: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (
  props
) => {
  return (
    <div
      className={`table__header ${
        props.className != undefined ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export const TableRow: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (
  props
) => {
  return (
    <div
      className={`table__row ${
        props.className != undefined ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export const TableRowWithSlider: React.FC<TableRowWithSliderProps> = (
  props
) => {
  const [showActions, setShowActions] = useState<boolean>(false);

  let displayMode = "";
  switch (props.showSliderOn) {
    case "both":
      displayMode = "";
      break;
    case "desktop":
      displayMode = "desktop";
      break;
    case "mobile":
      displayMode = "mobile";
      break;
  }

  const showActionHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowActions((prev) => !prev);
  };
  return (
    <div
      className={`table__row ${
        props.className != undefined ? props.className : ""
      } `}
    >
      <div
        className={`table__row__actions ${
          showActions ? "table__row__actions--slide" : ""
        } ${displayMode}`}
      >
        {props.sliderContent}
        <span
          className={`icon--outlined expand-right ${showActions ? "show" : ""}`}
          onClick={showActionHandler}
        >
          chevron_right
        </span>
      </div>
      {props.children}
      <TableItem className={`${displayMode}`}>
        <span
          className={`icon--outlined mobile expand-left ${
            showActions ? "not-show" : ""
          }`}
          onClick={showActionHandler}
        >
          chevron_left
        </span>
      </TableItem>
    </div>
  );
};

export const TableItemActionDropDown: React.FC<TableItemActionDropDownProps> = (
  props
) => {
  const [isDropped, setIsDropped] = useState<boolean>(false);
  let displayMode = "";
  switch (props.actionDroppedOn) {
    case "both":
      displayMode = "";
      break;
    case "desktop":
      displayMode = "desktop";
      break;
    case "mobile":
      displayMode = "mobile";
      break;
  }
  const dropdownHandler = (event: React.MouseEvent<HTMLAnchorElement>)=>  {
	event.preventDefault(); 
	setIsDropped((prevState) => !prevState); 
   }; 
  return (
    <div className="manage-table__actions-dropdown__container">
      <a
        href="#"
        onClick={dropdownHandler}
        className="button-icon"
      >
        <span className="icon--outlined">more_vert</span>
      </a>

      <div
        className={`manage-table__actions-dropdown__content ${
         isDropped ? "active" : ""
        } ${displayMode}`}
      >
        {props.actionContent}
      </div>
    </div>
  );
};
export const TableItem: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (
  props
) => {
  return (
    <div
      className={`table__item ${
        props.className != undefined ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

const Table: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
      className={`table ${props.className != undefined ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
};

export default Table;

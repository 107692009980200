import React, { useEffect, useState } from "react";
import { mapResponseToAccountOverviewDetailsApiModelList } from "../../../Helpers/AccountHelpers";
import { formatDate } from "../../../Helpers/DateHelpers";
import useHttp from "../../../hooks/UseHttp";
import AccountOverviewDetailsApiModel from "../../../models/Api/Accounts/AccountOverviewDetailsApiModel";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import CardInformation from "../../CardInformation/CardInformation";
import LoadingAnimation from "../../Loading/Loading";
import Table, { TableHeader, TableItem, TableRow } from "../../Tables/Table";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import ErrorNotification from "../../ui/ErrorNotification/ErrorNotification";

import "./_account-overview-table.scss";

const AccountsOverviewTable: React.FC<{}> = (props) => {
	let [selectedDate, setSelectedDate] = useState<string>();
	let { isLoading, executeGet: fetchStats } = useHttp();
	let [accountOverviewList, setAccountOverviewList] =
		useState<AccountOverviewDetailsApiModel[]>();
	let headerElements: string[] = [
		"Week number",
		"Account Name",
		"Account Type",
		"Number of message",
		"Payment rate",
		"Weekly payment",
		"Actions",
	];

	useEffect(() => {
		if (selectedDate != undefined) {
			fetchStats(
				EndpointUrls.AccountStatisticsOverview,
				(apiData) => {
					var mappedData =
						mapResponseToAccountOverviewDetailsApiModelList(apiData);
					setAccountOverviewList((prev) => mappedData);
				},
				[{ key: "date", value: formatDate(new Date(selectedDate)) }]
			);
		}
	}, [selectedDate]);

	return (
		<Card theme={"white"} isDashboardElement={true}>
			<CardInformation
				variant="fullGrid"
				title="Current week salary"
				header="Salary"
				headerCallToActionElement={
					<div>
						<input
							type="date"
							id="date"
							onChange={(event) => {
								setSelectedDate(event.currentTarget.value);
							}}
						></input>
					</div>
				}
				description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi sint tempora, molestiae dolor laboriosam officia facilis corporis ab iusto, ea suscipit delectus asperiores quos omnis atque aperiam architecto blanditiis unde."
			></CardInformation>
			{isLoading && <LoadingAnimation />}
			{!isLoading &&
				(accountOverviewList === undefined ||
					accountOverviewList === null ||
					accountOverviewList.length === 0) && (
					<ErrorNotification description="There is no data available for the selected period" />
				)}

			{!isLoading &&
				accountOverviewList !== undefined &&
				accountOverviewList !== null &&
				accountOverviewList.length > 0 && (
					<Table className="accounts-overview-statistics-table">
						<TableHeader className="accounts-overview-statistics-table__header">
							{headerElements.map((t) => (
								<TableItem key={Math.random()}>{t}</TableItem>
							))}
						</TableHeader>
						{accountOverviewList !== undefined &&
							accountOverviewList.length > 0 &&
							accountOverviewList.map((overview) => {
								let profit: number =
									+overview.totalEarnings - +overview.totalSalary;
								return (
									<TableRow
										key={Math.random()}
										className="accounts-overview-statistics-table__row"
									>
										<TableItem>{overview.weekNumber}</TableItem>
										<TableItem>{overview.accountName}</TableItem>
										<TableItem>{overview.accountTypeName}</TableItem>
										<TableItem>{overview.totalAmountOfMessagesSent}</TableItem>
										<TableItem>
											<span className="text-type--price--eur">
												{overview.applicableTariff}
											</span>
										</TableItem>
										<TableItem>
											{" "}
											<span className="text-type--price--eur">
												{overview.totalEarnings !== null &&
												overview.totalEarnings !== undefined
													? overview.totalEarnings.toFixed(2)
													: 0}
											</span>
										</TableItem>
										<TableItem>
											<Button type="button" theme="secondary">
												View Details
											</Button>
										</TableItem>
									</TableRow>
								);
							})}
					</Table>
				)}
		</Card>
	);
};

export default AccountsOverviewTable;

import React from "react";
import {
	BreadcrumbsListProps,
	BreadcrumbsProps,
} from "../../../models/Props/Breadcrumbs/Breadcrumbs";
import "./_breadcrumbs.scss";

export const Breadcrumb: React.FC<BreadcrumbsProps> = (props) => {
	return (
		<div className="breadcrumb">
			<a className="breadcrumb__link" href={props.crumbLink}>
				{props.pageTitle}
			</a>
			<span className="icon--outlined ">chevron_right</span>
		</div>
	);
};
const BreadcrumbsList: React.FC<BreadcrumbsListProps> = (props) => {
	return (
		<ul className="breadcrumb--list">
			{props.crumbsList !== undefined &&
				props.crumbsList.length > 0 &&
				props.crumbsList.map((crumb) => (
					<Breadcrumb crumbLink={crumb.crumbLink} pageTitle={crumb.pageTitle} />
				))}
		</ul>
	);
};

export default BreadcrumbsList;

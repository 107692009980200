import React, {
	ButtonHTMLAttributes,
	useEffect,
	useMemo,
	useState,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/StateHooks";
import { Navigation } from "../../../models/Props/Navigation/NavigationProps";
import { logOut } from "../../../store/Actions/AuthActions";
import { useDispatch } from "react-redux";

import "./_top-navigation.scss";
import BurgerNavigation from "../BurgerNavigation/BurgerNavigation";

import { uiSliceActions } from "../../../store/UiSlice";
import Button from "../../ui/Button/Button";
import { startFlowboosterSession } from "../../../store/Actions/FlowBoosterActions";
import { PlatformType } from "../../../models/Enums/Platform/PlaformEnum";

const TopNavigation: React.FC<{}> = (props) => {
	let userProfile = useAppSelector((state) => state.users.currentUser);
	let dispatch = useDispatch();
	let navigate = useNavigate();
	let [topNavigation, setTopNavigation] = useState<Navigation[]>([]);
	const [isDropped, setIsDropped] = useState<boolean>(false);
	const isFlowboosterSessionStarted = useAppSelector(
		(state) => state.flowbooster.isSessionStarted
	);
	const isMessageLoading = useAppSelector(
		(state) => state.flowbooster.isLoading
	);
	const platform = useAppSelector((state) => state.flowbooster.platform);
	const ipAddress = useAppSelector((state) => state.flowbooster.ipAddress);
	const showDashboardMenu = useAppSelector(
		(state) => state.ui.showDashboardMenu
	);

	const logoutHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		if (userProfile !== undefined) {
			dispatch(logOut(userProfile.userId));
		}
	};
	// Here the BurgerButton Handler
	const menuHandler = (event: React.MouseEvent) => {
		event.preventDefault();
		dispatch(uiSliceActions.displayDashboardMenu(false));
		setIsDropped((previousState) => !previousState);
	};
	useEffect(() => {
		let navigations: Navigation[] = [];

		if (userProfile !== undefined && userProfile !== null) {
			if (userProfile.roles !== undefined && userProfile.roles !== null) {
				let currentRole = userProfile.roles.name.toLowerCase();

				if (
					currentRole === "operator" ||
					currentRole === "admin" ||
					currentRole === "pro_operator" ||
					currentRole === "reviewer" ||
					currentRole === "customer_role"
				) {
					if (!isFlowboosterSessionStarted) {
						if (currentRole !== "reviewer" && currentRole !== "customer_role") {
							navigations.push(
								{
									text: "Messages",
									to: `/messages/${userProfile.userId}`,
									icon: "question_answer",
								},
								{
									text: "Statistics",
									to: `/stats/messages/${userProfile.userId}`,
									icon: "insights",
								},
								{
									text: "Profile",
									to: `/profile/${userProfile.userId}/overview`,
									icon: "face",
								}
							);
						}

						if (currentRole === "reviewer") {
							navigations.push(
								{
									text: "Feedback",
									to: `/messages/${userProfile.userId}/feedback`,
									icon: "question_answer",
								},
								{
									text: "Statistics",
									to: `/stats/messages/${userProfile.userId}`,
									icon: "insights",
								},
								{
									text: "Profile",
									to: `/profile/${userProfile.userId}/overview`,
									icon: "face",
								}
							);
						}
						if (currentRole === "admin") {
							navigations.push({
								text: "Feedback",
								to: `/messages/${userProfile.userId}/feedback`,
								icon: "question_answer",
							});
						}
						if (currentRole === "customer_role") {
							navigations.push(
								{
									text: "Home",
									to: `/customer/${userProfile.userId}/dashboard`,
									icon: "home",
								},
								{
									text: "Manage operator",
									to: `/customer/${userProfile.userId}/operators`,
									icon: "people_outline",
								},
								{
									text: "Manage accounts",
									to: `/customer/${userProfile.userId}/accounts`,
									icon: "rocket",
								}
							);
						}
					} else {
						if (currentRole !== "reviewer" && currentRole !== "customer_role") {
							navigations.push({
								text: "Messages",
								to: `/messages/${userProfile.userId}`,
								icon: "question_answer",
							});
						} else {
							navigations.push({
								text: "Feedback",
								to: `/messages/${userProfile.userId}/feedback`,
								icon: "question_answer",
							});
						}
					}
				}
			}
		}

		if (!isFlowboosterSessionStarted) {
			navigations.push({
				text: "Logout",
				to: "/logout",
				icon: "logout",
				onClick: logoutHandler,
			});
		}

		setTopNavigation((previousState) => navigations);
	}, [userProfile?.userId, isFlowboosterSessionStarted]);

	let count: number = 0;

	const stopSessionHandler = (event: React.MouseEvent) => {
		event.preventDefault();
		setIsDropped(false);
		dispatch(uiSliceActions.displayDashboardMenu(false));
		if (userProfile !== undefined) {
			dispatch(
				startFlowboosterSession({
					platform: PlatformType[platform],
					ipAddress: ipAddress,
					isLogin: false,
					userId: userProfile.id,
				})
			);
		}
	};

	return (
		<React.Fragment>
			<div className="top-navigation__navigation ">
				<nav className="top-navigation__nav-list-container">
					<ul
						className={`top-navigation__navigation__list ${
							isDropped || showDashboardMenu
								? `top-navigation__navigation__list--alternate`
								: ""
						}`}
					>
						<li
							className="top-navigation__navigation__list__item top-navigation__navigation__list__item--sticky-left"
							onClick={() => {
								dispatch(
									uiSliceActions.displayDashboardMenu(!showDashboardMenu)
								);
								setIsDropped(false);
							}}
						>
							<div
								className={`top-navigation__navigation__link ${
									isDropped || showDashboardMenu
										? "top-navigation__navigation__link--alternate"
										: ""
								}`}
							>
								<span className="icon--filled">space_dashboard</span>
							</div>
						</li>
						<div className="top-navigation__navigation__list--left desktop">
							{!isFlowboosterSessionStarted &&
								topNavigation.map((navigation) => (
									<li
										key={count++}
										className="top-navigation__navigation__list__item"
									>
										<NavLink
											key={count++}
											to={navigation.to}
											className={({ isActive }) =>
												!isActive
													? "top-navigation__navigation__link "
													: "top-navigation__navigation__link top-navigation__navigation__link--active"
											}
											onClick={(event) => {
												dispatch(uiSliceActions.displayDashboardMenu(false));
												if (navigation.onClick !== undefined) {
													navigation.onClick(event);
												}
											}}
										>
											<span className="icon icon--outlined">
												{navigation.icon}
											</span>
											<span>{navigation.text}</span>
										</NavLink>
									</li>
								))}
						</div>

						{isFlowboosterSessionStarted && (
							<li className="top-navigation__navigation__list__item desktop">
								<Button
									theme={"warning"}
									onClick={stopSessionHandler}
									className={`${isMessageLoading ? "button--disabled" : ""}`}
									disabled={isMessageLoading}
								>
									<span className="icon--filled">front_hand</span>
									<span>Stop Session</span>
								</Button>
							</li>
						)}
						<li
							className="top-navigation__navigation__list__item mobile"
							onClick={menuHandler}
						>
							<BurgerNavigation
								isDropped={isDropped || showDashboardMenu}
								className={`top-navigation__navigation__link ${
									isDropped || showDashboardMenu
										? "top-navigation__navigation__link--alternate"
										: ""
								}`}
							/>
						</li>
					</ul>
					<ul
						className={`burger-navigation-menu ${`${
							isDropped ? "burger-navigation-menu__drop" : ""
						}`}`}
					>
						{!isFlowboosterSessionStarted &&
							topNavigation.map((navigation) => (
								<li
									key={count++}
									className="burger-navigation-menu__navigation__list__item "
								>
									<NavLink
										key={count++}
										to={navigation.to}
										className={({ isActive }) =>
											!isActive
												? "burger-navigation-menu__navigation__link"
												: "burger-navigation-menu__navigation__link burger-navigation-menu__navigation__link--active"
										}
										onClick={(event) => {
											setIsDropped(false);
											dispatch(uiSliceActions.displayDashboardMenu(false));
											if (navigation.onClick !== undefined) {
												navigation.onClick(event);
											}
										}}
									>
										<span className="icon icon--outlined">
											{navigation.icon}
										</span>
										<span>{navigation.text}</span>
									</NavLink>
								</li>
							))}
						{isFlowboosterSessionStarted && (
							<li className="burger-navigation-menu__navigation__list__item  ">
								<Button
									theme={"warning"}
									onClick={stopSessionHandler}
									className={`${isMessageLoading ? "button--disabled" : ""}`}
									disabled={isMessageLoading}
								>
									<span className="icon--filled">front_hand</span>
									<span>Stop Session</span>
								</Button>
							</li>
						)}
					</ul>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default TopNavigation;

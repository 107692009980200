import React, { useEffect, useState } from "react";
import { OverviewTableDataProps } from "../../models/Props/OverviewTable/OverviewTableProps";
import CardInformation from "../CardInformation/CardInformation";
import OverviewTable from "../OverviewTable/OverviewTable";
import useHttp from "../../hooks/UseHttp";
import Card from "../ui/Card/Card";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import UserSalary from "../../models/Api/Salary/UserSalary";
import { mapApiDataToUserSalaryModelList } from "../../Helpers/SalaryHelper";
import LoadingAnimation from "../Loading/Loading";
import ErrorNotification from "../ui/ErrorNotification/ErrorNotification";

const UserSalaryHistoryBlock: React.FC<{ userId: string }> = (props) => {
	let [salaryData, setSalaryData] = useState<UserSalary[]>();
	let { isLoading, executeGet: fetchData } = useHttp();

	const handleApiResponse = (data: any) => {
		let mappedResponse = mapApiDataToUserSalaryModelList(data);
		if (mappedResponse != undefined) {
			setSalaryData((previousState) => mappedResponse);
		}
	};

	useEffect(() => {
		fetchData(EndpointUrls.WeeklySalary, handleApiResponse, [
			{ key: "userId", value: props.userId },
		]);
	}, [props.userId]);

	return (
		<Card
			theme={"white"}
			isDashboardElement={true}
			className="overview-item__column"
		>
			<CardInformation
				variant="fullGrid"
				title="Salary History Table"
				header="Salary"
				description=""
			></CardInformation>
			{isLoading && <LoadingAnimation />}
			{!isLoading &&
				(salaryData == undefined ||
					salaryData == null ||
					salaryData.length == 0) && (
					<ErrorNotification description="There is no data available for the selected period" />
				)}
			{!isLoading && salaryData != undefined && salaryData != null && (
				<OverviewTable
					size={6}
					header={[
						{element: "Week number"},
						{element: "Total Messages Sent"},
						{element: "Pay amount (Day Shift)"},
						{element: "Pay amount (Night Shift)"},
						{element: "Total Payout"},
						{element: "Payment Status"},
					]}
					tableData={salaryData.map((salary) => {
						let totalNightShiftSalary: number = 0;
						let totalDayShiftSalary: number = 0;

						let totalMessageSent: number = 0;

						salary.salary.forEach((dailyStats) => {
							totalNightShiftSalary += +dailyStats.nightSalary;
							totalDayShiftSalary += +dailyStats.daySalary;
							totalMessageSent += +dailyStats.dayStats + +dailyStats.nightStats;
						});

						let totalSalary: number =
							totalNightShiftSalary + totalDayShiftSalary;
						let overviewMessageDataProp: OverviewTableDataProps = {
							data: [
								{element: salary.weekNumber.toString()},
								{element: totalMessageSent.toString()},
								{element: totalDayShiftSalary.toString()},
								{element: totalNightShiftSalary.toString()},
								{element: totalSalary.toString()}, 
								{element: salary.isPaid ? (
									<span className="icon--outlined status-text--success">
										task_alt
									</span>
								) : (
									<span className="icon--outlined status-text--failed">
										highlight_off
									</span>
								)},
							],
						};
						return overviewMessageDataProp;
					})}
					display="spaceEvenly"
				></OverviewTable>
			)}
		</Card>
	);
};

export default UserSalaryHistoryBlock;

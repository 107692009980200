import React, { useEffect, useMemo, useState } from "react";
import { mapApiToCurrencyExchangeModelApi } from "../../Helpers/CurrencyExchangeHelpers";
import useHttp from "../../hooks/UseHttp";
import { CurrencyExchangeModelApi } from "../../models/Api/Forex/CurrencyExchangeModelApi";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import CardInformation from "../CardInformation/CardInformation";
import { FormRow } from "../Forms/FormElements/FormStructure";
import { BasicInput } from "../Forms/FormInputs/BasicInputs";
import Table from "../Tables/Table";
import Card from "../ui/Card/Card";
import UpdateForexButton from "./UpdateForexButton";

const ForexDetailsView: React.FC<{}> = (props) => {
	let { isLoading, executeGet: fetchCurrencyData } = useHttp();
	let [exchangeRates, setExchangeRates] = useState<CurrencyExchangeModelApi>();
	let [eur, setEur] = useState<number>(1);
	let [mga, setMga] = useState<number>();

	useEffect(() => {
		if (exchangeRates != undefined) {
			let mgaCurrencyValue = exchangeRates!.rates.find(
				(t) => t.key === "MGA"
			)!.value;

			if (mgaCurrencyValue !== undefined && mgaCurrencyValue !== null) {
				setMga((prev) => eur * mgaCurrencyValue);
			}
		}
		fetchCurrencyData(EndpointUrls.ExchangeRates, (apiData) => {
			setExchangeRates((prev) => mapApiToCurrencyExchangeModelApi(apiData));
		});
	}, [exchangeRates?.rates.length]);

	const calculateMGA = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		if (event.currentTarget !== null && event.currentTarget !== undefined) {
			let value: number = Number.parseFloat(event.currentTarget.value);
			setEur(value);

			let mgaCurrencyValue = exchangeRates!.rates.find(
				(t) => t.key === "MGA"
			)!.value;

			if (mgaCurrencyValue !== undefined && mgaCurrencyValue !== null) {
				setMga((prev) => value * mgaCurrencyValue);
			}
		}
	};

	const calculeEUR = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.preventDefault();

		if (event.currentTarget !== null && event.currentTarget !== undefined) {
			let value: number = Number.parseFloat(event.currentTarget.value);
			setMga(value);

			let mgaCurrencyValue = exchangeRates!.rates.find(
				(t) => t.key === "MGA"
			)!.value;

			if (mgaCurrencyValue !== undefined && mgaCurrencyValue !== null) {
				setEur((prev) => value / mgaCurrencyValue);
			}
		}
	};

	return (
		<Card theme={"white"} isDashboardElement={false}>
			<CardInformation
				variant={"fullGrid"}
				header="Forex"
				headerCallToActionElement={<UpdateForexButton />}
			></CardInformation>
			<FormRow type={"grid"}>
				<BasicInput
					value={eur.toFixed(2)}
					placeholder="EUR"
					type="number"
					step="0.01"
					min="0.01"
					onChange={calculateMGA}
				></BasicInput>
				<BasicInput
					value={mga?.toFixed(2)}
					placeholder="MGA"
					type="number"
					onChange={calculeEUR}
				></BasicInput>
			</FormRow>
		</Card>
	);
};

export default ForexDetailsView;

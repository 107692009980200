import { authSliceAction } from "../AuthSlice";
import useCookies from "../../hooks/UseCookies";
import { executeGet } from "./AsyncActions";
import EndpointUrls from "../../models/Enums/Api/Endpoints";

export const logOut = (userId: string) => {
	return async (dispatch: any) => {
		let { deleteCookie } = useCookies("");
		await dispatch(
			executeGet(
				EndpointUrls.Logout,
				(apiData) => {
					if (apiData.isSuccessful) {
						deleteCookie("GLOBAL_T");

						dispatch(
							authSliceAction.setLogoutState({
								isLoggedIn: false,
								role: "",
								token: "",
								userId: "",
							})
						);
					}
				},
				[{ key: "userId", value: userId }]
			)
		);
	};
};

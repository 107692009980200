import { CustomerDashboardProps } from "../../../models/Props/Customers/CustomerDashboardProps";
import Card from "../../ui/Card/Card";

export const CustomerCardView: React.FC<CustomerDashboardProps> = (props) => {
	let classes = "";
	switch (props.textColor) {
		case "blue":
			classes = "blue";
			break;
		case "yellow":
			classes = "yellow";
			break;
		case "green":
			classes = "green";
			break;
		default:
			classes = "blue";
			break;
	}

	return (
		<Card
			className="customer-card-view"
			theme={"white"}
			isDashboardElement={true}
		>
			<span className={`customer-card-view__account ${classes} mobile`}>
				{props.cardAccount}
			</span>
			<span className={`customer-card-view__account desktop`}>
				{props.cardAccount}
			</span>
			<h5 className={`customer-card-view__title ${classes} mobile`}>
				{props.cardTitle}
			</h5>
			<p className="customer-card-view__description desktop">
				{props.description}
			</p>
			<a className="customer-card-view__link mobile" href={props.cardLink}>
				View All
			</a>
			<a className="customer-card-view__link desktop" href={props.cardLink}>
				View All
			</a>
		</Card>
	);
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import { loadExtraCostsList } from "../../../store/Actions/ExtraCostsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import CardInformation from "../../CardInformation/CardInformation";
import { FormRow } from "../../Forms/FormElements/FormStructure";
import LoadingAnimation from "../../Loading/Loading";
import Table, { TableHeader, TableRow } from "../../Tables/Table";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";

import "./_extra-costs-table.scss";

const ExtraCostOverviewTable: React.FC<{}> = (props) => {
  let extraCostsList = useAppSelector(
    (state) => state.extraCosts.extraCostsList
  );
  let isLoading = useAppSelector((state) => state.extraCosts.isLoading);
  let dispatch = useDispatch();

  useEffect(() => {
    if (extraCostsList === undefined || extraCostsList.length === 0) {
      dispatch(loadExtraCostsList());
    }
  }, []);

  let count: number = 0;

  return (
    <Card theme={"white"} isDashboardElement={false}>
      <CardInformation
        variant={"fullGrid"}
        header="Extra cost"
        headerCallToActionElement={
          <React.Fragment>
            <FormRow type={"grid"}>
              <Button
                theme={"primary"}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(uiSliceActions.showModal(true));
                }}
              >
                Add Extra cost
              </Button>
              <input type="date" />
            </FormRow>
          </React.Fragment>
        }
      ></CardInformation>

      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <Table className="extra-costs-table">
          <TableHeader className="extra-costs-table__header">
            <span>id</span>
            <span>Designation</span>
            <span>For</span>
            <span>Date</span>
            <span>Amount</span>
            <span>Actions</span>
          </TableHeader>
          {extraCostsList !== undefined &&
            extraCostsList.length !== 0 &&
            extraCostsList.map((extraCost) => (
              <TableRow key={count++} className="extra-costs-table__row">
                <span>{extraCost.Id}</span>
                <span>{extraCost.title}</span>
                <span>{extraCost.designation}</span>
                <span>
                  {extraCost.date !== undefined &&
                    new Date(extraCost.date).toDateString()}
                </span>
                <span className="text-type--price--eur">
                  {extraCost.amount}
                </span>
                <span>Actions</span>
              </TableRow>
            ))}
        </Table>
      )}
    </Card>
  );
};

export default ExtraCostOverviewTable;

import React from "react";
import PayRangeInputProps from "../../../models/Props/PayRangeInput/PayRangeInputProps";

const PayRangeInput: React.FC<PayRangeInputProps> = (props) => {
  return (
    <div>
      <div className="grid-2-col">
        <label
          htmlFor={`starting-range_${props.counter}`}
          className="form__label"
        >
          Starting range:
        </label>
        <input
          type="number"
          id={`starting-range_${props.counter}`}
          className="form__input"
          onChange={props.onStartingRangeChange}
          value={props.tariffRangeValue.startRange}
        ></input>
      </div>
      <div className="grid-2-col">
        <label htmlFor={`end-range_${props.counter}`} className="form__label">
          Ending range:
        </label>
        <input
          type="number"
          id={`end-range_${props.counter}`}
          className="form__input"
          onChange={props.onEndRangeChange}
          value={props.tariffRangeValue.endRange}
        ></input>
      </div>
      <div className="grid-2-col">
        <label htmlFor={`tariff_${props.counter}`} className="form__label">
          Tariff:
        </label>
        <input
          type="number"
          min="0.0001"
          step="0.0001"
          id={`tariff_${props.counter}`}
          className="form__input"
          onChange={props.onTariffChange}
          value={props.tariffRangeValue.tariff}
        ></input>
      </div>
    </div>
  );
};

export default PayRangeInput;

import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import { uiSliceActions } from "../../../store/UiSlice";
import Modal from "../../Modal/Modal";
import DashboardContainer, {
	DashboardMainContent,
} from "../DashboardContainer/DashboardContainer";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
const CustomerLayout: React.FC<{}> = (props) => {
	const error = useAppSelector((state) => state.ui.error);
	const client = useAppSelector((state) => state.clients.currentClient);
	const dispatch = useDispatch();

	return (
		<div className="wrapper">
			{error.isError && (
				<Modal
					title="Something went wrong"
					onClose={() => {
						dispatch(uiSliceActions.resetErrorState());
					}}
				>
					<span>{error.errorMessage}</span>
				</Modal>
			)}

			<div className="wrapper__main-container">
				{client !== undefined && client.isConfirmed && (
					<Header
						logo="../../Assets/logo/lsc_logo.png"
						className="wrapper__main-container__header"
					/>
				)}

				<DashboardContainer className="wrapper__main-container__main">
					<DashboardMainContent>{props.children}</DashboardMainContent>
				</DashboardContainer>
				<Footer className="wrapper__main-container__footer" />
			</div>
		</div>
	);
};
export default CustomerLayout;

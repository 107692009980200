import React, {
	useState,
	useEffect,
	useRef,
	HTMLInputTypeAttribute,
} from "react";
import FormContainer from "../../Forms/FormContainer";
import Button from "../../ui/Button/Button";
import { InputPropsBase } from "../../../models/Props/Inputs/InputProps";
import { ReactReduxContext, useDispatch } from "react-redux";
import { authSliceAction } from "../../../store/AuthSlice";
import { useNavigate } from "react-router-dom";

import useHttp from "../../../hooks/UseHttp";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { LoginResponse } from "../../../models/Authentication/LoginModel";
import { usersSliceActions } from "../../../store/UsersSlice";
import useCookies from "../../../hooks/UseCookies";
import { FloatingLabelInput } from "../../Forms/FormInputs/FloatingInputs";

import "./_login.scss";
import { BasicInput } from "../../Forms/FormInputs/BasicInputs";
import { loadUser } from "../../../store/Actions/UsersActions";

const LoginFormAction: React.FC<{ isFormValid: boolean }> = (props) => {
	let [isFormValid, setIsFormValid] = useState<boolean>(props.isFormValid);

	useEffect(() => {
		setIsFormValid(props.isFormValid);
	}, [props.isFormValid]);

	return (
		<React.Fragment>
			<Button
				type="submit"
				theme="primary"
				className={`login-form__button ${
					!props.isFormValid ? "button--disabled" : ""
				}`}
				disabled={!props.isFormValid}
			>
				<span className="icon--outlined">login</span>
				<span>Login</span>
			</Button>
		</React.Fragment>
	);
};
const LoginForm: React.FC<{}> = (props) => {
	let { createCookie, deleteCookie } = useCookies("");
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [formErrorMessage, setFormErrorMessage] = useState<string>("");
	const [hasPasswordFieldError, setHasPasswordFieldError] =
		useState<boolean>(false);
	const [hasUsernameFieldError, setHasUsernameFieldError] =
		useState<boolean>(false);
	// const [isLoginSuccessful, setIsLoginSuccessful] = useState<boolean>(false);

	const loginDispatch = useDispatch();
	const userDispatch = useDispatch();
	let navigate = useNavigate();
	let { isLoading, executePost: identifyUser } = useHttp();

	let formInputElements: InputPropsBase[] = [
		{
			id: "email",
			placeholder: "Email address",
			type: "text",
			label: "Email address",
			value: username,
			hasError: hasUsernameFieldError,
			errorMessage: "Please enter your e-mail as username",
			onChange: (event) => {
				if (event != undefined && event.currentTarget != undefined) {
					setUsername(event.currentTarget.value);

					if (event.currentTarget.value.trim().length == 0) {
						setHasUsernameFieldError((prev) => true);
					} else {
						setHasUsernameFieldError(false);
					}
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.currentTarget != undefined) {
					if (event.currentTarget.value.trim().length == 0) {
						setHasUsernameFieldError((prev) => true);
					} else {
						setHasUsernameFieldError(false);
					}
				}
			},
			key: "email",
			required: true,
		},
		{
			id: "password",
			placeholder: "Password",
			type: "password",
			label: "Password",
			value: password,
			hasError: hasPasswordFieldError,
			errorMessage: "The password field is empty",
			onChange: (event) => {
				if (event != undefined && event.currentTarget != undefined) {
					setPassword(event.currentTarget.value.trim());
					console.log("here");
					if (event.currentTarget.value.trim().length == 0) {
						setHasPasswordFieldError((prev) => true);
					} else {
						setHasPasswordFieldError(false);
					}
				}
			},
			onBlur: (event) => {
				if (event != undefined && event.currentTarget != undefined) {
					console.log(event.currentTarget.value, "password field on blur");
					if (event.currentTarget.value.trim().length === 0) {
						console.log("password field has error");
						setHasPasswordFieldError((prev) => true);
					} else {
						console.log("password field has no error");
						setHasPasswordFieldError(false);
					}
				}
			},
			key: "password",
			required: true,
		},
	];

	console.log(hasPasswordFieldError, "hasPasswordFieldError");

	const submitFormHandler: (event: React.FormEvent<HTMLFormElement>) => void =
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			let isLoginSuccessful: boolean = false;
			let isClient: string | undefined = "";
			let userId: string | undefined = "";
			let errorMessage: string = "";

			if (!hasPasswordFieldError && !hasUsernameFieldError) {
				await identifyUser(
					EndpointUrls.Login,
					{
						username: username,
						password: password,
						isClientLogin: checked,
					},
					(apiData: LoginResponse) => {
						let loginResponse: LoginResponse = { ...apiData };

						isLoginSuccessful = loginResponse.isOk;
						isClient = loginResponse.authenticatedUserInformation?.role;
						userId = loginResponse.authenticatedUserInformation?.userId;
						if (
							loginResponse.isOk &&
							loginResponse.expirationTime != undefined
						) {
							//set the current auth state in redux

							if (loginResponse.authenticatedUserInformation != null) {
								//make sure that the cookie is always created upon login

								createCookie(
									"GLOBAL_T",
									loginResponse.authenticatedUserInformation?.userId,
									loginResponse.expirationTime
								);
								loginDispatch(
									authSliceAction.setLoginState({
										isLoggedIn: true,
										role: loginResponse.authenticatedUserInformation?.role,
										token: "someString",
										userId: loginResponse.userId,
									})
								);

								//set the current user state in redux
								if (loginResponse.userId !== undefined) {
									userDispatch(
										loadUser(
											loginResponse.userId,
											loginResponse.authenticatedUserInformation.role.toUpperCase() ===
												"CUSTOMER_ROLE"
										)
									);
								}
							}
						} else {
							if (
								loginResponse.errorMessage != null &&
								loginResponse.errorMessage != undefined
							) {
								errorMessage = loginResponse!.errorMessage;
								setFormErrorMessage((previousState) => errorMessage);
							}
						}
					}
				);
				console.log(isLoading, "isloading");
				console.log(
					formErrorMessage.trim().length,
					"formErrorMessage.trim().length"
				);
				console.log(formErrorMessage);
				console.log(isLoginSuccessful, "isLoginSuccessful");

				if (
					!isLoading &&
					errorMessage.trim().length === 0 &&
					isLoginSuccessful &&
					isClient.toUpperCase() !== "CUSTOMER_ROLE"
				) {
					navigate("/dashboard");
				} else if (
					!isLoading &&
					errorMessage.trim().length === 0 &&
					isLoginSuccessful &&
					isClient !== undefined &&
					isClient.toUpperCase() === "CUSTOMER_ROLE" &&
					userId !== undefined
				) {
					navigate(`/customer/${userId}/dashboard`);
				}
			}
		};
	let checked: boolean;
	const checkedVerification = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.checked) {
			checked = true;
			console.log(checked);
		} else {
			checked = false;
			console.log(checked);
		}
	};

	useEffect(() => {
		deleteCookie("GLOBAL_T");
	}, []);

	return (
		<React.Fragment>
			<FormContainer
				className="login-form"
				callToActionClassName="login-form__form-actions-container"
				formTitle=""
				onSubmit={submitFormHandler}
				formActions={
					<LoginFormAction
						isFormValid={!hasPasswordFieldError && !hasUsernameFieldError}
					/>
				}
			>
				{formInputElements.map((input) => (
					<React.Fragment>
						<div className="mobile">
							<FloatingLabelInput
								key={input.key}
								id={input.id}
								placeholder={input.placeholder}
								type={input.type}
								onChange={input.onChange}
								onBlur={input.onBlur}
								value={input.value}
								required={input.required}
								hasError={input.hasError}
								errorMessage={input.errorMessage}
							>
								{input.label}
							</FloatingLabelInput>
						</div>
						<div className="desktop">
							<BasicInput
								key={input.key}
								id={input.id}
								placeholder={input.placeholder}
								type={input.type}
								onChange={input.onChange}
								onBlur={input.onBlur}
								value={input.value}
								required={input.required}
								hasError={input.hasError}
								errorMessage={input.errorMessage}
							/>
						</div>
					</React.Fragment>
				))}
				<div className="login-form__additional-section">
					<label htmlFor="is-client">Are you a client</label>
					<input
						type="checkbox"
						id="is-client"
						onChange={checkedVerification}
					/>
				</div>
				<div
					className={`form__error-message ${
						formErrorMessage != undefined &&
						formErrorMessage != null &&
						formErrorMessage.trim().length > 0
							? "form__error-message--has-error"
							: ""
					}`}
				>
					{formErrorMessage}
				</div>
			</FormContainer>
		</React.Fragment>
	);
};

export default LoginForm;

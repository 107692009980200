import React from "react";
import Card from "../components/ui/Card/Card";
import DashboardLayout from "../components/Layouts/DashboardLayout/DashboardLayout";
import { SingleFullWidthColumnContentCentered } from "../components/Layouts/PageStructure/GridColumns";

const NotFoundPage: React.FC<{}> = (props) => {
  return (
    <DashboardLayout>
      <SingleFullWidthColumnContentCentered>
        <Card
          isDashboardElement={true}
          theme="white"
          className="overview-item__column"
        >
          <h3>
            Oops<span className="material-icons-outlined">report</span>
          </h3>
          <br />
          <p>
            The functionality you are looking for is not built yet! Check again
            later!
          </p>
          {/* <AmountOfMessagesSent></AmountOfMessagesSent> */}
        </Card>
      </SingleFullWidthColumnContentCentered>
    </DashboardLayout>
  );
};

export default NotFoundPage;

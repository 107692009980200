import React from "react";
import { NoteHistoryItemListProps } from "../../../models/Props/Messages/MessageNotesProps";
import Accordion from "../../List/Accordion";
import ProfileNotesHistoryItem from "./ProfileNotesHistoryItem";
import "./_profileNotes.scss";

const ProfileNotesHistoryList: React.FC<NoteHistoryItemListProps> = (props) => {
	let count = 0;
	return (
		<div className="note-history-container">
			<Accordion
				header={props.category}
				icon="+"
				className="note-history-accordion"
			>
				{props.notes !== undefined &&
					props.notes.length > 0 &&
					props.notes.map((profileNotesHistoryItemProps) => (
						<ProfileNotesHistoryItem
							key={profileNotesHistoryItemProps.id}
							{...profileNotesHistoryItemProps}
						/>
					))}
			</Accordion>
		</div>
	);
};

export default ProfileNotesHistoryList;

import React from "react";
import { NavLink } from "react-router-dom";
import { CardNavigationProps } from "../../../models/Props/Navigation/NavigationProps";

import "./_card-navigation.scss";

const CardNavigation: React.FC<CardNavigationProps> = (props) => {
	let count = 0;
	return (
		<div className="card-navigation">
			<nav>
				<ul>
					{props.navigations.map((nav) => (
						<li key={count++}>
							<NavLink
								to={nav.to}
								className={(isActive) => {
									return isActive
										? "card-navigation__link card-navigation__link--active"
										: "card-navigation__link";
								}}
							>
								{nav.text}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
};

export default CardNavigation;

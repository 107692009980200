import { mapApiDataToExtraCostsResponseModelList } from "../../Helpers/ExtraCostsHelpers";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import { extraCostSliceAction } from "../ExtraCostSlice";
import { executeGet } from "./AsyncActions";

export const loadExtraCostsList = () => {
  return (dispatch: any) => {
    dispatch(extraCostSliceAction.setIsLoading(true));
    dispatch(
      executeGet(EndpointUrls.ExtraCosts, (apiData) => {
        let extraCostsResponseList = mapApiDataToExtraCostsResponseModelList(
          apiData
        );

        if (extraCostsResponseList !== undefined) {
          dispatch(
            extraCostSliceAction.setExtraCostsList(extraCostsResponseList)
          );
        }
        dispatch(extraCostSliceAction.setIsLoading(false));
      })
    );
  };
};

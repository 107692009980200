import { isFulfilled } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/StateHooks";
import useHttp from "../../../hooks/UseHttp";
import { BaseApiResponse } from "../../../models/Api/Base/Model";
import { ClientRelationRequest } from "../../../models/Api/Customers/Models";
import { loadAccounts } from "../../../store/Actions/AccountActions";
import { loadClientList } from "../../../store/Actions/ClientsActions";
import { uiSliceActions } from "../../../store/UiSlice";
import FormContainer from "../../Forms/FormContainer";
import { FormRow } from "../../Forms/FormElements/FormStructure";
import { BasicSelectInput } from "../../Forms/FormInputs/BasicInputs";
import LoadingAnimation from "../../Loading/Loading";
import Button from "../../ui/Button/Button";

const SelectCustomerFormActions: React.FC<{
	isFormValid: boolean;
	isLoading: boolean;
}> = (props) => {
	return (
		<React.Fragment>
			<Button type="reset" theme="info" fullWidth={true}>
				<span>Cancel</span>
			</Button>
			<Button
				type="submit"
				theme="warning"
				fullWidth={true}
				className={`registration-form__submit-button ${
					props.isFormValid ? "" : "button--disabled"
				}`}
				disabled={!props.isFormValid}
			>
				{props.isLoading && <LoadingAnimation size="small" />}
				{!props.isLoading && <span>Assign</span>}
			</Button>
		</React.Fragment>
	);
};

const SelectCustomerForm: React.FC<{
	relationId: string;
	saveEndpoint: string;
}> = (props) => {
	const { isLoading, executePost: saveInformation } = useHttp();
	const [selectedCustomer, setSelectedCustomer] = useState<string>("default");
	const isFormValid =
		selectedCustomer !== "default" && selectedCustomer !== undefined;

	const onResetHandler = (event: FormEvent) => {
		event.preventDefault();
		dispatch(uiSliceActions.showModal(false));
	};
	const onSaveHandler = async (e: FormEvent) => {
		e.preventDefault();

		if (isFormValid) {
			let clientRelationRequest: ClientRelationRequest = {
				relationId: props.relationId,
				clientId: selectedCustomer,
			};
			console.log(clientRelationRequest);
			await saveInformation(
				props.saveEndpoint,
				clientRelationRequest,
				(response: BaseApiResponse) => {
					if (response.isSuccessful) {
						dispatch(loadAccounts());
						dispatch(uiSliceActions.showModal(false));
					}
				}
			);
		}
	};
	const clientsList = useAppSelector((state) => state.clients.clients);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(loadClientList());
	}, []);
	return (
		<FormContainer
			onSubmit={onSaveHandler}
			onReset={onResetHandler}
			formActions={
				<SelectCustomerFormActions
					isFormValid={isFormValid}
					isLoading={isLoading}
				/>
			}
		>
			<FormRow type={"row"}>
				<BasicSelectInput
					label="Client"
					id={"client-id"}
					required={true}
					value={selectedCustomer}
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
						event.preventDefault();
						setSelectedCustomer(event.currentTarget.value);
					}}
				>
					<option value={"default"}>Please Select</option>
					{clientsList.map((client) => (
						<option key={Math.random()} value={client.id}>
							{client.businessEmailAddress}
						</option>
					))}
				</BasicSelectInput>
			</FormRow>
		</FormContainer>
	);
};

export default SelectCustomerForm;

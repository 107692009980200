import React, { useState } from "react";
import { BasicSelectInputProps } from "../../../models/Props/Forms/BasicFormInputProps";
import {
	InputPropsBase,
	TextAreaPropsBase,
} from "../../../models/Props/Inputs/InputProps";

export const BasicSelectInput: React.FC<BasicSelectInputProps> = (props) => {
	return (
		<div className="form__input-container">
			<label
				htmlFor={props.id}
				className={`form__label ${
					props.labelClassName !== undefined ? props.labelClassName : ""
				}`}
			>
				{props.label}
			</label>
			<select
				id={props.id}
				className={`form__select ${
					props.selectClassName !== undefined ? props.selectClassName : ""
				}`}
				value={props.value}
				onChange={props.onChange}
				onClick={props.onClick}
			>
				{props.children}
			</select>
		</div>
	);
};

export const BasicInput: React.FC<InputPropsBase> = (props) => {
	const [isValid, setIsValid] = useState<boolean>(false);
	const [isTouched, setIsTouched] = useState<boolean>(false);
	const validateInput = (input: string) => {
		console.log("basic input fired");
		console.log(props, "basic input");
		if (props.validationRegex !== undefined) {
			const inputValidationRegex = new RegExp(props.validationRegex);
			let isValid = inputValidationRegex.test(input);
			console.log(isValid, "basic input is valid");
			setIsValid(isValid);
			if (props.onValidateInput !== undefined) {
				props.onValidateInput(isValid);
			}
		} else {
			setIsValid(true);
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		validateInput(event.currentTarget.value);
		if (props.onChange !== undefined) {
			props.onChange(event);
		}
	};

	const onBlurEventHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		event.preventDefault();
		validateInput(event.currentTarget.value);
		console.log(event.currentTarget.value, "basic input on blur");
		setIsTouched(true);
		if (props.onBlur !== undefined) {
			props.onBlur(event);
		}
	};

	return (
		<div className="form__input-container">
			<label
				htmlFor={props.id}
				className={`form__label ${
					props.labelClassName !== undefined ? props.labelClassName : ""
				}`}
			>
				{props.label}
			</label>
			<input
				{...props}
				id={props.id}
				className={`form__input ${
					props.inputClassName !== undefined ? props.inputClassName : ""
				} ${
					props.hasError !== undefined && props.hasError
						? "form__input--has-error"
						: ""
				} `}
				type={props.type}
				key={props.key}
				onChange={onChangeHandler}
				onBlur={onBlurEventHandler}
				value={props.value}
				required={props.required}
			/>
			{props.errorMessage !== undefined && (
				<div
					className={`form__error-message ${
						(props.hasError !== undefined && props.hasError) ||
						(!isValid && isTouched)
							? "form__error-message--has-error"
							: ""
					}`}
				>
					<p>{props.errorMessage}</p>
				</div>
			)}
		</div>
	);
};

export const BasicTextArea: React.FC<TextAreaPropsBase> = (props) => {
	return (
		<div
			className={`form__input-container ${
				props.className !== undefined ? props.className : ""
			}`}
		>
			<label
				htmlFor={props.id}
				className={`form__label ${
					props.labelClassName !== undefined ? props.labelClassName : ""
				}`}
			>
				{props.placeholder}
			</label>
			<textarea
				id={props.id}
				required={props.required}
				placeholder={props.placeholder}
				onChange={props.onChange}
				onBlur={props.onBlur}
				cols={props.cols}
				rows={props.rows}
				value={props.value}
				readOnly={props.readOnly}
				//className={props.className}
			></textarea>
			{props.errorMessage !== undefined && (
				<div
					className={`form__error-message ${
						props.hasError !== undefined && props.hasError
							? "form__error-message--has-error"
							: ""
					}`}
				>
					<p>{props.errorMessage}</p>
				</div>
			)}
		</div>
	);
};

import React, { useEffect, useState } from "react";
import {
	mapApiResponseToMessageModelList,
	mapApiResponseToUserMessageModel,
} from "../../../Helpers/MessageHelpers";
import useHttp from "../../../hooks/UseHttp";
import UserMessageApiModel from "../../../models/Api/Users/UserMessageApiModel";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import CardInformation from "../../CardInformation/CardInformation";
import LoadingAnimation from "../../Loading/Loading";
import OverviewTable from "../../OverviewTable/OverviewTable";
import Table, { TableHeader, TableItem, TableRow } from "../../Tables/Table";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/Card";
import "./_messageListCard.scss";

const MessageListCard: React.FC<{ userId: string }> = (props) => {
	const { isLoading, executeGet: fetchMessages } = useHttp();
	const [messageList, setMessageList] = useState<UserMessageApiModel[]>();
	useEffect(() => {
		fetchMessages(
			EndpointUrls.Message,
			(apiData) => {
				if (apiData !== null && apiData !== undefined) {
					var mappedData = apiData.map((message: any) =>
						mapApiResponseToUserMessageModel(message)
					);

					if (mappedData !== undefined) {
						setMessageList(mappedData);
					}
				}
			},
			[{ key: "userId", value: props.userId }]
		);
	}, []);
	return (
		<Card
			theme={"white"}
			isDashboardElement={true}
			className="overview-item__column message-list-card"
		>
			<CardInformation
				variant="fullGrid"
				title="Message History"
				header="Message"
				description="The list of messages that have been sent out."
			></CardInformation>
			<Table className="message-list-table">
				<TableHeader className="message-list-table__header">
					<TableItem>Account</TableItem>
					<TableItem>Sent Date</TableItem>
					<TableItem>Message Content</TableItem>
					<TableItem>Feedback</TableItem>
					<TableItem>Feedback Type</TableItem>
				</TableHeader>
				{isLoading && <LoadingAnimation />}
				{!isLoading && messageList !== undefined && (
					<React.Fragment>
						{messageList.map((message) => (
							<TableRow key={Math.random()} className="message-list-table__row">
								<TableItem>{message.accountName}</TableItem>
								<TableItem>
									{new Date(message.sendDate).toLocaleDateString()}-
									{new Date(message.sendDate).toLocaleTimeString()}
								</TableItem>
								<TableItem>{message.messageBody}</TableItem>
								<TableItem>{message.feedbackBody}</TableItem>
								<TableItem>{message.feedbackType}</TableItem>
							</TableRow>
						))}
					</React.Fragment>
				)}
			</Table>
		</Card>
	);
};

export default MessageListCard;

import { DateListApiModel } from "../models/Api/Dates/DateListApiModel";
import { DailyStatsApiModel } from "../models/Api/Stats/DailyStatsApiModel";
import DailyOverviewStats from "../models/Api/Users/DailyOverviewStats";
import { DynamicKeyValuePair } from "../models/General/GeneralModel";

export const mapApiResponseToDailyStatsApiModel: (
	data: DateListApiModel
) => DailyStatsApiModel | null | undefined = (data: any) => {
	if (data == undefined || data == null) {
		return null;
	}
	let result: DailyStatsApiModel = {
		weekNumber: data.weekNumber,
		stats: data.salary,
		year: data.year,
	};

	return result;
};

export const mapApiResponseToDailyOverviewStatsList: (
	apiData: any
) => undefined | DynamicKeyValuePair<string, DailyOverviewStats[]>[] = (
	apiData
) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	var mappedResponse: DynamicKeyValuePair<string, DailyOverviewStats[]>[] = [];

	for (const userId in apiData) {
		if (Object.prototype.hasOwnProperty.call(apiData, userId)) {
			const element = apiData[userId];

			let res: DynamicKeyValuePair<string, DailyOverviewStats[]> = {
				key: element[0].senderName,
				value: [],
			};
			element.forEach((el: any) => {
				const mappedDailyStats = mapApiResponseToDailyOverviewStats(el);

				if (mappedDailyStats !== undefined) {
					res.value = res.value.concat(mappedDailyStats);
				}
			});

			mappedResponse = mappedResponse.concat(res);
		}
	}

	return mappedResponse;
};

export const mapApiResponseToDailyOverviewStats: (
	apiData: any
) => undefined | DailyOverviewStats = (apiData) => {
	if (apiData === undefined || apiData === null) {
		return undefined;
	}

	var mappedResponse: DailyOverviewStats = {
		UserId: apiData.userId,
		Statistics: [],
		Date: apiData.date,
		SenderName: apiData.senderName,
	};

	if (apiData.statistics != null) {
		for (const accountKey in apiData.statistics) {
			if (
				Object.prototype.hasOwnProperty.call(apiData.statistics, accountKey)
			) {
				const element = apiData.statistics[accountKey];
				mappedResponse.Statistics = mappedResponse.Statistics.concat([
					{ key: accountKey, value: element },
				]);
			}
		}
	}

	return mappedResponse;
};

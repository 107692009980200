import React from "react";
import CardInformation from "../../CardInformation/CardInformation";
import Card from "../../ui/Card/Card";
import TarificationDetailsForm from "./TarificationDetailsForm";

const TarificationDetails: React.FC<{ tarificationItemId?: string }> = (
	props
) => {
	return (
		<Card
			theme={"white"}
			isDashboardElement={true}
			className="tarification-details-card"
		>
			<CardInformation
				variant={"full"}
				header="Tarification"
				description="To get started, click on one item on the right. If none are present, do not hesitate to add a new one."
			></CardInformation>

			<TarificationDetailsForm accountTypeId={props.tarificationItemId} />
		</Card>
	);
};

export default TarificationDetails;

import React, { useState } from "react";
import { useAppSelector } from "../../../hooks/StateHooks";
import { MessageHistoryListProps } from "../../../models/Props/Messages/MessagesHistoryProps";
import Modal from "../../Modal/Modal";
import MessagesHistoryItem from "./MessageHistoryItem";
import MessageHistoryItem from "./MessageHistoryItem";
import "./_messageHistoryList.scss";
const MessagesHistoryList: React.FC<MessageHistoryListProps> = (props) => {
	const [imagePath, setImagePath] = useState<string>();
	const showModal = useAppSelector((state) => state.ui.showModal);

	let count = 0;
	return (
		<div className="message-history-list">
			{showModal && (
				<Modal>
					<div className="message-container__image--container">
						<img className="message-container__image--popup" src={imagePath} />{" "}
					</div>
				</Modal>
			)}
			{props.messageList !== undefined &&
				props.messageList.length > 0 &&
				props.messageList.map((t) => (
					<MessagesHistoryItem
						key={count++}
						content={t.content}
						date={t.date}
						isInbound={t.isInbound}
						authorName={t.authorName}
						attachments={t.attachments}
						onDisplayImage={setImagePath}
					></MessagesHistoryItem>
				))}
		</div>
	);
};

export default MessagesHistoryList;

import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
	SideNavigationProps,
	NavigationInformation,
	Navigation,
} from "../../../models/Props/Navigation/NavigationProps";
import { uiSliceActions } from "../../../store/UiSlice";

import "./_side-navigation.scss";

export const SideNavigationItem: React.FC<Navigation> = (props) => {
	const dispatch = useDispatch();
	return (
		<li className="side-navigation__list-item">
			<NavLink
				to={props.to}
				className="side-navigation__link"
				onClick={(event) => {
					dispatch(uiSliceActions.displayDashboardMenu(false));
				}}
			>
				<span className="side-navigation__link__icon icon--filled">
					{" "}
					{props.icon}{" "}
				</span>
				<span className="side-navigation__link__text">{props.text}</span>
			</NavLink>
		</li>
	);
};

export const SideNavigationSection: React.FC<NavigationInformation> = (
	props
) => {
	let count = 0;
	return (
		<div className="side-navigation__navigation-section">
			<h3 className="side-navigation__navigation-section__title">
				{props.category}
			</h3>
			<ul>
				{props.navigations.map((navigation) => {
					count = count + 1;
					return (
						<SideNavigationItem
							key={count}
							to={navigation.to}
							icon={navigation.icon}
							text={navigation.text}
						/>
					);
				})}
			</ul>
		</div>
	);
};

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
	let count = 1;
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			<div
				className={`side-navigation__backdrop__black ${
					props.isShown ? "side-navigation__backdrop__black--active" : ""
				}`}
				onClick={(event) => {
					event.preventDefault();
					dispatch(uiSliceActions.displayDashboardMenu(false));
				}}
			></div>
			<nav
				className={`side-navigation ${
					props.isShown ? "side-navigation--active" : ""
				}`}
			>
				<div className="side-navigation__inner-container">
					{props.navigationInformation.map((navigationInformation) => {
						count = count + 1;
						return (
							<SideNavigationSection
								key={count}
								category={navigationInformation.category}
								navigations={navigationInformation.navigations}
							/>
						);
					})}
				</div>
			</nav>
		</React.Fragment>
	);
};

export default SideNavigation;

import React, { useEffect, useState } from "react";
import { TariffRange } from "../../models/Api/PayRange/PayRange";
import PayRangeInput from "../Forms/FormInputs/PayRangeInput";

const TariffInput: React.FC<{
  startRange: number;
  endRange: number;
  tariff: number;
  isDisabled: boolean;
  title: string;
  counter?: number;
  onTariffValueChange: (tariffRange: TariffRange) => void;
}> = (props) => {
  let [startRange, setStartRange] = useState<number>(props.startRange);
  let [endRange, setEndRange] = useState<number>(props.endRange);
  let [tariff, setTariff] = useState<number>(props.tariff);

  useEffect(() => {
    let response: TariffRange = { startRange, endRange, tariff };
    props.onTariffValueChange(response);
  }, [startRange, endRange, tariff]);

  const tariffFieldUpdateHandler = (
    value: any,
    callBack: (data: number) => void
  ) => {
    let updatedValue: number = Number.parseFloat(value);
    callBack(updatedValue);
  };

  return (
    <div>
      <h6>
        {props.title} {props.counter}
      </h6>
      <PayRangeInput
        disabled={props.isDisabled}
        key={0}
        counter={0}
        onStartingRangeChange={(e) => {
          if (e.currentTarget != null && e.currentTarget != undefined) {
            tariffFieldUpdateHandler(e.currentTarget.value, setStartRange);
          }
        }}
        onEndRangeChange={(e) => {
          if (e.currentTarget != null && e.currentTarget != undefined) {
            tariffFieldUpdateHandler(e.currentTarget.value, setEndRange);
          }
        }}
        onTariffChange={(e) => {
          if (e.currentTarget != null && e.currentTarget != undefined) {
            tariffFieldUpdateHandler(e.currentTarget.value, setTariff);
          }
        }}
        tariffRangeValue={{
          endRange: endRange,
          startRange: startRange,
          tariff: tariff,
        }}
      />
    </div>
  );
};

export default TariffInput;

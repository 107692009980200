import React from "react";
import ButtonProps from "../../../models/Props/Button/ButtonProps";

import "./_button.scss";

const Button: React.FC<ButtonProps> = (props) => {
	let className = "button";

	switch (props.theme) {
		case "primary":
			className += !props.isOutlined
				? " button--primary"
				: " button--primary--outlined";
			break;
		case "secondary":
			className += !props.isOutlined
				? " button--secondary"
				: " button--secondary--outlined";
			break;
		case "danger":
			className += !props.isOutlined
				? " button--danger"
				: " button--danger--outlined";
			break;
		case "success":
			className += !props.isOutlined
				? " button--success"
				: " button--success--outlined";
			break;
		case "info":
			className += !props.isOutlined
				? " button--info"
				: " button--info--outlined";
			break;
		case "warning":
			className += !props.isOutlined
				? " button--warning"
				: " button--warning--outlined";
			break;
	}

	if (props.isRounded) {
		className += " button--rounded";
	}

	if (props.size != undefined) {
		className += ` button--${props.size}`;
	}

	if (props.fullWidth !== undefined && props.fullWidth) {
		className = `${className} ${"full-width"}`;
	}

	if (props.isIcon) {
		className += ` button--icon`;
	}

	return (
		<button
			className={`${className} ${props.className}`}
			onClick={props.onClick}
			type={props.type}
			disabled={props.disabled}
			tabIndex={props.tabIndex}
		>
			{props.children}
		</button>
	);
};

export default Button;

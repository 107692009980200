import React from "react";
import OverviewItemProps from "../../models/Props/OverviewItem/OverviewItemProps";

import "./_overview-item.scss";

const OverviewItem: React.FC<OverviewItemProps> = (props) => {
	let iconColor: string = props.iconColor || "default";

	return (
		<div className={`card card__grey ${props.className}`}>
			<div className={`overview-item `}>
				{/* <div className={`overview-item--info ${props.className}`}> */}
				<div className={`overview-item__info`}>
					<h6>{props.title}</h6>
					<div className="overview-item__details-container">
						{props.children}
					</div>
				</div>
				<div>
					<span
						className={`overview-item__icon icon--filled icon--filled--large text--color--${iconColor}`}
						onClick={props.onClick}
					>
						{props.icon}
					</span>
				</div>
			</div>
		</div>
	);
};

export default OverviewItem;

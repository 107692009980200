import React from "react";
import "./_dashboard-container.scss";

export const DashboardSideContainer: React.FC<{}> = (props) => {
  return (
    <aside className="dashboard-container--side-menu">{props.children}</aside>
  );
};

export const DashboardMainContent: React.FC<{ className?: string }> = (
  props
) => {
  return (
    <aside
      className={`dashboard-container__main-content  ${
        props.className != undefined ? props.className : ""
      }`}
    >
      {props.children}
    
    </aside>
  );
};
const DashboardContainer: React.FC<{ className?: string }> = (props) => {
  return (
    <div
      className={`dashboard-container  ${
        props.className != undefined ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default DashboardContainer;

import React from "react";
import FormsProps from "../../models/Props/Forms/FormsProps";
import "./_form.scss";

const FormContainer: React.FC<FormsProps> = (props) => {
	return (
		<form
			className={`form ${props.className != undefined ? props.className : ""}`}
			onSubmit={props.onSubmit}
			onReset={props.onReset}
		>
			{props.formTitle !== undefined && props.formTitle !== "" && (
				<h2 className="form__title">{props.formTitle}</h2>
			)}

			<div className="form__form-inputs">{props.children}</div>
			<div
				className={`form__form-actions ${
					props.callToActionClassName != undefined
						? props.callToActionClassName
						: ""
				}`}
			>
				{props.formActions}
			</div>
		</form>
	);
};

export default FormContainer;

import React, { useEffect, useState } from "react";
import EndpointUrls from "../../../models/Enums/Api/Endpoints";
import { OneColumnGrid } from "../../Layouts/PageStructure/GridColumns";
import Button from "../../ui/Button/Button";
import { MessageInterfaceProps } from "../../../models/Props/Messages/MessageInterfaceProps";
import { useAppSelector } from "../../../hooks/StateHooks";
import { useDispatch } from "react-redux";
import {
	setupFlowboosterSession,
	startFlowboosterSession,
} from "../../../store/Actions/FlowBoosterActions";
import LoadingAnimation from "../../Loading/Loading";
import { flowboosterSliceActions } from "../../../store/FlowboosterSlice";
import MessagingContainer from "./MessagingContainer";
import { PlatformType } from "../../../models/Enums/Platform/PlaformEnum";
import useHttp from "../../../hooks/UseHttp";
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
} from "@microsoft/signalr";

import "./_messagingInterface.scss";
import Card from "../../ui/Card/Card";
import CardInformation from "../../CardInformation/CardInformation";

const MessagingInterface: React.FC<MessageInterfaceProps> = (props) => {
	const isSessionStarted = useAppSelector(
		(state) => state.flowbooster.isSessionStarted
	);
	const currentUser = useAppSelector((state) => state.users.currentUser);
	const isLoading = useAppSelector((state) => state.flowbooster.isLoading);
	const ipAddress = useAppSelector((state) => state.flowbooster.ipAddress);

	const error = useAppSelector((state) => state.flowbooster.error);
	const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
	const {
		isLoading: isFetchReviewerAvailabilityLoading,
		executeGet: fetchReviewersAvailability,
	} = useHttp();
	const [isAllowedToWork, setIsAllowedToWork] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			flowboosterSliceActions.setErrorState({
				errorMessage: "",
				isError: false,
			})
		);
		if (currentUser !== undefined && currentUser.accounts !== undefined) {
			dispatch(setupFlowboosterSession(currentUser?.accounts.id));
		}
	}, []);

	const startSessionHandler = async (event: React.MouseEvent) => {
		event.preventDefault();

		if (currentUser !== undefined) {
			dispatch(
				startFlowboosterSession({
					platform: PlatformType[props.Platform],
					ipAddress: ipAddress,
					isLogin: true,
					userId: currentUser.id,
				})
			);
		}
	};

	const stopSessionHandler = (event: React.MouseEvent) => {
		event.preventDefault();
		if (currentUser !== undefined) {
			dispatch(
				startFlowboosterSession({
					platform: PlatformType[props.Platform],
					ipAddress: ipAddress,
					isLogin: false,
					userId: currentUser.id,
				})
			);
		}
	};

	useEffect(() => {
		const isAllowedToWork = async () => {
			if (currentUser !== undefined) {
				await fetchReviewersAvailability(
					EndpointUrls.CheckReviewerAvailability,
					(apiData) => {
						setIsAllowedToWork(apiData.isSuccessful);
					},
					[{ key: "userId", value: currentUser?.userId }]
				);
			}
		};

		isAllowedToWork();
	}, []);

	const [connection, setConnection] = useState<null | HubConnection>(null);
	useEffect(() => {
		const connect = new HubConnectionBuilder()
			.withUrl(EndpointUrls.SignalRApi)
			.withAutomaticReconnect()
			.build();

		setConnection(connect);

		return () => {
			if (connection !== null) {
				connection.stop();
			}
		};
	}, []);

	useEffect(() => {
		const doConnect = async () => {
			if (connection !== null) {
				if (connection.state !== HubConnectionState.Connected) {
					await connection.start();
				}

				if (connection.state === HubConnectionState.Connected) {
					connection.on("TerminateAllSessions", () => {
						if (currentUser !== undefined) {
							if (currentUser.roles.name.toLowerCase() === "operator") {
								dispatch(
									startFlowboosterSession({
										platform: PlatformType[props.Platform],
										ipAddress: ipAddress,
										isLogin: false,
										userId: currentUser.id,
									})
								);
								window.location.reload();
							}
						}
					});

					connection.on("RefreshAvailableReviewerList", () => {
						if (currentUser !== undefined) {
							if (currentUser.roles.name.toLowerCase() === "operator") {
								window.location.reload();
							}
						}
					});
				}
			}
		};
		doConnect();
	}, [connection]);

	return (
		<React.Fragment>
			<iframe
				src={`${ipAddress}${EndpointUrls.ApplicationWindow}${
					PlatformType[props.Platform]
				}?userId=${props.UserId}`}
				style={{ height: 0, display: "none" }}
			/>
			<OneColumnGrid>
				{isLoading && <LoadingAnimation />}
				{!isSessionStarted && !isLoading && !isAllowedToWork && (
					<div>
						There are not reviewers available. You are not allowed to work at
						this time. Come back later
					</div>
				)}
				{!isSessionStarted && !isLoading && isAllowedToWork && (
					<div className="flowbooster-session">
						<Card theme={"lightBlue"} isDashboardElement={false}>
							<CardInformation
								variant={"normal"}
								header="flowbooster"
								title="Enjoy The Ride!"
							></CardInformation>
							<div className="flowbooster-session__intro">
								<div className="flowbooster-session__intro__text">
									<p>
										The FlowBooster has been developed to help you achieve your
										goal. It will allow you to work at an higher pace than other
										operators. To get started, click on the "Start Session"
										button below and enjoy the experience!
									</p>
								</div>

								<div className="">
									<Button theme={"warning"} onClick={startSessionHandler}>
										<span className="icon--filled">offline_bolt</span>
										<span>Start Session</span>
									</Button>
								</div>
							</div>
						</Card>
					</div>
				)}
			</OneColumnGrid>
			{isSessionStarted && (!isLoading || !isMessageLoading) && (
				<MessagingContainer
					platform={PlatformType[props.Platform]}
					onMessageIsLoading={setIsMessageLoading}
					userId={props.UserId}
					ipAddress={ipAddress}
				/>
			)}
		</React.Fragment>
	);
};

export default MessagingInterface;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { mapApiResponseToAccountModel } from "../../Helpers/AccountHelpers";
import { mapApiResponseToFlowboosterInterface } from "../../Helpers/FlowboosterHelper";
import EndpointUrls from "../../models/Enums/Api/Endpoints";
import {
	FlowboosterInterface,
	FlowboosterRequest,
} from "../../models/Flowbooster/Models";
import { flowboosterSliceActions } from "../FlowboosterSlice";
import { uiSliceActions } from "../UiSlice";
import { loadAccounts } from "./AccountActions";
import { executeGet, executePost } from "./AsyncActions";

export const startFlowboosterSession = createAsyncThunk(
	"flowboosterSession/login",
	async (request: FlowboosterRequest, thunkAPI) => {
		thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(true));

		if (request.ipAddress === "") {
			thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(false));
			thunkAPI.dispatch(
				flowboosterSliceActions.setErrorState({
					errorMessage:
						"Your account has not been associated with any IP. Please contact your system administrator.",
					isError: true,
				})
			);

			return;
		}

		let url = "";
		if (request.isLogin) {
			url = `${request.ipAddress}${EndpointUrls.MessageLogin}`;
		} else {
			url = `${request.ipAddress}${EndpointUrls.MessageLogout}`;
		}
		try {
			await executePost(
				url,
				request.userId,
				(apiData) => {
					let isSuccessful: boolean = apiData.isSuccessful;

					thunkAPI.dispatch(
						flowboosterSliceActions.updateSession(isSuccessful)
					);
					if (!isSuccessful) {
						thunkAPI.dispatch(
							flowboosterSliceActions.setErrorState({
								errorMessage:
									"Could not start the session. Invalid login informations",
								isError: true,
							})
						);
					} else {
						if (!request.isLogin) {
							thunkAPI.dispatch(flowboosterSliceActions.updateSession(false));
						}
					}
					thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(false));
				},
				[{ key: "platform", value: request.platform }],
				false
			);
		} catch (error: any) {
			thunkAPI.dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
			thunkAPI.dispatch(flowboosterSliceActions.updateSession(false));
			thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(false));
		}
	}
);

export const loadMessage = createAsyncThunk(
	"flowboosterSession/loadMessage",
	async (request: FlowboosterRequest, thunkAPI) => {
		thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(true));

		let informationInterface: FlowboosterInterface | undefined = undefined;
		try {
			await executeGet(
				`${request.ipAddress}${EndpointUrls.MessageInterface}`,
				(apiData) => {
					informationInterface = mapApiResponseToFlowboosterInterface(apiData);
					thunkAPI.dispatch(
						flowboosterSliceActions.setInterfaceInformation(apiData)
					);
				},
				[
					{ key: "userId", value: request.userId },
					{ key: "platform", value: request.platform },
				],
				false
			);
			thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(false));
		} catch (error: any) {
			thunkAPI.dispatch(
				uiSliceActions.setErrorState({
					isError: true,
					errorMessage: error.message,
				})
			);
			thunkAPI.dispatch(flowboosterSliceActions.updateSession(false));
			thunkAPI.dispatch(flowboosterSliceActions.setLoadingState(false));
		}

		//return informationInterface;
	}
);

export const checkMessagesLimit = (userId: string) => {
	return async (dispatch: any) => {
		try {
			dispatch(
				executeGet(
					EndpointUrls.CheckMessageLimit,
					(apiResponse) => {
						dispatch(
							flowboosterSliceActions.updateMessageLimit(
								apiResponse.isLimitReached
							)
						);
					},
					[{ key: "userId", value: userId }]
				)
			);
		} catch (error: any) {
			console.log(
				"There is an error fetching the check message limit",
				"FlowboosterActions.checkMessagesLimit"
			);
		}
	};
};

export const setupFlowboosterSession = (accountId: string) => {
	return async (dispatch: any) => {
		dispatch(flowboosterSliceActions.setLoadingState(true));
		try {
			await dispatch(
				loadAccounts(accountId, (apiData) => {
					let account = mapApiResponseToAccountModel(apiData);

					if (account !== undefined) {
						dispatch(flowboosterSliceActions.setIpAddress(account.ipAddress));
					}
				})
			);
			dispatch(flowboosterSliceActions.setLoadingState(false));
		} catch (error: any) {
			dispatch(flowboosterSliceActions.updateSession(false));

			dispatch(flowboosterSliceActions.setLoadingState(false));
		}
	};
};

import AccountOverviewDetailsApiModel from "../models/Api/Accounts/AccountOverviewDetailsApiModel";
import AccountApiModel from "../models/Api/Accounts/AccountsApiModel";
import AccountStatistics from "../models/Api/Accounts/AccountStatistics";

export const mapApiResponseToAccountModel = (data: any) => {
	if (data === undefined || data === null) {
		return undefined;
	}
	let account: AccountApiModel = {
		accountNumber: data.accountNumber,
		accountPassword: data.accountPassword,
		id: data.id,
		emailAccount: data.emailAccount,
		emailPassword: data.emailPassword,
		skypeAccount: data.skypeAccount,
		skypePassword: data.skypePassword,
		dateAdded: data.dateAdded,
		dateModified: data.dateModified,
		accountType: data.accountType,
		ipAddress: data.ipAddress,
		clientId: data.clientId,
		serverId: data.serverId,
	};

	return account;
};

export const mapResponseToApiModelList: (
	data: any
) => AccountApiModel[] | undefined = (data) => {
	if (data === undefined || data === null) {
		return undefined;
	}

	let accountApiModelList: AccountApiModel[] = [];

	for (const key in data) {
		let account = mapApiResponseToAccountModel(data[key]);
		if (account !== undefined) {
			accountApiModelList = accountApiModelList.concat(account);
		}
	}

	if (accountApiModelList.length > 0) {
		return accountApiModelList;
	}
	return undefined;
};

export const mapResponseToAccountOverviewDetailsApiModel: (
	data: any
) => AccountOverviewDetailsApiModel | undefined = (data: any) => {
	if (data === undefined || data === null) {
		return undefined;
	}

	let mappedResponse: AccountOverviewDetailsApiModel = {
		totalSalary: data.totalSalary,
		totalAmountOfMessagesSent: data.totalAmountOfMessagesSent,
		totalEarnings: data.totalEarnings,
		applicableTariff: data.applicableTariff,
		otherExpenses: data.otherExpenses,
		weekNumber: data.weekNumber,
		accountName: data.accountName,
		accountId: data.accountId,
		accountTypeName: data.accountTypeName,
		id: data.id,
	};

	return mappedResponse;
};

export const mapResponseToAccountOverviewDetailsApiModelList: (
	data: any
) => AccountOverviewDetailsApiModel[] | undefined = (data: any) => {
	if (data === undefined) {
		return undefined;
	}

	let response: AccountOverviewDetailsApiModel[] = [];

	response = data.map((t: any) =>
		mapResponseToAccountOverviewDetailsApiModel(t)
	);

	return response;
};

export const mapResponseToAccountStatistics: (
	data: any
) => AccountStatistics | undefined = (data) => {
	if (data === undefined || data === null) {
		return undefined;
	}

	return {
		messagesSent: data.messagesSent,
		weekNumber: data.weekNumber,
		year: data.year,
		accountId: data.accountId,
		accountName: data.accountName,
	};
};
